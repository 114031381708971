import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { IDefinition } from '@shared/interfaces/definition.interface';
import { DefinitionsQuery } from '@shared/state/definitions/definitions.query';

@Component({
  selector: 'app-alarm-view',
  templateUrl: './alarm-view.component.html',
  styleUrls: ['./alarm-view.component.scss'],
})
export class AlarmViewComponent implements OnInit {
  @Input()
  public alarm: any;

  public comment: string;

  public definition$ = this.definitionQuery.unitConfigs$;

  constructor(private modalService: ModalService, private definitionQuery: DefinitionsQuery) {}

  ngOnInit(): void {
      this.definition$.subscribe((definitions: IDefinition[]) => {
        var definitionFile = definitions.find((x) => x.ecuId === this.alarm.ecuId && x.errorCodeDefinitionFile?.errorCodeList[0].comment !== undefined)
        if ('alarmCode' in this.alarm) {
          var error = definitionFile?.errorCodeDefinitionFile?.errorCodeList.find((x) => x.errorCodeId === this.alarm.alarmCode);
        } else {
          var error = definitionFile?.errorCodeDefinitionFile?.errorCodeList.find((x) => x.errorCodeId === this.alarm.errorCodeId);
        }
        this.comment = error.comment ? error.comment : error.Name;
      });
  }

  public closeModal(): void {
    this.modalService.dismissModal('AlarmViewComponent');
  }
}
