import { Component, OnInit } from '@angular/core';

import { IDevice, IPreviousDevice } from '@interfaces/device.interface';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { BleCapacitorService } from '@services/ble/ble-capacitor.service';
import { alarms } from '@shared/constants/mock';
import { IAlarm } from '@shared/interfaces/alarm.interface';
import { ToastService } from '@shared/services/toast.service';
import { AlarmQuery } from '@shared/state/alarm/alarm.query';
import { AlarmService } from '@shared/state/alarm/alarm.service';

import { DeviceQuery } from '@state/device/device.query';
import { DeviceService } from '@state/device/device.service';
import { element } from 'protractor';

@Component({
  selector: 'app-ble-devices',
  templateUrl: './ble-devices.component.html',
  styleUrls: ['./ble-devices.component.scss'],
})
export class BleDevicesComponent implements OnInit {
  public activeView = true;

  private scannedDevices: string[] = [];

  public isScanning$ = this.deviceQuery.isScanning$;

  public scanResult$ = this.deviceQuery.scanResult$;

  public devicesInRange$ = this.deviceQuery.devicesInRange$;

  public previousDevices$ = this.deviceQuery.previousDevices$;

  constructor(
    private bleCapacitorService: BleCapacitorService,
    private deviceService: DeviceService,
    private deviceQuery: DeviceQuery,
    private alarmService: AlarmService,
    private alarmQuery: AlarmQuery,
    private alertController: AlertController,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // Fix for akita expressionchangedafterithasbeencheckederror
    setTimeout(() => {
      this.startScan();
    }, 10);
  }

  public async startScan(): Promise<void> {
    this.deviceService.resetScanResult();
    this.scannedDevices = [];
    // const connectedDevices = await this.bleCapacitorService.startScan();
    // if (connectedDevices?.length > 0) {
    //   this.bleCapacitorService
    //     .isConnected(connectedDevices[0].address)
    //     .then((isConnected) => {
    //       if (isConnected.isConnected) {
    //         this.bleCapacitorService.close(connectedDevices[0].address);
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // }
    // this.bleCapacitorService.isScanning().then(async (isScanning) => {
    //   if (isScanning.isScanning) {
    //     await this.bleCapacitorService.stopScan();
    //   }
    this.bleCapacitorService.startScan().subscribe((scanStatus) => {
      // if (scanStatus.status === 'scanResult') {
      if (!this.scannedDevices.includes(scanStatus.id)) {
        this.scannedDevices.push(scanStatus.id);
        this.deviceService.addScanResult(scanStatus);
      }
      // }
    });
    // });
    setTimeout(() => {
      this.stopScan();
    }, 10000);
  }

  public connectDevice(device: any): void {
    this.stopScan();
    this.bleCapacitorService
      .isConnected(device.id)
      .then((isConnected) => {
        if (isConnected.isConnected) {
          this.bleCapacitorService.close().then(() => {
            this.connect(device);
          });
        } else {
          this.connect(device);
        }
      })
      .catch((err) => {
       // console.log('err: ', err);
        this.connect(device);
      });
  }


  public viewOfflineDevice(device: IPreviousDevice): void {
   // console.log('viewOfflineDevice');
    this.connect(device);
  }

  private stopScan() {
    this.bleCapacitorService.stopScan();
    // this.bleCapacitorService.isScanning().then((isScanning) => {
    //   if (isScanning.isScanning) {
    //     this.bleCapacitorService.stopScan();
    //   }
    // });
  }
  private connect(device: any): void {
    this.bleCapacitorService.connectToDevice(device.id, device.name);
    this.closeModal();
  }

  // TESTED TO MOVE TO nleCapacitorService
  private subscribeToAlarms() {
    this.alarmQuery.alarms$.subscribe((alarms) => {
      if (alarms.length > 0) {
        alarms.forEach(alarm => {
          if (alarm && alarm.isOldAlarm === false) {
            this.showNewAlarm(alarm);
            this.alarmService.setAlarmToOld(alarm)
          }
        })
      }
    });
  }

  public async showNewAlarm(alarm: IAlarm): Promise<void> {
    //console.log(alarm);

    const dismiss = this.translateService.instant('alerts.dismiss');
    const later = this.translateService.instant('alerts.later');
    const alert = await this.alertController.create({
        header: "New Alarm",
        subHeader: "Name: " + alarm.Name + ", ECU ID: " + alarm.ecuId + ", Error Code ID: " + alarm.errorCodeId,
        message: alarm.comment,
        cssClass: "alert-wrapper",
        keyboardClose: false,
        backdropDismiss: false,
        buttons: alarm.alarmInfoList.AckAlarm ? [
            {
                text: dismiss,
                role: 'dismiss',

            },
            {
                text: later,
                role: 'later',
            }
        ] :
            [
                {
                    text: 'OK',
                    role: 'ok',
                }
            ],
    });
    
    alert.present();

    alert.onDidDismiss().then(async (res: { data: {}; role: 'dismiss' | 'later' | 'ok' }) => {
       // console.log('alert res: ', res);
        switch (res.role) {
            case 'dismiss':
                this.alarmService.removeAlarm(alarm);
                break;
            case 'later':
                this.toastService.dismisstoast();
                this.toastService.infoToast('toasts.alarm dismiss later', true);
                break;
            case 'ok':
                this.toastService.dismisstoast();
            default:
                break;
        }
    });
}



  public closeModal(): void {
    this.deviceService.closeModal();
  }

  public updateDevicedata(device: IPreviousDevice): void {
    this.deviceService.resetDeviceData();
    this.deviceService.updateDeviceData(device.tcuAddress, device.ccuAddress,device.hmiAddress );
    this.closeModal();
  }
}
