import { Component,  OnInit } from '@angular/core';
import { alarms } from '@constants/mock';
import { IAlarm } from '@interfaces/alarm.interface';
import { WorkerService } from '@shared/worker/worker.service';
import { WorkerMessage } from '@shared/worker/models/worker-message.model';
import { WORKER_TOPIC } from '@shared/worker/models/worker-topic.constants';
import { AlarmQuery } from '@shared/state/alarm/alarm.query';
import { AlarmService } from '@shared/state/alarm/alarm.service';
import { IAlarmLog } from '@shared/interfaces/alarm-log.interface';
import { UserQuery } from '@shared/state/user/user.query';
import { RemoteUserQuery } from '@state/remote-user/remote-user.query';
import { DeviceQuery } from '@shared/state/device/device.query';
import { RemoteSupportService } from '@shared/services/remote-support.service';
import { Browser } from '@capacitor/browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-alarm-log',
  templateUrl: './alarm-log.component.html',
  styleUrls: ['./alarm-log.component.scss'],
})
export class AlarmLogComponent implements OnInit {
  public dateFormat: Intl.DateTimeFormatOptions = {month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'};
  public lang = navigator.language;
  public alarmLog: IAlarmLog[] = [];
  public alarms: IAlarm[] = alarms;
  public pendingAlarmLogCount$ = this.alarmQuery.pendingAlarmLog$;
  public isOperator$ = this.userQuery.isOperator$;
  public isDeveloper$ = this.userQuery.isDeveloper$;
  public isSuperAdmin$ = this.userQuery.isSuperAdmin$;
  public isAdmin$ = this.userQuery.isAdmin$;
  public isLocalAdmin$ = this.userQuery.isLocalAdmin$;
  public serviceToolHasBleConnection$ = this.userQuery.serviceToolHasBleConnection$;
  public clearType = false;
  public loadDone = false;
  public alarmLog$ = this.alarmQuery.alarmLog$;
  public connectedUserUid$ = this.remoteUserQuery.connectedUserUid$;
  public notConnectedToDevice$ = this.userQuery.notConnectedToDevice$;

  public systemType$ = this.deviceQuery.systemType$;
  constructor(
    private workerService: WorkerService,
    private alarmQuery: AlarmQuery,
    private alarmService: AlarmService,
    private userQuery: UserQuery,
    private deviceQuery: DeviceQuery,
    private remoteSupportService: RemoteSupportService,
    private router: Router,
    private remoteUserQuery: RemoteUserQuery,
  ) { }

  ngOnInit(): void {
    if ((!this.userQuery.isOperator && !this.userQuery.isDeveloper && !this.userQuery.serviceToolHasBleConnection) || (this.userQuery.notConnectedToDevice)) {
      
      this.getAlarmLogServiceTool();
    } else {
      const setupMessage = new WorkerMessage(WORKER_TOPIC.getAlarmLog, {});
      this.workerService.doWork(setupMessage);
    }

    this.alarmQuery.alarmLog$.subscribe(alarmLog => {
      this.alarmLog = [...alarmLog.sort((a, b) =>
        (a.hoursSinceLast === 0 && a.hoursSinceLast === b.hoursSinceLast) ?
          a.secondsSinceLast - b.secondsSinceLast : a.hoursSinceLast - b.hoursSinceLast)];

      if (this.userQuery.isOperator || this.userQuery.isDeveloper) {
        this.alarmLog = [...this.alarmLog.filter(x => !x.tempErased)]
      }
    })

    // this.alarmService.updatePendingAlarmLog(true);
    setTimeout(() => {
      this.loadDone = true;
    }, 4000);
  }

  public async clearLog() {
    if (this.clearType) {
      await this.remoteSupportService.confirmDeepAlarmLogClear().then(res => {
        if (res === 'accepted') {
         
          this.alarmService.clearLog('deep');
        } else {
          console.log('Permanent alarm log clear declined');
        }
      })
    } else {
      this.alarmService.clearLog('temp');
    }

  }

  private getAlarmLogServiceTool() {
    this.alarmService.sendGetAlarmLogRequest();
  }

  public async clearLogServiceTool(): Promise<void> {
    if (this.clearType) {
      await this.remoteSupportService.confirmDeepAlarmLogClear().then(res => {
        if (res === 'accepted') {
         
          this.alarmService.sendClearAlarmLogRequest('deep');
        } else {
          console.log('Permanent alarm log clear declined');
        }
      })
    } else {
      this.alarmService.sendClearAlarmLogRequest('temp');
    }
  }


  public async handleAlarmInfoClick(alarm: IAlarmLog) {
    await Browser.open({ url: `https://parts.rototilt.com/AssertWeb/auto/Rototilt/Document/000${alarm.alarmCode}/Identity` });
  }

  public goBack() {

    if (this.userQuery.isOperator && !(this.userQuery.serviceToolHasBleConnection) ){
        this.router.navigate(['/alarm']);
    }else if ((this.userQuery.serviceToolHasBleConnection)) {
      this.systemType$.subscribe(item => {
        if(item){
          this.router.navigate(['system/', item, this.userQuery.userUid,])
        }
      }) 
      
    }else if(this.userQuery.notConnectedToDevice){
      this.systemType$.subscribe(item => {
        if(item === 'TCS'){
          
          this.router.navigate(['/offline', item, this.deviceQuery.currentDevice.uid , 'TcsDashboard'])
        } else {
          this.router.navigate(['/offline', item, this.userQuery.userUid, 'service',])
        }
    })}
  }
}
