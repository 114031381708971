export const alarmTranslations = {
    "hmiBuildNumber": "2305122",
    "alarm names": {
        "1": {
            "1": {
                "GB": "Low supply voltage",
                "SE": "Låg matningsspänning",
                "DE": "Niedrige Speisespannung",
                "FR": "Basse tension d’aliment.",
                "FI": "Matala syöttöjännite",
                "DK": "Lav forsyningsspænding",
                "NO": "Lav forsyningsspenning",
                "LV": "Zems barošanas spriegums",
                "NL": "Lage voedingsspanning",
                "CZ": "Nízké napájecí napětí",
                "PL": "Niskie napięcie zasilania",
                "EE": "Madal toitepinge",
                "PT": "Tensão alimentação baixa",
                "ES": "Tensión de alimentación baja",
                "IT": "Bassa tens. di alimentaz.",
                "LT": "Žema maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "2": {
                "GB": "High supply voltage",
                "SE": "hög matningsspänning",
                "DE": "Hohe Speisespannung",
                "FR": "Haute tension d’aliment.",
                "FI": "Korkea syöttöjännite",
                "DK": "Høj forsyningsspænding",
                "NO": "Høy forsyningsspenning",
                "LV": "Augsts barošanas sprieg.",
                "NL": "Hoge voedingsspanning",
                "CZ": "Vysoké napájecí napětí",
                "PL": "Wys. napięcie zasilania",
                "EE": "Kõrge toitepinge",
                "PT": "Tensão alimentação alta",
                "ES": "Tensión de alimentación alta",
                "IT": "Alta tens. di alimentaz.",
                "LT": "Aukšta maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "3": {
                "GB": "Incompatible SW versions",
                "SE": "Inkompatibel mjukvara",
                "DE": "SW-Versionen inkompatibel",
                "FR": "Versions de logiciel incompatibles",
                "FI": "Kelpaamattomat SW-versiot",
                "DK": "Inkompatible SW-versioner",
                "NO": "Inkompatible programvareversjoner",
                "LV": "Nesaderīgas programmat. versijas",
                "NL": "Incompatibele sw-versies",
                "CZ": "Nekompatibilní verze SW",
                "PL": "Niezgodne wersje SW",
                "EE": "Ühildumatud SW-versioonid",
                "PT": "Versões SW incompatíveis",
                "ES": "Incompatibles versiones SW",
                "IT": "Versioni SW incompatibili",
                "LT": "Nesuderinamos pr. įrangos versijos",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "4": {
                "GB": "Mismatching parameter pair",
                "SE": "Parameterpar stämmer inte överens",
                "DE": "Nicht übereinstimmend Parameterpaar",
                "FR": "Paire de paramètres non concordante",
                "FI": "Yhteensopimaton parametripari",
                "DK": "Ikke-matchende parameterpar",
                "NO": "Parameterpar som ikke samsvarer",
                "LV": "Neatbilstošs parametru pāris",
                "NL": "Mismatchend parameterpaar",
                "CZ": "Neshoda páru parametrů",
                "PL": "Niedopasowanie pary parametrów",
                "EE": "Kokkusobimatu parameetrite paar",
                "PT": "Par de parâmetros incompatível",
                "ES": "Parámetros no coinciden",
                "IT": "Coppia di parametri non corrisp.",
                "LT": "Nesutampančių parametrų pora",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "5": {
                "GB": "Firmware update in progress",
                "SE": "Mjukvaruuppdatering pågår",
                "DE": "Firmware-Update wird ausgeführt",
                "FR": "Mise à jour du firmware en cours",
                "FI": "Laiteohjelmistoa päivitetään",
                "DK": "Firmwareopdatering er i gang",
                "NO": "Fastvareoppdatering pågår",
                "LV": "Notiek aparātprogrammat.atjaunināš.",
                "NL": "Firmware-update bezig",
                "CZ": "Probíhá aktualizace firmwaru",
                "PL": "Aktualizacja firmware w toku",
                "EE": "Püsivara värskendus pooleli",
                "PT": "Atualização de firmware em curso",
                "ES": "Actualización de programa en curso",
                "IT": "Aggiornamento firmware in corso",
                "LT": "Vykdomas pr. įrangos atnaujinimas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "6": {
                "GB": "System restart needed",
                "SE": "Systemet behöver startas om",
                "DE": "Systemneustart erforderlich",
                "FR": "Le système doit être redémarré",
                "FI": "Uudelleenkäynnistys tarpeen",
                "DK": "System genstart nødvendig",
                "NO": "Systemet må startes på nytt",
                "LV": "Nepieciešama sistēmas atsāknēšana",
                "NL": "System reboot nodig",
                "CZ": "Nutné restartovat systém",
                "PL": "Wymagany restart systemu",
                "EE": "On vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. do sistema",
                "ES": "Es necesario reiniciar el sistema",
                "IT": "Riavvio del sistema necessario",
                "LT": "Reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "7": {
                "GB": "System restart needed after SW update",
                "SE": "Systemet behöver startas om efter mjukvaruuppdatering",
                "DE": "Nach dem SW-Update ist ein Neustart des Systems erforderlich",
                "FR": "Redémarrage du système requis après la mise à jour logicielle",
                "FI": "Ohjelmistopäivityksen jälkeen järjestelmä on käynnistettävä uudelleen",
                "DK": "Systemgenstart nødvendig efter SW opdatering",
                "NO": "Systemet må startes på nytt når SW er oppdatert",
                "LV": "Pēc SW atjaunināšanas nepieciešama sistēmas pārstartēšana",
                "NL": "Het systeem moet na SW-update opnieuw opstarten worden",
                "CZ": "Po aktualizaci SW je nutné restartovat systém",
                "PL": "Wymagany restart syst. po aktualizacji SW",
                "EE": "Pärast tarkvarauuendust on vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. após a atualização de SW",
                "ES": "Es necesario reiniciar el sistema después de actualizar SW",
                "IT": "Riavvio del sistema necessario dopo l'aggiornamento del SW",
                "LT": "Po programinės įrangos atnaujinimo reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "8": {
                "GB": "Roller active at start",
                "SE": "Rulle aktiv vid start",
                "DE": "Rolle beim Start aktiv",
                "FR": "Roller actif au démarrage",
                "FI": "Rulla aktiivinen käynnistettäessä",
                "DK": "Rulle aktiv ved start",
                "NO": "Rulle er aktiv fra start",
                "LV": "Rullītis darbojas, ieslēdzot sistēmu",
                "NL": "Rol actief bij start",
                "CZ": "Váleček aktivní při startu",
                "PL": "Rolka aktywna przy rozruchu",
                "EE": "Rull aktiivne alguses",
                "PT": "Rolo ativo no início",
                "ES": "Rótula activa en el inicio",
                "IT": "Selettore attivo all’avvio",
                "LT": "Paleidimo metu ratukas yra aktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "9": {
                "GB": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "SE": "Reservdel ansluten.\nUppdatera programvara och parametrar via Rototilt Connect appen",
                "DE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FI": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "DK": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NO": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LV": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "CZ": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "EE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "ES": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "KR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "JP": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IS": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App"
            },
            "11": {
                "GB": "Rotor sensor circuit, no pulses",
                "SE": "Rotationssensor, inga pulser",
                "DE": "Rotorsensorkreis, keine Impulse",
                "FR": "Circuit capteur rotor, pas d’impulsions",
                "FI": "Kiertoanturipiiri, ei pulsseja",
                "DK": "Rotorsensorkredsløb, ingen impulser",
                "NO": "Rotorsensorkrets, ingen pulser",
                "LV": "Rotora sensora ķēde, nav impulsu",
                "NL": "Sensorcircuit rotor, geen pulsen",
                "CZ": "Obvod senzor rotoru, bez impulzů",
                "PL": "Obwód czujnika wirnika, brak impulsów",
                "EE": "Rootori anduri ahel, impulsid puuduvad",
                "PT": "Circuito sensor rotor, sem impulsos",
                "ES": "Circuito del sensor del rotor, sin pulsaciónes",
                "IT": "Circuito sensore rotore, nessun impulso",
                "LT": "Rotoriaus jut. grandinė, impulsų nėra",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "12": {
                "GB": "Index sensor circuit, error",
                "SE": "Indexsensor, inga pulser",
                "DE": "Indexsensorkreis, Fehler",
                "FR": "Circuit capteur index, erreur",
                "FI": "Indeksianturipiiri, virhe",
                "DK": "Indekssensorkredsløb, fejl",
                "NO": "Indekssensorkrets, feil",
                "LV": "Indeksa sensora ķēde, kļūda",
                "NL": "Index sensorcircuit, fout",
                "CZ": "Obvod senzor indexu, chyba",
                "PL": "Obwód czujnika indeksującego, błąd",
                "EE": "Indeksi anduri ahel, viga",
                "PT": "Índice de circuito do sensor, erro",
                "ES": "Circuito del sensor del índice, error",
                "IT": "Circuito sensore indice, errore",
                "LT": "Indekso jutiklio grandinė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "13": {
                "GB": "Auto setup failed",
                "SE": "Autosetup fallerade",
                "DE": "Auto-Setup fehlgeschlagen",
                "FR": "Échec config. auto",
                "FI": "Autom. asetus epäonn.",
                "DK": "Autoopsætning fik fejl",
                "NO": "Feil under autooppsett",
                "LV": "Autom.iestatīš. neizdevās",
                "NL": "Auto setup mislukt",
                "CZ": "Auto nastavení selhalo",
                "PL": "Błąd auto konfiguracji",
                "EE": "Autom. seadist. nurjus",
                "PT": "Falha config. automática",
                "ES": "Auto setup ha fallado",
                "IT": "Config. autom. non riusc.",
                "LT": "Aut. nustatymas nepavyko",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "14": {
                "GB": "Pressure sensor out of range",
                "SE": "Trycksensor utanför område",
                "DE": "Drucksensor außerhalb des Bereichs",
                "FR": "Capteur de pression hors de portée",
                "FI": "Paineanturi ei yhteyttä",
                "DK": "Tryksensor udenfor område",
                "NO": "Trykksensor er utenfor rekkevidde",
                "LV": "Spiediena sensors ārpus diapazona",
                "NL": "Druksensor buiten bereik",
                "CZ": "Snímač tlaku mimo dosah",
                "PL": "Czujnik ciśnienia poza zasięgiem",
                "EE": "Rõhuandur ulatusest väljas",
                "PT": "Sensor de pressão fora de gama",
                "ES": "Sensor de presión fuera de tolerancia",
                "IT": "Sensore di pressione fuori portata",
                "LT": "Slėgio jutiklis už diapazono ribų",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "20": {
                "GB": "Pressure sensor is disabled!\nCplr.function not fully verified\n\nService immediately!",
                "SE": "Tryckgivaren är avstängd!\nLåsfunktion ej fullt verifierbar\n\nKräver omedelbar åtgärd!",
                "DE": "Drucksensor ist deaktiviert!\nKopplerfunktion nicht vollständig gesichert. \nSofort Service ausführen!",
                "FR": "Le capteur de pression est désactivé!\nLa fonction de l’attache n’est pas entièrement vérifiée.  Effectuez immédiatement l’entretien !",
                "FI": "Paineanturi ei ole käytössä!\nKytkimen toiminta ei ole täysin varmistettu. Korjaa välittömästi!",
                "DK": "Tryksensor er deaktiveret!\nFæstefunktionen er ikke fuldt verificeret. Udfør straks service!",
                "NO": "Trykksensor er deaktivert!\nFestefunksjonen er ikke fullstendig kontrollert. Gjennomfør service umiddelbart!",
                "LV": "Spiediena sensors ir atspējots!\nSavienotāja darbība nav pilnībā pārbaudīta. Nekavējoties veiciet apkopi!",
                "NL": "Druksensor is uitgeschakeld!\nKoppelingsfunctie niet volledig gecontroleerd. Onderhoud is direct nodig!",
                "CZ": "Tlakový snímač je deaktivován!\nFunkce spojky není plně ověřena. Ihned proveďte servis!",
                "PL": "Czujnik ciśnienia wyłączony!\nFunkcja łącz. nie zweryfikowana. Natychmiast przegląd!",
                "EE": "Rõhuandur on blokeeritud!\nHaakeseadise funktsioon ei ole täielikult kontrollitud. Tehke kohe hooldus!",
                "PT": "Sensor de pressão desativado!\nFunção de acoplador não total verifica. Manutenção imediata!",
                "ES": "Sensor de presión desactivado!\nFunction acoplador no verificado Revisión inmediata!",
                "IT": "Il sensore di pressione è disabilitato!\nFunzione dell’attacco non completamente verificata. Assistenza immediata!",
                "LT": "Slėgio jutiklis yra išjungtas!\nJungiamosios movos funkcija nėra iki galo patikrinta. Kuo greičiau atlikite techninę priežiūrą!",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "22": {
                "GB": "Quick Coupler sensor disabled",
                "SE": "Redskapslåssensorn är avstängd!",
                "DE": "Schnellwechslersensor deaktiviert",
                "FR": "Capteur de pression désactivé",
                "FI": "Työlaitekiinnikeanturi poissa käytöstä",
                "DK": "Redskabsfæstesensor deaktiveret",
                "NO": "Redskapsfestesensor deaktivert",
                "LV": "ātrais savienotājs sensors ir atspējots",
                "NL": "Quick Coupler-sensor uitgeschakeld",
                "CZ": "Rychlospojka senzor zakázáno",
                "PL": "Szybkozłącze czujnik jest wyłączony",
                "EE": "Kiirühendus andur puudega",
                "PT": "Acopl. Rápido sensor desativado",
                "ES": "Sensor del acoplador rápido desactivado",
                "IT": "Attacco rapido sensore disabilitato",
                "LT": "Greito suj. mova jutiklis yra išjungtas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "23": {
                "GB": "Tiltrotator coupler is open!",
                "SE": "Redskapsfästet är öppet!",
                "DE": "Schnellwechsler ist geöffnet!",
                "FR": "Attache rapide ouverte !",
                "FI": "Rototilttikytkin on auki!",
                "DK": "Redskabsfæste er åbent!",
                "NO": "Redskapsfeste er åpent!",
                "LV": "ātrais savienotājs ir atvērta",
                "NL": "Snelwissel is open!",
                "CZ": "Rychlospojky je otevřená",
                "PL": "Szybkozłącze jest otwarte",
                "EE": "Kiirühendaja on avatud",
                "PT": "Acoplador rápido está aberta!",
                "ES": "Acoplador del rotor basculante abierto!",
                "IT": "Attacco rapido è aperta!",
                "LT": "Tiltrotatoriaus atidaryta jungtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "24": {
                "GB": "The tool is not properly attached!",
                "SE": "Redskapet är inte kopplat!",
                "DE": "Das Anbaugerät ist nicht richtig befestigt!",
                "FR": "L’outil n’est pas attaché correctement !",
                "FI": "Työlaitteen kiinnitys virheellinen!",
                "DK": "Redskabet er ikke korrekt fastgjort!",
                "NO": "Verktøyet er ikke festet på riktig måte!",
                "LV": "Darba rīks nav pareizi piestiprināts!",
                "NL": "Het uitrustingsstuk zit niet goed vast!",
                "CZ": "Pracovní nástroj není správně připojen!",
                "PL": "Narzędzie rob. jest błędnie zamocowane!",
                "EE": "Töövahend ei ole korralikult kinnitatud!",
                "PT": "Ferramenta de trabalho não está ligada!",
                "ES": "¡La herramienta no está sujeta correctamente!",
                "IT": "L’attrezzo da lavoro non è ben collegato",
                "LT": "Netinkamai pritvirtintas darbo įrankis!",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "26": {
                "GB": "Quick Coupler sensor out of range",
                "SE": "Redskapslåssensor utanför område!",
                "DE": "Schnellwechslersensor außerhalb d. Bereichs",
                "FR": "Capteur attache rapide hors de portée",
                "FI": "Työlaitekiinnikeanturiin ei yhteyttä",
                "DK": "Redskabsfæstesensor uden for område",
                "NO": "Redskapsfestesensor er utenfor rekkevidde",
                "LV": "ātrais savienotājs sensors ārpus diapazona",
                "NL": "Quick Coupler sensor buiten bereik",
                "CZ": "Rychlospojka senzor mimo dosah",
                "PL": "Szybkozłącze czujnik poza zakresem",
                "EE": "Kiirühendusandur ulatusest väljas",
                "PT": "Acopl. Rápido sensor fora da faixa",
                "ES": "Sensor del acoplador rápido fuera de tolerancia",
                "IT": "Sensore attacco rapido fuori portata",
                "LT": "Greito suj. mova jutiklis už diapazono ribų",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "27": {
                "GB": "Quick Coupler blocked until restart",
                "SE": "Redskapslåset är blockerat tills omstart.",
                "DE": "Schnellwechsler bis zum Neustart blockiert",
                "FR": "Attache rapide bloquée jusqu’au redémarrage",
                "FI": "Työlaitekiinnike estetty, käynnistä uudelleen",
                "DK": "Redskabsfæste blokeret indtil genstart",
                "NO": "Redskapsfestet er blokkert til omstart",
                "LV": "ātrais savienotājs bloķēts līdz restartēšanai",
                "NL": "Quick Coupler geblokkeerd tot herstart",
                "CZ": "Rychlospojka zablokována až do restartu",
                "PL": "Szybkozłącze zablok. aż do restartu",
                "EE": "Kiirühendaja kuni taaskäiv. blokeeritud",
                "PT": "Acopl. Rápido bloqueado até reiniciar",
                "ES": "Acoplador rápido bloqueado hasta reinicio",
                "IT": "Attacco rapido bloccato fino al riavvio",
                "LT": "Greito suj. mova užblokuota iki pakart. paleidimo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "28": {
                "GB": "Coupler blocked due to buzzer failure",
                "SE": "Fel på varningssummer, låsfunktion blockerad",
                "DE": "Koppler aufgrund eines Summerausfalls blockiert",
                "FR": "Attache bloquée car défaillance du ronfleur",
                "FI": "Kytkin estetty summerivian vuoksi",
                "DK": "Fæste blokeret på grund af summerfejl",
                "NO": "Feste er blokkert på grunn av summerfeil",
                "LV": "Savienotājs bloķēts zummera atteices dēļ",
                "NL": "Koppeling geblokkeerd door zoemerstoring",
                "CZ": "Spojka zablokována kvůli poruše bzučáku",
                "PL": "Łącznik zablokowany z powodu awarii brzęczyka",
                "EE": "Haakeseadis on sumisti rikke tõttu blokeeritud",
                "PT": "Acoplador bloqueado devido a falha no vibrador",
                "ES": "Acoplador bloqueado, fallo del zumbador",
                "IT": "Attacco bloccato per guasto della cicalina",
                "LT": "Jungtis užblokuota dėl zirzeklio gedimo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "29": {
                "GB": "Quick Coupler not properly pressurized",
                "SE": "Redskapslåset är inte korrekt trycksatt",
                "DE": "Schnellwechsler nicht richtig unter Druck",
                "FR": "Attache rapide n'est pas correctement pressurisé",
                "FI": "Työlaitekiinnike ei ole kunnolla paineistettu",
                "DK": "Redskabsfæste er ikke sat korrekt under tryk",
                "NO": "Redskapsfestet er ikke satt under riktig trykk",
                "LV": "Ātrajam savienotājam nav pareizs spiediens",
                "NL": "De Quick Coupler is niet goed onder druk",
                "CZ": "Rychlospojka není správně natlakována",
                "PL": "Szybkozłącze pod nieodpowiednim ciśnieniem",
                "EE": "Kiirühendaja pole korralikult survestatud",
                "PT": "Acopl. Rápido não pressurizado corretamente",
                "ES": "Acoplador rápido no presurizado correctamente",
                "IT": "Attacco rapido non pressurizzato correttamente",
                "LT": "Netinkamas greitojo sujungimo jungties slėgis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "31": {
                "GB": "Safety lever activated at start",
                "SE": "Manöverspärr är aktiverad vid uppstart",
                "DE": "Bediensperre beim Start aktiviert",
                "FR": "Levier d'arrêt activé au démarrage",
                "FI": "Käynnistys aktivoi toimintojen eston",
                "DK": "Manøvrespærring aktiveret ved start",
                "NO": "Manøversperre er aktivert ved start",
                "LV": "Palaides laikā droš. svira aktīva",
                "NL": "Veiligheidshendel geactiveerd bij start",
                "CZ": "Bezpečnost páka aktivní při startu",
                "PL": "Dźwignia bezp. aktyw. przy rozruchu",
                "EE": "Turvahoob käivitamisel aktiivne",
                "PT": "Alavanca segurança ativa no início",
                "ES": "Palanca de seguridad activado al inicio",
                "IT": "Leva di sicurezza attiva all’avvio",
                "LT": "Saugos svirtis aktyvi paleidžiant",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "32": {
                "GB": "Safety lever inactive",
                "SE": "Manöverspärr är inaktiv.",
                "DE": "Bediensperre inaktiv",
                "FR": "Levier d'arrêt inactif",
                "FI": "Toimintojen esto ei käytössä",
                "DK": "Manøvrespærring inaktiv",
                "NO": "Manøversperre er inaktiv",
                "LV": "Drošības svira neaktīvs",
                "NL": "Veiligheidshendel inactief",
                "CZ": "Pojistná páka neaktivní",
                "PL": "Dźwignia bezp. nieczynny",
                "EE": "Turvahoova inaktiivne",
                "PT": "Alavancar segurança inativa",
                "ES": "Palanca de seguridad inactivado",
                "IT": "Leva di sicurezza inattiva",
                "LT": "Saugos svirtis neaktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "33": {
                "GB": "Machine Coupler blocked",
                "SE": "Maskinlåstet är blockerat ",
                "DE": "Baggerschnellwechsler blockiert",
                "FR": "Attache rapide machine bloquée",
                "FI": "Pikaliitin estetty",
                "DK": "Hurtigskifte blokeret",
                "NO": "Maskinfestet er blokkert",
                "LV": "Spraudsavienotājs bloķēts",
                "NL": "Machinesnelwissel geblokkeerd",
                "CZ": "Spojka stroje zablokována",
                "PL": "Szybkozłącze koparki zablokowane aż do restartu",
                "EE": "Masina haakeseadis blokeeritud",
                "PT": "Acoplador da máquina bloqueado",
                "ES": "Acoplador de máquina bloqueado",
                "IT": "Attacco macchina bloccato",
                "LT": "Mašinos mova užblokuota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "34": {
                "GB": "Machine control system off-line",
                "SE": "Maskinkontrollsystem offline",
                "DE": "Maschinensteuersystem offline",
                "FR": "Système de contrôle machine hors ligne",
                "FI": "Koneenohjausjärjestelmään ei yhteyttä",
                "DK": "Maskinstyresystem offline",
                "NO": "Maskinens styresystem er frakoblet",
                "LV": "Mašīnas vadības sistēma offline",
                "NL": "Machinebesturingssyst. offline",
                "CZ": "Systém řízení stroje off-line",
                "PL": "Maszyna system sterowania offline",
                "EE": "Masina juhtimissüsteem offline",
                "PT": "Sistema de controle da máquina off-line",
                "ES": "Control de sistema de maquina offline",
                "IT": "Sistema di controllo della macchina offline",
                "LT": "Mašinos valdymo sistema neprisijungusi",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "41": {
                "GB": "Left analog joystick, error",
                "SE": "Vänster analog joystick, error",
                "DE": "Linker Analog-Joystick, Fehler",
                "FR": "Joystick analogique gauche, erreur",
                "FI": "Vasen analoginen ohjain, virhe",
                "DK": "Venstre analoge joystick, fejl",
                "NO": "Venstre analoge styrespak, feil",
                "LV": "Kreisā analogā vadības svira, kļūda",
                "NL": "Linker analoge joystick, fout",
                "CZ": "Levý analogový joystick, chyba",
                "PL": "Lewy uchwyt analog, error",
                "EE": "Vasak analoogjuhtkang, viga",
                "PT": "Joystick analógico esquerdo, erro",
                "ES": "Joystick analógico izquierdo, error",
                "IT": "Joystick analogico sinistro, errore",
                "LT": "Kair. analog. vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "42": {
                "GB": "Right analog joystick, error",
                "SE": "Höger analog joystick, error",
                "DE": "Rechter Analog-Joystick, Fehler",
                "FR": "Joystick analogique droit, erreur",
                "FI": "Oikea analoginen ohjain, virhe",
                "DK": "Højre analoge joystick, fejl",
                "NO": "Høyre analoge styrespak, feil",
                "LV": "Labā analogā vadības svira, kļūda",
                "NL": "Rechter analoge joystick, fout",
                "CZ": "Pravý analogový joystick, chyba",
                "PL": "Prawy uchwyt analog, error",
                "EE": "Parem analoogjuhtkang, viga",
                "PT": "Joystick analógico direito, erro",
                "ES": "Joystick analógico derecho, error",
                "IT": "Joystick analogico destro, errore",
                "LT": "Dešin. analoginė vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "43": {
                "GB": "Left analog joystick, uncalibrated",
                "SE": "Vänster analog joystick, okalibrerad",
                "DE": "Linker Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique gauche, non étalonné",
                "FI": "Vasen analoginen ohjain, ei kalibroitu",
                "DK": "Venstre analoge joystick, ikke kalibreret",
                "NO": "Venstre analoge styrespak, ikke kalibrert",
                "LV": "Kreisā analogā vadības svira, nekalibrēta",
                "NL": "Linker analoge joystick, niet gekalibreerd",
                "CZ": "Levý analogový joystick, nekalibrovaný",
                "PL": "Lewy uchwyt analog, nieskalibrow.",
                "EE": "Vasak analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico esquerdo, não calibrado",
                "ES": "Joystick analógico izquierdo, sin calibrar",
                "IT": "Joystick analogico sinistro, non calibrato",
                "LT": "Kair. analog.vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "44": {
                "GB": "Right analog joystick, uncalibrated",
                "SE": "Höger analog joystick, okalibrerad",
                "DE": "Rechter Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique droit, non étalonné",
                "FI": "Oikea analoginen ohjain, ei kalibroitu",
                "DK": "Højre analoge joystick, ikke kalibreret",
                "NO": "Høyre analoge styrespak, ikke kalibrert",
                "LV": "Labā analogā vadības svira, nekalibrēta",
                "NL": "Rechter analoge joystick, niet gekalibreerd",
                "CZ": "Pravý analogový joystick, nekalibrovaný",
                "PL": "Prawy joystick analogowy, brak kalibracji",
                "EE": "Parem analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico direito, não calibrado",
                "ES": "Joystick analógico derecho, sin calibrar",
                "IT": "Joystick analogico destro, non calibrato",
                "LT": "Dešin. analoginė vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "51": {
                "GB": "Communication timeout, external machine CAN-bus",
                "SE": "Kommunikations-Timeout, bärarmaskin CAN-bus",
                "DE": "Timeout Kommunikation, externe Maschinen und CAN-Bus",
                "FR": "Délai de communication expiré, bus CAN machine externe",
                "FI": "Viestinnän aikakatkaisu, ulkoisen koneen CAN-väylä",
                "DK": "Kommunikationstimeout, ekstern maskine-CAN-bus",
                "NO": "Tidsavbrudd for kommunikasjon, ekstern maskin CAN-buss",
                "LV": "Sakaru taimauts, ārējās mašīnas CAN kopne",
                "NL": "Communicatie time-out, externe machine CAN-bus",
                "CZ": "Časový limit komunikace, externí sběrnice CAN stroje",
                "PL": "Limit czasu komunikacji, magistrala CAN maszyny zewnętrznej",
                "EE": "Side ajalõpp, välise masina CAN-siin",
                "PT": "Tempo de comunicação esgotado, barramento CAN da máquina externa",
                "ES": "Comunicación interrumpido, CAN bus de máquina externa",
                "IT": "Timeout di comunicazione, bus CAN macchina esterna",
                "LT": "Ryšio skirtasis laikas, išorinio įrenginio CAN magistralė",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "52": {
                "GB": "External machine system not in working mode",
                "SE": "Bärarmaskin är inte i rätt driftmode",
                "DE": "Externes Maschinensystem nicht im Betriebsmodus",
                "FR": "Système machine externe pas en mode de fonctionnement",
                "FI": "Ulkoisen koneen järjestelmä ei toimintatilassa",
                "DK": "Eksternt maskinsystem ikke i arbejdstilstand",
                "NO": "Eksternt maskinsystem er ikke i arbeidsmodus",
                "LV": "Ārējās mašīnas sistēma nav darba režīmā",
                "NL": "Extern machinesysteem niet in de werkmodus",
                "CZ": "Externí systém stroje není v pracovním režimu",
                "PL": "Układ maszyny zewnętrznej nie jest w trybie roboczym",
                "EE": "Välise masina süsteem ei ole töörežiimis",
                "PT": "O sistema de máquina externa não está em modo de trabalho",
                "ES": "Externo sistema de máquina no en modo de trabajo",
                "IT": "Sistema macchina est. non in modalità esercizio",
                "LT": "Išorinė įrenginio sistema neveikia",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "53": {
                "GB": "External machine software interface error",
                "SE": "Inkompatibelt mjukvaruinterface mot bärarmaskin",
                "DE": "Externer Softwareschnittstellen-Fehler Maschine",
                "FR": "Erreur d’interface logicielle machine externe",
                "FI": "Ulkoisen koneen ohjelmistoliittymän virhe",
                "DK": "Grænsefladefejl i ekstern maskinsoftware",
                "NO": "Grensesnittfeil for ekstern maskinprogramvare",
                "LV": "Ārējās mašīnas programmatūras interfeisa kļūda",
                "NL": "Fout in interface externe machinesoftware",
                "CZ": "Chyba rozhraní externího softwaru stroje",
                "PL": "Błąd interfejsu oprogramowania maszyny zewnętrznej",
                "EE": "Välise masina tarkvaraliidese viga",
                "PT": "Erro de interface de software de máquina externa",
                "ES": "Interface error, software de máquina externa",
                "IT": "Errore interfaccia software della macchina esterna",
                "LT": "Išorinės įrenginio pr. įrangos sąsajos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "54": {
                "GB": "Conflicting CAN messages detected on CAN-bus to external machine",
                "SE": "Inkompatibla CAN-meddelanden upptäcka på CAN-bus mot extern maskin.",
                "DE": "Konflikt bei CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Conflit de messages CAN détecté sur bus CAN vers machine externe",
                "FI": "Ristiriitaisia CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Modstridende CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Konflikt i CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti konfliktējoši CAN ziņojumi",
                "NL": "Conflicterende CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Konfliktní zprávy CAN zjištěné na sběrnici CAN s externím počítačem",
                "PL": "Sprzeczne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad vastuolulised sõnumid",
                "PT": "Detetado conflito mensagens CAN no barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN conflictivos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN in conflitto sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje aptikti nesuderinami CAN pranešimai išoriniam įreng.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "55": {
                "GB": "Incorrect CAN messages detected on CAN-bus to external machine",
                "SE": "Felaktiga CAN-meddelanden upptäcka på CAN-bus mot extern maskin",
                "DE": "Falsche CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Message incorrect CAN détecté sur bus CAN vers machine externe",
                "FI": "Virheellisiä CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Fejl på CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Feil på CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti nepareizi CAN ziņojumi",
                "NL": "Fouten in CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Na sběrnici CAN k externímu počítači zjištěny nesprávné zprávy CAN",
                "PL": "Błędne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad valed sõnumid",
                "PT": "Detetadas mensagens CAN incorretas barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN erróneos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN errati sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje išoriniam įreng. aptikti neteisingi CAN pranešimai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "71": {
                "GB": "Input mapping invalid",
                "SE": "Insignalsmappning, error",
                "DE": "Eingangsmapping ungültig",
                "FR": "Mappage d’entrée non valide",
                "FI": "Syöttömapitus ei kelpaa",
                "DK": "Kortlægning af input ugyldig",
                "NO": "Ugyldig inndatatilordning",
                "LV": "Ieejas kartēšana nav derīga",
                "NL": "Invoertoewijzing ongeldig",
                "CZ": "Mapování vstupu neplatné",
                "PL": "Mapowanie wejścia nieprawidłowe",
                "EE": "Sisendi kaardistamine kehtetu",
                "PT": "Mapeamento de entrada inválido",
                "ES": "Señal de entrada no válida",
                "IT": "Mapping ingressi non valida",
                "LT": "Įvesties susiejimas netinkamas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "72": {
                "GB": "Parameter setting error",
                "SE": "Felaktig parameter inställning",
                "DE": "Fehler bei der Parametrierung",
                "FR": "Erreur de paramétrage",
                "FI": "Parametrin asetusvirhe",
                "DK": "Parameterindstillingsfejl",
                "NO": "Feil under parameterinnstilling",
                "LV": "Parametru iestatīšanas kļūda",
                "NL": "Fout parameter instellen",
                "CZ": "Chyba nastavení parametrů",
                "PL": "Błąd ustawiania parametrów",
                "EE": "Parameetri seadistamise viga",
                "PT": "Erro de configuração de parâmetros",
                "ES": "Error de configuraciónes del parametro",
                "IT": "Errore impostazione dei parametri",
                "LT": "Parametrų nustatymo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "81": {
                "GB": "Activate double feeder",
                "SE": "Aktivera dubbelshunt",
                "DE": "Doppelfeeder aktivieren",
                "FR": "Activer double alimentateur",
                "FI": "Aktivoi kaksoissyöttö",
                "DK": "Aktivér dobbelt feeder",
                "NO": "Aktiver dobbel mating",
                "LV": "Aktivizēt Dub. Padevēja",
                "NL": "Dubbele feeder activeren",
                "CZ": "Aktivoi dvojit podavač",
                "PL": "Uruch. podwójny bocznik",
                "EE": "Aktiveeri To. ettean.",
                "PT": "Ativar aliment duplo",
                "ES": "Activar alimentador double",
                "IT": "Attiv. doppio alim.",
                "LT": "Suaktyvinti Dvig. tiektuvo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "82": {
                "GB": "Inactivate double feeder",
                "SE": "Inaktivera dubbelshunt",
                "DE": "Doppelfeeder deaktivieren",
                "FR": "Désactiver double alimentateur",
                "FI": "Sammuta kaksoissyöttö",
                "DK": "Inaktiver dobbelt feeder",
                "NO": "Deaktiver dobbel mating",
                "LV": "Deaktivēt dubulto padevēju",
                "NL": "Dubbele feeder deactiveren",
                "CZ": "Deaktivace dvojitého podavače",
                "PL": "Wyłącz podajnik podwójny",
                "EE": "Inaktiveerige topeltetteandur",
                "PT": "Desativar alimentador duplo",
                "ES": "Inactivar alimentador double",
                "IT": "Disattivare doppio alimentatore",
                "LT": "Išjungti dvigubą tiektuvą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "83": {
                "GB": "Double feeder active",
                "SE": "Dubbelshunt är aktiv",
                "DE": "Doppelfeeder aktiv",
                "FR": "Double alimentateur actif",
                "FI": "Kaksoissyöttö aktiivinen",
                "DK": "Dobbelt feeder aktiv",
                "NO": "Dobbel mating aktivert",
                "LV": "Dub. padevēja rež.",
                "NL": "Dubbele feeder actief",
                "CZ": "Dvojit podavač aktivní",
                "PL": "podwójny bocznik aktyw.",
                "EE": "To. ettean. aktiivsed",
                "PT": "Aliment duplo ativos",
                "ES": "Alimentador double activo",
                "IT": "Doppio alim. attivi",
                "LT": "Dvig. tiektuvo aktyvūs",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "91": {
                "GB": "HCU off-line",
                "SE": "HCU, offline",
                "DE": "HCU offline",
                "FR": "HCU hors ligne",
                "FI": "HCU ei yhteyttä",
                "DK": "HCU offline",
                "NO": "HCU frakoblet",
                "LV": "HCU offline",
                "NL": "HCU offline",
                "CZ": "HCU offline",
                "PL": "HCU offline",
                "EE": "HCU offline",
                "PT": "HCU offline",
                "ES": "HCU offline",
                "IT": "HCU offline",
                "LT": "HCU neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "101": {
                "GB": "Short circuit, PWM 01 (Rotation Right)",
                "SE": "Kabelfel, kortsluning, PWM 01 (Rotation Höger)",
                "DE": "Kurzschluss, PWM 01 (Drehung rechts)",
                "FR": "Court-circuit, PWM 01 (Rotation droite)",
                "FI": "Oikosulku, PWM 01 (kierto oikealle)",
                "DK": "Kortslutning, PWM 01 (rotation højre)",
                "NO": "Kortslutning, PWM 01 (rotasjon høyre)",
                "LV": "Īssavienojums, PWM 01 (Rotācijas Labā)",
                "NL": "Kortsluiting, PWM 01 (roteren rechtsom)",
                "CZ": "Zkrat, PWM 01 (Otáčení vlevo)",
                "PL": "Zwarcie, PWM 01 (obrotu prawo)",
                "EE": "Lühis, PWM 01 (Pööramine parem)",
                "PT": "Curto-circuito, PWM 01 (Rotação direita)",
                "ES": "Cortocircuito, PWM 01 (Rotación Derecha)",
                "IT": "Cortocircuito, PWM 01 (Rot. destra)",
                "LT": "Trumpasis jungimas, PWM 01 (Sukimosi dešinė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "102": {
                "GB": "Short circuit, PWM 02 (Rotation Left)",
                "SE": "Kabelfel, kortsluning, PWM 02 (Rotation Vänster)",
                "DE": "Kurzschluss, PWM 02 (Drehung links)",
                "FR": "Court-circuit, PWM 02 (Rotation gauche)",
                "FI": "Oikosulku, PWM 02 (kierto vasemmalle)",
                "DK": "Kortslutning, PWM 02 (rotation venstre)",
                "NO": "Kortslutning, PWM 02 (rotasjon venstre)",
                "LV": "Īssavienojums, PWM 02 (Rotācijas Kreisā)",
                "NL": "Kortsluiting, PWM 02 (roteren linksom)",
                "CZ": "Zkrat, PWM 02 (Otáčení vrapvo)",
                "PL": "Zwarcie, PWM 01 (obrotu Lewo)",
                "EE": "Lühis, PWM 02 (Pööramine vasak)",
                "PT": "Curto-circuito, PWM 02 (Rot. esquerda)",
                "ES": "Cortocircuito, PWM 02 (Rotación Izquierda)",
                "IT": "Cortocircuito, PWM 02 (Rot. sinistra)",
                "LT": "Trumpasis jungimas, PWM 02 (Sukimosi kairė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "103": {
                "GB": "Short circuit, PWM 03 (Extra3 R)",
                "SE": "Kabelfel, kortsluning, PWM 03 (Extra3 H)",
                "DE": "Kurzschluss, PWM 03 (Extra3 R)",
                "FR": "Court-circuit, PWM 03 (Suppl3 D)",
                "FI": "Oikosulku, PWM 03 (ylimääräinen3 O)",
                "DK": "Kortslutning, PWM 03 (ekstra3 H)",
                "NO": "Kortslutning, PWM 03 (ekstra3 H)",
                "LV": "Īssavienojums, PWM 03 (Extra3 R)",
                "NL": "Kortsluiting, PWM 03 (extra3 R)",
                "CZ": "Zkrat, PWM 03 (Extra3 R)",
                "PL": "Zwarcie, PWM 03 (dodatk.3 R)",
                "EE": "Lühis, PWM 03 (Ekstra3 R)",
                "PT": "Curto-circuito, PWM 03  (Extra3 R)",
                "ES": "Cortocircuito, PWM 03 (Extra3 D)",
                "IT": "Cortocircuito, PWM 03 (Extra3 R)",
                "LT": "Trumpasis jungimas, PWM 03 (Extra3 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "104": {
                "GB": "Short circuit, PWM 04 (Extra3 L)",
                "SE": "Kabelfel, kortsluning, PWM 04 (Extra3 V)",
                "DE": "Kurzschluss, PWM 04 (Extra3 L)",
                "FR": "Court-circuit, PWM 04 (Suppl3 G)",
                "FI": "Oikosulku, PWM 04 (ylimääräinen3 V)",
                "DK": "Kortslutning, PWM 04 (ekstra3 V)",
                "NO": "Kortslutning, PWM 04 (ekstra3 V)",
                "LV": "Īssavienojums, PWM 04 (Extra3 L)",
                "NL": "Kortsluiting, PWM 04 (extra3 L)",
                "CZ": "Zkrat, PWM 04 (Extra3 L)",
                "PL": "Zwarcie, PWM 04 (dodatk.3 L)",
                "EE": "Lühis, PWM 04 (Ekstra3 L)",
                "PT": "Curto-circuito, PWM 04  (Extra3 L)",
                "ES": "Cortocircuito, PWM 04 (Extra3 I)",
                "IT": "Cortocircuito, PWM 04 (Extra3 L)",
                "LT": "Trumpasis jungimas, PWM 04 (Extra3 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "105": {
                "GB": "Short circuit, PWM 05 (Extra1 R)",
                "SE": "Kabelfel, kortsluning, PWM 05 (Extra1 H)",
                "DE": "Kurzschluss, PWM 05 (Extra1 R)",
                "FR": "Court-circuit, PWM 05 (Suppl1 D)",
                "FI": "Oikosulku, PWM 05 (ylimääräinen1 O)",
                "DK": "Kortslutning, PWM 05 (ekstra1 H)",
                "NO": "Kortslutning, PWM 05 (ekstra1 H)",
                "LV": "Īssavienojums, PWM 03 (Extra1 R)",
                "NL": "Kortsluiting, PWM 05 (extra1 R)",
                "CZ": "Zkrat, PWM 05 (Extra1 R)",
                "PL": "Zwarcie, PWM 05 (dodatk.1 R)",
                "EE": "Lühis, PWM 05 (Ekstra1 R)",
                "PT": "Curto-circuito, PWM 05  (Extra1 R)",
                "ES": "Cortocircuito, PWM 05 (Extra1 D)",
                "IT": "Cortocircuito, PWM 05 (Extra1 R)",
                "LT": "Trumpasis jungimas, PWM 05 (Extra1 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "106": {
                "GB": "Short circuit, PWM 06 (Extra1 L)",
                "SE": "Kabelfel, kortsluning, PWM 06 (Extra1 V)",
                "DE": "Kurzschluss, PWM 06 (Extra1 L)",
                "FR": "Court-circuit, PWM 06 (Suppl1 G)",
                "FI": "Oikosulku, PWM 06 (ylimääräinen1 V)",
                "DK": "Kortslutning, PWM 06 (ekstra1 V)",
                "NO": "Kortslutning, PWM 06 (ekstra1 V)",
                "LV": "Īssavienojums, PWM 06 (Extra1 L)",
                "NL": "Kortsluiting, PWM 06 (extra1 L)",
                "CZ": "Zkrat, PWM 06 (Extra1 L)",
                "PL": "Zwarcie, PWM 06 (dodatk.1 L)",
                "EE": "Lühis, PWM 06  (Ekstra1 L)",
                "PT": "Curto-circuito, PWM 06  (Extra1 L)",
                "ES": "Cortocircuito, PWM 06 (Extra1 I)",
                "IT": "Cortocircuito, PWM 06 (Extra1 L)",
                "LT": "Trumpasis jungimas, PWM 06 (Extra1 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "107": {
                "GB": "Short circuit, PWM 07 (Extra2 R)",
                "SE": "Kabelfel, kortsluning, PWM 07 (Extra2 H)",
                "DE": "Kurzschluss, PWM 07 (Extra2 R)",
                "FR": "Court-circuit, PWM 07 (Suppl2 D)",
                "FI": "Oikosulku, PWM 07 (ylimääräinen2 O)",
                "DK": "Kortslutning, PWM 07 (ekstra2 H)",
                "NO": "Kortslutning, PWM 07 (ekstra2 H)",
                "LV": "Īssavienojums, PWM 07 (Extra2 R)",
                "NL": "Kortsluiting, PWM 07 (extra2 R)",
                "CZ": "Zkrat, PWM 07 (Extra2 R)",
                "PL": "Zwarcie, PWM 07 (dodatk.2 R)",
                "EE": "Lühis, PWM 07 (Ekstra2 R)",
                "PT": "Curto-circuito, PWM 07   (Extra2 R)",
                "ES": "Cortocircuito, PWM 07 (Extra2 D)",
                "IT": "Cortocircuito, PWM 07 (Extra2 R)",
                "LT": "Trumpasis jungimas, PWM 07 (Extra2 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "108": {
                "GB": "Short circuit, PWM 08 (Extra2 L)",
                "SE": "Kabelfel, kortsluning, PWM 08 (Extra2 V)",
                "DE": "Kurzschluss, PWM 08 (Extra2 L)",
                "FR": "Court-circuit, PWM 08 (Suppl2 G)",
                "FI": "Oikosulku, PWM 08 (ylimääräinen2 V)",
                "DK": "Kortslutning, PWM 08 (ekstra2 V)",
                "NO": "Kortslutning, PWM 08 (ekstra2 V)",
                "LV": "Īssavienojums, PWM 08 (Extra2 L)",
                "NL": "Kortsluiting, PWM 08 (extra2 L)",
                "CZ": "Zkrat, PWM 08 (Extra2 L)",
                "PL": "Zwarcie, PWM 08 (dodatk.2 L)",
                "EE": "Lühis, PWM 08 (Ekstra2 L)",
                "PT": "Curto-circuito, PWM 08   (Extra2 L)",
                "ES": "Cortocircuito, PWM 08 (Extra2 I)",
                "IT": "Cortocircuito, PWM 08 (Extra2 R)",
                "LT": "Trumpasis jungimas, PWM 08 (Extra2 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "109": {
                "GB": "Short circuit, PWM 09 (Tilt Right)",
                "SE": "Kabelfel, kortsluning, PWM 09 (Tilt Höger)",
                "DE": "Kurzschluss, PWM 09 (Kippen rechts)",
                "FR": "Court-circuit, PWM 09 (Inclinaison D)",
                "FI": "Oikosulku, PWM 09 (kallistus oikealle)",
                "DK": "Kortslutning, PWM 09 (tilt højre)",
                "NO": "Kortslutning, PWM 09 (tilt høyre)",
                "LV": "Īssavienojums, PWM 09 (Slīpums R)",
                "NL": "Kortsluiting, PWM 09 (kantelen rechtsom)",
                "CZ": "Zkrat, PWM 09 (Nakláp.vpravo)",
                "PL": "Zwarcie, PWM 09 (Wych. prawo)",
                "EE": "Lühis, PWM 09 (Kallutamine parem)",
                "PT": "Curto-circuito, PWM 09 (Inclin. direita)",
                "ES": "Cortocircuito, PWM 09 (Inclinación Derecha)",
                "IT": "Cortocircuito, PWM 09 (Inclin. destra)",
                "LT": "Trumpasis jungimas, PWM 09 (Pakreipti R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "110": {
                "GB": "Short circuit, PWM 10 (Tilt Left)",
                "SE": "Kabelfel, kortsluning, PWM 10 (Tilt Vänster)",
                "DE": "Kurzschluss, PWM 10 (Kippen links)",
                "FR": "Court-circuit, PWM 10 (Inclinaison G)",
                "FI": "Oikosulku, PWM 10 (kallistus vasemmalle)",
                "DK": "Kortslutning, PWM 10 (tilt venstre)",
                "NO": "Kortslutning, PWM 10 (tilt venstre)",
                "LV": "Īssavienojums, PWM 10 (Slīpums L)",
                "NL": "Kortsluiting, PWM 10 (kantelen linksom)",
                "CZ": "Zkrat, PWM 10 (Nakláp.vlevo)",
                "PL": "Zwarcie, PWM 10 (Wych. Lewo)",
                "EE": "Lühis, PWM 10 (Kallutamine vasak)",
                "PT": "Curto-circuito, PWM 10 (Inclin. esquerda)",
                "ES": "Cortocircuito, PWM 10 (Inclinación Izquierda)",
                "IT": "Cortocircuito, PWM 10 (Inclin. sinistra)",
                "LT": "Trumpasis jungimas, PWM 10 (Pakreipti L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "111": {
                "GB": "Open circuit, PWM 01 (Rotation Right)",
                "SE": "Kabelbrott, PWM 01 (Rotation Höger)",
                "DE": "Schaltkreis offen, PWM 01 (Drehung rechts)",
                "FR": "Circuit ouvert, PWM 01 (Rotation droite)",
                "FI": "Avoin virtapiiri, PWM 01 (kierto oikealle)",
                "DK": "Åbent kredsløb, PWM 01 (rotation højre)",
                "NO": "Kortslutning, PWM 01 (rotasjon høyre)",
                "LV": "Pārtraukta ķēde, PWM 01 (Rotācijas Labā)",
                "NL": "Open circuit, PWM 01 (roteren rechtsom)",
                "CZ": "Přerušený obvod, PWM 01 (Otáčení vlevo)",
                "PL": "Obwód otwarty, PWM 01 (obrotu prawo)",
                "EE": "Tühijooksupinge, PWM 01 (Pööramine parem)",
                "PT": "Abrir circuito, PWM 01 (Rotação direita)",
                "ES": "Circuito abierto, PWM 01 (Inclinación Derecha)",
                "IT": "Circuito aperto, PWM 01 (Rot. destra)",
                "LT": "Atvira grandinė, PWM 01 (Sukimosi dešinė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "112": {
                "GB": "Open circuit, PWM 02 (Rotation Left)",
                "SE": "Kabelbrott, PWM 02 (Rotation Vänster)",
                "DE": "Schaltkreis offen, PWM 02 (Drehung links)",
                "FR": "Circuit ouvert, PWM 02 (Rotation gauche)",
                "FI": "Avoin virtapiiri, PWM 02 (kierto vasemmalle)",
                "DK": "Åbent kredsløb, PWM 02 (rotation venstre)",
                "NO": "Kortslutning, PWM 02 (rotasjon venstre)",
                "LV": "Pārtraukta ķēde, PWM 02 (Rotācijas Kreisā)",
                "NL": "Open circuit, PWM 02 (roteren linksom)",
                "CZ": "Přerušený obvod, PWM 02 (Otáčení vrapvo)",
                "PL": "Obwód otwarty, PWM 01 (obrotu Lewo)",
                "EE": "Tühijooksupinge, PWM 02 (Pööramine vasak)",
                "PT": "Abrir circuito, PWM 02 (Rot. esquerda)",
                "ES": "Circuito abierto, PWM 02 (Inclinación Izquierda)",
                "IT": "Circuito aperto, PWM 02 (Rot. sinistra)",
                "LT": "Atvira grandinė, PWM 02 (Sukimosi kairė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "113": {
                "GB": "Open circuit, PWM 03 (Extra3 R)",
                "SE": "Kabelbrott, PWM 03 (Extra3 H)",
                "DE": "Schaltkreis offen, PWM 03 (Extra3 R)",
                "FR": "Circuit ouvert, PWM 03 (Suppl3 D)",
                "FI": "Avoin virtapiiri, PWM 03 (ylimäär.3 O)",
                "DK": "Åbent kredsløb, PWM 03 (ekstra3 H)",
                "NO": "Åpen krets, PWM 03 (ekstra3 H)",
                "LV": "Pārtraukta ķēde, PWM 03 (Extra3 R)",
                "NL": "Open circuit, PWM 03 (extra3 R)",
                "CZ": "Přerušený obvod, PWM 03 (Extra3 R)",
                "PL": "Obwód otwarty, PWM 03 (dodatk.3 R)",
                "EE": "Tühijooksupinge, PWM 03 (Ekstra3 R)",
                "PT": "Abrir circuito, PWM 03  (Extra3 R)",
                "ES": "Circuito abierto, PWM 03 (Extra3 D)",
                "IT": "Circuito aperto, PWM 03 (Extra3 R)",
                "LT": "Atvira grandinė, PWM 03 (Extra3 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "114": {
                "GB": "Open circuit, PWM 04 (Extra3 L)",
                "SE": "Kabelbrott, PWM 04 (Extra3 V)",
                "DE": "Schaltkreis offen, PWM 04 (Extra3 L)",
                "FR": "Circuit ouvert, PWM 04 (Suppl3 G)",
                "FI": "Avoin virtapiiri, PWM 04 (ylimäär.3 V)",
                "DK": "Åbent kredsløb, PWM 04 (ekstra3 V)",
                "NO": "Åpen krets, PWM 04 (ekstra3 V)",
                "LV": "Pārtraukta ķēde, PWM 04 (Extra3 L)",
                "NL": "Open circuit, PWM 04 (extra3 L)",
                "CZ": "Přerušený obvod, PWM 04 (Extra3 L)",
                "PL": "Obwód otwarty, PWM 04 (dodatk.3 L)",
                "EE": "Tühijooksupinge, PWM 04 (Ekstra3 L)",
                "PT": "Abrir circuito, PWM 04  (Extra3 L)",
                "ES": "Circuito abierto, PWM 04 (Extra3 I)",
                "IT": "Circuito aperto, PWM 04 (Extra3 L)",
                "LT": "Atvira grandinė, PWM 04 (Extra3 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "115": {
                "GB": "Open circuit, PWM 05 (Extra1 R)",
                "SE": "Kabelbrott, PWM 05 (Extra1 H)",
                "DE": "Schaltkreis offen, PWM 05 (Extra1 R)",
                "FR": "Circuit ouvert, PWM 05 (Suppl1 D)",
                "FI": "Avoin virtapiiri, PWM 05 (ylimäär.1 O)",
                "DK": "Åbent kredsløb, PWM 05 (ekstra1 H)",
                "NO": "Åpen krets, PWM 05 (ekstra1 H)",
                "LV": "Pārtraukta ķēde, PWM 05 (Extra1 R)",
                "NL": "Open circuit, PWM 05 (extra1 R)",
                "CZ": "Přerušený obvod, PWM 05 (Extra1 R)",
                "PL": "Obwód otwarty, PWM 05 (dodatk.1 R)",
                "EE": "Tühijooksupinge, PWM 05 (Ekstra1 R)",
                "PT": "Abrir circuito, PWM 05  (Extra1 R)",
                "ES": "Circuito abierto, PWM 05 (Extra1 D)",
                "IT": "Circuito aperto, PWM 05 (Extra1 R)",
                "LT": "Atvira grandinė, PWM 05 (Extra1 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "116": {
                "GB": "Open circuit, PWM 06 (Extra1 L)",
                "SE": "Kabelbrott, PWM 06 (Extra1 V)",
                "DE": "Schaltkreis offen, PWM 06 (Extra1 L)",
                "FR": "Circuit ouvert, PWM 06 (Suppl1 G)",
                "FI": "Avoin virtapiiri, PWM 06 (ylimäär.1 V)",
                "DK": "Åbent kredsløb, PWM 06 (ekstra1 V)",
                "NO": "Åpen krets, PWM 06 (ekstra1 V)",
                "LV": "Pārtraukta ķēde, PWM 06 (Extra3 L)",
                "NL": "Open circuit, PWM 06 (extra1 L)",
                "CZ": "Přerušený obvod, PWM 06 (Extra1 L)",
                "PL": "Obwód otwarty, PWM 06 (dodatk.1 L)",
                "EE": "Tühijooksupinge, PWM 06  (Ekstra1 L)",
                "PT": "Abrir circuito, PWM 06  (Extra1 L)",
                "ES": "Circuito abierto, PWM 06 (Extra1 I)",
                "IT": "Circuito aperto, PWM 06 (Extra1 L)",
                "LT": "Atvira grandinė, PWM 06 (Extra1 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "117": {
                "GB": "Open circuit, PWM 07 (Extra2 R)",
                "SE": "Kabelbrott, PWM 07 (Extra2 H)",
                "DE": "Schaltkreis offen, PWM 07 (Extra2 R)",
                "FR": "Circuit ouvert, PWM 07 (Suppl2 D)",
                "FI": "Avoin virtapiiri, PWM 07 (ylimäär.2 O)",
                "DK": "Åbent kredsløb, PWM 07 (ekstra2 H)",
                "NO": "Åpen krets, PWM 07 (ekstra2 H)",
                "LV": "Pārtraukta ķēde, PWM 07 (Extra2 R)",
                "NL": "Open circuit, PWM 07 (extra2 R)",
                "CZ": "Přerušený obvod, PWM 07 (Extra2 R)",
                "PL": "Obwód otwarty, PWM 07 (dodatk.2 R)",
                "EE": "Tühijooksupinge, PWM 07 (Ekstra2 R)",
                "PT": "Abrir circuito, PWM 07   (Extra2 R)",
                "ES": "Circuito abierto, PWM 07 (Extra2 D)",
                "IT": "Circuito aperto, PWM 07 (Extra2 R)",
                "LT": "Atvira grandinė, PWM 07 (Extra2 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "118": {
                "GB": "Open circuit, PWM 08 (Extra2 L)",
                "SE": "Kabelbrott, PWM 08 (Extra2 V)",
                "DE": "Schaltkreis offen, PWM 08 (Extra2 L)",
                "FR": "Circuit ouvert, PWM 08 (Suppl2 G)",
                "FI": "Avoin virtapiiri, PWM 08 (ylimäär.2 V)",
                "DK": "Åbent kredsløb, PWM 08 (ekstra2 V)",
                "NO": "Åpen krets, PWM 08 (ekstra2 V)",
                "LV": "Pārtraukta ķēde, PWM 08 (Extra2 L)",
                "NL": "Open circuit, PWM 08 (extra2 L)",
                "CZ": "Přerušený obvod, PWM 08 (Extra2 L)",
                "PL": "Obwód otwarty, PWM 08 (dodatk.2 L)",
                "EE": "Tühijooksupinge, PWM 08 (Ekstra2 L)",
                "PT": "Abrir circuito, PWM 08   (Extra2 L)",
                "ES": "Circuito abierto, PWM 08 (Extra2 I)",
                "IT": "Circuito aperto, PWM 08 (Extra2 R)",
                "LT": "Atvira grandinė, PWM 08 (Extra2 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "119": {
                "GB": "Open circuit, PWM 09 (Tilt Right)",
                "SE": "Kabelbrott, PWM 09 (Tilt Höger)",
                "DE": "Schaltkreis offen, PWM 09 (Kippen rechts)",
                "FR": "Circuit ouvert, PWM 09 (Inclinaison D)",
                "FI": "Avoin virtapiiri, PWM 09 (kallistus oik.)",
                "DK": "Åbent kredsløb, PWM 09 (tilt højre)",
                "NO": "Åpen krets, PWM 09 (tilt høyre)",
                "LV": "Pārtraukta ķēde, PWM 09 (Slīpums R)",
                "NL": "Open circuit, PWM 09 (kantelen rechtsom)",
                "CZ": "Přerušený obvod, PWM 09 (Nakláp.vpravo)",
                "PL": "Obwód otwarty, PWM 09 (Wych. prawo)",
                "EE": "Tühijooksupinge, PWM 09 (Kallutamine parem)",
                "PT": "Abrir circuito, PWM 09 (Inclin. direita)",
                "ES": "Circuito abierto, PWM 09 (Inclinación Derecha)",
                "IT": "Circuito aperto, PWM 09 (Inclin. destra)",
                "LT": "Atvira grandinė, PWM 09 (Pakreipti R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "120": {
                "GB": "Open circuit, PWM 10, (Tilt Left)",
                "SE": "Kabelbrott, PWM 10 (Tilt Vänster)",
                "DE": "Schaltkreis offen, PWM 10 (Kippen links)",
                "FR": "Circuit ouvert, PWM 10 (Inclinaison G)",
                "FI": "Avoin virtapiiri, PWM 10 (kallistus vas.)",
                "DK": "Åbent kredsløb, PWM 10 (tilt venstre)",
                "NO": "Åpen krets, PWM 10, (tilt venstre)",
                "LV": "Pārtraukta ķēde, PWM 10 (Slīpums L)",
                "NL": "Open circuit, PWM 10 (kantelen linksom)",
                "CZ": "Přerušený obvod, PWM 10 (Nakláp.vlevo)",
                "PL": "Obwód otwarty, PWM 10 (Wych. Lewo)",
                "EE": "Tühijooksupinge, PWM 10 (Kallutamine vasak)",
                "PT": "Abrir circuito, PWM 10 (Inclin. esquerda)",
                "ES": "Circuito abierto, PWM 10 (Inclinación Derecha)",
                "IT": "Circuito aperto, PWM 10 (Inclin. sinistra)",
                "LT": "Atvira grandinė, PWM 10 (Pakreipti L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "121": {
                "GB": "Foregin Voltage, PWM 01 (Rotation Right)",
                "SE": "Främmandespänning, PWM 01 (Rotation Höger)",
                "DE": "Fremdspannung, PWM 01 (Drehung rechts)",
                "FR": "Tension étrangère, PWM 01 (Rotation droite)",
                "FI": "Ulkoinen jännite, PWM 01 (kierto oikealle)",
                "DK": "Spænding udefra, PWM 01 (rotation højre)",
                "NO": "Ukjent spenning, PWM 01 (rotasjon høyre)",
                "LV": "Svešspriegums, PWM 01 (Rotācijas Labā)",
                "NL": "Externe spanning, PWM 01 (roteren rechtsom)",
                "CZ": "Cizí napětí, PWM 01 (Otáčení vlevo)",
                "PL": "Napięcie obce, PWM 01(obrotu prawo)",
                "EE": "Võõrpinge, PWM 01 (Pööramine parem)",
                "PT": "Tensão desconhecida, PWM 01 (Rotação direita)",
                "ES": "Tensión exterior, PWM 01 (Rotación Derecha)",
                "IT": "Tensione esterna, PWM 01 (Rot. destra)",
                "LT": "Išorinė įtampa, PWM 01 (Sukimosi dešinė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "122": {
                "GB": "Foregin Voltage, PWM 02 (Rotation Left)",
                "SE": "Främmandespänning, PWM 02 (Rotation Vänster)",
                "DE": "Fremdspannung, PWM 02 (Drehung links)",
                "FR": "Tension étrangère, PWM 02 (Rotation gauche)",
                "FI": "Ulkoinen jännite, PWM 02 (kierto vasemmalle)",
                "DK": "Spænding udefra, PWM 02 (rotation venstre)",
                "NO": "Ukjent spenning, PWM 02 (rotasjon venstre)",
                "LV": "Svešspriegums, PWM 02 (Rotācijas Kreisā)",
                "NL": "Externe spanning, PWM 02 (roteren linksom)",
                "CZ": "Cizí napětí, PWM 02 (Otáčení vrapvo)",
                "PL": "Napięcie obce, PWM 02(obrotu Lewo)",
                "EE": "Võõrpinge, PWM 02 (Pööramine vasak)",
                "PT": "Tensão desconhecida, PWM 02 (Rot. esquerda)",
                "ES": "Tensión exterior, PWM 02 (Rotación Izquierda)",
                "IT": "Tensione esterna, PWM 02 (Rot. sinistra)",
                "LT": "Išorinė įtampa, PWM 02 (Sukimosi kairė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "123": {
                "GB": "Foregin Voltage, PWM 03 (Extra3 R)",
                "SE": "Främmandespänning, PWM 03 (Extra3 H)",
                "DE": "Fremdspannung, PWM 03 (Ersatz3 R)",
                "FR": "Tension étrangère, PWM 03 (Suppl3 D)",
                "FI": "Ulkoinen jännite, PWM 03 (ylimääräinen3 O)",
                "DK": "Spænding udefra, PWM 03 (ekstra3 H)",
                "NO": "Ukjent spenning, PWM 03 (ekstra3 H)",
                "LV": "Svešspriegums, PWM 03 (Extra3 R)",
                "NL": "Externe spanning, PWM 03 (extra3 R)",
                "CZ": "Cizí napětí, PWM 03 (Extra3 R)",
                "PL": "Napięcie obce, PWM 03 (dodatk.3 R)",
                "EE": "Võõrpinge, PWM 03 (Ekstra3 R)",
                "PT": "Tensão desconhecida, PWM 03  (Extra3 R)",
                "ES": "Tensión exterior, PWM 03 (Extra3 D)",
                "IT": "Tensione esterna, PWM 03 (Extra3 R)",
                "LT": "Išorinė įtampa, PWM 03 (Extra3 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "124": {
                "GB": "Foregin Voltage, PWM 04 (Extra3 L)",
                "SE": "Främmandespänning, PWM 04 (Extra3 V)",
                "DE": "Fremdspannung, PWM 04 (Ersatz3 L)",
                "FR": "Tension étrangère, PWM 04 (Suppl3 G)",
                "FI": "Ulkoinen jännite, PWM 04 (ylimääräinen3 V)",
                "DK": "Spænding udefra, PWM 04 (ekstra3 V)",
                "NO": "Ukjent spenning, PWM 04 (ekstra3 V)",
                "LV": "Svešspriegums, PWM 04 (Extra3 L)",
                "NL": "Externe spanning, PWM 04 (extra3 L)",
                "CZ": "Cizí napětí, PWM 04 (Extra3 L)",
                "PL": "Napięcie obce, PWM 04 (dodatk.3 L)",
                "EE": "Võõrpinge, PWM 04 (Ekstra3 L)",
                "PT": "Tensão desconhecida, PWM 04  (Extra3 L)",
                "ES": "Tensión exterior, PWM 04 (Extra3 I)",
                "IT": "Tensione esterna, PWM 04 (Extra3 L)",
                "LT": "Išorinė įtampa, PWM 04 (Extra3 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "125": {
                "GB": "Foregin Voltage, PWM 05 (Extra1 R)",
                "SE": "Främmandespänning, PWM 05 (Extra1 H)",
                "DE": "Fremdspannung, PWM 05 (Ersatz1 R)",
                "FR": "Tension étrangère, PWM 05 (Suppl1 D)",
                "FI": "Ulkoinen jännite, PWM 05 (ylimääräinen1 O)",
                "DK": "Spænding udefra, PWM 05 (ekstra1 H)",
                "NO": "Ukjent spenning, PWM 05 (ekstra1 H)",
                "LV": "Svešspriegums, PWM 05 (Extra1 R)",
                "NL": "Externe spanning, PWM 05 (extra1 R)",
                "CZ": "Cizí napětí, PWM 05 (Extra1 R)",
                "PL": "Napięcie obce, PWM 05 (dodatk.1 R)",
                "EE": "Võõrpinge, PWM 05 (Ekstra1 R)",
                "PT": "Tensão desconhecida, PWM 05  (Extra1 R)",
                "ES": "Voltage externo, PWM 05 (Extra3 D)",
                "IT": "Tensione esterna, PWM 05 (Extra1 R)",
                "LT": "Išorinė įtampa, PWM 05 (Extra1 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "126": {
                "GB": "Foregin Voltage, PWM 06 (Extra1 L)",
                "SE": "Främmandespänning, PWM 06 (Extra1 V)",
                "DE": "Fremdspannung, PWM 06 (Ersatz1 L)",
                "FR": "Tension étrangère, PWM 06 (Suppl1 G)",
                "FI": "Ulkoinen jännite, PWM 06 (ylimääräinen1 V)",
                "DK": "Spænding udefra, PWM 06 (ekstra1 V)",
                "NO": "Ukjent spenning, PWM 06 (ekstra1 V)",
                "LV": "Svešspriegums, PWM 06 (Extra3 L)",
                "NL": "Externe spanning, PWM 06 (extra1 L)",
                "CZ": "Cizí napětí, PWM 06 (Extra1 L)",
                "PL": "Napięcie obce, PWM 06 (dodatk.1 L)",
                "EE": "Võõrpinge, PWM 06  (Ekstra1 L)",
                "PT": "Tensão desconhecida, PWM 06  (Extra1 L)",
                "ES": "Tensión exterior, PWM 06 (Extra1 I)",
                "IT": "Tensione esterna, PWM 06 (Extra1 L)",
                "LT": "Išorinė įtampa, PWM 06 (Extra1 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "127": {
                "GB": "Foregin Voltage, PWM 07 (Extra2 R)",
                "SE": "Främmandespänning, PWM 07 (Extra2 H)",
                "DE": "Fremdspannung, PWM 07 (Ersatz2 R)",
                "FR": "Tension étrangère, PWM 07 (Suppl2 D)",
                "FI": "Ulkoinen jännite, PWM 07 (ylimääräinen2 O)",
                "DK": "Spænding udefra, PWM 07 (ekstra2 H)",
                "NO": "Ukjent spenning, PWM 07 (ekstra2 H)",
                "LV": "Svešspriegums, PWM 07 (Extra2 R)",
                "NL": "Externe spanning, PWM 07 (extra2 R)",
                "CZ": "Cizí napětí, PWM 07 (Extra2 R)",
                "PL": "Napięcie obce, PWM 07 (dodatk.2 R)",
                "EE": "Võõrpinge, PWM 07 (Ekstra2 R)",
                "PT": "Tensão desconhecida, PWM 07   (Extra2 R)",
                "ES": "Tensión exterior, PWM 07 (Extra1 D)",
                "IT": "Tensione esterna, PWM 07 (Extra2 R)",
                "LT": "Išorinė įtampa, PWM 07 (Extra2 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "128": {
                "GB": "Foregin Voltage, PWM 08 (Extra2 L)",
                "SE": "Främmandespänning, PWM 08 (Extra2 V)",
                "DE": "Fremdspannung, PWM 08 (Ersatz2 L)",
                "FR": "Tension étrangère, PWM 08 (Suppl2 G)",
                "FI": "Ulkoinen jännite, PWM 08 (ylimääräinen2 V)",
                "DK": "Spænding udefra, PWM 08 (ekstra2 V)",
                "NO": "Ukjent spenning, PWM 08 (ekstra2 V)",
                "LV": "Svešspriegums, PWM 08 (Extra2 L)",
                "NL": "Externe spanning, PWM 08 (extra1 L)",
                "CZ": "Cizí napětí, PWM 08 (Extra2 L)",
                "PL": "Napięcie obce, PWM 08 (dodatk.2 L)",
                "EE": "Võõrpinge, PWM 08 (Ekstra2 L)",
                "PT": "Tensão desconhecida, PWM 08   (Extra2 L)",
                "ES": "Tensión exterior, PWM 08 (Extra2 I)",
                "IT": "Tensione esterna, PWM 08 (Extra2 R)",
                "LT": "Išorinė įtampa, PWM 08 (Extra2 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "129": {
                "GB": "Foregin Voltage, PWM 09 (Tilt Right)",
                "SE": "Främmandespänning, PWM 09 (Tilt Höger)",
                "DE": "Fremdspannung, PWM 09 (Kippen rechts)",
                "FR": "Tension étrangère, PWM 09 (Inclin. droite)",
                "FI": "Ulkoinen jännite, PWM 09 (kallistus oikealle)",
                "DK": "Spænding udefra, PWM 09 (tilt højre)",
                "NO": "Ukjent spenning, PWM 09 (tilt høyre)",
                "LV": "Svešspriegums, PWM 09 (Slīpums R)",
                "NL": "Externe spanning, PWM 09 (kantelen rechtsom)",
                "CZ": "Cizí napětí, PWM 09 (Nakláp.vpravo)",
                "PL": "Napięcie obce, PWM 09 (Wych. prawo)",
                "EE": "Võõrpinge, PWM 09 (Kallutamine parem)",
                "PT": "Tensão desconhecida, PWM 09 (Inclin. direita)",
                "ES": "Tensión exterior, PWM 09 (Inclinación Derecha)",
                "IT": "Tensione esterna, PWM 09 (Inclin. destra)",
                "LT": "Išorinė įtampa, PWM 09 (Pakreipti R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "130": {
                "GB": "Foregin Voltage, PWM 10 (Tilt Left)",
                "SE": "Främmandespänning, PWM 10 (Tilt Vänster)",
                "DE": "Fremdspannung, PWM 10 (Kippen links)",
                "FR": "Tension étrangère, PWM 10 (Inclin. gauche)",
                "FI": "Ulkoinen jännite, PWM 10 (kallistus vas.)",
                "DK": "Spænding udefra, PWM 10 (tilt venstre)",
                "NO": "Ukjent spenning, PWM 10 (tilt venstre)",
                "LV": "Svešspriegums, PWM 10 (Slīpums L)",
                "NL": "Externe spanning, PWM 10 (kantelen linksom)",
                "CZ": "Cizí napětí, PWM 10 (Nakláp.vlevo)",
                "PL": "Napięcie obce, PWM 10 (Wych. Lewo)",
                "EE": "Võõrpinge, PWM 10 (Kallutamine vasak)",
                "PT": "Tensão desconhecida, PWM 10 (Inclin. esquerda)",
                "ES": "Tensión exterior, PWM 10 (Inclinación Izquierda)",
                "IT": "Tensione esterna, PWM 10 (Inclin. sinistra)",
                "LT": "Išorinė įtampa, PWM 10 (Pakreipti L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "131": {
                "GB": "Short to GND, PWM 01 (Rotation Right)",
                "SE": "Kortslutning mot jord, PWM 01 (Rotation Höger)",
                "DE": "Kurzschluss gg. Masse, PWM 01 (Drehung rechts)",
                "FR": "Court vers GND, PWM 01 (Rotation droite)",
                "FI": "Oikosulku GND:hen, PWM 01 (kierto oikealle)",
                "DK": "Kortslutning til GND, PWM 01 (rotation H)",
                "NO": "Kortslutning til jord, PWM 01 (rotasjon H)",
                "LV": "Īsslēgums ar masu GND, PWM 01 (Rotācijas Labā)",
                "NL": "Kortsluiting aarde, PWM 01 (roteren rechtsom)",
                "CZ": "Zkrat k GND, PWM 01 (Otáčení vlevo)",
                "PL": "Zwarcie do GND, PWM 01 (obrotu prawo)",
                "EE": "Lühike GNDsse, PWM 01 (Pööramine parem)",
                "PT": "Curto-circ. GND, PWM 01 (Rotação direita)",
                "ES": "Cortocircuito a masa, PWM 01 (Rotación Derecha)",
                "IT": "Cortocirc. v. GND, PWM 01 (Rot. destra)",
                "LT": "Trumpasis jung. su GND, PWM 01 (Sukimosi dešinė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "132": {
                "GB": "Short to GND, PWM 02 (Rotation Left)",
                "SE": "Kortslutning mot jord, PWM 02 (Rotation Vänster)",
                "DE": "Kurzschluss gg. Masse, PWM 02 (Drehung links)",
                "FR": "Court vers GND, PWM 02 (Rotation gauche)",
                "FI": "Oikosulku GND:hen, PWM 02 (kierto vasemmalle)",
                "DK": "Kortslutning til GND, PWM 02 (rotation V)",
                "NO": "Kortslutning til jord, PWM 02 (rotasjon V)",
                "LV": "Īsslēgums ar masu GND, PWM 02 (Rotācijas Kreisā)",
                "NL": "Kortsluiting aarde, PWM 02 (roteren linksom)",
                "CZ": "Zkrat k GND, PWM 02 (Otáčení vrapvo)",
                "PL": "Zwarcie do GND, PWM 01 (obrotu Lewo)",
                "EE": "Lühike GNDsse, PWM 02 (Pööramine vasak)",
                "PT": "Curto-circ. GND, PWM 02 (Rot. esquerda)",
                "ES": "Cortocircuito a masa, PWM 02 (Rotación Izquierda)",
                "IT": "Cortocirc. v. GND, PWM 02 (Rot. sinistra)",
                "LT": "Trumpasis jung. su GND, PWM 02 (Sukimosi kairė)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "133": {
                "GB": "Short to GND, PWM 03 (Extra3 R)",
                "SE": "Kortslutning mot jord, PWM 03 (Extra3 H)",
                "DE": "Kurzschluss gg. Masse, PWM 03 (Ersatz3 R)",
                "FR": "Court vers GND, PWM 03 (Suppl3 D)",
                "FI": "Oikosulku GND:hen, PWM 03 (ylimäär.3 O)",
                "DK": "Kortslutning til GND, PWM 03 (ekstra3 H)",
                "NO": "Kortslutning til jord, PWM 03 (ekstra3 H)",
                "LV": "Īsslēgums ar masu GND, PWM 03 (Extra3 R)",
                "NL": "Kortsluiting aarde, PWM 03 (extra3 R)",
                "CZ": "Zkrat k GND, PWM 03 (Extra3 R)",
                "PL": "Zwarcie do GND, PWM 03 (dodatk.3 R)",
                "EE": "Lühike GNDsse, PWM 03 (Ekstra3 R)",
                "PT": "Curto-circ. GND, PWM 03  (Extra3 R)",
                "ES": "Cortocircuito a masa, PWM 03 (Extra3 D)",
                "IT": "Cortocirc. v. GND, PWM 03 (Extra3 R)",
                "LT": "Trumpasis jung. su GND, PWM 03 (Extra3 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "134": {
                "GB": "Short to GND, PWM 04 (Extra3 L)",
                "SE": "Kortslutning mot jord, PWM 04 (Extra3 V)",
                "DE": "Kurzschluss gg. Masse, PWM 04 (Ersatz3 L)",
                "FR": "Court vers GND, PWM 04 (Suppl3 G)",
                "FI": "Oikosulku GND:hen, PWM 04 (ylimäär.3 V)",
                "DK": "Kortslutning til GND, PWM 04 (ekstra3 V)",
                "NO": "Kortslutning til jord, PWM 04 (ekstra3 V)",
                "LV": "Īsslēgums ar masu GND, PWM 04 (Extra3 L)",
                "NL": "Kortsluiting aarde, PWM 04 (extra3 L)",
                "CZ": "Zkrat k GND, PWM 04 (Extra3 L)",
                "PL": "Zwarcie do GND, PWM 04 (dodatk.3 L)",
                "EE": "Lühike GNDsse, PWM 04 (Ekstra3 L)",
                "PT": "Curto-circ. GND, PWM 04  (Extra3 L)",
                "ES": "Cortocircuito a masa, PWM 04 (Extra3 I)",
                "IT": "Cortocirc. v. GND, PWM 04 (Extra3 L)",
                "LT": "Trumpasis jung. su GND, PWM 04 (Extra3 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "135": {
                "GB": "Short to GND, PWM 05 (Extra1 R)",
                "SE": "Kortslutning mot jord, PWM 05 (Extra1 H)",
                "DE": "Kurzschluss gg. Masse, PWM 05 (Ersatz1 R)",
                "FR": "Court vers GND, PWM 05 (Suppl1 D)",
                "FI": "Oikosulku GND:hen, PWM 05 (ylimäär.1 O)",
                "DK": "Kortslutning til GND, PWM 05 (ekstra1 H)",
                "NO": "Kortslutning til jord, PWM 05 (ekstra1 H)",
                "LV": "Īsslēgums ar masu GND, PWM 03 (Extra1 R)",
                "NL": "Kortsluiting aarde, PWM 05 (extra1 R)",
                "CZ": "Zkrat k GND, PWM 05 (Extra1 R)",
                "PL": "Zwarcie do GND, PWM 05 (dodatk.1 R)",
                "EE": "Lühike GNDsse, PWM 05 (Ekstra1 R)",
                "PT": "Curto-circ. GND, PWM 05  (Extra1 R)",
                "ES": "Cortocircuito a masa, PWM 05 (Extra1 D)",
                "IT": "Cortocirc. v. GND, PWM 05 (Extra1 R)",
                "LT": "Trumpasis jung. su GND, PWM 05 (Extra1 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "136": {
                "GB": "Short to GND, PWM 06 (Extra1 L)",
                "SE": "Kortslutning mot jord, PWM 06 (Extra1 V)",
                "DE": "Kurzschluss gg. Masse, PWM 06 (Ersatz1 L)",
                "FR": "Court vers GND, PWM 06 (Suppl1 G)",
                "FI": "Oikosulku GND:hen, PWM 06 (ylimäär.1 V)",
                "DK": "Kortslutning til GND, PWM 06 (ekstra1 V)",
                "NO": "Kortslutning til jord, PWM 06 (ekstra1 V)",
                "LV": "Īsslēgums ar masu GND, PWM 06 (Extra1 L)",
                "NL": "Kortsluiting aarde, PWM 06 (extra1 L)",
                "CZ": "Zkrat k GND, PWM 06 (Extra1 L)",
                "PL": "Zwarcie do GND, PWM 06 (dodatk.1 L)",
                "EE": "Lühike GNDsse, PWM 06  (Ekstra1 L)",
                "PT": "Curto-circ. GND, PWM 06  (Extra1 L)",
                "ES": "Cortocircuito a masa, PWM 06 (Extra1 I)",
                "IT": "Cortocirc. v. GND, PWM 06 (Extra1 L)",
                "LT": "Trumpasis jung. su GND, PWM 06 (Extra1 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "137": {
                "GB": "Short to GND, PWM 07 (Extra2 R)",
                "SE": "Kortslutning mot jord, PWM 07 (Extra2 H)",
                "DE": "Kurzschluss gg. Masse, PWM 07 (Ersatz2 R)",
                "FR": "Court vers GND, PWM 07 (Suppl2 D)",
                "FI": "Oikosulku GND:hen, PWM 07 (ylimäär.2 O)",
                "DK": "Kortslutning til GND, PWM 07 (ekstra2 H)",
                "NO": "Kortslutning til jord, PWM 07 (ekstra2 H)",
                "LV": "Īsslēgums ar masu GND, PWM 07 (Extra2 R)",
                "NL": "Kortsluiting aarde, PWM 07 (extra2 R)",
                "CZ": "Zkrat k GND, PWM 07 (Extra2 R)",
                "PL": "Zwarcie do GND, PWM 07 (dodatk.2 R)",
                "EE": "Lühike GNDsse, PWM 07 (Ekstra2 R)",
                "PT": "Curto-circ. GND, PWM 07   (Extra2 R)",
                "ES": "Cortocircuito a masa, PWM 07 (Extra2 D)",
                "IT": "Cortocirc. v. GND, PWM 07 (Extra2 R)",
                "LT": "Trumpasis jung. su GND, PWM 07 (Extra2 R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "138": {
                "GB": "Short to GND, PWM 08 (Extra2 L)",
                "SE": "Kortslutning mot jord, PWM 08 (Extra2 V)",
                "DE": "Kurzschluss gg. Masse, PWM 08 (Ersatz2 L)",
                "FR": "Court vers GND, PWM 08 (Suppl2 G)",
                "FI": "Oikosulku GND:hen, PWM 08 (ylimäär.2 V)",
                "DK": "Kortslutning til GND, PWM 08 (ekstra2 V)",
                "NO": "Kortslutning til jord, PWM 08 (ekstra2 V)",
                "LV": "Īsslēgums ar masu GND, PWM 0/8 (Extra2 L)",
                "NL": "Kortsluiting aarde, PWM 08 (extra2 L)",
                "CZ": "Zkrat k GND, PWM 08 (Extra2 L)",
                "PL": "Zwarcie do GND, PWM 08 (dodatk.2 L)",
                "EE": "Lühike GNDsse, PWM 08 (Ekstra2 L)",
                "PT": "Curto-circ. GND, PWM 08   (Extra2 L)",
                "ES": "Cortocircuito a masa, PWM 08 (Extra2 I)",
                "IT": "Cortocirc. v. GND, PWM 08 (Extra2 R)",
                "LT": "Trumpasis jung. su GND, PWM 08 (Extra2 L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "139": {
                "GB": "Short to GND, PWM 09 (Tilt Right)",
                "SE": "Kortslutning mot jord, PWM 09 (Tilt Höger)",
                "DE": "Kurzschluss gg. Masse, PWM 09 (Kippen re.)",
                "FR": "Court vers GND, PWM 09 (Inclin. droite)",
                "FI": "Oikosulku GND:hen, PWM 09 (kallistus oik.)",
                "DK": "Kortslutning til GND, PWM 09 (tilt H)",
                "NO": "Kortslutning til jord, PWM 09 (tilt H)",
                "LV": "Īsslēgums ar masu GND, PWM 09 (Slīpums R)",
                "NL": "Kortsluiting aarde, PWM 09 (kantel rechtsom",
                "CZ": "Zkrat k GND, PWM 09 (Nakláp.vpravo)",
                "PL": "Zwarcie do GND, PWM 09 (Wych. prawo)",
                "EE": "Lühike GNDsse, PWM 09 (Kallutamine parem)",
                "PT": "Curto-circ. GND, PWM 09 (Inclin. direita)",
                "ES": "Cortocircuito a masa, PWM 09 (Inclinación Derecha)",
                "IT": "Cortocirc. v. GND, PWM 09 (Inclin. destra)",
                "LT": "Trumpasis jung. su GND, PWM 09 (Pakreipti R)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "140": {
                "GB": "Short to GND, PWM 10 (Tilt Left)",
                "SE": "Kortslutning mot jord, PWM 10 (Tilt Vänster)",
                "DE": "Kurzschluss gg. Masse, PWM 10 (Kippen li.)",
                "FR": "Court vers GND, PWM 10 (Inclin. gauche)",
                "FI": "Oikosulku GND:hen, PWM 10 (kallistus vas.)",
                "DK": "Kortslutning til GND, PWM 10 (tilt V)",
                "NO": "Kortslutning til jord, PWM 10 (tilt V)",
                "LV": "Īsslēgums ar masu GND, PWM 10 (Slīpums L)",
                "NL": "Kortsluiting aarde, PWM 10 (kantel linksom",
                "CZ": "Zkrat k GND, PWM 10 (Nakláp.vlevo)",
                "PL": "Zwarcie do GND, PWM 10 (Wych. Lewo)",
                "EE": "Lühike GNDsse, PWM 10 (Kallutamine vasak)",
                "PT": "Curto-circuito, PWM 10 (Inclin. esquerda)",
                "ES": "Cortocircuito a masa, PWM 10 (Inclinación Derecha)",
                "IT": "Cortocirc. v. GND, PWM 10 (Inclin. sinistra)",
                "LT": "Trumpasis jung. su GND, PWM 10 (Pakreipti L)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "201": {
                "GB": "Short circuit, digital output 01",
                "SE": "Kabelfel, kortslutning, digital utgång 01",
                "DE": "Kurzschluss, Digitalausgang 01",
                "FR": "Court-circuit, sortie numérique 01",
                "FI": "Oikosulku, digitaalinen lähtö 01",
                "DK": "Kortslutning, digital udgang 01",
                "NO": "Kortslutning, digital utgang 01",
                "LV": "Īssavienojums, digitālā izeja 01",
                "NL": "Kortsluiting, digitale uitgang 01",
                "CZ": "Zkrat, digitální výstup 01",
                "PL": "Zwarcie, wyjście cyfrowe 01",
                "EE": "Lühis, digitaalne väljund 01",
                "PT": "Curto-circuito, saída digital 01",
                "ES": "Cortocircuito, salida digital 01",
                "IT": "Cortocircuito, uscita digitale 01",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "202": {
                "GB": "Short circuit, digital output 02",
                "SE": "Kabelfel, kortslutning, digital utgång 02",
                "DE": "Kurzschluss, Digitalausgang 02",
                "FR": "Court-circuit, sortie numérique 02",
                "FI": "Oikosulku, digitaalinen lähtö 02",
                "DK": "Kortslutning, digital udgang 02",
                "NO": "Kortslutning, digital utgang 02",
                "LV": "Īssavienojums, digitālā izeja 02",
                "NL": "Kortsluiting, digitale uitgang 02",
                "CZ": "Zkrat, digitální výstup 02",
                "PL": "Zwarcie, wyjście cyfrowe 02",
                "EE": "Lühis, digitaalne väljund 02",
                "PT": "Curto-circuito, saída digital 02",
                "ES": "Cortocircuito, salida digital 02",
                "IT": "Cortocircuito, uscita digitale 02",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "203": {
                "GB": "Short circuit, digital output 03",
                "SE": "Kabelfel, kortslutning, digital utgång 03",
                "DE": "Kurzschluss, Digitalausgang 03",
                "FR": "Court-circuit, sortie numérique 03",
                "FI": "Oikosulku, digitaalinen lähtö 03",
                "DK": "Kortslutning, digital udgang 03",
                "NO": "Kortslutning, digital utgang 03",
                "LV": "Īssavienojums, digitālā izeja 03",
                "NL": "Kortsluiting, digitale uitgang 03",
                "CZ": "Zkrat, digitální výstup 03",
                "PL": "Zwarcie, wyjście cyfrowe 03",
                "EE": "Lühis, digitaalne väljund 03",
                "PT": "Curto-circuito, saída digital 03",
                "ES": "Cortocircuito, salida digital 03",
                "IT": "Cortocircuito, uscita digitale 03",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "204": {
                "GB": "Short circuit, digital output 04",
                "SE": "Kabelfel, kortslutning, digital utgång 04",
                "DE": "Kurzschluss, Digitalausgang 04",
                "FR": "Court-circuit, sortie numérique 04",
                "FI": "Oikosulku, digitaalinen lähtö 04",
                "DK": "Kortslutning, digital udgang 04",
                "NO": "Kortslutning, digital utgang 04",
                "LV": "Īssavienojums, digitālā izeja 04",
                "NL": "Kortsluiting, digitale uitgang 04",
                "CZ": "Zkrat, digitální výstup 04",
                "PL": "Zwarcie, wyjście cyfrowe 04",
                "EE": "Lühis, digitaalne väljund 04",
                "PT": "Curto-circuito, saída digital 04",
                "ES": "Cortocircuito, salida digital 04",
                "IT": "Cortocircuito, uscita digitale 04",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "211": {
                "GB": "Open circuit, digital output 01",
                "SE": "Kabelbrott, digital utgång 01",
                "DE": "Schaltkreis offen, Digitalausgang 01",
                "FR": "Circuit ouvert, sortie numérique 01",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 01",
                "DK": "Åbent kredsløb, digital udgang 01",
                "NO": "Åpen krets, digital utgang 01",
                "LV": "Pārtraukta ķēde, digitālā izeja 01",
                "NL": "Open circuit, digitale uitgang 01",
                "CZ": "Přerušený obvod, digitální výstup 01",
                "PL": "Obwód otwarty, wyjście cyfrowe 01",
                "EE": "Tühijooksupinge, digitaalne väljund 01",
                "PT": "Abrir circuito, saída digital 01",
                "ES": "Circuito abierto, salida digital 01",
                "IT": "Circuito aperto, uscita digitale 01",
                "LT": "Atvira grandinė, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "212": {
                "GB": "Open circuit, digital output 02",
                "SE": "Kabelbrott, digital utgång 02",
                "DE": "Schaltkreis offen, Digitalausgang 02",
                "FR": "Circuit ouvert, sortie numérique 02",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 02",
                "DK": "Åbent kredsløb, digital udgang 02",
                "NO": "Åpen krets, digital utgang 02",
                "LV": "Pārtraukta ķēde, digitālā izeja 02",
                "NL": "Open circuit, digitale uitgang 02",
                "CZ": "Přerušený obvod, digitální výstup 02",
                "PL": "Obwód otwarty, wyjście cyfrowe 02",
                "EE": "Tühijooksupinge, digitaalne väljund 02",
                "PT": "Abrir circuito, saída digital 02",
                "ES": "Circuito abierto, salida digital 02",
                "IT": "Circuito aperto, uscita digitale 02",
                "LT": "Atvira grandinė, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "213": {
                "GB": "Open circuit, digital output 03",
                "SE": "Kabelbrott, digital utgång 03",
                "DE": "Schaltkreis offen, Digitalausgang 03",
                "FR": "Circuit ouvert, sortie numérique 03",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 03",
                "DK": "Åbent kredsløb, digital udgang 03",
                "NO": "Åpen krets, digital utgang 03",
                "LV": "Pārtraukta ķēde, digitālā izeja 03",
                "NL": "Open circuit, digitale uitgang 03",
                "CZ": "Přerušený obvod, digitální výstup 03",
                "PL": "Obwód otwarty, wyjście cyfrowe 03",
                "EE": "Tühijooksupinge, digitaalne väljund 03",
                "PT": "Abrir circuito, saída digital 03",
                "ES": "Circuito abierto, salida digital 03",
                "IT": "Circuito aperto, uscita digitale 03",
                "LT": "Atvira grandinė, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "214": {
                "GB": "Open circuit, digital output 04",
                "SE": "Kabelbrott, digital utgång 04",
                "DE": "Schaltkreis offen, Digitalausgang 04",
                "FR": "Circuit ouvert, sortie numérique 04",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 04",
                "DK": "Åbent kredsløb, digital udgang 04",
                "NO": "Åpen krets, digital utgang 04",
                "LV": "Pārtraukta ķēde, digitālā izeja 04",
                "NL": "Open circuit, digitale uitgang 04",
                "CZ": "Přerušený obvod, digitální výstup 04",
                "PL": "Obwód otwarty, wyjście cyfrowe 04",
                "EE": "Tühijooksupinge, digitaalne väljund 04",
                "PT": "Abrir circuito, saída digital 04",
                "ES": "Circuito abierto, salida digital 04",
                "IT": "Circuito aperto, uscita digitale 04",
                "LT": "Atvira grandinė, skaitmeninė išvestis 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "221": {
                "GB": "Foregin Voltage, digital output 01",
                "SE": "Främmandespänning, digital utgång 01",
                "DE": "Fremdspannung, Digitalausgang 01",
                "FR": "Tension étrangère, sortie numérique 01",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 01",
                "DK": "Spænding udefra, digital udgang 01",
                "NO": "Ukjent spenning, digital utgang 01",
                "LV": "Svešspriegums, digitālā izeja 01",
                "NL": "Externe spanning, digitale uitgang 01",
                "CZ": "Cizí napětí, digitální výstup 01",
                "PL": "Napięcie obce, wyjście cyfrowe 01",
                "EE": "Võõrpinge, digitaalne väljund 01",
                "PT": "Tensão desconhecida, saída digital 01",
                "ES": "Voltage externo, salida digital 01",
                "IT": "Tensione esterna, uscita digitale 01",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "222": {
                "GB": "Foregin Voltage, digital output 02",
                "SE": "Främmandespänning, digital utgång 02",
                "DE": "Fremdspannung, Digitalausgang 02",
                "FR": "Tension étrangère, sortie numérique 02",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 02",
                "DK": "Spænding udefra, digital udgang 02",
                "NO": "Ukjent spenning, digital utgang 02",
                "LV": "Svešspriegums, digitālā izeja 02",
                "NL": "Externe spanning, digitale uitgang 02",
                "CZ": "Cizí napětí, digitální výstup 02",
                "PL": "Napięcie obce, wyjście cyfrowe 02",
                "EE": "Võõrpinge, digitaalne väljund 02",
                "PT": "Tensão desconhecida, saída digital 02",
                "ES": "Voltage externo, salida digital 02",
                "IT": "Tensione esterna, uscita digitale 02",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "223": {
                "GB": "Foregin Voltage, digital output 03",
                "SE": "Främmandespänning, digital utgång 03",
                "DE": "Fremdspannung, Digitalausgang 03",
                "FR": "Tension étrangère, sortie numérique 03",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 03",
                "DK": "Spænding udefra, digital udgang 03",
                "NO": "Ukjent spenning, digital utgang 03",
                "LV": "Svešspriegums, digitālā izeja 03",
                "NL": "Externe spanning, digitale uitgang 03",
                "CZ": "Cizí napětí, digitální výstup 03",
                "PL": "Napięcie obce, wyjście cyfrowe 03",
                "EE": "Võõrpinge, digitaalne väljund 03",
                "PT": "Tensão desconhecida, saída digital 03",
                "ES": "Voltage externo, salida digital 03",
                "IT": "Tensione esterna, uscita digitale 03",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "224": {
                "GB": "Foregin Voltage, digital output 04",
                "SE": "Främmandespänning, digital utgång 04",
                "DE": "Fremdspannung, Digitalausgang 04",
                "FR": "Tension étrangère, sortie numérique 04",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 04",
                "DK": "Spænding udefra, digital udgang 04",
                "NO": "Ukjent spenning, digital utgang 04",
                "LV": "Svešspriegums, digitālā izeja 04",
                "NL": "Externe spanning, digitale uitgang 04",
                "CZ": "Cizí napětí, digitální výstup 04",
                "PL": "Napięcie obce, wyjście cyfrowe 04",
                "EE": "Võõrpinge, digitaalne väljund 04",
                "PT": "Tensão desconhecida, saída digital 04",
                "ES": "Voltage externo, salida digital 04",
                "IT": "Tensione esterna, uscita digitale 04",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "231": {
                "GB": "Short to GND, digital output 01",
                "SE": "Kortslutning mot jord, digital utgång 01",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 01",
                "FR": "Court vers GND, sortie numérique 01",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 01",
                "DK": "Kortslutning til GND, digital udgang 01",
                "NO": "Kortslutning til jord, digital utgang 01",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 01",
                "NL": "Kortsluiting aarde, digitale uitgang 01",
                "CZ": "Zkrat k GND, digitální výstup 01",
                "PL": "Zwarcie do GND (masa), wyjście cyfrowe 01",
                "EE": "Lühike GNDsse, digitaalne väljund 01",
                "PT": "Curto-circ. GND, saída digital 01",
                "ES": "Cortocircuito a masa, salida digital 01",
                "IT": "Cortocirc. v. GND, uscita digitale 01",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "232": {
                "GB": "Short to GND, digital output 02",
                "SE": "Kortslutning mot jord, digital utgång 02",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 02",
                "FR": "Court vers GND, sortie numérique 02",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 02",
                "DK": "Kortslutning til GND, digital udgang 02",
                "NO": "Kortslutning til jord, digital utgang 02",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 02",
                "NL": "Kortsluiting aarde, digitale uitgang 02",
                "CZ": "Zkrat k GND, digitální výstup 02",
                "PL": "Zwarcie do GND, wyjście cyfrowe 02",
                "EE": "Lühike GNDsse, digitaalne väljund 02",
                "PT": "Curto-circ. GND, saída digital 02",
                "ES": "Cortocircuito a masa, salida digital 02",
                "IT": "Cortocirc. v. GND, uscita digitale 02",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "233": {
                "GB": "Short to GND, digital output 03",
                "SE": "Kortslutning mot jord, digital utgång 03",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 03",
                "FR": "Court vers GND, sortie numérique 03",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 03",
                "DK": "Kortslutning til GND, digital udgang 03",
                "NO": "Kortslutning til jord, digital utgang 03",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 03",
                "NL": "Kortsluiting aarde, digitale uitgang 03",
                "CZ": "Zkrat k GND, digitální výstup 03",
                "PL": "Zwarcie do GND, wyjście cyfrowe 03",
                "EE": "Lühike GNDsse, digitaalne väljund 03",
                "PT": "Curto-circ. GND, saída digital 03",
                "ES": "Cortocircuito a masa, salida digital 03",
                "IT": "Cortocirc. v. GND, uscita digitale 03",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "301": {
                "GB": "Short circuit, sensor supply 1 (Pressure)",
                "SE": "Kabelfel, kortslutning, sensormatning 1 (Tryck)",
                "DE": "Kurzschluss, Sensorversorgung 1 (Druck)",
                "FR": "Court-circuit, alimentation capteur 1 (Pression)",
                "FI": "Oikosulku, anturin syöttö 1 (paine)",
                "DK": "Kortslutning, sensorforsyning 1 (tryk)",
                "NO": "Kortslutning, sensortilførsel 1 (trykk)",
                "LV": "Īsslēgums, sensora barošana 1 (Spiediena)",
                "NL": "Kortsluiting, sensorvoeding 1 (druk)",
                "CZ": "Zkrat, napájení senzor 1 (tlak)",
                "PL": "Zwarcie, czujnik zasil. 1 (Ciśnienie)",
                "EE": "Lühis, anduri toide 1 (Rõhk)",
                "PT": "Curto-circuito, sensor de alim. 1 (pressão)",
                "ES": "Cortocircuito, sensor de alim. 1 (Presión)",
                "IT": "Cortocircuito, alim. sensore 1 (pressione)",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 1 (slėgis)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "302": {
                "GB": "Short circuit, sensor supply 2 (Coupler)",
                "SE": "Kabelfel, kortslutning, sensormatning 2 (Redskapslås)",
                "DE": "Kurzschluss, Sensorversorgung 2 (Koppler)",
                "FR": "Court-circuit, alimentation capteur 2 (Attache)",
                "FI": "Oikosulku, anturin syöttö 2 (kytkin)",
                "DK": "Kortslutning, sensorforsyning 2 (fæste)",
                "NO": "Kortslutning, sensortilførsel 2 (feste)",
                "LV": "Īsslēgums, sensora barošana 2 (savienotāja)",
                "NL": "Kortsluiting, sensorvoeding 2 (koppeling)",
                "CZ": "Zkrat, napájení senzor 2 (spojka)",
                "PL": "Zwarcie, czujnik zasil. 2 (Coupler)",
                "EE": "Lühis, anduri toide 2 (Haakeseadise)",
                "PT": "Curto-circuito, sensor de aliment. 2 (Acoplador)",
                "ES": "Cortocircuito, sensor de alim. 2 (Acoplador)",
                "IT": "Cortocircuito, alim.. sensore 2 (attacco)",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 2 (jungtis)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "303": {
                "GB": "Short circuit, sensor supply 3 (PRLOCK)",
                "SE": "Kabelfel, kortslutning, sensormatning 3 (PRLOCK)",
                "DE": "Kurzschluss, Sensorversorgung 3 (PRLOCK)",
                "FR": "Court-circuit, alimentation capteur 3 (PRLOCK)",
                "FI": "Oikosulku, anturin syöttö 3 (PRLOCK)",
                "DK": "Kortslutning, sensorforsyning 3 (PRLOCK)",
                "NO": "Kortslutning, sensortilførsel 3 (PRLOCK)",
                "LV": "Īsslēgums, sensora barošana 3 (PRLOCK)",
                "NL": "Kortsluiting, sensorvoeding 3 (PRLOCK)",
                "CZ": "Zkrat, napájení senzor 3 (PRLOCK)",
                "PL": "Zwarcie, zasilanie czujnika 3 (PRLOCK)",
                "EE": "Lühis, anduri toide 3 (PRLOCK)",
                "PT": "Curto-circuito, sensor de alimentação 3 (PRLOCK)",
                "ES": "Cortocircuito, sensor de alim. 3 (PRLOCK)",
                "IT": "Cortocircuito, alimentazione sensore 3 (PRLOCK)",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 3 (PRLOCK)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "304": {
                "GB": "Short circuit, sensor supply 4 (Spare)",
                "SE": "Kabelfel, kortslutning, sensormatning 4 (reserv)",
                "DE": "Kurzschluss, Sensorversorgung 4 (Ersatz)",
                "FR": "Court-circuit, alimentation capteur 4 (Réserve)",
                "FI": "Oikosulku, anturin syöttö 4 (vara)",
                "DK": "Kortslutning, sensorforsyning 4 (reserve)",
                "NO": "Kortslutning, sensortilførsel 4 (reserve)",
                "LV": "Īsslēgums, sensora barošana 4 (rezerve)",
                "NL": "Kortsluiting, sensorvoeding 4 (reserve)",
                "CZ": "Zkrat, napájení senzor 4 (záloha)",
                "PL": "Zwarcie, zasilanie czujnika 4 (Zapas)",
                "EE": "Lühis, anduri toide 4 (varu)",
                "PT": "Curto-circuito, sensor de alimentação 4 (vago)",
                "ES": "Cortocircuito, sensor de alim. 4 (Libre)",
                "IT": "Cortocircuito, alimentazione sensore 4 (ricambio)",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 4 (atsarg.)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "305": {
                "GB": "Short circuit, sensor supply 5 (Rotation)",
                "SE": "Kabelfel, kortslutning, sensormatning 5 (Rotation)",
                "DE": "Kurzschluss, Sensorversorgung 5 (Drehung)",
                "FR": "Court-circuit, alimentation capteur 5 (Rotation)",
                "FI": "Oikosulku, anturin syöttö 5 (kierto)",
                "DK": "Kortslutning, sensorforsyning 5 (rotation)",
                "NO": "Kortslutning, sensortilførsel 5 (rotasjon)",
                "LV": "Īsslēgums, sensora barošana 5 (Rotācijas)",
                "NL": "Kortsluiting, sensorvoeding 5 (rotatie)",
                "CZ": "Zkrat, napájení senzor 5 (Otáčení)",
                "PL": "Zwarcie, czujnik zasil. 5 (obrotu)",
                "EE": "Lühis, anduri toide 5  (Pööramine)",
                "PT": "Curto-circuito, sensor de aliment. (Rotação)",
                "ES": "Cortocircuito, sensor de alim. 5 (Rotación)",
                "IT": "Cortocircuito, alim. sensore 5 (Rotazione)",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 5 (Sukimas)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "306": {
                "GB": "Short circuit, sensor supply 6 (Index)",
                "SE": "Kabelfel, kortslutning, sensormatning 6 (Index)",
                "DE": "Kurzschluss, Sensorversorgung 6 (Index)",
                "FR": "Court-circuit, alimentation capteur 6 (Index)",
                "FI": "Oikosulku, anturin syöttö 6 (index)",
                "DK": "Kortslutning, sensorforsyning 6 (indeks)",
                "NO": "Kortslutning, sensortilførsel 6 (indeks)",
                "LV": "Īsslēgums, sensora barošana 6 (indeksa)",
                "NL": "Kortsluiting, sensorvoeding 6 (index)",
                "CZ": "Zkrat, napájení senzor 6 (Index)",
                "PL": "Zwarcie, czujnik zasil. 6 (Index)",
                "EE": "Lühis, anduri toide 6 (Indeksi)",
                "PT": "Curto-circuito, sensor de alim. 6 (Índice)",
                "ES": "Cortocircuito, sensor de alim. 6 (Ìndice)",
                "IT": "Cortocircuito, alim. sensore 6 (indice)",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 6 (Indekso)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "311": {
                "GB": "Open circuit, sensor supply 1 (Pressure)",
                "SE": "Kabelbrott, sensormatning 1 (Tryck)",
                "DE": "Schaltkreis offen, Sensorversorgung 1 (Druck)",
                "FR": "Circuit ouvert, alimentation capteur 1 (Pression)",
                "FI": "Avoin virtapiiri, anturin syöttö 1 (paine)",
                "DK": "Åbent kredsløb, sensorforsyning 1 (tryk)",
                "NO": "Åpen krets, sensortilførsel 1 (trykk)",
                "LV": "Pārtraukta ķēde, sensora barošana 1 (spiediens)",
                "NL": "Open circuit, sensorvoeding 1 (druk)",
                "CZ": "Přerušený obvod, napájení senzor 1 (tlak)",
                "PL": "Obwód otwarty, zasilanie czujnika 1 (Ciśnienie)",
                "EE": "Tühijooksupinge, anduri toide 1 (rõhk)",
                "PT": "Abrir circuito, sensor de alim. 1 (pressão)",
                "ES": "Circuito abierto, sensor de alim. 1 (Presión)",
                "IT": "Circuito aperto, alim. sensore 1 (pressione)",
                "LT": "Atvira grandinė, jutiklio tiekimas 1 (slėgis)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "312": {
                "GB": "Open circuit, sensor supply 2 (Coupler)",
                "SE": "Kabelbrott, sensormatning 2 (Redskapslås)",
                "DE": "Schaltkreis offen, Sensorversorgung 2 (Koppler)",
                "FR": "Circuit ouvert, alimentation capteur 2 (Attache)",
                "FI": "Avoin virtapiiri, anturin syöttö 2 (kytkin)",
                "DK": "Åbent kredsløb, sensorforsyning 2 (fæste)",
                "NO": "Åpen krets, sensortilførsel 2 (feste)",
                "LV": "Pārtraukta ķēde, sensora barošana 2 (savienotājs)",
                "NL": "Open circuit, sensorvoeding 2 (koppeling)",
                "CZ": "Přerušený obvod, napájení senzor 2 (spojka)",
                "PL": "Obwód otwarty, zasilanie czujnika 2 (Łącznik)",
                "EE": "Tühijooksupinge, anduri toide 2 (haakeseadis)",
                "PT": "Abrir circuito, sensor de alimentação 2 (Acoplador)",
                "ES": "Circuito abierto, sensor de alim. 2 (Acoplador)",
                "IT": "Circuito aperto, alim.. sensore 2 (attacco)",
                "LT": "Atvira grandinė, jutiklio tiekimas 2 (jungtis)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "313": {
                "GB": "Open circuit, sensor supply 3 (PRLOCK)",
                "SE": "Kabelbrott, sensormatning 3 (PRLOCK)",
                "DE": "Schaltkreis offen, Sensorversorgung 3 (PRLOCK)",
                "FR": "Circuit ouvert, alimentation capteur 3 (PRLOCK)",
                "FI": "Avoin virtapiiri, anturin syöttö 3 (PRLOCK)",
                "DK": "Åbent kredsløb, sensorforsyning 3 (PRLOCK)",
                "NO": "Åpen krets, sensortilførsel 3 (PRLOCK)",
                "LV": "Pārtraukta ķēde, sensora barošana 3 (PRLOCK)",
                "NL": "Open circuit, sensorvoeding 3 (PRLOCK)",
                "CZ": "Přerušený obvod, napájení senzor 3  (PRLOCK)",
                "PL": "Obwód otwarty, czujnik zasil. 3 (PRLOCK)",
                "EE": "Tühijooksupinge, anduri toide 3 (PRLOCK)",
                "PT": "Abrir circuito, sensor de alim. 3 (PRLOCK)",
                "ES": "Circuito abierto, sensor de alim. 3 (PRLOCK)",
                "IT": "Circuito aperto, alim. sensore 3 (PRLOCK)",
                "LT": "Atvira grandinė, jutiklio tiekimas 3 (PRLOCK)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "314": {
                "GB": "Open circuit, sensor supply 4 (Spare)",
                "SE": "Kabelbrott, sensormatning 4 (Reserv)",
                "DE": "Schaltkreis offen, Sensorversorgung 4 (Ersatz)",
                "FR": "Circuit ouvert, alimentat. capteur 4 (Réserve)",
                "FI": "Avoin virtapiiri, anturin syöttö 4 (vara)",
                "DK": "Åbent kredsløb, sensorforsyning 4 (reserve)",
                "NO": "Åpen krets, sensortilførsel 4 (reserve)",
                "LV": "Pārtraukta ķēde, sensora barošana 4 (rezerve)",
                "NL": "Open circuit, sensorvoeding 4 (reserve)",
                "CZ": "Přerušený obvod, napájení senzor 4 (záloha)",
                "PL": "Obwód otwarty, czujnik zasil. 4 (spare)",
                "EE": "Tühijooksupinge, anduri toide 4 (varu)",
                "PT": "Abrir circuito, sensor de alim. 4 (vago)",
                "ES": "Circuito abierto, sensor de alim. 4 (Libre)",
                "IT": "Circuito aperto, alim. sensore 4 (ricambio)",
                "LT": "Atvira grandinė, jutiklio tiekimas 4 (atsarg.)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "315": {
                "GB": "Open circuit, sensor supply 5 (Rotation)",
                "SE": "Kabelbrott, sensormatning 5 (Rotation)",
                "DE": "Schaltkreis offen, Sensorversorgung 5 (Drehung)",
                "FR": "Circuit ouvert, alimentation capteur 5 (Rotation)",
                "FI": "Avoin virtapiiri, anturin syöttö 5 (kierto)",
                "DK": "Åbent kredsløb, sensorforsyning 5 (rotation)",
                "NO": "Åpen krets, sensortilførsel 5 (rotasjon)",
                "LV": "Pārtraukta ķēde, sensora barošana 5 (Rotācijas)",
                "NL": "Open circuit, sensorvoeding 5 (rotatie)",
                "CZ": "Přerušený obvod, napájení senzor 5 (Otáčení)",
                "PL": "Obwód otwarty, czujnik zasil. 5 (obrotu)",
                "EE": "Tühijooksupinge, anduri toide 5  (Pööramine)",
                "PT": "Abrir circuito, sensor de alim. 5 (Rot.)",
                "ES": "Circuito abierto, sensor de alim. 5 (Rotación)",
                "IT": "Circuito aperto, alim. sensore 5 (Rotazione)",
                "LT": "Atvira grandinė, jutiklio tiekimas 5 (Sukimas)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "316": {
                "GB": "Open circuit, sensor supply 6 (Index)",
                "SE": "Kabelbrott, sensormatning 6 (Index)",
                "DE": "Schaltkreis offen, Sensorversorgung 6 (Index)",
                "FR": "Circuit ouvert, alimentation capteur 6 (Index)",
                "FI": "Avoin virtapiiri, anturin syöttö 6 (index)",
                "DK": "Åbent kredsløb, sensorforsyning 6 (indeks)",
                "NO": "Åpen krets, sensortilførsel 6 (indeks)",
                "LV": "Pārtraukta ķēde, sensora barošana 6 (indeksa)",
                "NL": "Open circuit, sensorvoeding 6 (index)",
                "CZ": "Přerušený obvod, napájení senzor 6 (Index)",
                "PL": "Obwód otwarty, czujnik zasil. 6 (Index)",
                "EE": "Tühijooksupinge, anduri toide 6 (Indeksi)",
                "PT": "Abrir circuito, sensor de alim. 6 (Índice)",
                "ES": "Circuito abierto, sensor de alim. 6 (Ìndice)",
                "IT": "Circuito aperto, alim. sensore 6 (indice)",
                "LT": "Atvira grandinė, jutiklio tiekimas 6 (Indekso)",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "497": {
                "GB": "MIC40, CAN bus timeout",
                "SE": "MIC40, CAN bus timeout",
                "DE": "MIC40, CAN bus timeout",
                "FR": "MIC40, CAN bus timeout",
                "FI": "MIC40, CAN bus timeout",
                "DK": "MIC40, CAN bus timeout",
                "NO": "MIC40, CAN bus timeout",
                "LV": "MIC40, CAN bus timeout",
                "NL": "MIC40, CAN bus timeout",
                "CZ": "MIC40, CAN bus timeout",
                "PL": "MIC40, CAN bus timeout",
                "EE": "MIC40, CAN bus timeout",
                "PT": "MIC40, CAN bus timeout",
                "ES": "MIC40, CAN bus timeout",
                "IT": "MIC40, CAN bus timeout",
                "LT": "MIC40, CAN bus timeout",
                "KR": "MIC40, CAN bus timeout",
                "JP": "MIC40, CAN bus timeout",
                "IS": "MIC40, CAN bus timeout"
            },
            "498": {
                "GB": "MIC40, API mismatch",
                "SE": "MIC40, API mismatch",
                "DE": "MIC40, API mismatch",
                "FR": "MIC40, API mismatch",
                "FI": "MIC40, API mismatch",
                "DK": "MIC40, API mismatch",
                "NO": "MIC40, API mismatch",
                "LV": "MIC40, API mismatch",
                "NL": "MIC40, API mismatch",
                "CZ": "MIC40, API mismatch",
                "PL": "MIC40, API mismatch",
                "EE": "MIC40, API mismatch",
                "PT": "MIC40, API mismatch",
                "ES": "MIC40, API mismatch",
                "IT": "MIC40, API mismatch",
                "LT": "MIC40, API mismatch",
                "KR": "MIC40, API mismatch",
                "JP": "MIC40, API mismatch",
                "IS": "MIC40, API mismatch"
            },
            "499": {
                "GB": "MIC40, CAN bus tampering",
                "SE": "MIC40, CAN bus tampering",
                "DE": "MIC40, CAN bus tampering",
                "FR": "MIC40, CAN bus tampering",
                "FI": "MIC40, CAN bus tampering",
                "DK": "MIC40, CAN bus tampering",
                "NO": "MIC40, CAN bus tampering",
                "LV": "MIC40, CAN bus tampering",
                "NL": "MIC40, CAN bus tampering",
                "CZ": "MIC40, CAN bus tampering",
                "PL": "MIC40, CAN bus tampering",
                "EE": "MIC40, CAN bus tampering",
                "PT": "MIC40, CAN bus tampering",
                "ES": "MIC40, CAN bus tampering",
                "IT": "MIC40, CAN bus tampering",
                "LT": "MIC40, CAN bus tampering",
                "KR": "MIC40, CAN bus tampering",
                "JP": "MIC40, CAN bus tampering",
                "IS": "MIC40, CAN bus tampering"
            },
            "501": {
                "GB": "Service, 8h",
                "SE": "Service, 8h",
                "DE": "Service, 8h",
                "FR": "Entretien, 8 h",
                "FI": "Huolto, 8h",
                "DK": "Service, 8t",
                "NO": "Service, 8 t",
                "LV": "Apkope, 8h",
                "NL": "Service, 8u",
                "CZ": "Servis, 8 h",
                "PL": "Usługa, 8h",
                "EE": "Hooldus, 8h",
                "PT": "Serviço, 8h",
                "ES": "Servicio, 8 h",
                "IT": "Assistenza, 8h",
                "LT": "Tech. priež., 8 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "502": {
                "GB": "Service, 40h",
                "SE": "Service, 40h",
                "DE": "Service, 40h",
                "FR": "Entretien, 40 h",
                "FI": "Huolto, 40h",
                "DK": "Service, 40t",
                "NO": "Service, 40 t",
                "LV": "Apkope,40h",
                "NL": "Service, 40 u",
                "CZ": "Servis, 40 h",
                "PL": "Usługa, 40h",
                "EE": "Hooldus, 40h",
                "PT": "Serviço, 40h",
                "ES": "Servicio, 40 h",
                "IT": "Assistenza, 40h",
                "LT": "Tech. priež., 40 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "503": {
                "GB": "Service, 160h",
                "SE": "Service, 160h",
                "DE": "Service, 160h",
                "FR": "Entretien, 160 h",
                "FI": "Huolto, 160h",
                "DK": "Service, 160t",
                "NO": "Service, 160 t",
                "LV": "Apkope, 160h",
                "NL": "Service, 160 u",
                "CZ": "Servis, 160 h",
                "PL": "Usługa, 160h",
                "EE": "Hooldus, 160h",
                "PT": "Serviço, 160h",
                "ES": "Servicio, 160 h",
                "IT": "Assistenza, 160h",
                "LT": "Tech. priež., 160 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "504": {
                "GB": "Service, 500/1000h",
                "SE": "Service, 500/1000h",
                "DE": "Service, 500/1000h",
                "FR": "Entretien, 500/1000 h",
                "FI": "Huolto, 500/1000h",
                "DK": "Service, 500/1000t",
                "NO": "Service, 500/1000 t",
                "LV": "Apkope, 500/1000h",
                "NL": "Service, 500/1000 u",
                "CZ": "Servis, 500/1000 h",
                "PL": "Usługa, 500/1000 h",
                "EE": "Hooldus, 500/1000h",
                "PT": "Serviço, 500/1000h",
                "ES": "Servicio, 500/ 1000 h",
                "IT": "Assistenza, 500/1000h",
                "LT": "Tech. priež., 500/1000 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "600": {
                "GB": "Left joystick, off-line",
                "SE": "Vänster joystick, offline",
                "DE": "Linker Joystick, offline",
                "FR": "Joystick gauche, hors ligne",
                "FI": "Vasen ohjain, ei yhteyttä",
                "DK": "Venstre joystick, offline",
                "NO": "Venstre styrespak, frakoblet",
                "LV": "Kr. vadības svira, off-line",
                "NL": "Linker joystick, offline",
                "CZ": "Levý joystick, off-line",
                "PL": "Lewy uchwyt, offline",
                "EE": "Vasak juhtkang, offline",
                "PT": "Joystick esquerdo, offline",
                "ES": "Mando Izquierdo, offline",
                "IT": "Joystick sinistro, offline",
                "LT": "Kair. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "601": {
                "GB": "Left joystick, error on roller: LR1",
                "SE": "Vänster joystick, rullfel: LR1",
                "DE": "Linker Joystick, Fehler an Rolle: LR1",
                "FR": "Joystick gauche, erreur sur roller : LR1",
                "FI": "Vasen ohjain, virhe rullassa: LR1",
                "DK": "Venstre joystick, fejl på rulle: LR1",
                "NO": "Venstre styrespak, feil på rulle: LR1",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR1",
                "NL": "Linker joystick, fout roller: LR1",
                "CZ": "Levý joystick, chyba na válci: LR1",
                "PL": "Lewy uchwyt, error na rolka: LR1",
                "EE": "Vasak juhtkang, rulli viga: LR1",
                "PT": "Joystick esquerdo, erro no rolo: LR1",
                "ES": "Mando izquierdo, error de rótula LR1",
                "IT": "Joystick sinistro, errore sul selettore: LR1",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "602": {
                "GB": "Left joystick, error on roller: LR2",
                "SE": "Vänster joystick, rullfel: LR2",
                "DE": "Linker Joystick, Fehler an Rolle: LR2",
                "FR": "Joystick gauche, erreur sur roller : LR2",
                "FI": "Vasen ohjain, virhe rullassa: LR2",
                "DK": "Venstre joystick, fejl på rulle: LR2",
                "NO": "Venstre styrespak, feil på rulle: LR2",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR2",
                "NL": "Linker joystick, fout roller: LR2",
                "CZ": "Levý joystick, chyba na válci: LR2",
                "PL": "Lewy uchwyt, error na rolka: LR2",
                "EE": "Vasak juhtkang, rulli viga: LR2",
                "PT": "Joystick esquerdo, erro no rolo: LR2",
                "ES": "Mando izquierdo, error de rótula LR2",
                "IT": "Joystick sinistro, errore sul selettore: LR2",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "603": {
                "GB": "Left joystick, error on roller: LR3",
                "SE": "Vänster joystick, rullfel: LR3",
                "DE": "Linker Joystick, Fehler an Rolle: LR3",
                "FR": "Joystick gauche, erreur sur roller : LR3",
                "FI": "Vasen ohjain, virhe rullassa: LR3",
                "DK": "Venstre joystick, fejl på rulle: LR3",
                "NO": "Venstre styrespak, feil på rulle: LR3",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR3",
                "NL": "Linker joystick, fout roller: LR3",
                "CZ": "Levý joystick, chyba na válci: LR3",
                "PL": "Lewy uchwyt, error na rolka: LR3",
                "EE": "Vasak juhtkang, rulli viga: LR3",
                "PT": "Joystick esquerdo, erro no rolo: LR3",
                "ES": "Mando izquierdo, error de rótula LR3",
                "IT": "Joystick sinistro, errore sul selettore: LR3",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "604": {
                "GB": "Left joystick, error on roller: LR4",
                "SE": "Vänster joystick, rullfel: LR4",
                "DE": "Linker Joystick, Fehler an Rolle: LR4",
                "FR": "Joystick gauche, erreur sur roller : LR4",
                "FI": "Vasen ohjain, virhe rullassa: LR4",
                "DK": "Venstre joystick, fejl på rulle: LR4",
                "NO": "Venstre styrespak, feil på rulle: LR4",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR4",
                "NL": "Linker joystick, fout roller: LR4",
                "CZ": "Levý joystick, chyba na válci: LR4",
                "PL": "Lewy uchwyt, error na rolka: LR4",
                "EE": "Vasak juhtkang, rulli viga: LR4",
                "PT": "Joystick esquerdo, erro no rolo: LR4",
                "ES": "Mando izquierdo, error de rótula LR4",
                "IT": "Joystick sinistro, errore sul selettore: LR4",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "611": {
                "GB": "Left joystick, error on button: LS1",
                "SE": "Vänster joystick, knappfel: LS1",
                "DE": "Linker Joystick, Fehler an Taste: LS1",
                "FR": "Joystick gauche, erreur sur bouton : LS1",
                "FI": "Vasen ohjain, virhe painikkeessa: LS1",
                "DK": "Venstre joystick, fejl på knap: LS1",
                "NO": "Venstre styrespak, feil på knapp: LS1",
                "LV": "Kreisā vadības svira, pogas kļūda: LS1",
                "NL": "Linker joystick, fout op knop: LS1",
                "CZ": "Levý joystick, chyba na tlačítku: LS1",
                "PL": "Lewy uchwyt, error na przycisku: LS1",
                "EE": "Vasak juhtkang, viga nupul: LS1",
                "PT": "Joystick esquerdo, erro no botão: LS1",
                "ES": "Mando izquierdo, error de botón: LS1",
                "IT": "Joystick sinistro, errore sul pulsante: LS1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "612": {
                "GB": "Left joystick, error on button: LS2",
                "SE": "Vänster joystick, knappfel: LS2",
                "DE": "Linker Joystick, Fehler an Taste: LS2",
                "FR": "Joystick gauche, erreur sur bouton : LS2",
                "FI": "Vasen ohjain, virhe painikkeessa: LS2",
                "DK": "Venstre joystick, fejl på knap: LS2",
                "NO": "Venstre styrespak, feil på knapp: LS2",
                "LV": "Kreisā vadības svira, pogas kļūda: LS2",
                "NL": "Linker joystick, fout op knop: LS2",
                "CZ": "Levý joystick, chyba na tlačítku: LS2",
                "PL": "Lewy uchwyt, error na przycisku: LS2",
                "EE": "Vasak juhtkang, viga nupul: LS2",
                "PT": "Joystick esquerdo, erro no botão: LS2",
                "ES": "Mando izquierdo, error de botón: LS2",
                "IT": "Joystick sinistro, errore sul pulsante: LS2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "613": {
                "GB": "Left joystick, error on button: LS3",
                "SE": "Vänster joystick, knappfel: LS3",
                "DE": "Linker Joystick, Fehler an Taste: LS3",
                "FR": "Joystick gauche, erreur sur bouton : LS3",
                "FI": "Vasen ohjain, virhe painikkeessa: LS3",
                "DK": "Venstre joystick, fejl på knap: LS3",
                "NO": "Venstre styrespak, feil på knapp: LS3",
                "LV": "Kreisā vadības svira, pogas kļūda: LS3",
                "NL": "Linker joystick, fout op knop: LS3",
                "CZ": "Levý joystick, chyba na tlačítku: LS3",
                "PL": "Lewy uchwyt, error na przycisku: LS3",
                "EE": "Vasak juhtkang, viga nupul: LS3",
                "PT": "Joystick esquerdo, erro no botão: LS3",
                "ES": "Mando izquierdo, error de botón: LS3",
                "IT": "Joystick sinistro, errore sul pulsante: LS3",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "614": {
                "GB": "Left joystick, error on button: LS4",
                "SE": "Vänster joystick, knappfel: LS4",
                "DE": "Linker Joystick, Fehler an Taste: LS4",
                "FR": "Joystick gauche, erreur sur bouton : LS4",
                "FI": "Vasen ohjain, virhe painikkeessa: LS4",
                "DK": "Venstre joystick, fejl på knap: LS4",
                "NO": "Venstre styrespak, feil på knapp: LS4",
                "LV": "Kreisā vadības svira, pogas kļūda: LS4",
                "NL": "Linker joystick, fout op knop: LS4",
                "CZ": "Levý joystick, chyba na tlačítku: LS4",
                "PL": "Lewy uchwyt, error na przycisku: LS4",
                "EE": "Vasak juhtkang, viga nupul: LS4",
                "PT": "Joystick esquerdo, erro no botão: LS4",
                "ES": "Mando izquierdo, error de botón: LS4",
                "IT": "Joystick sinistro, errore sul pulsante: LS4",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "615": {
                "GB": "Left joystick, error on button: LS5",
                "SE": "Vänster joystick, knappfel: LS5",
                "DE": "Linker Joystick, Fehler an Taste: LS5",
                "FR": "Joystick gauche, erreur sur bouton : LS5",
                "FI": "Vasen ohjain, virhe painikkeessa: LS5",
                "DK": "Venstre joystick, fejl på knap: LS5",
                "NO": "Venstre styrespak, feil på knapp: LS5",
                "LV": "Kreisā vadības svira, pogas kļūda: LS5",
                "NL": "Linker joystick, fout op knop: LS5",
                "CZ": "Levý joystick, chyba na tlačítku: LS5",
                "PL": "Lewy uchwyt, error na przycisku: LS5",
                "EE": "Vasak juhtkang, viga nupul: LS5",
                "PT": "Joystick esquerdo, erro no botão: LS5",
                "ES": "Mando izquierdo, error de botón: LS5",
                "IT": "Joystick sinistro, errore sul pulsante: LS5",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "616": {
                "GB": "Left joystick, error on button: LS6",
                "SE": "Vänster joystick, knappfel: LS6",
                "DE": "Linker Joystick, Fehler an Taste: LS6",
                "FR": "Joystick gauche, erreur sur bouton : LS6",
                "FI": "Vasen ohjain, virhe painikkeessa: LS6",
                "DK": "Venstre joystick, fejl på knap: LS6",
                "NO": "Venstre styrespak, feil på knapp: LS6",
                "LV": "Kreisā vadības svira, pogas kļūda: LS6",
                "NL": "Linker joystick, fout op knop: LS6",
                "CZ": "Levý joystick, chyba na tlačítku: LS6",
                "PL": "Lewy uchwyt, error na przycisku: LS6",
                "EE": "Vasak juhtkang, viga nupul: LS6",
                "PT": "Joystick esquerdo, erro no botão: LS6",
                "ES": "Mando izquierdo, error de botón: LS6",
                "IT": "Joystick sinistro, errore sul pulsante: LS6",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "617": {
                "GB": "Left joystick, error on button: LS7",
                "SE": "Vänster joystick, knappfel: LS7",
                "DE": "Linker Joystick, Fehler an Taste: LS7",
                "FR": "Joystick gauche, erreur sur bouton : LS7",
                "FI": "Vasen ohjain, virhe painikkeessa: LS7",
                "DK": "Venstre joystick, fejl på knap: LS7",
                "NO": "Venstre styrespak, feil på knapp: LS7",
                "LV": "Kreisā vadības svira, pogas kļūda: LS7",
                "NL": "Linker joystick, fout op knop: LS7",
                "CZ": "Levý joystick, chyba na tlačítku: LS7",
                "PL": "Lewy uchwyt, error na przycisku: LS7",
                "EE": "Vasak juhtkang, viga nupul: LS7",
                "PT": "Joystick esquerdo, erro no botão: LS7",
                "ES": "Mando izquierdo, error de botón: LS7",
                "IT": "Joystick sinistro, errore sul pulsante: LS7",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "618": {
                "GB": "Left joystick, error on button: LS8",
                "SE": "Vänster joystick, knappfel: LS8",
                "DE": "Linker Joystick, Fehler an Taste: LS8",
                "FR": "Joystick gauche, erreur sur bouton : LS8",
                "FI": "Vasen ohjain, virhe painikkeessa: LS8",
                "DK": "Venstre joystick, fejl på knap: LS8",
                "NO": "Venstre styrespak, feil på knapp: LS8",
                "LV": "Kreisā vadības svira, pogas kļūda: LS8",
                "NL": "Linker joystick, fout op knop: LS8",
                "CZ": "Levý joystick, chyba na tlačítku: LS8",
                "PL": "Lewy uchwyt, error na przycisku: LS8",
                "EE": "Vasak juhtkang, viga nupul: LS8",
                "PT": "Joystick esquerdo, erro no botão: LS8",
                "ES": "Mando izquierdo, error de botón: LS8",
                "IT": "Joystick sinistro, errore sul pulsante: LS8",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "619": {
                "GB": "Left joystick, error on button: LFNR1",
                "SE": "Vänster joystick, knappfel: LFNR1",
                "DE": "Linker Joystick, Fehler an Taste: LFNR1",
                "FR": "Joystick gauche, erreur sur bouton : LFNR1",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR1",
                "DK": "Venstre joystick, fejl på knap: LFNR1",
                "NO": "Venstre styrespak, feil på knapp: LFNR1",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR1",
                "NL": "Linker joystick, fout op knop: LFNR1",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR1",
                "PL": "Lewy joystick, błąd na przycisku: LFNR1",
                "EE": "Vasak juhtkang, viga nupul: LFNR1",
                "PT": "Joystick esquerdo, erro no botão: LFNR1",
                "ES": "Mando izquierdo, error de botón: LFNR1",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "620": {
                "GB": "Left joystick, error on button: LFNR2",
                "SE": "Vänster joystick, knappfel: LFNR2",
                "DE": "Linker Joystick, Fehler an Taste: LFNR2",
                "FR": "Joystick gauche, erreur sur bouton : LFNR2",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR2",
                "DK": "Venstre joystick, fejl på knap: LFNR2",
                "NO": "Venstre styrespak, feil på knapp: LFNR2",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR2",
                "NL": "Linker joystick, fout op knop: LFNR2",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR2",
                "PL": "Lewy uchwyt, error na przycisku: LFNR2",
                "EE": "Vasak juhtkang, viga nupul: LFNR2",
                "PT": "Joystick esquerdo, erro no botão: LFNR2",
                "ES": "Mando izquierdo, error de botón: LFNR2",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "631": {
                "GB": "Left joystick, CAN1 problem",
                "SE": "Vänster joystick, CAN1 problem",
                "DE": "Linker Joystick, CAN1-Problem",
                "FR": "Joystick gauche, problème CAN1",
                "FI": "Vasen ohjain, CAN1-ongelma",
                "DK": "Venstre joystick, CAN1-problem",
                "NO": "Venstre styrespak, CAN1-problem",
                "LV": "Kreisā vadības svira, CAN1 problēma",
                "NL": "Linker joystick, CAN1 probleem",
                "CZ": "Levý joystick, problém CAN1",
                "PL": "Lewy joystick, problem z CAN1",
                "EE": "Vasak juhtkang, CAN1 probleem",
                "PT": "Joystick esquerdo, problema CAN1",
                "ES": "Mando izquierdo, error de CAN1:",
                "IT": "Joystick sinistro, problema CAN1",
                "LT": "Kair. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "632": {
                "GB": "Left joystick, CRC error flash1",
                "SE": "Vänster joystick, CRC-fel flash1",
                "DE": "Linker Joystick, CRC-Fehler Blink1",
                "FR": "Joystick gauche, erreur CRC flash1",
                "FI": "Vasen ohjain, CRC-virhe valo1",
                "DK": "Venstre joystick, CRC-fejlblink1",
                "NO": "Venstre styrespak, CRC-feil flash1",
                "LV": "Kreisā vadības svira, CRC kļūda flash1",
                "NL": "Linker joystick, CRC fout flash1",
                "CZ": "Levý joystick, CRC chyba flash1",
                "PL": "Lewy uchwyt, CRC error flash1",
                "EE": "Vasak juhtkang, CRC viga flash1",
                "PT": "Joystick esquerdo, CRC erro flash1",
                "ES": "Mando izquierdo, error de CAN1, flash1",
                "IT": "Joystick sinistro, CRC errore flash1",
                "LT": "Kair. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "633": {
                "GB": "Left joystick, CAN2 problem",
                "SE": "Vänster joystick, CAN2 problem",
                "DE": "Linker Joystick, CAN2-Problem",
                "FR": "Joystick gauche, problème CAN2",
                "FI": "Vasen ohjain, CAN2-ongelma",
                "DK": "Venstre joystick, CAN2-problem",
                "NO": "Venstre styrespak, CAN2-problem",
                "LV": "Kreisā vadības svira, CAN2 problēma",
                "NL": "Linker joystick, CAN2 probleem",
                "CZ": "Levý joystick, problém CAN2",
                "PL": "Lewy uchwyt, CAN2 problem",
                "EE": "Vasak juhtkang, CAN2 probleem",
                "PT": "Joystick esquerdo, problema CAN2",
                "ES": "Mando izquierdo, error de CAN2",
                "IT": "Joystick sinistro, problema CAN2",
                "LT": "Kair. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "634": {
                "GB": "Left joystick, CRC error flash2",
                "SE": "Vänster joystick, CRC-fel flash2",
                "DE": "Linker Joystick, CRC-Fehler Blink2",
                "FR": "Joystick gauche, erreur CRC flash2",
                "FI": "Vasen ohjain, CRC-virhe valo2",
                "DK": "Venstre joystick, CRC-fejlblink2",
                "NO": "Venstre styrespak, CRC-feil flash2",
                "LV": "Kreisā vadības svira, CRC kļūda flash2",
                "NL": "Linker joystick, CRC fout flash2",
                "CZ": "Levý joystick, CRC chyba flash2",
                "PL": "Lewy uchwyt, CRC error flash2",
                "EE": "Vasak juhtkang, CRC viga flash2",
                "PT": "Joystick esquerdo, CRC erro flash2",
                "ES": "Mando izquierdo, error de CAN1, flash2",
                "IT": "Joystick sinistro, CRC errore flash2",
                "LT": "Kair. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "649": {
                "GB": "Left joystick, internal error",
                "SE": "Vänster joystick, internt fel",
                "DE": "Linker Joystick, interner Fehler",
                "FR": "Joystick gauche, erreur interne",
                "FI": "Vasen ohjain, sisäinen virhe",
                "DK": "Venstre joystick, intern fejl",
                "NO": "Venstre styrespak, intern feil",
                "LV": "Kr. vadības svira, iekšējās kļūda",
                "NL": "Linker joystick, interne fout",
                "CZ": "Levý joystick, interní chyba",
                "PL": "Lewy uchwyt, internal error",
                "EE": "Vasak juhtkang, sisemine viga",
                "PT": "Joystick esquerdo, erro interno",
                "ES": "Mando izquierdo, error interno",
                "IT": "Joystick sinistro, errore interno",
                "LT": "Kair. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "650": {
                "GB": "Right joystick, off-line",
                "SE": "Höger joystick, offline",
                "DE": "Rechter Joystick, offline",
                "FR": "Joystick droit, hors ligne",
                "FI": "Oikea ohjain, ei yhteyttä",
                "DK": "Højre joystick, offline",
                "NO": "Høyre styrespak, frakoblet",
                "LV": "La. vadības svira, off-line",
                "NL": "Rechter joystick, off-line",
                "CZ": "Pravý joystick, off-line",
                "PL": "Prawy uchwyt, offline",
                "EE": "Parem juhtkang, offline",
                "PT": "Joystick direito, offline",
                "ES": "Mando derecho, inactivado",
                "IT": "Joystick destro, offline",
                "LT": "Dešin. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "651": {
                "GB": "Right joystick, error on roller: RR1",
                "SE": "Höger joystick, rullfel: RR1",
                "DE": "Rechter Joystick, Fehler an Rolle: RR1",
                "FR": "Joystick droit, erreur sur roller : RR1",
                "FI": "Oikea ohjain, virhe rullassa: RR1",
                "DK": "Højre joystick, fejl på rulle: RR1",
                "NO": "Høyre styrespak, feil på rulle: RR1",
                "LV": "Labā vadības svira, ritenīša kļūda: RR1",
                "NL": "Rechter joystick, fout op roller: RR1",
                "CZ": "Pravý joystick, chyba na válci: RR1",
                "PL": "Prawy uchwyt, error na rolka: RR1",
                "EE": "Parem juhtkang, rulli viga: RR1",
                "PT": "Joystick direito, erro no rolo: RR1",
                "ES": "Mando derecho, error de rótula: RR1",
                "IT": "Joystick destro, errore sul selettore: RR1",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "652": {
                "GB": "Right joystick, error on roller: RR2",
                "SE": "Höger joystick, rullfel: RR2",
                "DE": "Rechter Joystick, Fehler an Rolle: RR2",
                "FR": "Joystick droit, erreur sur roller : RR2",
                "FI": "Oikea ohjain, virhe rullassa: RR2",
                "DK": "Højre joystick, fejl på rulle: RR2",
                "NO": "Høyre styrespak, feil på rulle: RR2",
                "LV": "Labā vadības svira, ritenīša kļūda: RR2",
                "NL": "Rechter joystick, fout op roller: RR2",
                "CZ": "Pravý joystick, chyba na válci: RR2",
                "PL": "Prawy uchwyt, error na rolka: RR2",
                "EE": "Parem juhtkang, rulli viga: RR2",
                "PT": "Joystick direito, erro no rolo: RR2",
                "ES": "Mando derecho, error de rótula: RR2",
                "IT": "Joystick destro, errore sul selettore: RR2",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "653": {
                "GB": "Right joystick, error on roller: RR3",
                "SE": "Höger joystick, rullfel: RR3",
                "DE": "Rechter Joystick, Fehler an Rolle: RR3",
                "FR": "Joystick droit, erreur sur roller : RR3",
                "FI": "Oikea ohjain, virhe rullassa: RR3",
                "DK": "Højre joystick, fejl på rulle: RR3",
                "NO": "Høyre styrespak, feil på rulle: RR3",
                "LV": "Labā vadības svira, ritenīša kļūda: RR3",
                "NL": "Rechter joystick, fout op roller: RR3",
                "CZ": "Pravý joystick, chyba na válci: RR3",
                "PL": "Prawy uchwyt, error na rolka: RR3",
                "EE": "Parem juhtkang, rulli viga: RR3",
                "PT": "Joystick direito, erro no rolo: RR3",
                "ES": "Mando derecho, error de rótula: RR3",
                "IT": "Joystick destro, errore sul selettore: RR3",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "654": {
                "GB": "Right joystick, error on roller: RR4",
                "SE": "Höger joystick, rullfel: RR4",
                "DE": "Rechter Joystick, Fehler an Rolle: RR4",
                "FR": "Joystick droit, erreur sur roller : RR4",
                "FI": "Oikea ohjain, virhe rullassa: RR4",
                "DK": "Højre joystick, fejl på rulle: RR4",
                "NO": "Høyre styrespak, feil på rulle: RR4",
                "LV": "Labā vadības svira, ritenīša kļūda: RR4",
                "NL": "Rechter joystick, fout op roller: RR4",
                "CZ": "Pravý joystick, chyba na válci: RR4",
                "PL": "Prawy uchwyt, error na rolka: RR4",
                "EE": "Parem juhtkang, rulli viga: RR4",
                "PT": "Joystick direito, erro no rolo: RR4",
                "ES": "Mando derecho, error de rótula: RR4",
                "IT": "Joystick destro, errore sul selettore: RR4",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "661": {
                "GB": "Right joystick, error on button: RS1",
                "SE": "Höger joystick, knappfel: RS1",
                "DE": "Rechter Joystick, Fehler an Taste: RS1",
                "FR": "Joystick droit, erreur sur bouton : RS1",
                "FI": "Oikea ohjain, virhe painikkeessa: RS1",
                "DK": "Højre joystick, fejl på knap: RS1",
                "NO": "Høyre styrespak, feil på knapp: RS1",
                "LV": "Labā vadības svira, pogas kļūda: RS1",
                "NL": "Rechter joystick, fout op knop: RS1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS1",
                "PL": "Prawy uchwyt, error na Przycisk: RS1",
                "EE": "Parem juhtkang, viga nupul: RS1",
                "PT": "Joystick direito, erro no botão: RS1",
                "ES": "Mando derecho, error de botón: RS1",
                "IT": "Joystick destro, errore sul pulsante: RS1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "662": {
                "GB": "Right joystick, error on button: RS2",
                "SE": "Höger joystick, knappfel: RS2",
                "DE": "Rechter Joystick, Fehler an Taste: RS2",
                "FR": "Joystick droit, erreur sur bouton : RS2",
                "FI": "Oikea ohjain, virhe painikkeessa: RS2",
                "DK": "Højre joystick, fejl på knap: RS2",
                "NO": "Høyre styrespak, feil på knapp: RS2",
                "LV": "Labā vadības svira, pogas kļūda: RS2",
                "NL": "Rechter joystick, fout op knop: RS2",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS2",
                "PL": "Prawy uchwyt, error na Przycisk: RS2",
                "EE": "Parem juhtkang, viga nupul: RS2",
                "PT": "Joystick direito, erro no botão: RS2",
                "ES": "Mando derecho, error de botón: RS2",
                "IT": "Joystick destro, errore sul pulsante: RS2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "663": {
                "GB": "Right joystick, error on button: RS3",
                "SE": "Höger joystick, knappfel: RS3",
                "DE": "Rechter Joystick, Fehler an Taste: RS3",
                "FR": "Joystick droit, erreur sur bouton : RS3",
                "FI": "Oikea ohjain, virhe painikkeessa: RS3",
                "DK": "Højre joystick, fejl på knap: RS3",
                "NO": "Høyre styrespak, feil på knapp: RS3",
                "LV": "Labā vadības svira, pogas kļūda: RS3",
                "NL": "Rechter joystick, fout op knop: RS3",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS3",
                "PL": "Prawy uchwyt, error na Przycisk: RS3",
                "EE": "Parem juhtkang, viga nupul: RS3",
                "PT": "Joystick direito, erro no botão: RS3",
                "ES": "Mando derecho, error de botón: RS3",
                "IT": "Joystick destro, errore sul pulsante: RS3",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "664": {
                "GB": "Right joystick, error on button: RS4",
                "SE": "Höger joystick, knappfel: RS4",
                "DE": "Rechter Joystick, Fehler an Taste: RS4",
                "FR": "Joystick droit, erreur sur bouton : RS4",
                "FI": "Oikea ohjain, virhe painikkeessa: RS4",
                "DK": "Højre joystick, fejl på knap: RS4",
                "NO": "Høyre styrespak, feil på knapp: RS4",
                "LV": "Labā vadības svira, pogas kļūda: RS4",
                "NL": "Rechter joystick, fout op knop: RS4",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS4",
                "PL": "Prawy uchwyt, error na Przycisk: RS4",
                "EE": "Parem juhtkang, viga nupul: RS4",
                "PT": "Joystick direito, erro no botão: RS4",
                "ES": "Mando derecho, error de botón: RS4",
                "IT": "Joystick destro, errore sul pulsante: RS4",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "665": {
                "GB": "Right joystick, error on button: RS5",
                "SE": "Höger joystick, knappfel: RS5",
                "DE": "Rechter Joystick, Fehler an Taste: RS5",
                "FR": "Joystick droit, erreur sur bouton : RS5",
                "FI": "Oikea ohjain, virhe painikkeessa: RS5",
                "DK": "Højre joystick, fejl på knap: RS5",
                "NO": "Høyre styrespak, feil på knapp: RS5",
                "LV": "Labā vadības svira, pogas kļūda: RS5",
                "NL": "Rechter joystick, fout op knop: RS5",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS5",
                "PL": "Prawy uchwyt, error na Przycisk: RS5",
                "EE": "Parem juhtkang, viga nupul: RS5",
                "PT": "Joystick direito, erro no botão: RS5",
                "ES": "Mando derecho, error de botón: RS5",
                "IT": "Joystick destro, errore sul pulsante: RS5",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "666": {
                "GB": "Right joystick, error on button: RS6",
                "SE": "Höger joystick, knappfel: RS6",
                "DE": "Rechter Joystick, Fehler an Taste: RS6",
                "FR": "Joystick droit, erreur sur bouton : RS6",
                "FI": "Oikea ohjain, virhe painikkeessa: RS6",
                "DK": "Højre joystick, fejl på knap: RS6",
                "NO": "Høyre styrespak, feil på knapp: RS6",
                "LV": "Labā vadības svira, pogas kļūda: RS6",
                "NL": "Rechter joystick, fout op knop: RS6",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS6",
                "PL": "Prawy uchwyt, error na Przycisk: RS6",
                "EE": "Parem juhtkang, viga nupul: RS6",
                "PT": "Joystick direito, erro no botão: RS6",
                "ES": "Mando derecho, error de botón: RS6",
                "IT": "Joystick destro, errore sul pulsante: RS6",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "667": {
                "GB": "Right joystick, error on button: RS7",
                "SE": "Höger joystick, knappfel: RS7",
                "DE": "Rechter Joystick, Fehler an Taste: RS7",
                "FR": "Joystick droit, erreur sur bouton : RS7",
                "FI": "Oikea ohjain, virhe painikkeessa: RS7",
                "DK": "Højre joystick, fejl på knap: RS7",
                "NO": "Høyre styrespak, feil på knapp: RS7",
                "LV": "Labā vadības svira, pogas kļūda: RS7",
                "NL": "Rechter joystick, fout op knop: RS7",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS7",
                "PL": "Prawy uchwyt, error na Przycisk: RS7",
                "EE": "Parem juhtkang, viga nupul: RS7",
                "PT": "Joystick direito, erro no botão: RS7",
                "ES": "Mando derecho, error de botón: RS7",
                "IT": "Joystick destro, errore sul pulsante: RS7",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "668": {
                "GB": "Right joystick, error on button: RS8",
                "SE": "Höger joystick, knappfel: RS8",
                "DE": "Rechter Joystick, Fehler an Taste: RS8",
                "FR": "Joystick droit, erreur sur bouton : RS8",
                "FI": "Oikea ohjain, virhe painikkeessa: RS8",
                "DK": "Højre joystick, fejl på knap: RS8",
                "NO": "Høyre styrespak, feil på knapp: RS8",
                "LV": "Labā vadības svira, pogas kļūda: RS8",
                "NL": "Rechter joystick, fout op knop: RS8",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS8",
                "PL": "Prawy uchwyt, error na Przycisk: RS8",
                "EE": "Parem juhtkang, viga nupul: RS8",
                "PT": "Joystick direito, erro no botão: RS8",
                "ES": "Mando derecho, error de botón: RS8",
                "IT": "Joystick destro, errore sul pulsante: RS8",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "669": {
                "GB": "Right joystick, error on button: RFNR1",
                "SE": "Höger joystick, knappfel: RFNR1",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR1",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR1",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR1",
                "PT": "Joystick direito, erro no botão: RFNR1",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "670": {
                "GB": "Right joystick, error on button: RFNR2",
                "SE": "Höger joystick, knappfel: RFNR2",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR2",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR2",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR2",
                "PT": "Joystick direito, erro no botão: RFNR2",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "681": {
                "GB": "Right joystick, CAN1 problem",
                "SE": "Höger joystick, CAN1 problem",
                "DE": "Rechter Joystick, CAN1-Problem",
                "FR": "Joystick droit, problème CAN1",
                "FI": "Oikea ohjain, CAN1-ongelma",
                "DK": "Højre joystick, CAN1-problem",
                "NO": "Høyre styrespak, CAN1-problem",
                "LV": "La. vadības svira, CAN1 problēma",
                "NL": "Rechter joystick, CAN1 probleem",
                "CZ": "Pravý joystick, problém CAN1",
                "PL": "Prawy uchwyt, CAN1 problem",
                "EE": "Parem juhtkang, CAN1 probleem",
                "PT": "Joystick direito, problema de CAN1",
                "ES": "Mando derecho, error de CAN1",
                "IT": "Joystick destro, problema CAN1",
                "LT": "Dešin. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "682": {
                "GB": "Right joystick, CRC error flash1",
                "SE": "Höger joystick, CRC-fel flash1",
                "DE": "Rechter Joystick, CRC-Fehler Blink1",
                "FR": "Joystick droit, erreur CRC flash1",
                "FI": "Oikea ohjain, CRC-virhe valo1",
                "DK": "Højre joystick, CRC-fejlblink1",
                "NO": "Høyre styrespak, CRC-feil flash1",
                "LV": "La. vadības svira, CRC kļūda flash1",
                "NL": "Rechter joystick, CRC fout flash1",
                "CZ": "Pravý joystick, CRC chyba flash1",
                "PL": "Prawy uchwyt, CRC error flash1",
                "EE": "Parem juhtkang, CRC viga flash1",
                "PT": "Joystick direito, CRC erro flash1",
                "ES": "Mando derecho, error de CAN1, flash1",
                "IT": "Joystick destro, CRC errore flash1",
                "LT": "Dešin. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "683": {
                "GB": "Right joystick, CAN2 problem",
                "SE": "Höger joystick, CAN2 problem",
                "DE": "Rechter Joystick, CAN2-Problem",
                "FR": "Joystick droit, problème CAN2",
                "FI": "Oikea ohjain, CAN2-ongelma",
                "DK": "Højre joystick, CAN2-problem",
                "NO": "Høyre styrespak, CAN2-problem",
                "LV": "La. vadības svira, CAN2 problēma",
                "NL": "Rechter joystick, CAN2 probleem",
                "CZ": "Pravý joystick, problém CAN2",
                "PL": "Prawy uchwyt, CAN2 problem",
                "EE": "Parem juhtkang, CAN2 probleem",
                "PT": "Joystick direito, problema de CAN2",
                "ES": "Mando derecho, error de CAN2",
                "IT": "Joystick destro, problema CAN2",
                "LT": "Dešin. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "684": {
                "GB": "Right joystick, CRC error flash2",
                "SE": "Höger joystick, CRC-fel flash2",
                "DE": "Rechter Joystick, CRC-Fehler Blink2",
                "FR": "Joystick droit, erreur CRC flash2",
                "FI": "Oikea ohjain, CRC-virhe valo2",
                "DK": "Højre joystick, CRC-fejlblink2",
                "NO": "Høyre styrespak, CRC-feil flash2",
                "LV": "La. vadības svira, CRC kļūda flash2",
                "NL": "Rechter joystick, CRC fout flash2",
                "CZ": "Pravý joystick, CRC chyba flash2",
                "PL": "Prawy uchwyt, CRC error flash2",
                "EE": "Parem juhtkang, CRC viga flash2",
                "PT": "Joystick direito, CRC erro flash2",
                "ES": "Mando derecho, error de CAN1, flash2",
                "IT": "Joystick destro, CRC errore flash2",
                "LT": "Dešin. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "699": {
                "GB": "Right joystick, internal error",
                "SE": "Höger joystick, internt fel",
                "DE": "Rechter Joystick, interner Fehler",
                "FR": "Joystick droit, erreur interne",
                "FI": "Oikea ohjain, sisäinen virhe",
                "DK": "Højre joystick, intern fejl",
                "NO": "Høyre styrespak, intern feil",
                "LV": "La. vadības svira, iekšējās kļūda",
                "NL": "Rechter joystick, interne fout",
                "CZ": "Pravý joystick, interní chyba",
                "PL": "Prawy uchwyt, internal error",
                "EE": "Parem juhtkang, sisemine viga",
                "PT": "Joystick direito, erro interno",
                "ES": "Mando derecho, error interno",
                "IT": "Joystick destro, errore interno",
                "LT": "Dešin. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "700": {
                "GB": "CAC1, off-line",
                "SE": "CAC1, offline",
                "DE": "CAC1, offline",
                "FR": "CAC1, hors ligne",
                "FI": "CAC1, ei yhteyttä",
                "DK": "CAC1, offline",
                "NO": "CAC1, frakoblet",
                "LV": "CAC1, off-line",
                "NL": "CAC1, off-line",
                "CZ": "CAC1, off-line",
                "PL": "CAC1, off-line",
                "EE": "CAC1, off-line",
                "PT": "CAC1, off-line",
                "ES": "CAC1, offline",
                "IT": "CAC1, off-line",
                "LT": "CAC1 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "701": {
                "GB": "CAC1, error on channel1",
                "SE": "CAC1, fel på kanal1",
                "DE": "CAC1, Fehler an Kanal1",
                "FR": "CAC1, erreur sur canal1",
                "FI": "CAC1, virhe kanavassa1",
                "DK": "CAC1, fejl på kanal1",
                "NO": "CAC1, feil på kanal1",
                "LV": "CAC1, kļūda 1. kanālā",
                "NL": "CAC1, fout op kanaal1",
                "CZ": "CAC1, chyba na kanálu1",
                "PL": "CAC1, błąd na kanale 1",
                "EE": "CAC1, kanal1 viga",
                "PT": "CAC1, erro no canal1",
                "ES": "CAC1, error en canal 1",
                "IT": "CAC1, errore sul canale1",
                "LT": "CAC1, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "702": {
                "GB": "CAC1, error on channel2",
                "SE": "CAC1, fel på kanal2",
                "DE": "CAC1, Fehler an Kanal2",
                "FR": "CAC1, erreur sur canal2",
                "FI": "CAC1, virhe kanavassa2",
                "DK": "CAC1, fejl på kanal2",
                "NO": "CAC1, feil på kanal2",
                "LV": "CAC1, kļūda 2. kanālā",
                "NL": "CAC1, fout op kanaal2",
                "CZ": "CAC1, chyba na kanálu2",
                "PL": "CAC1, error on channel2",
                "EE": "CAC1, kanal2 viga",
                "PT": "CAC1, erro no canal2",
                "ES": "CAC1, error en canal 2",
                "IT": "CAC1, errore sul canale2",
                "LT": "CAC1, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "703": {
                "GB": "CAC1, CAN-bus problem",
                "SE": "CAC1, CAN-bus problem",
                "DE": "CAC1, CAN-Bus-Problem",
                "FR": "CAC1, problème de bus CAN",
                "FI": "CAC1, CAN-väyläongelma",
                "DK": "CAC1, CAN-bus-problem",
                "NO": "CAC1, CAN-bussproblem",
                "LV": "CAC1, CAN kopnes problēma",
                "NL": "CAC1, CAN-bus probleem",
                "CZ": "CAC1, problém se sběrnicí CAN",
                "PL": "CAC1, problem z magistralą CAN",
                "EE": "CAC1, CAN-siini probleem",
                "PT": "CAC1, problema de barramento CAN",
                "ES": "CAC1, error de CAN bus",
                "IT": "CAC1, problema bus CAN",
                "LT": "CAC1, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "704": {
                "GB": "CAC1, internal error",
                "SE": "CAC1, internt fel",
                "DE": "CAC1, interner Fehler",
                "FR": "CAC1, erreur interne",
                "FI": "CAC1, sisäinen virhe",
                "DK": "CAC1, intern fejl",
                "NO": "CAC1, intern feil",
                "LV": "CAC1, iekšējās kļūda",
                "NL": "CAC1, interne fout",
                "CZ": "CAC1, interní chyba",
                "PL": "CAC1, internal error",
                "EE": "CAC1, sisemine viga",
                "PT": "CAC1, erro interno",
                "ES": "CAC1, error interno",
                "IT": "CAC1, Errore interno",
                "LT": "CAC1, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "705": {
                "GB": "CAC1, CAN-bus address conflict",
                "SE": "CAC1, CAN-bus adresskonflikt",
                "DE": "CAC1, Konflikt CAN-Bus-Adresse",
                "FR": "CAC1, conflit d’adresse de bus CAN",
                "FI": "CAC1, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC1, adressekonflikt for CAN-bus",
                "NO": "CAC1, CAN-buss adressekonflikt",
                "LV": "CAC1, CAN kopnes adrešu konflikts",
                "NL": "CAC1, CAN-bus adresconflict",
                "CZ": "CAC1, konflikt adres sběrnice CAN",
                "PL": "CAC1, konflikt adresów magistrali CAN",
                "EE": "CAC1, CAN-siin tegeleb konfliktiga",
                "PT": "CAC1, conflito de endereços barramento CAN",
                "ES": "CAC1, conflicto con dirección de CAN bus",
                "IT": "CAC1, conflitto indirizzi bus CAN",
                "LT": "CAC1, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "720": {
                "GB": "CAC2, off-line",
                "SE": "CAC2, offline",
                "DE": "CAC2, offline",
                "FR": "CAC2, hors ligne",
                "FI": "CAC2, ei yhteyttä",
                "DK": "CAC2, offline",
                "NO": "CAC2, frakoblet",
                "LV": "CAC2, offline",
                "NL": "CAC2, offline",
                "CZ": "CAC2, offline",
                "PL": "CAC2, offline",
                "EE": "CAC2, offline",
                "PT": "CAC2, offline",
                "ES": "CAC2, offline",
                "IT": "CAC2, offline",
                "LT": "CAC2 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "721": {
                "GB": "CAC2, error on channel1",
                "SE": "CAC2, fel på kanal1",
                "DE": "CAC2, Fehler an Kanal1",
                "FR": "CAC2, erreur sur canal1",
                "FI": "CAC2, virhe kanavassa1",
                "DK": "CAC2, fejl på kanal1",
                "NO": "CAC2, feil på kanal1",
                "LV": "CAC2, kļūda 1. kanālā",
                "NL": "CAC2, fout op kanaal1",
                "CZ": "CAC2, chyba na kanálu1",
                "PL": "CAC2, error on channel1",
                "EE": "CAC2, kanal1 viga",
                "PT": "CAC2, erro no canal1",
                "ES": "CAC2, error en canal 1",
                "IT": "CAC2, errore sul canale1",
                "LT": "CAC2, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "722": {
                "GB": "CAC2, error on channel2",
                "SE": "CAC2, fel på kanal2",
                "DE": "CAC2, Fehler an Kanal2",
                "FR": "CAC2, erreur sur canal2",
                "FI": "CAC2, virhe kanavassa2",
                "DK": "CAC2, fejl på kanal2",
                "NO": "CAC2, feil på kanal2",
                "LV": "CAC2, kļūda 2. kanālā",
                "NL": "CAC2, fout op kanaal2",
                "CZ": "CAC2, chyba na kanálu2",
                "PL": "CAC2, error on channel2",
                "EE": "CAC2, kanal2 viga",
                "PT": "CAC2, erro no canal2",
                "ES": "CAC2, error en canal 2",
                "IT": "CAC2, errore sul canale2",
                "LT": "CAC2, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "723": {
                "GB": "CAC2, CAN-bus problem",
                "SE": "CAC2, CAN-bus problem",
                "DE": "CAC2, CAN-Bus-Problem",
                "FR": "CAC2, problème de bus CAN",
                "FI": "CAC2, CAN-väyläongelma",
                "DK": "CAC2, CAN-bus-problem",
                "NO": "CAC2, CAN-bussproblem",
                "LV": "CAC2, CAN kopnes problēma",
                "NL": "CAC2, CAN-bus probleem",
                "CZ": "CAC2, problém se sběrnicí CAN",
                "PL": "CAC2, CAN-bus problem",
                "EE": "CAC2, CAN-siini probleem",
                "PT": "CAC2, problema de barramento CAN",
                "ES": "CAC2, problema con el CAN bus",
                "IT": "CAC2, problema bus CAN",
                "LT": "CAC2, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "724": {
                "GB": "CAC2, internal error",
                "SE": "CAC2, internt fel",
                "DE": "CAC2, interner Fehler",
                "FR": "CAC2, erreur interne",
                "FI": "CAC2, sisäinen virhe",
                "DK": "CAC2, intern fejl",
                "NO": "CAC2, intern feil",
                "LV": "CAC2, iekšējās kļūda",
                "NL": "CAC2, interne fout",
                "CZ": "CAC2, interní chyba",
                "PL": "CAC2, internal error",
                "EE": "CAC2, sisemine viga",
                "PT": "CAC2, erro interno",
                "ES": "CAC2, error interno",
                "IT": "CAC2, Errore interno",
                "LT": "CAC2, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "725": {
                "GB": "CAC2, CAN-bus address conflict",
                "SE": "CAC2, CAN-bus adresskonflikt",
                "DE": "CAC2, Konflikt CAN-Bus-Adresse",
                "FR": "CAC2, conflit d’adresse de bus CAN",
                "FI": "CAC2, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC2, adressekonflikt for CAN-bus",
                "NO": "CAC2, CAN-buss adressekonflikt",
                "LV": "CAC2, CAN kopnes adrešu konflikts",
                "NL": "CAC2, CAN-bus adresconflict",
                "CZ": "CAC2, konflikt adres sběrnice CAN",
                "PL": "CAC2, CAN-bus address conflict",
                "EE": "CAC2, CAN-siin tegeleb konfliktiga",
                "PT": "CAC2, conflito de endereços barramento CAN",
                "ES": "CAC2, conflicto con dirección de CAN bus",
                "IT": "CAC2, conflitto indirizzi bus CAN",
                "LT": "CAC2, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "801": {
                "GB": "HW FAIL I2C EEPROM FAILED",
                "SE": "HW FAIL I2C EEPROM FAILED",
                "DE": "HW FEHLER I2C EEPROM FEHLER",
                "FR": "DÉFAIL. MAT. ÉCHEC EEPROM I2C",
                "FI": "HW FAIL I2C EEPROM FAILED",
                "DK": "HW-FEJL, I2C EEPROM MISLYKKEDES",
                "NO": "HW FEIL I2C EEPROM FEIL",
                "LV": "HW Neizdevās I2C EEPROM FAILED",
                "NL": "HW FOUT I2C EEPROM MISLUKT",
                "CZ": "HW FAIL I2C EEPROM FAILED",
                "PL": "HW FAIL I2C EEPROM FAILED",
                "EE": "HW FAIL I2C EEPROM FAILED",
                "PT": "HW FALHAR I2C EEPROM FAILED",
                "ES": "HW FAIL I2C EEPROM AVERIADO",
                "IT": "HW FAIL I2C EEPROM FAILED",
                "LT": "HW FAIL I2C EEPROM FAILED",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "802": {
                "GB": "HW SW FAIL EEPROM PARAM",
                "SE": "HW SW FAIL EEPROM PARAM",
                "DE": "HW SW FEHLER EEPROM PARAMETER",
                "FR": "DÉFAIL. LOG. MAT. PARAM. EEPROM",
                "FI": "HW SW FAIL EEPROM PARAM",
                "DK": "HW SW-FEJL, EEPROM-PARAM",
                "NO": "HW SW FEIL EEPROM PARAM",
                "LV": "HW SW Neizdevās EEPROM PARAM",
                "NL": "HW SW FOUT EEPROM PARAM",
                "CZ": "HW SW FAIL EEPROM PARAM",
                "PL": "HW SW FAIL EEPROM PARAM",
                "EE": "HW SW FAIL EEPROM PARAM",
                "PT": "HW SW FALHAR EEPROM PARAM",
                "ES": "HW SW FAIL EEPROM PARAM",
                "IT": "HW SW FAIL EEPROM PARAM",
                "LT": "HW SW FAIL EEPROM PARAM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "803": {
                "GB": "HW SW FAIL EEPROM DATA",
                "SE": "HW SW FAIL EEPROM DATA",
                "DE": "HW SW FEHLER EEPROM DATEN",
                "FR": "DÉFAIL. LOG. MAT. DONNÉES EEPROM",
                "FI": "HW SW FAIL EEPROM DATA",
                "DK": "HW SW-FEJL, EEPROM-DATA",
                "NO": "HW SW FEIL EEPROM DATA",
                "LV": "HW SW Neizdevās EEPROM DATA",
                "NL": "HW SW FOUT EEPROM DATA",
                "CZ": "HW SW FAIL EEPROM DATA",
                "PL": "HW SW FAIL EEPROM DATA",
                "EE": "HW SW FAIL EEPROM DATA",
                "PT": "HW SW FALHAR EEPROM DATA",
                "ES": "HW SW FAIL EEPROM DATA",
                "IT": "HW SW FAIL EEPROM DATA",
                "LT": "HW SW FAIL EEPROM DATA",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "804": {
                "GB": "HW SW FAIL I2C ANGLE EEPROM",
                "SE": "HW SW FAIL I2C ANGLE EEPROM",
                "DE": "HW SW FEHLER I2C WINKEL EEPROM",
                "FR": "DÉFAIL. LOG. MAT. EEPROM ANGLE I2C",
                "FI": "HW SW FAIL I2C ANGLE EEPROM",
                "DK": "HW SW-FEJL, I2C-VINKEL, EEPROM",
                "NO": "HW SW FEIL I2C VINKEL EEPROM",
                "LV": "HW SW Neizdevās I2C ANGLE EEPROM",
                "NL": "HW SW FOUT I2C HOEK EEPROM",
                "CZ": "HW SW FAIL I2C ANGLE EEPROM",
                "PL": "HW SW FAIL I2C ANGLE EEPROM",
                "EE": "HW SW FAIL I2C ANGLE EEPROM",
                "PT": "HW SW FALHAR I2C ANGLE EEPROM",
                "ES": "HW SW FAIL I2C ÀNGULO EEPROM",
                "IT": "HW SW FAIL I2C ANGLE EEPROM",
                "LT": "HW SW FAIL I2C ANGLE EEPROM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "880": {
                "GB": "HW SW FAIL TE GENERAL",
                "SE": "HW SW FAIL TE GENERAL",
                "DE": "HW SW FEHLER TE ALLGEMEIN",
                "FR": "DÉFAIL. LOG. MAT. GÉNÉRAL TE",
                "FI": "HW SW FAIL TE GENERAL",
                "DK": "HW SW-FEJL, TE GENERELT",
                "NO": "HW SW FEIL TE GENERELT",
                "LV": "HW SW Neizdevās TE GENERAL",
                "NL": "HW SW FOUT TE ALGEMEEN",
                "CZ": "HW SW FAIL TE GENERAL",
                "PL": "HW SW FAIL TE GENERAL",
                "EE": "HW SW FAIL TE GENERAL",
                "PT": "HW SW FALHAR TE GENERAL",
                "ES": "HW SW FAIL TE GENERAL",
                "IT": "HW SW FAIL TE GENERAL",
                "LT": "HW SW FAIL TE GENERAL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "881": {
                "GB": "HW FAIL FS4500 ERROR",
                "SE": "HW-fel, FS4500 AD SUPL",
                "DE": "HW FEHLER FS4500 FEHLER",
                "FR": "DÉFAIL. MAT. ERREUR FS4500",
                "FI": "HW FAIL FS4500 ERROR",
                "DK": "HW-FEJL, FS4500-FEJL",
                "NO": "HW FEIL FS4500 FEIL",
                "LV": "HW Neizdevās FS4500 kļūda",
                "NL": "HW FOUT FS4500 FOUT",
                "CZ": "HW FAIL FS4500 ERROR",
                "PL": "HW FAIL FS4500 ERROR",
                "EE": "HW FAIL FS4500 ERROR",
                "PT": "HW FALHAR FS4500 ERROR",
                "ES": "HW FAIL FS4500 ERROR",
                "IT": "HW FAIL FS4500 ERROR",
                "LT": "HW FAIL FS4500 ERROR",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "882": {
                "GB": "HW FAIL FS4500 AD SUPL",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS VERSORGUNG",
                "FR": "DÉFAIL. MAT. ALIM. FS4500 AD",
                "FI": "HW FAIL FS4500 AD SUPL",
                "DK": "HW-FEJL, FS4500 AD SUPL",
                "NO": "HW FEIL FS4500 AD FORS",
                "LV": "HW Neizdevās FS4500 AD SUPL",
                "NL": "HW FOUT FS4500 BIJ SUPL",
                "CZ": "HW FAIL FS4500 AD SUPL",
                "PL": "HW FAIL FS4500 AD SUPL",
                "EE": "HW FAIL FS4500 AD SUPL",
                "PT": "HW FALHAR FS4500 AD SUPL",
                "ES": "HW FAIL FS4500 AD SUPL",
                "IT": "HW FAIL FS4500 AD SUPL",
                "LT": "HW FAIL FS4500 AD SUPL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "883": {
                "GB": "HW FAIL FS4500 AD MUX",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS MUX",
                "FR": "DÉFAIL. MAT. MUX FS4500 AD",
                "FI": "HW FAIL FS4500 AD MUX",
                "DK": "HW-FEJL, FS4500 AD MUX",
                "NO": "HW FEIL FS4500 AD MUX",
                "LV": "HW Neizdevās FS4500 AD MUX",
                "NL": "HW FOUT FS4500 BIJ MUX",
                "CZ": "HW FAIL FS4500 AD MUX",
                "PL": "HW FAIL FS4500 AD MUX",
                "EE": "HW FAIL FS4500 AD MUX",
                "PT": "HW FALHAR FS4500 AD MUX",
                "ES": "HW FAIL FS4500 AD MUX",
                "IT": "HW FAIL FS4500 AD MUX",
                "LT": "HW FAIL FS4500 AD MUX",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "884": {
                "GB": "HW SW FAIL TE DATA INTEGRITY",
                "SE": "HW SW FAIL TE DATA INTEGRITY",
                "DE": "HW SW FEHLER TE DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. MAT. INTÉGRITÉ DONNÉES TE",
                "FI": "HW SW FAIL TE DATA INTEGRITY",
                "DK": "HW SW-FEJL, TE-DATAINTEGRITET",
                "NO": "HW SW FEIL TE DATAINTEGRITET",
                "LV": "HW SW Neizdevās TE DATA INTEGRITY",
                "NL": "HW SW FOUT TE DATA-INTEGRITEIT",
                "CZ": "HW SW FAIL TE DATA INTEGRITY",
                "PL": "HW SW FAIL TE DATA INTEGRITY",
                "EE": "HW SW FAIL TE DATA INTEGRITY",
                "PT": "HW SW FALHAR TE DATA INTEGRITY",
                "ES": "HW SW FAIL TE DATA INTEGRITY",
                "IT": "HW SW FAIL TE DATA INTEGRITY",
                "LT": "HW SW FAIL TE DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "885": {
                "GB": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "SE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DE": "SW FEHLER SICHERHEITSFUNKT DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. INTÉGRITÉ DONNÉES FONC. SÉCU.",
                "FI": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DK": "SW-FEJL, SIKKERHEDSFUNK, DATAINTEGRITET",
                "NO": "SW FEIL SIKKERHETSFUNK DATAINTEGRITET",
                "LV": "SW Neizdevās SAFETY FUNC DATA INTEGRITY",
                "NL": "SW FOUT VEILIGHEID FUNC DATA INTEGRITEIT",
                "CZ": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PL": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "EE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PT": "SW FALHA SAFETY FUNC DATA INTEGRITY",
                "ES": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "IT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "LT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "891": {
                "GB": "J1939-76, SDG-diagnostic from CCU",
                "SE": "J1939-76, SDG-diagnostic from CCU",
                "DE": "J1939-76, SDG-Diagnose von CCU",
                "FR": "J1939-76, diagnostic SDG de CCU",
                "FI": "J1939-76, SDG-diagnostiikka CCU:sta",
                "DK": "J1939-76, SDG-diagnostik fra CCU",
                "NO": "J1939-76, SDG-diagnose fra CCU",
                "LV": "J1939-76, SDG-diagnostika no CCU",
                "NL": "J1939-76, SDG-diagnostiek van CCU",
                "CZ": "J1939-76, SDG-diagnostika z CCU",
                "PL": "J1939-76, SDG-diagnostic from CCU",
                "EE": "J1939-76, SDG-diagnostika CCU-st",
                "PT": "J1939-76, Diagnóstico SDG da CCU",
                "ES": "J1939-76, SDG-diagnóstico del CCU",
                "IT": "J1939-76, SDG-diagnostica da CCU",
                "LT": "J1939-76, SDG diagnostika iš CCU",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "892": {
                "GB": "J1939-76, SDG-diagnostic from HMI",
                "SE": "J1939-76, SDG-diagnostic from HMI",
                "DE": "J1939-76, SDG-Diagnose von HMI",
                "FR": "J1939-76, diagnostic SDG d’IHM",
                "FI": "J1939-76, SDG-diagnostiikka HMI:stä",
                "DK": "J1939-76, SDG-diagnostik fra HMI",
                "NO": "J1939-76, SDG-diagnose fra HMI",
                "LV": "J1939-76, SDG-diagnostika no HMI",
                "NL": "J1939-76, SDG-diagnostiek van HMI",
                "CZ": "J1939-76, SDG-diagnostika z HMI",
                "PL": "J1939-76, SDG-diagnostic from HMI",
                "EE": "J1939-76, SDG-diagnostika HMI-st",
                "PT": "J1939-76, Diagnóstico SDG da HMI",
                "ES": "J1939-76, SDG-diagnóstico del HMI",
                "IT": "J1939-76, SDG-diagnostica da HMI",
                "LT": "J1939-76, SDG diagnostika iš HMI",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "901": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "902": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "903": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "904": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "905": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "906": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "907": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "908": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "909": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "910": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "921": {
                "GB": "Faulty parameter settings detected",
                "SE": "Fel på parameterinställningar detekterad",
                "DE": "Fehlerhafte Parametereinstellungen erkannt",
                "FR": "Paramètres erronés détectés",
                "FI": "Virheellisiä parametriasetuksia havaittu",
                "DK": "Fejl registreret i parameterindstillinger",
                "NO": "Feil parameterinnstilling registrert",
                "LV": "Konstatēti kļūdaini parametru iestatījumi",
                "NL": "Foutieve parameterinstellingen gedetecteerd",
                "CZ": "Bylo zjištěno chybné nastavení parametrů",
                "PL": "Wykryto błędne ustawienia parametrów",
                "EE": "Tuvastati vigased parameetriseaded",
                "PT": "Detetadas definições de parâmetros incorretas",
                "ES": "Detectado: Configuración de parámetros defectuosa",
                "IT": "Rilevate impostazioni di parametro errate",
                "LT": "Aptikti klaidingi parametrų nustatymai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "922": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "923": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "931": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "932": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "933": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "941": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "942": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "943": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "944": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "945": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "946": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "947": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "948": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "949": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "950": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "961": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "962": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "963": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "971": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "972": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "981": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "982": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "983": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "984": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "985": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "991": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "992": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "993": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "994": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "995": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "996": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "997": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            }
        },
        "2": {
            "1": {
                "GB": "Low supply voltage",
                "SE": "Låg matningsspänning",
                "DE": "Niedrige Speisespannung",
                "FR": "Basse tension d’aliment.",
                "FI": "Matala syöttöjännite",
                "DK": "Lav forsyningsspænding",
                "NO": "Lav forsyningsspenning",
                "LV": "Zems barošanas spriegums",
                "NL": "Lage voedingsspanning",
                "CZ": "Nízké napájecí napětí",
                "PL": "Niskie napięcie zasilania",
                "EE": "Madal toitepinge",
                "PT": "Tensão alimentação baixa",
                "ES": "Tensión de alimentación baja",
                "IT": "Bassa tens. di alimentaz.",
                "LT": "Žema maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "2": {
                "GB": "High supply voltage",
                "SE": "hög matningsspänning",
                "DE": "Hohe Speisespannung",
                "FR": "Haute tension d’aliment.",
                "FI": "Korkea syöttöjännite",
                "DK": "Høj forsyningsspænding",
                "NO": "Høy forsyningsspenning",
                "LV": "Augsts barošanas sprieg.",
                "NL": "Hoge voedingsspanning",
                "CZ": "Vysoké napájecí napětí",
                "PL": "Wys. napięcie zasilania",
                "EE": "Kõrge toitepinge",
                "PT": "Tensão alimentação alta",
                "ES": "Tensión de alimentación alta",
                "IT": "Alta tens. di alimentaz.",
                "LT": "Aukšta maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "3": {
                "GB": "Incompatible SW versions",
                "SE": "Inkompatibel mjukvara",
                "DE": "SW-Versionen inkompatibel",
                "FR": "Versions de logiciel incompatibles",
                "FI": "Kelpaamattomat SW-versiot",
                "DK": "Inkompatible SW-versioner",
                "NO": "Inkompatible programvareversjoner",
                "LV": "Nesaderīgas programmat. versijas",
                "NL": "Incompatibele sw-versies",
                "CZ": "Nekompatibilní verze SW",
                "PL": "Niezgodne wersje SW",
                "EE": "Ühildumatud SW-versioonid",
                "PT": "Versões SW incompatíveis",
                "ES": "Incompatibles versiones SW",
                "IT": "Versioni SW incompatibili",
                "LT": "Nesuderinamos pr. įrangos versijos",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "4": {
                "GB": "Mismatching parameter pair",
                "SE": "Parameterpar stämmer inte överens",
                "DE": "Nicht übereinstimmend Parameterpaar",
                "FR": "Paire de paramètres non concordante",
                "FI": "Yhteensopimaton parametripari",
                "DK": "Ikke-matchende parameterpar",
                "NO": "Parameterpar som ikke samsvarer",
                "LV": "Neatbilstošs parametru pāris",
                "NL": "Mismatchend parameterpaar",
                "CZ": "Neshoda páru parametrů",
                "PL": "Niedopasowanie pary parametrów",
                "EE": "Kokkusobimatu parameetrite paar",
                "PT": "Par de parâmetros incompatível",
                "ES": "Parámetros no coinciden",
                "IT": "Coppia di parametri non corrisp.",
                "LT": "Nesutampančių parametrų pora",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "5": {
                "GB": "Firmware update in progress",
                "SE": "Mjukvaruuppdatering pågår",
                "DE": "Firmware-Update wird ausgeführt",
                "FR": "Mise à jour du firmware en cours",
                "FI": "Laiteohjelmistoa päivitetään",
                "DK": "Firmwareopdatering er i gang",
                "NO": "Fastvareoppdatering pågår",
                "LV": "Notiek aparātprogrammat.atjaunināš.",
                "NL": "Firmware-update bezig",
                "CZ": "Probíhá aktualizace firmwaru",
                "PL": "Aktualizacja firmware w toku",
                "EE": "Püsivara värskendus pooleli",
                "PT": "Atualização de firmware em curso",
                "ES": "Actualización de programa en curso",
                "IT": "Aggiornamento firmware in corso",
                "LT": "Vykdomas pr. įrangos atnaujinimas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "6": {
                "GB": "System restart needed",
                "SE": "Systemet behöver startas om",
                "DE": "Systemneustart erforderlich",
                "FR": "Le système doit être redémarré",
                "FI": "Uudelleenkäynnistys tarpeen",
                "DK": "System genstart nødvendig",
                "NO": "Systemet må startes på nytt",
                "LV": "Nepieciešama sistēmas atsāknēšana",
                "NL": "System reboot nodig",
                "CZ": "Nutné restartovat systém",
                "PL": "Wymagany restart systemu",
                "EE": "On vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. do sistema",
                "ES": "Es necesario reiniciar el sistema",
                "IT": "Riavvio del sistema necessario",
                "LT": "Reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "7": {
                "GB": "System restart needed after SW update",
                "SE": "Systemet behöver startas om efter mjukvaruuppdatering",
                "DE": "Nach dem SW-Update ist ein Neustart des Systems erforderlich",
                "FR": "Redémarrage du système requis après la mise à jour logicielle",
                "FI": "Ohjelmistopäivityksen jälkeen järjestelmä on käynnistettävä uudelleen",
                "DK": "Systemgenstart nødvendig efter SW opdatering",
                "NO": "Systemet må startes på nytt når SW er oppdatert",
                "LV": "Pēc SW atjaunināšanas nepieciešama sistēmas pārstartēšana",
                "NL": "Het systeem moet na SW-update opnieuw opstarten worden",
                "CZ": "Po aktualizaci SW je nutné restartovat systém",
                "PL": "Wymagany restart syst. po aktualizacji SW",
                "EE": "Pärast tarkvarauuendust on vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. após a atualização de SW",
                "ES": "Es necesario reiniciar el sistema después de actualizar SW",
                "IT": "Riavvio del sistema necessario dopo l'aggiornamento del SW",
                "LT": "Po programinės įrangos atnaujinimo reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "8": {
                "GB": "Roller active at start",
                "SE": "Rulle aktiv vid start",
                "DE": "Rolle beim Start aktiv",
                "FR": "Roller actif au démarrage",
                "FI": "Rulla aktiivinen käynnistettäessä",
                "DK": "Rulle aktiv ved start",
                "NO": "Rulle er aktiv fra start",
                "LV": "Rullītis darbojas, ieslēdzot sistēmu",
                "NL": "Rol actief bij start",
                "CZ": "Váleček aktivní při startu",
                "PL": "Rolka aktywna przy rozruchu",
                "EE": "Rull aktiivne alguses",
                "PT": "Rolo ativo no início",
                "ES": "Rótula activa en el inicio",
                "IT": "Selettore attivo all’avvio",
                "LT": "Paleidimo metu ratukas yra aktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "9": {
                "GB": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "SE": "Reservdel ansluten.\nUppdatera programvara och parametrar via Rototilt Connect appen",
                "DE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FI": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "DK": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NO": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LV": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "CZ": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "EE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "ES": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "KR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "JP": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IS": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App"
            },
            "11": {
                "GB": "Rotor sensor circuit, no pulses",
                "SE": "Rotationssensor, inga pulser",
                "DE": "Rotorsensorkreis, keine Impulse",
                "FR": "Circuit capteur rotor, pas d’impulsions",
                "FI": "Kiertoanturipiiri, ei pulsseja",
                "DK": "Rotorsensorkredsløb, ingen impulser",
                "NO": "Rotorsensorkrets, ingen pulser",
                "LV": "Rotora sensora ķēde, nav impulsu",
                "NL": "Sensorcircuit rotor, geen pulsen",
                "CZ": "Obvod senzor rotoru, bez impulzů",
                "PL": "Obwód czujnika wirnika, brak impulsów",
                "EE": "Rootori anduri ahel, impulsid puuduvad",
                "PT": "Circuito sensor rotor, sem impulsos",
                "ES": "Circuito del sensor del rotor, sin pulsaciónes",
                "IT": "Circuito sensore rotore, nessun impulso",
                "LT": "Rotoriaus jut. grandinė, impulsų nėra",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "12": {
                "GB": "Index sensor circuit, error",
                "SE": "Indexsensor, inga pulser",
                "DE": "Indexsensorkreis, Fehler",
                "FR": "Circuit capteur index, erreur",
                "FI": "Indeksianturipiiri, virhe",
                "DK": "Indekssensorkredsløb, fejl",
                "NO": "Indekssensorkrets, feil",
                "LV": "Indeksa sensora ķēde, kļūda",
                "NL": "Index sensorcircuit, fout",
                "CZ": "Obvod senzor indexu, chyba",
                "PL": "Obwód czujnika indeksującego, błąd",
                "EE": "Indeksi anduri ahel, viga",
                "PT": "Índice de circuito do sensor, erro",
                "ES": "Circuito del sensor del índice, error",
                "IT": "Circuito sensore indice, errore",
                "LT": "Indekso jutiklio grandinė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "13": {
                "GB": "Auto setup failed",
                "SE": "Autosetup fallerade",
                "DE": "Auto-Setup fehlgeschlagen",
                "FR": "Échec config. auto",
                "FI": "Autom. asetus epäonn.",
                "DK": "Autoopsætning fik fejl",
                "NO": "Feil under autooppsett",
                "LV": "Autom.iestatīš. neizdevās",
                "NL": "Auto setup mislukt",
                "CZ": "Auto nastavení selhalo",
                "PL": "Błąd auto konfiguracji",
                "EE": "Autom. seadist. nurjus",
                "PT": "Falha config. automática",
                "ES": "Auto setup ha fallado",
                "IT": "Config. autom. non riusc.",
                "LT": "Aut. nustatymas nepavyko",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "21": {
                "GB": "Machine Coupler sensor off-line",
                "SE": "Maskinlåssensorn är offline",
                "DE": "Baggerschnellwechsler sensor offline",
                "FR": "Capteur attache rapide machine hors ligne",
                "FI": "Pikaliitinanturiin ei yhteyttä",
                "DK": "Hurtigskiftesensor offline",
                "NO": "Maskinfestesensor er frakoblet",
                "LV": "Spraudsavienotājs sensors off-line",
                "NL": "Sensor machinesnelwissel offline",
                "CZ": "spojka stroje snímače off-line",
                "PL": "Szybkozłącze koparki czujnik offline",
                "EE": "Masina haakeseadis andur off-line",
                "PT": "Acopl. da máquina sensor offline",
                "ES": "Sensor de acoplador de máquina offline",
                "IT": "Sensore attacco macchina offline",
                "LT": "Mašinos mova jutiklis neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "22": {
                "GB": "Machine Coupler sensor disabled",
                "SE": "Maskinlåssensorn är avstängd!",
                "DE": "Baggerschnellwechsler sensor deaktiviert",
                "FR": "Capteur attache rapide machine désactivé",
                "FI": "Pikaliitinanturi poissa käytöstä",
                "DK": "Hurtigskiftesensor deaktiveret",
                "NO": "Maskinfestesensor er deaktivert",
                "LV": "Spraudsavienotājs sensors ir atspējots",
                "NL": "Sensor Quick Coupler uitgeschakeld",
                "CZ": "Spojka stroje senzor zakázáno",
                "PL": "Szybkozłącze koparki czujnik jest wyłączony",
                "EE": "Masina haakeseadis andur puudega",
                "PT": "Acopl. da máquina sensor desativado",
                "ES": "Sensor de acoplador de máquina desactivado",
                "IT": "Attacco macchina sensore disabilitato",
                "LT": "Mašinos mova jutiklis yra išjungtas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "23": {
                "GB": "Machine coupler is open!",
                "SE": "Maskinlåset är öppet",
                "DE": "Baggerschnellwechsler offen!",
                "FR": "Attache rapide machine ouverte !",
                "FI": "Pikaliitin on auki!",
                "DK": "Hurtigskiftet er åbent!",
                "NO": "Maskinfeste er åpent!",
                "LV": "Spraudsavienotājs ir atvērta!",
                "NL": "Machinesnelwissel is open!",
                "CZ": "Spojka stroje je otevřená",
                "PL": "Szybkozłącze koparki jest otwarte",
                "EE": "Masina haakeseadis Ava!",
                "PT": "Acopl. da máquina está aberta!",
                "ES": "¡El acoplador de máquina está abierto!",
                "IT": "Attacco macchina è aperta!",
                "LT": "Mašinos mova atidaryta!",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "24": {
                "GB": "The tool is not properly attached!",
                "SE": "Redskapet är inte kopplat!",
                "DE": "Das Anbaugerät ist nicht richtig befestigt!",
                "FR": "L’outil n’est pas attaché correctement !",
                "FI": "Työlaitteen kiinnitys virheellinen!",
                "DK": "Redskabet er ikke korrekt fastgjort!",
                "NO": "Verktøyet er ikke festet på riktig måte!",
                "LV": "Darba rīks nav pareizi piestiprināts!",
                "NL": "Het uitrustingsstuk zit niet goed vast!",
                "CZ": "Pracovní nástroj není správně připojen!",
                "PL": "Narzędzie rob. jest błędnie zamocowane!",
                "EE": "Töövahend ei ole korralikult kinnitatud!",
                "PT": "Ferramenta de trabalho não está ligada!",
                "ES": "¡La herramienta no está sujeta correctamente!",
                "IT": "L’attrezzo da lavoro non è ben collegato",
                "LT": "Netinkamai pritvirtintas darbo įrankis!",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "26": {
                "GB": "Machine Coupler sensor out of range",
                "SE": "Maskinlåssensorn utanför område!",
                "DE": "Baggerschnellw.-Sensor außerhalb des Bereichs",
                "FR": "Capteur attache rapide machine hors de portée",
                "FI": "Pikaliitinanturiin ei yhteyttä",
                "DK": "Hurtigskiftesensor uden for område",
                "NO": "Maskinfestesensoren er utenfor rekkevidde",
                "LV": "spraudsavienotājs sensors ārpus diapazona",
                "NL": "Sensor Quick Coupler buiten bereik",
                "CZ": "Spojka stroje snímače mimo dosah",
                "PL": "Szybkozłącze koparki czujnik poza zakresem",
                "EE": "Masina haakeseadis andur ulatusest väljas",
                "PT": "Acopl. da máquina sensor sensor fora da faixa",
                "ES": "Sensor de acoplador de máquina fuera de tolerancia",
                "IT": "Sensore attacco macchina fuori portata",
                "LT": "Mašinos mova jutiklis už diapazono ribų",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "27": {
                "GB": "Machine Coupler blocked until restart",
                "SE": "Maskinfästet är blockerat tills omstart.",
                "DE": "Baggerschnellwechsler b. zum Neustart blockiert",
                "FR": "Attache rapide machine bloquée jusqu’au redém.",
                "FI": "Pikaliitin estetty, käynnistä uudelleen",
                "DK": "Hurtigskifte blokeret indtil genstart",
                "NO": "Maskinfestet er blokkert til omstart",
                "LV": "Spraudsavienotājs bloķēts līdz restartēšanai",
                "NL": "Machinesnelwissel geblokkeerd tot herstart",
                "CZ": "Spojka stroje zablokována až do restartu",
                "PL": "Szybkozłącze koparki zablokowane",
                "EE": "Masina haakeseadis kuni taaskäiv. blokeeritud",
                "PT": "Acoplador da máquina bloqueado até reiniciar",
                "ES": "Acoplador de máquina bloqueado hasta reinicio",
                "IT": "Attacco macchina bloccato fino al riavvio",
                "LT": "Mašinos mova užblokuota iki pakart. paleidimo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "28": {
                "GB": "Coupler blocked due to buzzer failure",
                "SE": "Fel på varningssummer, låsfunktion blockerad",
                "DE": "Koppler aufgrund eines Summerausfalls blockiert",
                "FR": "Attache bloquée car défaillance du ronfleur",
                "FI": "Kytkin estetty summerivian vuoksi",
                "DK": "Fæste blokeret på grund af summerfejl",
                "NO": "Feste er blokkert på grunn av summerfeil",
                "LV": "Savienotājs bloķēts zummera atteices dēļ",
                "NL": "Koppeling geblokkeerd door zoemerstoring",
                "CZ": "Spojka zablokována kvůli poruše bzučáku",
                "PL": "Łącznik zablokowany z powodu awarii brzęczyka",
                "EE": "Haakeseadis on sumisti rikke tõttu blokeeritud",
                "PT": "Acoplador bloqueado devido a falha no vibrador",
                "ES": "Acoplador bloqueado, fallo del zumbador",
                "IT": "Attacco bloccato per guasto della cicalina",
                "LT": "Jungtis užblokuota dėl zirzeklio gedimo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "31": {
                "GB": "Safety lever activated at start",
                "SE": "Manöverspärr är aktiverad vid uppstart",
                "DE": "Bediensperre beim Start aktiviert",
                "FR": "Levier d'arrêt activé au démarrage",
                "FI": "Käynnistys aktivoi toimintojen eston",
                "DK": "Manøvrespærring aktiveret ved start",
                "NO": "Manøversperre er aktivert ved start",
                "LV": "Palaides laikā droš. svira aktīva",
                "NL": "Veiligheidshendel geactiveerd bij start",
                "CZ": "Bezpečnost páka aktivní při startu",
                "PL": "Dźwignia bezp. aktyw. przy rozruchu",
                "EE": "Turvahoob käivitamisel aktiivne",
                "PT": "Alavanca segurança ativa no início",
                "ES": "Palanca de seguridad activado al inicio",
                "IT": "Leva di sicurezza attiva all’avvio",
                "LT": "Saugos svirtis aktyvi paleidžiant",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "32": {
                "GB": "Safety lever inactive",
                "SE": "Manöverspärr är inaktiv.",
                "DE": "Bediensperre inaktiv",
                "FR": "Levier d'arrêt inactif",
                "FI": "Toimintojen esto ei käytössä",
                "DK": "Manøvrespærring inaktiv",
                "NO": "Manøversperre er inaktiv",
                "LV": "Drošības svira neaktīvs",
                "NL": "Veiligheidshendel inactief",
                "CZ": "Pojistná páka neaktivní",
                "PL": "Dźwignia bezp. nieczynny",
                "EE": "Turvahoova inaktiivne",
                "PT": "Alavancar segurança inativa",
                "ES": "Palanca de seguridad inactivado",
                "IT": "Leva di sicurezza inattiva",
                "LT": "Saugos svirtis neaktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "33": {
                "GB": "Machine Coupler blocked",
                "SE": "Maskinlåstet är blockerat ",
                "DE": "Baggerschnellwechsler blockiert",
                "FR": "Attache rapide machine bloquée",
                "FI": "Pikaliitin estetty",
                "DK": "Hurtigskifte blokeret",
                "NO": "Maskinfestet er blokkert",
                "LV": "Spraudsavienotājs bloķēts",
                "NL": "Machinesnelwissel geblokkeerd",
                "CZ": "Spojka stroje zablokována",
                "PL": "Szybkozłącze koparki zablokowane aż do restartu",
                "EE": "Masina haakeseadis blokeeritud",
                "PT": "Acoplador da máquina bloqueado",
                "ES": "Acoplador de máquina bloqueado",
                "IT": "Attacco macchina bloccato",
                "LT": "Mašinos mova užblokuota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "34": {
                "GB": "Machine control system off-line",
                "SE": "Maskinkontrollsystem offline",
                "DE": "Maschinensteuersystem offline",
                "FR": "Système de contrôle machine hors ligne",
                "FI": "Koneenohjausjärjestelmään ei yhteyttä",
                "DK": "Maskinstyresystem offline",
                "NO": "Maskinens styresystem er frakoblet",
                "LV": "Mašīnas vadības sistēma offline",
                "NL": "Machinebesturingssyst. offline",
                "CZ": "Systém řízení stroje off-line",
                "PL": "Maszyna system sterowania offline",
                "EE": "Masina juhtimissüsteem offline",
                "PT": "Sistema de controle da máquina off-line",
                "ES": "Control de sistema de maquina offline",
                "IT": "Sistema di controllo della macchina offline",
                "LT": "Mašinos valdymo sistema neprisijungusi",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "35": {
                "GB": "Wheel and Crawler control system off-line",
                "SE": "Hjul och bandstyrningsenhet offline",
                "DE": "Rad- und Ketten-Steuersystem offline",
                "FR": "Système de contrôle des roues et des chenilles hors ligne",
                "FI": "Pyörien ja telojen ohjaus. ei saada yhteyttä",
                "DK": "Hjul- og bæltestyresystem offline",
                "NO": "Styresystem for hjul og belter er frakoblet",
                "LV": "Riteņu un kāpurķēžu vadības sistēma ir bezsaistē",
                "NL": "Wiel en rupsband bedieningssysteem off-line",
                "CZ": "Řídicí systém kol a pásů off-line",
                "PL": "System sterowania kołami i gąsienicami off-line",
                "EE": "Rataste ja roomikute juhtimissüsteem ühenduseta",
                "PT": "Sistema de controlo de rodas e lagartas offline",
                "ES": "Control de traslación offline",
                "IT": "Sistema di comando ruote e cingoli off-line",
                "LT": "Ratų ir vikšrų valdymo sistema neprisijungusi",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "36": {
                "GB": "Wheel and Crawler control system alarm",
                "SE": "Hjul och bandstyrningsenhet alarm",
                "DE": "Rad- und Ketten-Steuersystem Alarm",
                "FR": "Alarme du système de contrôle des roues et des chenilles",
                "FI": "Pyörien ja telojen ohjaus. hälytys",
                "DK": "Alarm for hjul- og bæltestyresystem",
                "NO": "Alarm i styresystem for hjul og belter",
                "LV": "Riteņu un kāpurķēžu vadības sistēmas trauksmes stāvoklis",
                "NL": "Alarm wiel- en rupsband bedieningssysteem",
                "CZ": "Poplach řídicího systému kol a pásů",
                "PL": "Alarm systemu sterowania kołami i gąsienicami",
                "EE": "Rataste ja roomikute juhtimissüsteemi alarm",
                "PT": "Alarme do sistema de controlo de rodas e lagartas",
                "ES": "Alarma del sistema de control de traslación",
                "IT": "Allarme sistema di comando ruote e cingoli",
                "LT": "Ratų ir vikšrų valdymo sistemos signalas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "41": {
                "GB": "Left analog joystick, error",
                "SE": "Vänster analog joystick, error",
                "DE": "Linker Analog-Joystick, Fehler",
                "FR": "Joystick analogique gauche, erreur",
                "FI": "Vasen analoginen ohjain, virhe",
                "DK": "Venstre analoge joystick, fejl",
                "NO": "Venstre analoge styrespak, feil",
                "LV": "Kreisā analogā vadības svira, kļūda",
                "NL": "Linker analoge joystick, fout",
                "CZ": "Levý analogový joystick, chyba",
                "PL": "Lewy uchwyt analog, error",
                "EE": "Vasak analoogjuhtkang, viga",
                "PT": "Joystick analógico esquerdo, erro",
                "ES": "Joystick analógico izquierdo, error",
                "IT": "Joystick analogico sinistro, errore",
                "LT": "Kair. analog. vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "42": {
                "GB": "Right analog joystick, error",
                "SE": "Höger analog joystick, error",
                "DE": "Rechter Analog-Joystick, Fehler",
                "FR": "Joystick analogique droit, erreur",
                "FI": "Oikea analoginen ohjain, virhe",
                "DK": "Højre analoge joystick, fejl",
                "NO": "Høyre analoge styrespak, feil",
                "LV": "Labā analogā vadības svira, kļūda",
                "NL": "Rechter analoge joystick, fout",
                "CZ": "Pravý analogový joystick, chyba",
                "PL": "Prawy uchwyt analog, error",
                "EE": "Parem analoogjuhtkang, viga",
                "PT": "Joystick analógico direito, erro",
                "ES": "Joystick analógico derecho, error",
                "IT": "Joystick analogico destro, errore",
                "LT": "Dešin. analoginė vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "43": {
                "GB": "Left analog joystick, uncalibrated",
                "SE": "Vänster analog joystick, okalibrerad",
                "DE": "Linker Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique gauche, non étalonné",
                "FI": "Vasen analoginen ohjain, ei kalibroitu",
                "DK": "Venstre analoge joystick, ikke kalibreret",
                "NO": "Venstre analoge styrespak, ikke kalibrert",
                "LV": "Kreisā analogā vadības svira, nekalibrēta",
                "NL": "Linker analoge joystick, niet gekalibreerd",
                "CZ": "Levý analogový joystick, nekalibrovaný",
                "PL": "Lewy uchwyt analog, nieskalibrow.",
                "EE": "Vasak analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico esquerdo, não calibrado",
                "ES": "Joystick analógico izquierdo, sin calibrar",
                "IT": "Joystick analogico sinistro, non calibrato",
                "LT": "Kair. analog.vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "44": {
                "GB": "Right analog joystick, uncalibrated",
                "SE": "Höger analog joystick, okalibrerad",
                "DE": "Rechter Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique droit, non étalonné",
                "FI": "Oikea analoginen ohjain, ei kalibroitu",
                "DK": "Højre analoge joystick, ikke kalibreret",
                "NO": "Høyre analoge styrespak, ikke kalibrert",
                "LV": "Labā analogā vadības svira, nekalibrēta",
                "NL": "Rechter analoge joystick, niet gekalibreerd",
                "CZ": "Pravý analogový joystick, nekalibrovaný",
                "PL": "Prawy joystick analogowy, brak kalibracji",
                "EE": "Parem analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico direito, não calibrado",
                "ES": "Joystick analógico derecho, sin calibrar",
                "IT": "Joystick analogico destro, non calibrato",
                "LT": "Dešin. analoginė vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "51": {
                "GB": "Communication timeout, external machine CAN-bus",
                "SE": "Kommunikations-Timeout, bärarmaskin CAN-bus",
                "DE": "Timeout Kommunikation, externe Maschinen und CAN-Bus",
                "FR": "Délai de communication expiré, bus CAN machine externe",
                "FI": "Viestinnän aikakatkaisu, ulkoisen koneen CAN-väylä",
                "DK": "Kommunikationstimeout, ekstern maskine-CAN-bus",
                "NO": "Tidsavbrudd for kommunikasjon, ekstern maskin CAN-buss",
                "LV": "Sakaru taimauts, ārējās mašīnas CAN kopne",
                "NL": "Communicatie time-out, externe machine CAN-bus",
                "CZ": "Časový limit komunikace, externí sběrnice CAN stroje",
                "PL": "Limit czasu komunikacji, magistrala CAN maszyny zewnętrznej",
                "EE": "Side ajalõpp, välise masina CAN-siin",
                "PT": "Tempo de comunicação esgotado, barramento CAN da máquina externa",
                "ES": "Comunicación interrumpido, CAN bus de máquina externa",
                "IT": "Timeout di comunicazione, bus CAN macchina esterna",
                "LT": "Ryšio skirtasis laikas, išorinio įrenginio CAN magistralė",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "52": {
                "GB": "External machine system not in working mode",
                "SE": "Bärarmaskin är inte i rätt driftmode",
                "DE": "Externes Maschinensystem nicht im Betriebsmodus",
                "FR": "Système machine externe pas en mode de fonctionnement",
                "FI": "Ulkoisen koneen järjestelmä ei toimintatilassa",
                "DK": "Eksternt maskinsystem ikke i arbejdstilstand",
                "NO": "Eksternt maskinsystem er ikke i arbeidsmodus",
                "LV": "Ārējās mašīnas sistēma nav darba režīmā",
                "NL": "Extern machinesysteem niet in de werkmodus",
                "CZ": "Externí systém stroje není v pracovním režimu",
                "PL": "Układ maszyny zewnętrznej nie jest w trybie roboczym",
                "EE": "Välise masina süsteem ei ole töörežiimis",
                "PT": "O sistema de máquina externa não está em modo de trabalho",
                "ES": "Externo sistema de máquina no en modo de trabajo",
                "IT": "Sistema macchina est. non in modalità esercizio",
                "LT": "Išorinė įrenginio sistema neveikia",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "53": {
                "GB": "External machine software interface error",
                "SE": "Inkompatibelt mjukvaruinterface mot bärarmaskin",
                "DE": "Externer Softwareschnittstellen-Fehler Maschine",
                "FR": "Erreur d’interface logicielle machine externe",
                "FI": "Ulkoisen koneen ohjelmistoliittymän virhe",
                "DK": "Grænsefladefejl i ekstern maskinsoftware",
                "NO": "Grensesnittfeil for ekstern maskinprogramvare",
                "LV": "Ārējās mašīnas programmatūras interfeisa kļūda",
                "NL": "Fout in interface externe machinesoftware",
                "CZ": "Chyba rozhraní externího softwaru stroje",
                "PL": "Błąd interfejsu oprogramowania maszyny zewnętrznej",
                "EE": "Välise masina tarkvaraliidese viga",
                "PT": "Erro de interface de software de máquina externa",
                "ES": "Interface error, software de máquina externa",
                "IT": "Errore interfaccia software della macchina esterna",
                "LT": "Išorinės įrenginio pr. įrangos sąsajos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "54": {
                "GB": "Conflicting CAN messages detected on CAN-bus to external machine",
                "SE": "Inkompatibla CAN-meddelanden upptäcka på CAN-bus mot extern maskin.",
                "DE": "Konflikt bei CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Conflit de messages CAN détecté sur bus CAN vers machine externe",
                "FI": "Ristiriitaisia CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Modstridende CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Konflikt i CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti konfliktējoši CAN ziņojumi",
                "NL": "Conflicterende CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Konfliktní zprávy CAN zjištěné na sběrnici CAN s externím počítačem",
                "PL": "Sprzeczne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad vastuolulised sõnumid",
                "PT": "Detetado conflito mensagens CAN no barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN conflictivos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN in conflitto sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje aptikti nesuderinami CAN pranešimai išoriniam įreng.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "55": {
                "GB": "Incorrect CAN messages detected on CAN-bus to external machine",
                "SE": "Felaktiga CAN-meddelanden upptäcka på CAN-bus mot extern maskin",
                "DE": "Falsche CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Message incorrect CAN détecté sur bus CAN vers machine externe",
                "FI": "Virheellisiä CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Fejl på CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Feil på CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti nepareizi CAN ziņojumi",
                "NL": "Fouten in CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Na sběrnici CAN k externímu počítači zjištěny nesprávné zprávy CAN",
                "PL": "Błędne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad valed sõnumid",
                "PT": "Detetadas mensagens CAN incorretas barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN erróneos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN errati sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje išoriniam įreng. aptikti neteisingi CAN pranešimai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "71": {
                "GB": "Input mapping invalid",
                "SE": "Insignalsmappning, error",
                "DE": "Eingangsmapping ungültig",
                "FR": "Mappage d’entrée non valide",
                "FI": "Syöttömapitus ei kelpaa",
                "DK": "Kortlægning af input ugyldig",
                "NO": "Ugyldig inndatatilordning",
                "LV": "Ieejas kartēšana nav derīga",
                "NL": "Invoertoewijzing ongeldig",
                "CZ": "Mapování vstupu neplatné",
                "PL": "Mapowanie wejścia nieprawidłowe",
                "EE": "Sisendi kaardistamine kehtetu",
                "PT": "Mapeamento de entrada inválido",
                "ES": "Señal de entrada no válida",
                "IT": "Mapping ingressi non valida",
                "LT": "Įvesties susiejimas netinkamas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "72": {
                "GB": "Parameter setting error",
                "SE": "Felaktig parameter inställning",
                "DE": "Fehler bei der Parametrierung",
                "FR": "Erreur de paramétrage",
                "FI": "Parametrin asetusvirhe",
                "DK": "Parameterindstillingsfejl",
                "NO": "Feil under parameterinnstilling",
                "LV": "Parametru iestatīšanas kļūda",
                "NL": "Fout parameter instellen",
                "CZ": "Chyba nastavení parametrů",
                "PL": "Błąd ustawiania parametrów",
                "EE": "Parameetri seadistamise viga",
                "PT": "Erro de configuração de parâmetros",
                "ES": "Error de configuraciónes del parametro",
                "IT": "Errore impostazione dei parametri",
                "LT": "Parametrų nustatymo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "81": {
                "GB": "Activate double feeder",
                "SE": "Aktivera dubbelshunt",
                "DE": "Doppelfeeder aktivieren",
                "FR": "Activer double alimentateur",
                "FI": "Aktivoi kaksoissyöttö",
                "DK": "Aktivér dobbelt feeder",
                "NO": "Aktiver dobbel mating",
                "LV": "Aktivizēt Dub. Padevēja",
                "NL": "Dubbele feeder activeren",
                "CZ": "Aktivoi dvojit podavač",
                "PL": "Uruch. podwójny bocznik",
                "EE": "Aktiveeri To. ettean.",
                "PT": "Ativar aliment duplo",
                "ES": "Activar alimentador double",
                "IT": "Attiv. doppio alim.",
                "LT": "Suaktyvinti Dvig. tiektuvo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "82": {
                "GB": "Inactivate double feeder",
                "SE": "Inaktivera dubbelshunt",
                "DE": "Doppelfeeder deaktivieren",
                "FR": "Désactiver double alimentateur",
                "FI": "Sammuta kaksoissyöttö",
                "DK": "Inaktiver dobbelt feeder",
                "NO": "Deaktiver dobbel mating",
                "LV": "Deaktivēt dubulto padevēju",
                "NL": "Dubbele feeder deactiveren",
                "CZ": "Deaktivace dvojitého podavače",
                "PL": "Wyłącz podajnik podwójny",
                "EE": "Inaktiveerige topeltetteandur",
                "PT": "Desativar alimentador duplo",
                "ES": "Inactivar alimentador double",
                "IT": "Disattivare doppio alimentatore",
                "LT": "Išjungti dvigubą tiektuvą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "83": {
                "GB": "Double feeder active",
                "SE": "Dubbelshunt är aktiv",
                "DE": "Doppelfeeder aktiv",
                "FR": "Double alimentateur actif",
                "FI": "Kaksoissyöttö aktiivinen",
                "DK": "Dobbelt feeder aktiv",
                "NO": "Dobbel mating aktivert",
                "LV": "Dub. padevēja rež.",
                "NL": "Dubbele feeder actief",
                "CZ": "Dvojit podavač aktivní",
                "PL": "podwójny bocznik aktyw.",
                "EE": "To. ettean. aktiivsed",
                "PT": "Aliment duplo ativos",
                "ES": "Alimentador double activo",
                "IT": "Doppio alim. attivi",
                "LT": "Dvig. tiektuvo aktyvūs",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "91": {
                "GB": "HCU off-line",
                "SE": "HCU, offline",
                "DE": "HCU offline",
                "FR": "HCU hors ligne",
                "FI": "HCU ei yhteyttä",
                "DK": "HCU offline",
                "NO": "HCU frakoblet",
                "LV": "HCU offline",
                "NL": "HCU offline",
                "CZ": "HCU offline",
                "PL": "HCU offline",
                "EE": "HCU offline",
                "PT": "HCU offline",
                "ES": "HCU offline",
                "IT": "HCU offline",
                "LT": "HCU neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "201": {
                "GB": "Short circuit, digital output 01",
                "SE": "Kabelfel, kortslutning, digital utgång 01",
                "DE": "Kurzschluss, Digitalausgang 01",
                "FR": "Court-circuit, sortie numérique 01",
                "FI": "Oikosulku, digitaalinen lähtö 01",
                "DK": "Kortslutning, digital udgang 01",
                "NO": "Kortslutning, digital utgang 01",
                "LV": "Īssavienojums, digitālā izeja 01",
                "NL": "Kortsluiting, digitale uitgang 01",
                "CZ": "Zkrat, digitální výstup 01",
                "PL": "Zwarcie, wyjście cyfrowe 01",
                "EE": "Lühis, digitaalne väljund 01",
                "PT": "Curto-circuito, saída digital 01",
                "ES": "Cortocircuito, salida digital 01",
                "IT": "Cortocircuito, uscita digitale 01",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "202": {
                "GB": "Short circuit, digital output 02",
                "SE": "Kabelfel, kortslutning, digital utgång 02",
                "DE": "Kurzschluss, Digitalausgang 02",
                "FR": "Court-circuit, sortie numérique 02",
                "FI": "Oikosulku, digitaalinen lähtö 02",
                "DK": "Kortslutning, digital udgang 02",
                "NO": "Kortslutning, digital utgang 02",
                "LV": "Īssavienojums, digitālā izeja 02",
                "NL": "Kortsluiting, digitale uitgang 02",
                "CZ": "Zkrat, digitální výstup 02",
                "PL": "Zwarcie, wyjście cyfrowe 02",
                "EE": "Lühis, digitaalne väljund 02",
                "PT": "Curto-circuito, saída digital 02",
                "ES": "Cortocircuito, salida digital 02",
                "IT": "Cortocircuito, uscita digitale 02",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "203": {
                "GB": "Short circuit, digital output 03",
                "SE": "Kabelfel, kortslutning, digital utgång 03",
                "DE": "Kurzschluss, Digitalausgang 03",
                "FR": "Court-circuit, sortie numérique 03",
                "FI": "Oikosulku, digitaalinen lähtö 03",
                "DK": "Kortslutning, digital udgang 03",
                "NO": "Kortslutning, digital utgang 03",
                "LV": "Īssavienojums, digitālā izeja 03",
                "NL": "Kortsluiting, digitale uitgang 03",
                "CZ": "Zkrat, digitální výstup 03",
                "PL": "Zwarcie, wyjście cyfrowe 03",
                "EE": "Lühis, digitaalne väljund 03",
                "PT": "Curto-circuito, saída digital 03",
                "ES": "Cortocircuito, salida digital 03",
                "IT": "Cortocircuito, uscita digitale 03",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "204": {
                "GB": "Short circuit, digital output 04",
                "SE": "Kabelfel, kortslutning, digital utgång 04",
                "DE": "Kurzschluss, Digitalausgang 04",
                "FR": "Court-circuit, sortie numérique 04",
                "FI": "Oikosulku, digitaalinen lähtö 04",
                "DK": "Kortslutning, digital udgang 04",
                "NO": "Kortslutning, digital utgang 04",
                "LV": "Īssavienojums, digitālā izeja 04",
                "NL": "Kortsluiting, digitale uitgang 04",
                "CZ": "Zkrat, digitální výstup 04",
                "PL": "Zwarcie, wyjście cyfrowe 04",
                "EE": "Lühis, digitaalne väljund 04",
                "PT": "Curto-circuito, saída digital 04",
                "ES": "Cortocircuito, salida digital 04",
                "IT": "Cortocircuito, uscita digitale 04",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "205": {
                "GB": "Short circuit, digital output 05",
                "SE": "Kabelfel, kortslutning, digital utgång 05",
                "DE": "Kurzschluss, Digitalausgang 05",
                "FR": "Court-circuit, sortie numérique 05",
                "FI": "Oikosulku, digitaalinen lähtö 05",
                "DK": "Kortslutning, digital udgang 05",
                "NO": "Kortslutning, digital utgang 05",
                "LV": "Īssavienojums, digitālā izeja 05",
                "NL": "Kortsluiting, digitale uitgang 05",
                "CZ": "Zkrat, digitální výstup 05",
                "PL": "Zwarcie, wyjście cyfrowe 05",
                "EE": "Lühis, digitaalne väljund 05",
                "PT": "Curto-circuito, saída digital 05",
                "ES": "Cortocircuito, salida digital 05",
                "IT": "Cortocircuito, uscita digitale 05",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 05",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "211": {
                "GB": "Open circuit, digital output 01",
                "SE": "Kabelbrott, digital utgång 01",
                "DE": "Schaltkreis offen, Digitalausgang 01",
                "FR": "Circuit ouvert, sortie numérique 01",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 01",
                "DK": "Åbent kredsløb, digital udgang 01",
                "NO": "Åpen krets, digital utgang 01",
                "LV": "Pārtraukta ķēde, digitālā izeja 01",
                "NL": "Open circuit, digitale uitgang 01",
                "CZ": "Přerušený obvod, digitální výstup 01",
                "PL": "Obwód otwarty, wyjście cyfrowe 01",
                "EE": "Tühijooksupinge, digitaalne väljund 01",
                "PT": "Abrir circuito, saída digital 01",
                "ES": "Circuito abierto, salida digital 01",
                "IT": "Circuito aperto, uscita digitale 01",
                "LT": "Atvira grandinė, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "212": {
                "GB": "Open circuit, digital output 02",
                "SE": "Kabelbrott, digital utgång 02",
                "DE": "Schaltkreis offen, Digitalausgang 02",
                "FR": "Circuit ouvert, sortie numérique 02",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 02",
                "DK": "Åbent kredsløb, digital udgang 02",
                "NO": "Åpen krets, digital utgang 02",
                "LV": "Pārtraukta ķēde, digitālā izeja 02",
                "NL": "Open circuit, digitale uitgang 02",
                "CZ": "Přerušený obvod, digitální výstup 02",
                "PL": "Obwód otwarty, wyjście cyfrowe 02",
                "EE": "Tühijooksupinge, digitaalne väljund 02",
                "PT": "Abrir circuito, saída digital 02",
                "ES": "Circuito abierto, salida digital 02",
                "IT": "Circuito aperto, uscita digitale 02",
                "LT": "Atvira grandinė, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "213": {
                "GB": "Open circuit, digital output 03",
                "SE": "Kabelbrott, digital utgång 03",
                "DE": "Schaltkreis offen, Digitalausgang 03",
                "FR": "Circuit ouvert, sortie numérique 03",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 03",
                "DK": "Åbent kredsløb, digital udgang 03",
                "NO": "Åpen krets, digital utgang 03",
                "LV": "Pārtraukta ķēde, digitālā izeja 03",
                "NL": "Open circuit, digitale uitgang 03",
                "CZ": "Přerušený obvod, digitální výstup 03",
                "PL": "Obwód otwarty, wyjście cyfrowe 03",
                "EE": "Tühijooksupinge, digitaalne väljund 03",
                "PT": "Abrir circuito, saída digital 03",
                "ES": "Circuito abierto, salida digital 03",
                "IT": "Circuito aperto, uscita digitale 03",
                "LT": "Atvira grandinė, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "214": {
                "GB": "Open circuit, digital output 04",
                "SE": "Kabelbrott, digital utgång 04",
                "DE": "Schaltkreis offen, Digitalausgang 04",
                "FR": "Circuit ouvert, sortie numérique 04",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 04",
                "DK": "Åbent kredsløb, digital udgang 04",
                "NO": "Åpen krets, digital utgang 04",
                "LV": "Pārtraukta ķēde, digitālā izeja 04",
                "NL": "Open circuit, digitale uitgang 04",
                "CZ": "Přerušený obvod, digitální výstup 04",
                "PL": "Obwód otwarty, wyjście cyfrowe 04",
                "EE": "Tühijooksupinge, digitaalne väljund 04",
                "PT": "Abrir circuito, saída digital 04",
                "ES": "Circuito abierto, salida digital 04",
                "IT": "Circuito aperto, uscita digitale 04",
                "LT": "Atvira grandinė, skaitmeninė išvestis 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "215": {
                "GB": "Open circuit, digital output 05",
                "SE": "Kabelbrott, digital utgång 05",
                "DE": "Schaltkreis offen, Digitalausgang 05",
                "FR": "Circuit ouvert, sortie numérique 05",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 05",
                "DK": "Åbent kredsløb, digital udgang 05",
                "NO": "Åpen krets, digital utgang 05",
                "LV": "Pārtraukta ķēde, digitālā izeja 05",
                "NL": "Open circuit, digitale uitgang 05",
                "CZ": "Přerušený obvod, digitální výstup 05",
                "PL": "Obwód otwarty, wyjście cyfrowe 05",
                "EE": "Tühijooksupinge, digitaalne väljund 05",
                "PT": "Abrir circuito, saída digital 05",
                "ES": "Circuito abierto, salida digital 05",
                "IT": "Circuito aperto, uscita digitale 05",
                "LT": "Atvira grandinė, skaitmeninė išvestis 05",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "221": {
                "GB": "Foregin Voltage, digital output 01",
                "SE": "Främmandespänning, digital utgång 01",
                "DE": "Fremdspannung, Digitalausgang 01",
                "FR": "Tension étrangère, sortie numérique 01",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 01",
                "DK": "Spænding udefra, digital udgang 01",
                "NO": "Ukjent spenning, digital utgang 01",
                "LV": "Svešspriegums, digitālā izeja 01",
                "NL": "Externe spanning, digitale uitgang 01",
                "CZ": "Cizí napětí, digitální výstup 01",
                "PL": "Napięcie obce, wyjście cyfrowe 01",
                "EE": "Võõrpinge, digitaalne väljund 01",
                "PT": "Tensão desconhecida, saída digital 01",
                "ES": "Voltage externo, salida digital 01",
                "IT": "Tensione esterna, uscita digitale 01",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "222": {
                "GB": "Foregin Voltage, digital output 02",
                "SE": "Främmandespänning, digital utgång 02",
                "DE": "Fremdspannung, Digitalausgang 02",
                "FR": "Tension étrangère, sortie numérique 02",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 02",
                "DK": "Spænding udefra, digital udgang 02",
                "NO": "Ukjent spenning, digital utgang 02",
                "LV": "Svešspriegums, digitālā izeja 02",
                "NL": "Externe spanning, digitale uitgang 02",
                "CZ": "Cizí napětí, digitální výstup 02",
                "PL": "Napięcie obce, wyjście cyfrowe 02",
                "EE": "Võõrpinge, digitaalne väljund 02",
                "PT": "Tensão desconhecida, saída digital 02",
                "ES": "Voltage externo, salida digital 02",
                "IT": "Tensione esterna, uscita digitale 02",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "223": {
                "GB": "Foregin Voltage, digital output 03",
                "SE": "Främmandespänning, digital utgång 03",
                "DE": "Fremdspannung, Digitalausgang 03",
                "FR": "Tension étrangère, sortie numérique 03",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 03",
                "DK": "Spænding udefra, digital udgang 03",
                "NO": "Ukjent spenning, digital utgang 03",
                "LV": "Svešspriegums, digitālā izeja 03",
                "NL": "Externe spanning, digitale uitgang 03",
                "CZ": "Cizí napětí, digitální výstup 03",
                "PL": "Napięcie obce, wyjście cyfrowe 03",
                "EE": "Võõrpinge, digitaalne väljund 03",
                "PT": "Tensão desconhecida, saída digital 03",
                "ES": "Voltage externo, salida digital 03",
                "IT": "Tensione esterna, uscita digitale 03",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "224": {
                "GB": "Foregin Voltage, digital output 04",
                "SE": "Främmandespänning, digital utgång 04",
                "DE": "Fremdspannung, Digitalausgang 04",
                "FR": "Tension étrangère, sortie numérique 04",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 04",
                "DK": "Spænding udefra, digital udgang 04",
                "NO": "Ukjent spenning, digital utgang 04",
                "LV": "Svešspriegums, digitālā izeja 04",
                "NL": "Externe spanning, digitale uitgang 04",
                "CZ": "Cizí napětí, digitální výstup 04",
                "PL": "Napięcie obce, wyjście cyfrowe 04",
                "EE": "Võõrpinge, digitaalne väljund 04",
                "PT": "Tensão desconhecida, saída digital 04",
                "ES": "Voltage externo, salida digital 04",
                "IT": "Tensione esterna, uscita digitale 04",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "225": {
                "GB": "Foregin Voltage, digital output 05",
                "SE": "Främmandespänning, digital utgång 05",
                "DE": "Fremdspannung, Digitalausgang 05",
                "FR": "Tension étrangère, sortie numérique 05",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 05",
                "DK": "Spænding udefra, digital udgang 05",
                "NO": "Ukjent spenning, digital utgang 05",
                "LV": "Svešspriegums, digitālā izeja 05",
                "NL": "Externe spanning, digitale uitgang 05",
                "CZ": "Cizí napětí, digitální výstup 05",
                "PL": "Napięcie obce, wyjście cyfrowe 05",
                "EE": "Võõrpinge, digitaalne väljund 05",
                "PT": "Tensão desconhecida, saída digital 05",
                "ES": "Voltage externo, salida digital 05",
                "IT": "Tensione esterna, uscita digitale 05",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 05",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "231": {
                "GB": "Short to GND, digital output 01",
                "SE": "Kortslutning mot jord, digital utgång 01",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 01",
                "FR": "Court vers GND, sortie numérique 01",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 01",
                "DK": "Kortslutning til GND, digital udgang 01",
                "NO": "Kortslutning til jord, digital utgang 01",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 01",
                "NL": "Kortsluiting aarde, digitale uitgang 01",
                "CZ": "Zkrat k GND, digitální výstup 01",
                "PL": "Zwarcie do GND, wyjście cyfrowe 01",
                "EE": "Lühike GNDsse, digitaalne väljund 01",
                "PT": "Curto-circ. GND, saída digital 01",
                "ES": "Cortocircuito a masa, salida digital 01",
                "IT": "Cortocirc. v. GND, uscita digitale 01",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "232": {
                "GB": "Short to GND, digital output 02",
                "SE": "Kortslutning mot jord, digital utgång 02",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 02",
                "FR": "Court vers GND, sortie numérique 02",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 02",
                "DK": "Kortslutning til GND, digital udgang 02",
                "NO": "Kortslutning til jord, digital utgang 02",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 02",
                "NL": "Kortsluiting aarde, digitale uitgang 02",
                "CZ": "Zkrat k GND, digitální výstup 02",
                "PL": "Zwarcie do GND, wyjście cyfrowe 02",
                "EE": "Lühike GNDsse, digitaalne väljund 02",
                "PT": "Curto-circ. GND, saída digital 02",
                "ES": "Cortocircuito a masa, salida digital 02",
                "IT": "Cortocirc. v. GND, uscita digitale 02",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "233": {
                "GB": "Short to GND, digital output 03",
                "SE": "Kortslutning mot jord, digital utgång 03",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 03",
                "FR": "Court vers GND, sortie numérique 03",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 03",
                "DK": "Kortslutning til GND, digital udgang 03",
                "NO": "Kortslutning til jord, digital utgang 03",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 03",
                "NL": "Kortsluiting aarde, digitale uitgang 03",
                "CZ": "Zkrat k GND, digitální výstup 03",
                "PL": "Zwarcie do GND, wyjście cyfrowe 03",
                "EE": "Lühike GNDsse, digitaalne väljund 03",
                "PT": "Curto-circ. GND, saída digital 03",
                "ES": "Cortocircuito a masa, salida digital 03",
                "IT": "Cortocirc. v. GND, uscita digitale 03",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "234": {
                "GB": "Short to GND, digital output 04",
                "SE": "Kortslutning mot jord, digital utgång 04",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 04",
                "FR": "Court vers GND, sortie numérique 04",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 04",
                "DK": "Kortslutning til GND, digital udgang 04",
                "NO": "Kortslutning til jord, digital utgang 04",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 04",
                "NL": "Kortsluiting aarde, digitale uitgang 04",
                "CZ": "Zkrat k GND, digitální výstup 04",
                "PL": "Zwarcie do GND, wyjście cyfrowe 04",
                "EE": "Lühike GNDsse, digitaalne väljund 04",
                "PT": "Curto-circ. GND, saída digital 04",
                "ES": "Cortocircuito a masa, salida digital 04",
                "IT": "Cortocirc. v. GND, uscita digitale 04",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "301": {
                "GB": "Short circuit, sensor supply 1",
                "SE": "Kabelfel, kortslutning, sensormatning 1",
                "DE": "Kurzschluss, Sensorversorgung 1",
                "FR": "Court-circuit, alimentation capteur 1",
                "FI": "Oikosulku, anturin syöttö 1",
                "DK": "Kortslutning, sensorforsyning 1",
                "NO": "Kortslutning, sensortilførsel 1",
                "LV": "Īsslēgums, sensora barošana 1",
                "NL": "Kortsluiting, sensorvoeding 1",
                "CZ": "Zkrat, napájení senzor 1",
                "PL": "Zwarcie, czujnik zasil. 1",
                "EE": "Lühis, anduri toide 1",
                "PT": "Curto-circuito, sensor de alimentação 1",
                "ES": "Cortocircuito, sensor de alim. 1",
                "IT": "Cortocircuito, alimentazione sensore 1",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "302": {
                "GB": "Short circuit, sensor supply 2",
                "SE": "Kabelfel, kortslutning, sensormatning 2",
                "DE": "Kurzschluss, Sensorversorgung 2",
                "FR": "Court-circuit, alimentation capteur 2",
                "FI": "Oikosulku, anturin syöttö 2",
                "DK": "Kortslutning, sensorforsyning 2",
                "NO": "Kortslutning, sensortilførsel 2",
                "LV": "Īsslēgums, sensora barošana 2",
                "NL": "Kortsluiting, sensorvoeding 2",
                "CZ": "Zkrat, napájení senzor 2",
                "PL": "Zwarcie, czujnik zasil. 2",
                "EE": "Lühis, anduri toide 2",
                "PT": "Curto-circuito, sensor de alimentação 2",
                "ES": "Cortocircuito, sensor de alim. 2",
                "IT": "Cortocircuito, alimentazione sensore 2",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "303": {
                "GB": "Short circuit, sensor supply 3",
                "SE": "Kabelfel, kortslutning, sensormatning 3",
                "DE": "Kurzschluss, Sensorversorgung 3",
                "FR": "Court-circuit, alimentation capteur 3",
                "FI": "Oikosulku, anturin syöttö 3",
                "DK": "Kortslutning, sensorforsyning 3",
                "NO": "Kortslutning, sensortilførsel 3",
                "LV": "Īsslēgums, sensora barošana 3",
                "NL": "Kortsluiting, sensorvoeding 3",
                "CZ": "Zkrat, napájení senzor 3",
                "PL": "Zwarcie, czujnik zasil. 3",
                "EE": "Lühis, anduri toide 3",
                "PT": "Curto-circuito, sensor de alimentação 3",
                "ES": "Cortocircuito, sensor de alim. 3",
                "IT": "Cortocircuito, alimentazione sensore 3",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "304": {
                "GB": "Short circuit, sensor supply 4",
                "SE": "Kabelfel, kortslutning, sensormatning 4",
                "DE": "Kurzschluss, Sensorversorgung 4",
                "FR": "Court-circuit, alimentation capteur 4",
                "FI": "Oikosulku, anturin syöttö 4",
                "DK": "Kortslutning, sensorforsyning 4",
                "NO": "Kortslutning, sensortilførsel 4",
                "LV": "Īsslēgums, sensora barošana 4",
                "NL": "Kortsluiting, sensorvoeding 4",
                "CZ": "Zkrat, napájení senzor 4",
                "PL": "Zwarcie, czujnik zasil. 4",
                "EE": "Lühis, anduri toide 4",
                "PT": "Curto-circuito, sensor de alimentação 4",
                "ES": "Cortocircuito, sensor de alim. 4",
                "IT": "Cortocircuito, alimentazione sensore 4",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "305": {
                "GB": "Short circuit, sensor supply 5",
                "SE": "Kabelfel, kortslutning, sensormatning 5",
                "DE": "Kurzschluss, Sensorversorgung 5",
                "FR": "Court-circuit, alimentation capteur 5",
                "FI": "Oikosulku, anturin syöttö 5",
                "DK": "Kortslutning, sensorforsyning 5",
                "NO": "Kortslutning, sensortilførsel 5",
                "LV": "Īsslēgums, sensora barošana 5",
                "NL": "Kortsluiting, sensorvoeding 5",
                "CZ": "Zkrat, napájení senzor 5",
                "PL": "Zwarcie, czujnik zasil. 5",
                "EE": "Lühis, anduri toide 5",
                "PT": "Curto-circuito, sensor de alimentação 5",
                "ES": "Cortocircuito, sensor de alim. 5",
                "IT": "Cortocircuito, alimentazione sensore 5",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "306": {
                "GB": "Short circuit, sensor supply 6",
                "SE": "Kabelfel, kortslutning, sensormatning 6",
                "DE": "Kurzschluss, Sensorversorgung 6",
                "FR": "Court-circuit, alimentation capteur 6",
                "FI": "Oikosulku, anturin syöttö 6",
                "DK": "Kortslutning, sensorforsyning 6",
                "NO": "Kortslutning, sensortilførsel 6",
                "LV": "Īsslēgums, sensora barošana 6",
                "NL": "Kortsluiting, sensorvoeding 6",
                "CZ": "Zkrat, napájení senzor 6",
                "PL": "Zwarcie, czujnik zasil. 6",
                "EE": "Lühis, anduri toide 6",
                "PT": "Curto-circuito, sensor de alimentação 6",
                "ES": "Cortocircuito, sensor de alim. 6",
                "IT": "Cortocircuito, alimentazione sensore 6",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "311": {
                "GB": "Open circuit, sensor supply 1",
                "SE": "Kabelbrott, sensormatning 1",
                "DE": "Schaltkreis offen, Sensorversorgung 1",
                "FR": "Circuit ouvert, alimentation capteur 1",
                "FI": "Avoin virtapiiri, anturin syöttö 1",
                "DK": "Åbent kredsløb, sensorforsyning 1",
                "NO": "Åpen krets, sensortilførsel 1",
                "LV": "Pārtraukta ķēde, sensora barošana 1",
                "NL": "Open circuit, sensorvoeding 1",
                "CZ": "Přerušený obvod, napájení senzor 1",
                "PL": "Obwód otwarty, czujnik zasil. 1",
                "EE": "Tühijooksupinge, anduri toide 1",
                "PT": "Abrir circuito, sensor de alim. 1",
                "ES": "Circuito abierto. sensor de alim. 1",
                "IT": "Circuito aperto, alim. sensore 1",
                "LT": "Atvira grandinė, jutiklio tiekimas 1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "312": {
                "GB": "Open circuit, sensor supply 2",
                "SE": "Kabelbrott, sensormatning 2",
                "DE": "Schaltkreis offen, Sensorversorgung 2",
                "FR": "Circuit ouvert, alimentation capteur 2",
                "FI": "Avoin virtapiiri, anturin syöttö 2",
                "DK": "Åbent kredsløb, sensorforsyning 2",
                "NO": "Åpen krets, sensortilførsel 2",
                "LV": "Pārtraukta ķēde, sensora barošana 2",
                "NL": "Open circuit, sensorvoeding 2",
                "CZ": "Přerušený obvod, napájení senzor 2",
                "PL": "Obwód otwarty, czujnik zasil. 2",
                "EE": "Tühijooksupinge, anduri toide 2",
                "PT": "Abrir circuito, sensor de alim. 2",
                "ES": "Circuito abierto, sensor de alim. 2",
                "IT": "Circuito aperto, alim. sensore 2",
                "LT": "Atvira grandinė, jutiklio tiekimas 2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "313": {
                "GB": "Open circuit, sensor supply 3",
                "SE": "Kabelbrott, sensormatning 3",
                "DE": "Schaltkreis offen, Sensorversorgung 3",
                "FR": "Circuit ouvert, alimentation capteur 3",
                "FI": "Avoin virtapiiri, anturin syöttö 3",
                "DK": "Åbent kredsløb, sensorforsyning 3",
                "NO": "Åpen krets, sensortilførsel 3",
                "LV": "Pārtraukta ķēde, sensora barošana 3",
                "NL": "Open circuit, sensorvoeding 3",
                "CZ": "Přerušený obvod, napájení senzor 3",
                "PL": "Obwód otwarty, czujnik zasil. 3",
                "EE": "Tühijooksupinge, anduri toide 3",
                "PT": "Abrir circuito, sensor de alim. 3",
                "ES": "Circuito abierto, sensor de alim. 3",
                "IT": "Circuito aperto, alim. sensore 3",
                "LT": "Atvira grandinė, jutiklio tiekimas 3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "314": {
                "GB": "Open circuit, sensor supply 4",
                "SE": "Kabelbrott, sensormatning 4",
                "DE": "Schaltkreis offen, Sensorversorgung 4",
                "FR": "Circuit ouvert, alimentation capteur 4",
                "FI": "Avoin virtapiiri, anturin syöttö 4",
                "DK": "Åbent kredsløb, sensorforsyning 4",
                "NO": "Åpen krets, sensortilførsel 4",
                "LV": "Pārtraukta ķēde, sensora barošana 4",
                "NL": "Open circuit, sensorvoeding 4",
                "CZ": "Přerušený obvod, napájení senzor 4",
                "PL": "Obwód otwarty, czujnik zasil. 4",
                "EE": "Tühijooksupinge, anduri toide 4",
                "PT": "Abrir circuito, sensor de alim. 4",
                "ES": "Circuito abierto, sensor de alim. 4",
                "IT": "Circuito aperto, alim. sensore 4",
                "LT": "Atvira grandinė, jutiklio tiekimas 4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "315": {
                "GB": "Open circuit, sensor supply 5",
                "SE": "Kabelbrott, sensormatning 5",
                "DE": "Schaltkreis offen, Sensorversorgung 5",
                "FR": "Circuit ouvert, alimentation capteur 5",
                "FI": "Avoin virtapiiri, anturin syöttö 5",
                "DK": "Åbent kredsløb, sensorforsyning 5",
                "NO": "Åpen krets, sensortilførsel 5",
                "LV": "Pārtraukta ķēde, sensora barošana 5",
                "NL": "Open circuit, sensorvoeding 5",
                "CZ": "Přerušený obvod, napájení senzor 5",
                "PL": "Obwód otwarty, czujnik zasil. 5",
                "EE": "Tühijooksupinge, anduri toide 5",
                "PT": "Abrir circuito, sensor de alim. 5",
                "ES": "Circuito abierto, sensor de alim. 5",
                "IT": "Circuito aperto, alim. sensore 5",
                "LT": "Atvira grandinė, jutiklio tiekimas 5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "316": {
                "GB": "Open circuit, sensor supply 6",
                "SE": "Kabelbrott, sensormatning 6",
                "DE": "Schaltkreis offen, Sensorversorgung 6",
                "FR": "Circuit ouvert, alimentation capteur 6",
                "FI": "Avoin virtapiiri, anturin syöttö 6",
                "DK": "Åbent kredsløb, sensorforsyning 6",
                "NO": "Åpen krets, sensortilførsel 6",
                "LV": "Pārtraukta ķēde, sensora barošana 6",
                "NL": "Open circuit, sensorvoeding 6",
                "CZ": "Přerušený obvod, napájení senzor 6",
                "PL": "Obwód otwarty, czujnik zasil. 6",
                "EE": "Tühijooksupinge, anduri toide 6",
                "PT": "Abrir circuito, sensor de alim. 6",
                "ES": "Circuito abierto, sensor de alim. 6",
                "IT": "Circuito aperto, alim. sensore 6",
                "LT": "Atvira grandinė, jutiklio tiekimas 6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "497": {
                "GB": "MIC40, CAN bus timeout",
                "SE": "MIC40, CAN bus timeout",
                "DE": "MIC40, CAN bus timeout",
                "FR": "MIC40, CAN bus timeout",
                "FI": "MIC40, CAN bus timeout",
                "DK": "MIC40, CAN bus timeout",
                "NO": "MIC40, CAN bus timeout",
                "LV": "MIC40, CAN bus timeout",
                "NL": "MIC40, CAN bus timeout",
                "CZ": "MIC40, CAN bus timeout",
                "PL": "MIC40, CAN bus timeout",
                "EE": "MIC40, CAN bus timeout",
                "PT": "MIC40, CAN bus timeout",
                "ES": "MIC40, CAN bus timeout",
                "IT": "MIC40, CAN bus timeout",
                "LT": "MIC40, CAN bus timeout",
                "KR": "MIC40, CAN bus timeout",
                "JP": "MIC40, CAN bus timeout",
                "IS": "MIC40, CAN bus timeout"
            },
            "498": {
                "GB": "MIC40, API mismatch",
                "SE": "MIC40, API mismatch",
                "DE": "MIC40, API mismatch",
                "FR": "MIC40, API mismatch",
                "FI": "MIC40, API mismatch",
                "DK": "MIC40, API mismatch",
                "NO": "MIC40, API mismatch",
                "LV": "MIC40, API mismatch",
                "NL": "MIC40, API mismatch",
                "CZ": "MIC40, API mismatch",
                "PL": "MIC40, API mismatch",
                "EE": "MIC40, API mismatch",
                "PT": "MIC40, API mismatch",
                "ES": "MIC40, API mismatch",
                "IT": "MIC40, API mismatch",
                "LT": "MIC40, API mismatch",
                "KR": "MIC40, API mismatch",
                "JP": "MIC40, API mismatch",
                "IS": "MIC40, API mismatch"
            },
            "499": {
                "GB": "MIC40, CAN bus tampering",
                "SE": "MIC40, CAN bus tampering",
                "DE": "MIC40, CAN bus tampering",
                "FR": "MIC40, CAN bus tampering",
                "FI": "MIC40, CAN bus tampering",
                "DK": "MIC40, CAN bus tampering",
                "NO": "MIC40, CAN bus tampering",
                "LV": "MIC40, CAN bus tampering",
                "NL": "MIC40, CAN bus tampering",
                "CZ": "MIC40, CAN bus tampering",
                "PL": "MIC40, CAN bus tampering",
                "EE": "MIC40, CAN bus tampering",
                "PT": "MIC40, CAN bus tampering",
                "ES": "MIC40, CAN bus tampering",
                "IT": "MIC40, CAN bus tampering",
                "LT": "MIC40, CAN bus tampering",
                "KR": "MIC40, CAN bus tampering",
                "JP": "MIC40, CAN bus tampering",
                "IS": "MIC40, CAN bus tampering"
            },
            "501": {
                "GB": "Service, 8h",
                "SE": "Service, 8h",
                "DE": "Service, 8h",
                "FR": "Entretien, 8 h",
                "FI": "Huolto, 8h",
                "DK": "Service, 8t",
                "NO": "Service, 8 t",
                "LV": "Apkope, 8h",
                "NL": "Service, 8u",
                "CZ": "Servis, 8 h",
                "PL": "Usługa, 8h",
                "EE": "Hooldus, 8h",
                "PT": "Serviço, 8h",
                "ES": "Servicio, 8 h",
                "IT": "Assistenza, 8h",
                "LT": "Tech. priež., 8 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "502": {
                "GB": "Service, 40h",
                "SE": "Service, 40h",
                "DE": "Service, 40h",
                "FR": "Entretien, 40 h",
                "FI": "Huolto, 40h",
                "DK": "Service, 40t",
                "NO": "Service, 40 t",
                "LV": "Apkope,40h",
                "NL": "Service, 40 u",
                "CZ": "Servis, 40 h",
                "PL": "Usługa, 40h",
                "EE": "Hooldus, 40h",
                "PT": "Serviço, 40h",
                "ES": "Servicio, 40 h",
                "IT": "Assistenza, 40h",
                "LT": "Tech. priež., 40 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "503": {
                "GB": "Service, 160h",
                "SE": "Service, 160h",
                "DE": "Service, 160h",
                "FR": "Entretien, 160 h",
                "FI": "Huolto, 160h",
                "DK": "Service, 160t",
                "NO": "Service, 160 t",
                "LV": "Apkope, 160h",
                "NL": "Service, 160 u",
                "CZ": "Servis, 160 h",
                "PL": "Usługa, 160h",
                "EE": "Hooldus, 160h",
                "PT": "Serviço, 160h",
                "ES": "Servicio, 160 h",
                "IT": "Assistenza, 160h",
                "LT": "Tech. priež., 160 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "504": {
                "GB": "Service, 500/1000h",
                "SE": "Service, 500/1000h",
                "DE": "Service, 500/1000h",
                "FR": "Entretien, 500/1000 h",
                "FI": "Huolto, 500/1000h",
                "DK": "Service, 500/1000t",
                "NO": "Service, 500/1000 t",
                "LV": "Apkope, 500/1000h",
                "NL": "Service, 500/1000 u",
                "CZ": "Servis, 500/1000 h",
                "PL": "Usługa, 500/1000 h",
                "EE": "Hooldus, 500/1000h",
                "PT": "Serviço, 500/1000h",
                "ES": "Servicio, 500/ 1000 h",
                "IT": "Assistenza, 500/1000h",
                "LT": "Tech. priež., 500/1000 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "600": {
                "GB": "Left joystick, off-line",
                "SE": "Vänster joystick, offline",
                "DE": "Linker Joystick, offline",
                "FR": "Joystick gauche, hors ligne",
                "FI": "Vasen ohjain, ei yhteyttä",
                "DK": "Venstre joystick, offline",
                "NO": "Venstre styrespak, frakoblet",
                "LV": "Kr. vadības svira, off-line",
                "NL": "Linker joystick, offline",
                "CZ": "Levý joystick, off-line",
                "PL": "Lewy uchwyt, offline",
                "EE": "Vasak juhtkang, offline",
                "PT": "Joystick esquerdo, offline",
                "ES": "Mando Izquierdo, offline",
                "IT": "Joystick sinistro, offline",
                "LT": "Kair. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "601": {
                "GB": "Left joystick, error on roller: LR1",
                "SE": "Vänster joystick, rullfel: LR1",
                "DE": "Linker Joystick, Fehler an Rolle: LR1",
                "FR": "Joystick gauche, erreur sur roller : LR1",
                "FI": "Vasen ohjain, virhe rullassa: LR1",
                "DK": "Venstre joystick, fejl på rulle: LR1",
                "NO": "Venstre styrespak, feil på rulle: LR1",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR1",
                "NL": "Linker joystick, fout roller: LR1",
                "CZ": "Levý joystick, chyba na válci: LR1",
                "PL": "Lewy uchwyt, error na rolka: LR1",
                "EE": "Vasak juhtkang, rulli viga: LR1",
                "PT": "Joystick esquerdo, erro no rolo: LR1",
                "ES": "Mando izquierdo, error de rótula LR1",
                "IT": "Joystick sinistro, errore sul selettore: LR1",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "602": {
                "GB": "Left joystick, error on roller: LR2",
                "SE": "Vänster joystick, rullfel: LR2",
                "DE": "Linker Joystick, Fehler an Rolle: LR2",
                "FR": "Joystick gauche, erreur sur roller : LR2",
                "FI": "Vasen ohjain, virhe rullassa: LR2",
                "DK": "Venstre joystick, fejl på rulle: LR2",
                "NO": "Venstre styrespak, feil på rulle: LR2",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR2",
                "NL": "Linker joystick, fout roller: LR2",
                "CZ": "Levý joystick, chyba na válci: LR2",
                "PL": "Lewy uchwyt, error na rolka: LR2",
                "EE": "Vasak juhtkang, rulli viga: LR2",
                "PT": "Joystick esquerdo, erro no rolo: LR2",
                "ES": "Mando izquierdo, error de rótula LR2",
                "IT": "Joystick sinistro, errore sul selettore: LR2",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "603": {
                "GB": "Left joystick, error on roller: LR3",
                "SE": "Vänster joystick, rullfel: LR3",
                "DE": "Linker Joystick, Fehler an Rolle: LR3",
                "FR": "Joystick gauche, erreur sur roller : LR3",
                "FI": "Vasen ohjain, virhe rullassa: LR3",
                "DK": "Venstre joystick, fejl på rulle: LR3",
                "NO": "Venstre styrespak, feil på rulle: LR3",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR3",
                "NL": "Linker joystick, fout roller: LR3",
                "CZ": "Levý joystick, chyba na válci: LR3",
                "PL": "Lewy uchwyt, error na rolka: LR3",
                "EE": "Vasak juhtkang, rulli viga: LR3",
                "PT": "Joystick esquerdo, erro no rolo: LR3",
                "ES": "Mando izquierdo, error de rótula LR3",
                "IT": "Joystick sinistro, errore sul selettore: LR3",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "604": {
                "GB": "Left joystick, error on roller: LR4",
                "SE": "Vänster joystick, rullfel: LR4",
                "DE": "Linker Joystick, Fehler an Rolle: LR4",
                "FR": "Joystick gauche, erreur sur roller : LR4",
                "FI": "Vasen ohjain, virhe rullassa: LR4",
                "DK": "Venstre joystick, fejl på rulle: LR4",
                "NO": "Venstre styrespak, feil på rulle: LR4",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR4",
                "NL": "Linker joystick, fout roller: LR4",
                "CZ": "Levý joystick, chyba na válci: LR4",
                "PL": "Lewy uchwyt, error na rolka: LR4",
                "EE": "Vasak juhtkang, rulli viga: LR4",
                "PT": "Joystick esquerdo, erro no rolo: LR4",
                "ES": "Mando izquierdo, error de rótula LR4",
                "IT": "Joystick sinistro, errore sul selettore: LR4",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "611": {
                "GB": "Left joystick, error on button: LS1",
                "SE": "Vänster joystick, knappfel: LS1",
                "DE": "Linker Joystick, Fehler an Taste: LS1",
                "FR": "Joystick gauche, erreur sur bouton : LS1",
                "FI": "Vasen ohjain, virhe painikkeessa: LS1",
                "DK": "Venstre joystick, fejl på knap: LS1",
                "NO": "Venstre styrespak, feil på knapp: LS1",
                "LV": "Kreisā vadības svira, pogas kļūda: LS1",
                "NL": "Linker joystick, fout op knop: LS1",
                "CZ": "Levý joystick, chyba na tlačítku: LS1",
                "PL": "Lewy uchwyt, error na przycisku: LS1",
                "EE": "Vasak juhtkang, viga nupul: LS1",
                "PT": "Joystick esquerdo, erro no botão: LS1",
                "ES": "Mando izquierdo, error de botón: LS1",
                "IT": "Joystick sinistro, errore sul pulsante: LS1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "612": {
                "GB": "Left joystick, error on button: LS2",
                "SE": "Vänster joystick, knappfel: LS2",
                "DE": "Linker Joystick, Fehler an Taste: LS2",
                "FR": "Joystick gauche, erreur sur bouton : LS2",
                "FI": "Vasen ohjain, virhe painikkeessa: LS2",
                "DK": "Venstre joystick, fejl på knap: LS2",
                "NO": "Venstre styrespak, feil på knapp: LS2",
                "LV": "Kreisā vadības svira, pogas kļūda: LS2",
                "NL": "Linker joystick, fout op knop: LS2",
                "CZ": "Levý joystick, chyba na tlačítku: LS2",
                "PL": "Lewy uchwyt, error na przycisku: LS2",
                "EE": "Vasak juhtkang, viga nupul: LS2",
                "PT": "Joystick esquerdo, erro no botão: LS2",
                "ES": "Mando izquierdo, error de botón: LS2",
                "IT": "Joystick sinistro, errore sul pulsante: LS2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "613": {
                "GB": "Left joystick, error on button: LS3",
                "SE": "Vänster joystick, knappfel: LS3",
                "DE": "Linker Joystick, Fehler an Taste: LS3",
                "FR": "Joystick gauche, erreur sur bouton : LS3",
                "FI": "Vasen ohjain, virhe painikkeessa: LS3",
                "DK": "Venstre joystick, fejl på knap: LS3",
                "NO": "Venstre styrespak, feil på knapp: LS3",
                "LV": "Kreisā vadības svira, pogas kļūda: LS3",
                "NL": "Linker joystick, fout op knop: LS3",
                "CZ": "Levý joystick, chyba na tlačítku: LS3",
                "PL": "Lewy uchwyt, error na przycisku: LS3",
                "EE": "Vasak juhtkang, viga nupul: LS3",
                "PT": "Joystick esquerdo, erro no botão: LS3",
                "ES": "Mando izquierdo, error de botón: LS3",
                "IT": "Joystick sinistro, errore sul pulsante: LS3",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "614": {
                "GB": "Left joystick, error on button: LS4",
                "SE": "Vänster joystick, knappfel: LS4",
                "DE": "Linker Joystick, Fehler an Taste: LS4",
                "FR": "Joystick gauche, erreur sur bouton : LS4",
                "FI": "Vasen ohjain, virhe painikkeessa: LS4",
                "DK": "Venstre joystick, fejl på knap: LS4",
                "NO": "Venstre styrespak, feil på knapp: LS4",
                "LV": "Kreisā vadības svira, pogas kļūda: LS4",
                "NL": "Linker joystick, fout op knop: LS4",
                "CZ": "Levý joystick, chyba na tlačítku: LS4",
                "PL": "Lewy uchwyt, error na przycisku: LS4",
                "EE": "Vasak juhtkang, viga nupul: LS4",
                "PT": "Joystick esquerdo, erro no botão: LS4",
                "ES": "Mando izquierdo, error de botón: LS4",
                "IT": "Joystick sinistro, errore sul pulsante: LS4",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "615": {
                "GB": "Left joystick, error on button: LS5",
                "SE": "Vänster joystick, knappfel: LS5",
                "DE": "Linker Joystick, Fehler an Taste: LS5",
                "FR": "Joystick gauche, erreur sur bouton : LS5",
                "FI": "Vasen ohjain, virhe painikkeessa: LS5",
                "DK": "Venstre joystick, fejl på knap: LS5",
                "NO": "Venstre styrespak, feil på knapp: LS5",
                "LV": "Kreisā vadības svira, pogas kļūda: LS5",
                "NL": "Linker joystick, fout op knop: LS5",
                "CZ": "Levý joystick, chyba na tlačítku: LS5",
                "PL": "Lewy uchwyt, error na przycisku: LS5",
                "EE": "Vasak juhtkang, viga nupul: LS5",
                "PT": "Joystick esquerdo, erro no botão: LS5",
                "ES": "Mando izquierdo, error de botón: LS5",
                "IT": "Joystick sinistro, errore sul pulsante: LS5",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "616": {
                "GB": "Left joystick, error on button: LS6",
                "SE": "Vänster joystick, knappfel: LS6",
                "DE": "Linker Joystick, Fehler an Taste: LS6",
                "FR": "Joystick gauche, erreur sur bouton : LS6",
                "FI": "Vasen ohjain, virhe painikkeessa: LS6",
                "DK": "Venstre joystick, fejl på knap: LS6",
                "NO": "Venstre styrespak, feil på knapp: LS6",
                "LV": "Kreisā vadības svira, pogas kļūda: LS6",
                "NL": "Linker joystick, fout op knop: LS6",
                "CZ": "Levý joystick, chyba na tlačítku: LS6",
                "PL": "Lewy uchwyt, error na przycisku: LS6",
                "EE": "Vasak juhtkang, viga nupul: LS6",
                "PT": "Joystick esquerdo, erro no botão: LS6",
                "ES": "Mando izquierdo, error de botón: LS6",
                "IT": "Joystick sinistro, errore sul pulsante: LS6",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "617": {
                "GB": "Left joystick, error on button: LS7",
                "SE": "Vänster joystick, knappfel: LS7",
                "DE": "Linker Joystick, Fehler an Taste: LS7",
                "FR": "Joystick gauche, erreur sur bouton : LS7",
                "FI": "Vasen ohjain, virhe painikkeessa: LS7",
                "DK": "Venstre joystick, fejl på knap: LS7",
                "NO": "Venstre styrespak, feil på knapp: LS7",
                "LV": "Kreisā vadības svira, pogas kļūda: LS7",
                "NL": "Linker joystick, fout op knop: LS7",
                "CZ": "Levý joystick, chyba na tlačítku: LS7",
                "PL": "Lewy uchwyt, error na przycisku: LS7",
                "EE": "Vasak juhtkang, viga nupul: LS7",
                "PT": "Joystick esquerdo, erro no botão: LS7",
                "ES": "Mando izquierdo, error de botón: LS7",
                "IT": "Joystick sinistro, errore sul pulsante: LS7",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "618": {
                "GB": "Left joystick, error on button: LS8",
                "SE": "Vänster joystick, knappfel: LS8",
                "DE": "Linker Joystick, Fehler an Taste: LS8",
                "FR": "Joystick gauche, erreur sur bouton : LS8",
                "FI": "Vasen ohjain, virhe painikkeessa: LS8",
                "DK": "Venstre joystick, fejl på knap: LS8",
                "NO": "Venstre styrespak, feil på knapp: LS8",
                "LV": "Kreisā vadības svira, pogas kļūda: LS8",
                "NL": "Linker joystick, fout op knop: LS8",
                "CZ": "Levý joystick, chyba na tlačítku: LS8",
                "PL": "Lewy uchwyt, error na przycisku: LS8",
                "EE": "Vasak juhtkang, viga nupul: LS8",
                "PT": "Joystick esquerdo, erro no botão: LS8",
                "ES": "Mando izquierdo, error de botón: LS8",
                "IT": "Joystick sinistro, errore sul pulsante: LS8",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "619": {
                "GB": "Left joystick, error on button: LFNR1",
                "SE": "Vänster joystick, knappfel: LFNR1",
                "DE": "Linker Joystick, Fehler an Taste: LFNR1",
                "FR": "Joystick gauche, erreur sur bouton : LFNR1",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR1",
                "DK": "Venstre joystick, fejl på knap: LFNR1",
                "NO": "Venstre styrespak, feil på knapp: LFNR1",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR1",
                "NL": "Linker joystick, fout op knop: LFNR1",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR1",
                "PL": "Lewy joystick, błąd na przycisku: LFNR1",
                "EE": "Vasak juhtkang, viga nupul: LFNR1",
                "PT": "Joystick esquerdo, erro no botão: LFNR1",
                "ES": "Mando izquierdo, error de botón: LFNR1",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "620": {
                "GB": "Left joystick, error on button: LFNR2",
                "SE": "Vänster joystick, knappfel: LFNR2",
                "DE": "Linker Joystick, Fehler an Taste: LFNR2",
                "FR": "Joystick gauche, erreur sur bouton : LFNR2",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR2",
                "DK": "Venstre joystick, fejl på knap: LFNR2",
                "NO": "Venstre styrespak, feil på knapp: LFNR2",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR2",
                "NL": "Linker joystick, fout op knop: LFNR2",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR2",
                "PL": "Lewy uchwyt, error na przycisku: LFNR2",
                "EE": "Vasak juhtkang, viga nupul: LFNR2",
                "PT": "Joystick esquerdo, erro no botão: LFNR2",
                "ES": "Mando izquierdo, error de botón: LFNR2",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "631": {
                "GB": "Left joystick, CAN1 problem",
                "SE": "Vänster joystick, CAN1 problem",
                "DE": "Linker Joystick, CAN1-Problem",
                "FR": "Joystick gauche, problème CAN1",
                "FI": "Vasen ohjain, CAN1-ongelma",
                "DK": "Venstre joystick, CAN1-problem",
                "NO": "Venstre styrespak, CAN1-problem",
                "LV": "Kreisā vadības svira, CAN1 problēma",
                "NL": "Linker joystick, CAN1 probleem",
                "CZ": "Levý joystick, problém CAN1",
                "PL": "Lewy joystick, problem z CAN1",
                "EE": "Vasak juhtkang, CAN1 probleem",
                "PT": "Joystick esquerdo, problema CAN1",
                "ES": "Mando izquierdo, error de CAN1:",
                "IT": "Joystick sinistro, problema CAN1",
                "LT": "Kair. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "632": {
                "GB": "Left joystick, CRC error flash1",
                "SE": "Vänster joystick, CRC-fel flash1",
                "DE": "Linker Joystick, CRC-Fehler Blink1",
                "FR": "Joystick gauche, erreur CRC flash1",
                "FI": "Vasen ohjain, CRC-virhe valo1",
                "DK": "Venstre joystick, CRC-fejlblink1",
                "NO": "Venstre styrespak, CRC-feil flash1",
                "LV": "Kreisā vadības svira, CRC kļūda flash1",
                "NL": "Linker joystick, CRC fout flash1",
                "CZ": "Levý joystick, CRC chyba flash1",
                "PL": "Lewy uchwyt, CRC error flash1",
                "EE": "Vasak juhtkang, CRC viga flash1",
                "PT": "Joystick esquerdo, CRC erro flash1",
                "ES": "Mando izquierdo, error de CAN1, flash1",
                "IT": "Joystick sinistro, CRC errore flash1",
                "LT": "Kair. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "633": {
                "GB": "Left joystick, CAN2 problem",
                "SE": "Vänster joystick, CAN2 problem",
                "DE": "Linker Joystick, CAN2-Problem",
                "FR": "Joystick gauche, problème CAN2",
                "FI": "Vasen ohjain, CAN2-ongelma",
                "DK": "Venstre joystick, CAN2-problem",
                "NO": "Venstre styrespak, CAN2-problem",
                "LV": "Kreisā vadības svira, CAN2 problēma",
                "NL": "Linker joystick, CAN2 probleem",
                "CZ": "Levý joystick, problém CAN2",
                "PL": "Lewy uchwyt, CAN2 problem",
                "EE": "Vasak juhtkang, CAN2 probleem",
                "PT": "Joystick esquerdo, problema CAN2",
                "ES": "Mando izquierdo, error de CAN2",
                "IT": "Joystick sinistro, problema CAN2",
                "LT": "Kair. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "634": {
                "GB": "Left joystick, CRC error flash2",
                "SE": "Vänster joystick, CRC-fel flash2",
                "DE": "Linker Joystick, CRC-Fehler Blink2",
                "FR": "Joystick gauche, erreur CRC flash2",
                "FI": "Vasen ohjain, CRC-virhe valo2",
                "DK": "Venstre joystick, CRC-fejlblink2",
                "NO": "Venstre styrespak, CRC-feil flash2",
                "LV": "Kreisā vadības svira, CRC kļūda flash2",
                "NL": "Linker joystick, CRC fout flash2",
                "CZ": "Levý joystick, CRC chyba flash2",
                "PL": "Lewy uchwyt, CRC error flash2",
                "EE": "Vasak juhtkang, CRC viga flash2",
                "PT": "Joystick esquerdo, CRC erro flash2",
                "ES": "Mando izquierdo, error de CAN1, flash2",
                "IT": "Joystick sinistro, CRC errore flash2",
                "LT": "Kair. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "649": {
                "GB": "Left joystick, internal error",
                "SE": "Vänster joystick, internt fel",
                "DE": "Linker Joystick, interner Fehler",
                "FR": "Joystick gauche, erreur interne",
                "FI": "Vasen ohjain, sisäinen virhe",
                "DK": "Venstre joystick, intern fejl",
                "NO": "Venstre styrespak, intern feil",
                "LV": "Kr. vadības svira, iekšējās kļūda",
                "NL": "Linker joystick, interne fout",
                "CZ": "Levý joystick, interní chyba",
                "PL": "Lewy uchwyt, internal error",
                "EE": "Vasak juhtkang, sisemine viga",
                "PT": "Joystick esquerdo, erro interno",
                "ES": "Mando izquierdo, error interno",
                "IT": "Joystick sinistro, errore interno",
                "LT": "Kair. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "650": {
                "GB": "Right joystick, off-line",
                "SE": "Höger joystick, offline",
                "DE": "Rechter Joystick, offline",
                "FR": "Joystick droit, hors ligne",
                "FI": "Oikea ohjain, ei yhteyttä",
                "DK": "Højre joystick, offline",
                "NO": "Høyre styrespak, frakoblet",
                "LV": "La. vadības svira, off-line",
                "NL": "Rechter joystick, off-line",
                "CZ": "Pravý joystick, off-line",
                "PL": "Prawy uchwyt, offline",
                "EE": "Parem juhtkang, offline",
                "PT": "Joystick direito, offline",
                "ES": "Mando derecho, inactivado",
                "IT": "Joystick destro, offline",
                "LT": "Dešin. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "651": {
                "GB": "Right joystick, error on roller: RR1",
                "SE": "Höger joystick, rullfel: RR1",
                "DE": "Rechter Joystick, Fehler an Rolle: RR1",
                "FR": "Joystick droit, erreur sur roller : RR1",
                "FI": "Oikea ohjain, virhe rullassa: RR1",
                "DK": "Højre joystick, fejl på rulle: RR1",
                "NO": "Høyre styrespak, feil på rulle: RR1",
                "LV": "Labā vadības svira, ritenīša kļūda: RR1",
                "NL": "Rechter joystick, fout op roller: RR1",
                "CZ": "Pravý joystick, chyba na válci: RR1",
                "PL": "Prawy uchwyt, error na rolka: RR1",
                "EE": "Parem juhtkang, rulli viga: RR1",
                "PT": "Joystick direito, erro no rolo: RR1",
                "ES": "Mando derecho, error de rótula: RR1",
                "IT": "Joystick destro, errore sul selettore: RR1",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "652": {
                "GB": "Right joystick, error on roller: RR2",
                "SE": "Höger joystick, rullfel: RR2",
                "DE": "Rechter Joystick, Fehler an Rolle: RR2",
                "FR": "Joystick droit, erreur sur roller : RR2",
                "FI": "Oikea ohjain, virhe rullassa: RR2",
                "DK": "Højre joystick, fejl på rulle: RR2",
                "NO": "Høyre styrespak, feil på rulle: RR2",
                "LV": "Labā vadības svira, ritenīša kļūda: RR2",
                "NL": "Rechter joystick, fout op roller: RR2",
                "CZ": "Pravý joystick, chyba na válci: RR2",
                "PL": "Prawy uchwyt, error na rolka: RR2",
                "EE": "Parem juhtkang, rulli viga: RR2",
                "PT": "Joystick direito, erro no rolo: RR2",
                "ES": "Mando derecho, error de rótula: RR2",
                "IT": "Joystick destro, errore sul selettore: RR2",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "653": {
                "GB": "Right joystick, error on roller: RR3",
                "SE": "Höger joystick, rullfel: RR3",
                "DE": "Rechter Joystick, Fehler an Rolle: RR3",
                "FR": "Joystick droit, erreur sur roller : RR3",
                "FI": "Oikea ohjain, virhe rullassa: RR3",
                "DK": "Højre joystick, fejl på rulle: RR3",
                "NO": "Høyre styrespak, feil på rulle: RR3",
                "LV": "Labā vadības svira, ritenīša kļūda: RR3",
                "NL": "Rechter joystick, fout op roller: RR3",
                "CZ": "Pravý joystick, chyba na válci: RR3",
                "PL": "Prawy uchwyt, error na rolka: RR3",
                "EE": "Parem juhtkang, rulli viga: RR3",
                "PT": "Joystick direito, erro no rolo: RR3",
                "ES": "Mando derecho, error de rótula: RR3",
                "IT": "Joystick destro, errore sul selettore: RR3",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "654": {
                "GB": "Right joystick, error on roller: RR4",
                "SE": "Höger joystick, rullfel: RR4",
                "DE": "Rechter Joystick, Fehler an Rolle: RR4",
                "FR": "Joystick droit, erreur sur roller : RR4",
                "FI": "Oikea ohjain, virhe rullassa: RR4",
                "DK": "Højre joystick, fejl på rulle: RR4",
                "NO": "Høyre styrespak, feil på rulle: RR4",
                "LV": "Labā vadības svira, ritenīša kļūda: RR4",
                "NL": "Rechter joystick, fout op roller: RR4",
                "CZ": "Pravý joystick, chyba na válci: RR4",
                "PL": "Prawy uchwyt, error na rolka: RR4",
                "EE": "Parem juhtkang, rulli viga: RR4",
                "PT": "Joystick direito, erro no rolo: RR4",
                "ES": "Mando derecho, error de rótula: RR4",
                "IT": "Joystick destro, errore sul selettore: RR4",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "661": {
                "GB": "Right joystick, error on button: RS1",
                "SE": "Höger joystick, knappfel: RS1",
                "DE": "Rechter Joystick, Fehler an Taste: RS1",
                "FR": "Joystick droit, erreur sur bouton : RS1",
                "FI": "Oikea ohjain, virhe painikkeessa: RS1",
                "DK": "Højre joystick, fejl på knap: RS1",
                "NO": "Høyre styrespak, feil på knapp: RS1",
                "LV": "Labā vadības svira, pogas kļūda: RS1",
                "NL": "Rechter joystick, fout op knop: RS1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS1",
                "PL": "Prawy uchwyt, error na Przycisk: RS1",
                "EE": "Parem juhtkang, viga nupul: RS1",
                "PT": "Joystick direito, erro no botão: RS1",
                "ES": "Mando derecho, error de botón: RS1",
                "IT": "Joystick destro, errore sul pulsante: RS1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "662": {
                "GB": "Right joystick, error on button: RS2",
                "SE": "Höger joystick, knappfel: RS2",
                "DE": "Rechter Joystick, Fehler an Taste: RS2",
                "FR": "Joystick droit, erreur sur bouton : RS2",
                "FI": "Oikea ohjain, virhe painikkeessa: RS2",
                "DK": "Højre joystick, fejl på knap: RS2",
                "NO": "Høyre styrespak, feil på knapp: RS2",
                "LV": "Labā vadības svira, pogas kļūda: RS2",
                "NL": "Rechter joystick, fout op knop: RS2",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS2",
                "PL": "Prawy uchwyt, error na Przycisk: RS2",
                "EE": "Parem juhtkang, viga nupul: RS2",
                "PT": "Joystick direito, erro no botão: RS2",
                "ES": "Mando derecho, error de botón: RS2",
                "IT": "Joystick destro, errore sul pulsante: RS2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "663": {
                "GB": "Right joystick, error on button: RS3",
                "SE": "Höger joystick, knappfel: RS3",
                "DE": "Rechter Joystick, Fehler an Taste: RS3",
                "FR": "Joystick droit, erreur sur bouton : RS3",
                "FI": "Oikea ohjain, virhe painikkeessa: RS3",
                "DK": "Højre joystick, fejl på knap: RS3",
                "NO": "Høyre styrespak, feil på knapp: RS3",
                "LV": "Labā vadības svira, pogas kļūda: RS3",
                "NL": "Rechter joystick, fout op knop: RS3",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS3",
                "PL": "Prawy uchwyt, error na Przycisk: RS3",
                "EE": "Parem juhtkang, viga nupul: RS3",
                "PT": "Joystick direito, erro no botão: RS3",
                "ES": "Mando derecho, error de botón: RS3",
                "IT": "Joystick destro, errore sul pulsante: RS3",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "664": {
                "GB": "Right joystick, error on button: RS4",
                "SE": "Höger joystick, knappfel: RS4",
                "DE": "Rechter Joystick, Fehler an Taste: RS4",
                "FR": "Joystick droit, erreur sur bouton : RS4",
                "FI": "Oikea ohjain, virhe painikkeessa: RS4",
                "DK": "Højre joystick, fejl på knap: RS4",
                "NO": "Høyre styrespak, feil på knapp: RS4",
                "LV": "Labā vadības svira, pogas kļūda: RS4",
                "NL": "Rechter joystick, fout op knop: RS4",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS4",
                "PL": "Prawy uchwyt, error na Przycisk: RS4",
                "EE": "Parem juhtkang, viga nupul: RS4",
                "PT": "Joystick direito, erro no botão: RS4",
                "ES": "Mando derecho, error de botón: RS4",
                "IT": "Joystick destro, errore sul pulsante: RS4",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "665": {
                "GB": "Right joystick, error on button: RS5",
                "SE": "Höger joystick, knappfel: RS5",
                "DE": "Rechter Joystick, Fehler an Taste: RS5",
                "FR": "Joystick droit, erreur sur bouton : RS5",
                "FI": "Oikea ohjain, virhe painikkeessa: RS5",
                "DK": "Højre joystick, fejl på knap: RS5",
                "NO": "Høyre styrespak, feil på knapp: RS5",
                "LV": "Labā vadības svira, pogas kļūda: RS5",
                "NL": "Rechter joystick, fout op knop: RS5",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS5",
                "PL": "Prawy uchwyt, error na Przycisk: RS5",
                "EE": "Parem juhtkang, viga nupul: RS5",
                "PT": "Joystick direito, erro no botão: RS5",
                "ES": "Mando derecho, error de botón: RS5",
                "IT": "Joystick destro, errore sul pulsante: RS5",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "666": {
                "GB": "Right joystick, error on button: RS6",
                "SE": "Höger joystick, knappfel: RS6",
                "DE": "Rechter Joystick, Fehler an Taste: RS6",
                "FR": "Joystick droit, erreur sur bouton : RS6",
                "FI": "Oikea ohjain, virhe painikkeessa: RS6",
                "DK": "Højre joystick, fejl på knap: RS6",
                "NO": "Høyre styrespak, feil på knapp: RS6",
                "LV": "Labā vadības svira, pogas kļūda: RS6",
                "NL": "Rechter joystick, fout op knop: RS6",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS6",
                "PL": "Prawy uchwyt, error na Przycisk: RS6",
                "EE": "Parem juhtkang, viga nupul: RS6",
                "PT": "Joystick direito, erro no botão: RS6",
                "ES": "Mando derecho, error de botón: RS6",
                "IT": "Joystick destro, errore sul pulsante: RS6",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "667": {
                "GB": "Right joystick, error on button: RS7",
                "SE": "Höger joystick, knappfel: RS7",
                "DE": "Rechter Joystick, Fehler an Taste: RS7",
                "FR": "Joystick droit, erreur sur bouton : RS7",
                "FI": "Oikea ohjain, virhe painikkeessa: RS7",
                "DK": "Højre joystick, fejl på knap: RS7",
                "NO": "Høyre styrespak, feil på knapp: RS7",
                "LV": "Labā vadības svira, pogas kļūda: RS7",
                "NL": "Rechter joystick, fout op knop: RS7",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS7",
                "PL": "Prawy uchwyt, error na Przycisk: RS7",
                "EE": "Parem juhtkang, viga nupul: RS7",
                "PT": "Joystick direito, erro no botão: RS7",
                "ES": "Mando derecho, error de botón: RS7",
                "IT": "Joystick destro, errore sul pulsante: RS7",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "668": {
                "GB": "Right joystick, error on button: RS8",
                "SE": "Höger joystick, knappfel: RS8",
                "DE": "Rechter Joystick, Fehler an Taste: RS8",
                "FR": "Joystick droit, erreur sur bouton : RS8",
                "FI": "Oikea ohjain, virhe painikkeessa: RS8",
                "DK": "Højre joystick, fejl på knap: RS8",
                "NO": "Høyre styrespak, feil på knapp: RS8",
                "LV": "Labā vadības svira, pogas kļūda: RS8",
                "NL": "Rechter joystick, fout op knop: RS8",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS8",
                "PL": "Prawy uchwyt, error na Przycisk: RS8",
                "EE": "Parem juhtkang, viga nupul: RS8",
                "PT": "Joystick direito, erro no botão: RS8",
                "ES": "Mando derecho, error de botón: RS8",
                "IT": "Joystick destro, errore sul pulsante: RS8",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "669": {
                "GB": "Right joystick, error on button: RFNR1",
                "SE": "Höger joystick, knappfel: RFNR1",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR1",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR1",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR1",
                "PT": "Joystick direito, erro no botão: RFNR1",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "670": {
                "GB": "Right joystick, error on button: RFNR2",
                "SE": "Höger joystick, knappfel: RFNR2",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR2",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR2",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR2",
                "PT": "Joystick direito, erro no botão: RFNR2",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "681": {
                "GB": "Right joystick, CAN1 problem",
                "SE": "Höger joystick, CAN1 problem",
                "DE": "Rechter Joystick, CAN1-Problem",
                "FR": "Joystick droit, problème CAN1",
                "FI": "Oikea ohjain, CAN1-ongelma",
                "DK": "Højre joystick, CAN1-problem",
                "NO": "Høyre styrespak, CAN1-problem",
                "LV": "La. vadības svira, CAN1 problēma",
                "NL": "Rechter joystick, CAN1 probleem",
                "CZ": "Pravý joystick, problém CAN1",
                "PL": "Prawy uchwyt, CAN1 problem",
                "EE": "Parem juhtkang, CAN1 probleem",
                "PT": "Joystick direito, problema de CAN1",
                "ES": "Mando derecho, error de CAN1",
                "IT": "Joystick destro, problema CAN1",
                "LT": "Dešin. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "682": {
                "GB": "Right joystick, CRC error flash1",
                "SE": "Höger joystick, CRC-fel flash1",
                "DE": "Rechter Joystick, CRC-Fehler Blink1",
                "FR": "Joystick droit, erreur CRC flash1",
                "FI": "Oikea ohjain, CRC-virhe valo1",
                "DK": "Højre joystick, CRC-fejlblink1",
                "NO": "Høyre styrespak, CRC-feil flash1",
                "LV": "La. vadības svira, CRC kļūda flash1",
                "NL": "Rechter joystick, CRC fout flash1",
                "CZ": "Pravý joystick, CRC chyba flash1",
                "PL": "Prawy uchwyt, CRC error flash1",
                "EE": "Parem juhtkang, CRC viga flash1",
                "PT": "Joystick direito, CRC erro flash1",
                "ES": "Mando derecho, error de CAN1, flash1",
                "IT": "Joystick destro, CRC errore flash1",
                "LT": "Dešin. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "683": {
                "GB": "Right joystick, CAN2 problem",
                "SE": "Höger joystick, CAN2 problem",
                "DE": "Rechter Joystick, CAN2-Problem",
                "FR": "Joystick droit, problème CAN2",
                "FI": "Oikea ohjain, CAN2-ongelma",
                "DK": "Højre joystick, CAN2-problem",
                "NO": "Høyre styrespak, CAN2-problem",
                "LV": "La. vadības svira, CAN2 problēma",
                "NL": "Rechter joystick, CAN2 probleem",
                "CZ": "Pravý joystick, problém CAN2",
                "PL": "Prawy uchwyt, CAN2 problem",
                "EE": "Parem juhtkang, CAN2 probleem",
                "PT": "Joystick direito, problema de CAN2",
                "ES": "Mando derecho, error de CAN2",
                "IT": "Joystick destro, problema CAN2",
                "LT": "Dešin. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "684": {
                "GB": "Right joystick, CRC error flash2",
                "SE": "Höger joystick, CRC-fel flash2",
                "DE": "Rechter Joystick, CRC-Fehler Blink2",
                "FR": "Joystick droit, erreur CRC flash2",
                "FI": "Oikea ohjain, CRC-virhe valo2",
                "DK": "Højre joystick, CRC-fejlblink2",
                "NO": "Høyre styrespak, CRC-feil flash2",
                "LV": "La. vadības svira, CRC kļūda flash2",
                "NL": "Rechter joystick, CRC fout flash2",
                "CZ": "Pravý joystick, CRC chyba flash2",
                "PL": "Prawy uchwyt, CRC error flash2",
                "EE": "Parem juhtkang, CRC viga flash2",
                "PT": "Joystick direito, CRC erro flash2",
                "ES": "Mando derecho, error de CAN1, flash2",
                "IT": "Joystick destro, CRC errore flash2",
                "LT": "Dešin. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "699": {
                "GB": "Right joystick, internal error",
                "SE": "Höger joystick, internt fel",
                "DE": "Rechter Joystick, interner Fehler",
                "FR": "Joystick droit, erreur interne",
                "FI": "Oikea ohjain, sisäinen virhe",
                "DK": "Højre joystick, intern fejl",
                "NO": "Høyre styrespak, intern feil",
                "LV": "La. vadības svira, iekšējās kļūda",
                "NL": "Rechter joystick, interne fout",
                "CZ": "Pravý joystick, interní chyba",
                "PL": "Prawy uchwyt, internal error",
                "EE": "Parem juhtkang, sisemine viga",
                "PT": "Joystick direito, erro interno",
                "ES": "Mando derecho, error interno",
                "IT": "Joystick destro, errore interno",
                "LT": "Dešin. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "700": {
                "GB": "CAC1, off-line",
                "SE": "CAC1, offline",
                "DE": "CAC1, offline",
                "FR": "CAC1, hors ligne",
                "FI": "CAC1, ei yhteyttä",
                "DK": "CAC1, offline",
                "NO": "CAC1, frakoblet",
                "LV": "CAC1, off-line",
                "NL": "CAC1, off-line",
                "CZ": "CAC1, off-line",
                "PL": "CAC1, off-line",
                "EE": "CAC1, off-line",
                "PT": "CAC1, off-line",
                "ES": "CAC1, offline",
                "IT": "CAC1, off-line",
                "LT": "CAC1 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "701": {
                "GB": "CAC1, error on channel1",
                "SE": "CAC1, fel på kanal1",
                "DE": "CAC1, Fehler an Kanal1",
                "FR": "CAC1, erreur sur canal1",
                "FI": "CAC1, virhe kanavassa1",
                "DK": "CAC1, fejl på kanal1",
                "NO": "CAC1, feil på kanal1",
                "LV": "CAC1, kļūda 1. kanālā",
                "NL": "CAC1, fout op kanaal1",
                "CZ": "CAC1, chyba na kanálu1",
                "PL": "CAC1, błąd na kanale 1",
                "EE": "CAC1, kanal1 viga",
                "PT": "CAC1, erro no canal1",
                "ES": "CAC1, error en canal 1",
                "IT": "CAC1, errore sul canale1",
                "LT": "CAC1, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "702": {
                "GB": "CAC1, error on channel2",
                "SE": "CAC1, fel på kanal2",
                "DE": "CAC1, Fehler an Kanal2",
                "FR": "CAC1, erreur sur canal2",
                "FI": "CAC1, virhe kanavassa2",
                "DK": "CAC1, fejl på kanal2",
                "NO": "CAC1, feil på kanal2",
                "LV": "CAC1, kļūda 2. kanālā",
                "NL": "CAC1, fout op kanaal2",
                "CZ": "CAC1, chyba na kanálu2",
                "PL": "CAC1, error on channel2",
                "EE": "CAC1, kanal2 viga",
                "PT": "CAC1, erro no canal2",
                "ES": "CAC1, error en canal 2",
                "IT": "CAC1, errore sul canale2",
                "LT": "CAC1, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "703": {
                "GB": "CAC1, CAN-bus problem",
                "SE": "CAC1, CAN-bus problem",
                "DE": "CAC1, CAN-Bus-Problem",
                "FR": "CAC1, problème de bus CAN",
                "FI": "CAC1, CAN-väyläongelma",
                "DK": "CAC1, CAN-bus-problem",
                "NO": "CAC1, CAN-bussproblem",
                "LV": "CAC1, CAN kopnes problēma",
                "NL": "CAC1, CAN-bus probleem",
                "CZ": "CAC1, problém se sběrnicí CAN",
                "PL": "CAC1, problem z magistralą CAN",
                "EE": "CAC1, CAN-siini probleem",
                "PT": "CAC1, problema de barramento CAN",
                "ES": "CAC1, error de CAN bus",
                "IT": "CAC1, problema bus CAN",
                "LT": "CAC1, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "704": {
                "GB": "CAC1, internal error",
                "SE": "CAC1, internt fel",
                "DE": "CAC1, interner Fehler",
                "FR": "CAC1, erreur interne",
                "FI": "CAC1, sisäinen virhe",
                "DK": "CAC1, intern fejl",
                "NO": "CAC1, intern feil",
                "LV": "CAC1, iekšējās kļūda",
                "NL": "CAC1, interne fout",
                "CZ": "CAC1, interní chyba",
                "PL": "CAC1, internal error",
                "EE": "CAC1, sisemine viga",
                "PT": "CAC1, erro interno",
                "ES": "CAC1, error interno",
                "IT": "CAC1, Errore interno",
                "LT": "CAC1, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "705": {
                "GB": "CAC1, CAN-bus address conflict",
                "SE": "CAC1, CAN-bus adresskonflikt",
                "DE": "CAC1, Konflikt CAN-Bus-Adresse",
                "FR": "CAC1, conflit d’adresse de bus CAN",
                "FI": "CAC1, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC1, adressekonflikt for CAN-bus",
                "NO": "CAC1, CAN-buss adressekonflikt",
                "LV": "CAC1, CAN kopnes adrešu konflikts",
                "NL": "CAC1, CAN-bus adresconflict",
                "CZ": "CAC1, konflikt adres sběrnice CAN",
                "PL": "CAC1, konflikt adresów magistrali CAN",
                "EE": "CAC1, CAN-siin tegeleb konfliktiga",
                "PT": "CAC1, conflito de endereços barramento CAN",
                "ES": "CAC1, conflicto con dirección de CAN bus",
                "IT": "CAC1, conflitto indirizzi bus CAN",
                "LT": "CAC1, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "720": {
                "GB": "CAC2, off-line",
                "SE": "CAC2, offline",
                "DE": "CAC2, offline",
                "FR": "CAC2, hors ligne",
                "FI": "CAC2, ei yhteyttä",
                "DK": "CAC2, offline",
                "NO": "CAC2, frakoblet",
                "LV": "CAC2, offline",
                "NL": "CAC2, offline",
                "CZ": "CAC2, offline",
                "PL": "CAC2, offline",
                "EE": "CAC2, offline",
                "PT": "CAC2, offline",
                "ES": "CAC2, offline",
                "IT": "CAC2, offline",
                "LT": "CAC2 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "721": {
                "GB": "CAC2, error on channel1",
                "SE": "CAC2, fel på kanal1",
                "DE": "CAC2, Fehler an Kanal1",
                "FR": "CAC2, erreur sur canal1",
                "FI": "CAC2, virhe kanavassa1",
                "DK": "CAC2, fejl på kanal1",
                "NO": "CAC2, feil på kanal1",
                "LV": "CAC2, kļūda 1. kanālā",
                "NL": "CAC2, fout op kanaal1",
                "CZ": "CAC2, chyba na kanálu1",
                "PL": "CAC2, error on channel1",
                "EE": "CAC2, kanal1 viga",
                "PT": "CAC2, erro no canal1",
                "ES": "CAC2, error en canal 1",
                "IT": "CAC2, errore sul canale1",
                "LT": "CAC2, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "722": {
                "GB": "CAC2, error on channel2",
                "SE": "CAC2, fel på kanal2",
                "DE": "CAC2, Fehler an Kanal2",
                "FR": "CAC2, erreur sur canal2",
                "FI": "CAC2, virhe kanavassa2",
                "DK": "CAC2, fejl på kanal2",
                "NO": "CAC2, feil på kanal2",
                "LV": "CAC2, kļūda 2. kanālā",
                "NL": "CAC2, fout op kanaal2",
                "CZ": "CAC2, chyba na kanálu2",
                "PL": "CAC2, error on channel2",
                "EE": "CAC2, kanal2 viga",
                "PT": "CAC2, erro no canal2",
                "ES": "CAC2, error en canal 2",
                "IT": "CAC2, errore sul canale2",
                "LT": "CAC2, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "723": {
                "GB": "CAC2, CAN-bus problem",
                "SE": "CAC2, CAN-bus problem",
                "DE": "CAC2, CAN-Bus-Problem",
                "FR": "CAC2, problème de bus CAN",
                "FI": "CAC2, CAN-väyläongelma",
                "DK": "CAC2, CAN-bus-problem",
                "NO": "CAC2, CAN-bussproblem",
                "LV": "CAC2, CAN kopnes problēma",
                "NL": "CAC2, CAN-bus probleem",
                "CZ": "CAC2, problém se sběrnicí CAN",
                "PL": "CAC2, CAN-bus problem",
                "EE": "CAC2, CAN-siini probleem",
                "PT": "CAC2, problema de barramento CAN",
                "ES": "CAC2, problema con el CAN bus",
                "IT": "CAC2, problema bus CAN",
                "LT": "CAC2, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "724": {
                "GB": "CAC2, internal error",
                "SE": "CAC2, internt fel",
                "DE": "CAC2, interner Fehler",
                "FR": "CAC2, erreur interne",
                "FI": "CAC2, sisäinen virhe",
                "DK": "CAC2, intern fejl",
                "NO": "CAC2, intern feil",
                "LV": "CAC2, iekšējās kļūda",
                "NL": "CAC2, interne fout",
                "CZ": "CAC2, interní chyba",
                "PL": "CAC2, internal error",
                "EE": "CAC2, sisemine viga",
                "PT": "CAC2, erro interno",
                "ES": "CAC2, error interno",
                "IT": "CAC2, Errore interno",
                "LT": "CAC2, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "725": {
                "GB": "CAC2, CAN-bus address conflict",
                "SE": "CAC2, CAN-bus adresskonflikt",
                "DE": "CAC2, Konflikt CAN-Bus-Adresse",
                "FR": "CAC2, conflit d’adresse de bus CAN",
                "FI": "CAC2, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC2, adressekonflikt for CAN-bus",
                "NO": "CAC2, CAN-buss adressekonflikt",
                "LV": "CAC2, CAN kopnes adrešu konflikts",
                "NL": "CAC2, CAN-bus adresconflict",
                "CZ": "CAC2, konflikt adres sběrnice CAN",
                "PL": "CAC2, CAN-bus address conflict",
                "EE": "CAC2, CAN-siin tegeleb konfliktiga",
                "PT": "CAC2, conflito de endereços barramento CAN",
                "ES": "CAC2, conflicto con dirección de CAN bus",
                "IT": "CAC2, conflitto indirizzi bus CAN",
                "LT": "CAC2, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "801": {
                "GB": "HW FAIL I2C EEPROM FAILED",
                "SE": "HW FAIL I2C EEPROM FAILED",
                "DE": "HW FEHLER I2C EEPROM FEHLER",
                "FR": "DÉFAIL. MAT. ÉCHEC EEPROM I2C",
                "FI": "HW FAIL I2C EEPROM FAILED",
                "DK": "HW-FEJL, I2C EEPROM MISLYKKEDES",
                "NO": "HW FEIL I2C EEPROM FEIL",
                "LV": "HW Neizdevās I2C EEPROM FAILED",
                "NL": "HW FOUT I2C EEPROM MISLUKT",
                "CZ": "HW FAIL I2C EEPROM FAILED",
                "PL": "HW FAIL I2C EEPROM FAILED",
                "EE": "HW FAIL I2C EEPROM FAILED",
                "PT": "HW FALHAR I2C EEPROM FAILED",
                "ES": "HW FAIL I2C EEPROM AVERIADO",
                "IT": "HW FAIL I2C EEPROM FAILED",
                "LT": "HW FAIL I2C EEPROM FAILED",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "802": {
                "GB": "HW SW FAIL EEPROM PARAM",
                "SE": "HW SW FAIL EEPROM PARAM",
                "DE": "HW SW FEHLER EEPROM PARAMETER",
                "FR": "DÉFAIL. LOG. MAT. PARAM. EEPROM",
                "FI": "HW SW FAIL EEPROM PARAM",
                "DK": "HW SW-FEJL, EEPROM-PARAM",
                "NO": "HW SW FEIL EEPROM PARAM",
                "LV": "HW SW Neizdevās EEPROM PARAM",
                "NL": "HW SW FOUT EEPROM PARAM",
                "CZ": "HW SW FAIL EEPROM PARAM",
                "PL": "HW SW FAIL EEPROM PARAM",
                "EE": "HW SW FAIL EEPROM PARAM",
                "PT": "HW SW FALHAR EEPROM PARAM",
                "ES": "HW SW FAIL EEPROM PARAM",
                "IT": "HW SW FAIL EEPROM PARAM",
                "LT": "HW SW FAIL EEPROM PARAM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "803": {
                "GB": "HW SW FAIL EEPROM DATA",
                "SE": "HW SW FAIL EEPROM DATA",
                "DE": "HW SW FEHLER EEPROM DATEN",
                "FR": "DÉFAIL. LOG. MAT. DONNÉES EEPROM",
                "FI": "HW SW FAIL EEPROM DATA",
                "DK": "HW SW-FEJL, EEPROM-DATA",
                "NO": "HW SW FEIL EEPROM DATA",
                "LV": "HW SW Neizdevās EEPROM DATA",
                "NL": "HW SW FOUT EEPROM DATA",
                "CZ": "HW SW FAIL EEPROM DATA",
                "PL": "HW SW FAIL EEPROM DATA",
                "EE": "HW SW FAIL EEPROM DATA",
                "PT": "HW SW FALHAR EEPROM DATA",
                "ES": "HW SW FAIL EEPROM DATA",
                "IT": "HW SW FAIL EEPROM DATA",
                "LT": "HW SW FAIL EEPROM DATA",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "804": {
                "GB": "HW SW FAIL I2C ANGLE EEPROM",
                "SE": "HW SW FAIL I2C ANGLE EEPROM",
                "DE": "HW SW FEHLER I2C WINKEL EEPROM",
                "FR": "DÉFAIL. LOG. MAT. EEPROM ANGLE I2C",
                "FI": "HW SW FAIL I2C ANGLE EEPROM",
                "DK": "HW SW-FEJL, I2C-VINKEL, EEPROM",
                "NO": "HW SW FEIL I2C VINKEL EEPROM",
                "LV": "HW SW Neizdevās I2C ANGLE EEPROM",
                "NL": "HW SW FOUT I2C HOEK EEPROM",
                "CZ": "HW SW FAIL I2C ANGLE EEPROM",
                "PL": "HW SW FAIL I2C ANGLE EEPROM",
                "EE": "HW SW FAIL I2C ANGLE EEPROM",
                "PT": "HW SW FALHAR I2C ANGLE EEPROM",
                "ES": "HW SW FAIL I2C ÀNGULO EEPROM",
                "IT": "HW SW FAIL I2C ANGLE EEPROM",
                "LT": "HW SW FAIL I2C ANGLE EEPROM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "880": {
                "GB": "HW SW FAIL TE GENERAL",
                "SE": "HW SW FAIL TE GENERAL",
                "DE": "HW SW FEHLER TE ALLGEMEIN",
                "FR": "DÉFAIL. LOG. MAT. GÉNÉRAL TE",
                "FI": "HW SW FAIL TE GENERAL",
                "DK": "HW SW-FEJL, TE GENERELT",
                "NO": "HW SW FEIL TE GENERELT",
                "LV": "HW SW Neizdevās TE GENERAL",
                "NL": "HW SW FOUT TE ALGEMEEN",
                "CZ": "HW SW FAIL TE GENERAL",
                "PL": "HW SW FAIL TE GENERAL",
                "EE": "HW SW FAIL TE GENERAL",
                "PT": "HW SW FALHAR TE GENERAL",
                "ES": "HW SW FAIL TE GENERAL",
                "IT": "HW SW FAIL TE GENERAL",
                "LT": "HW SW FAIL TE GENERAL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "881": {
                "GB": "HW FAIL FS4500 ERROR",
                "SE": "HW-fel, FS4500 AD SUPL",
                "DE": "HW FEHLER FS4500 FEHLER",
                "FR": "DÉFAIL. MAT. ERREUR FS4500",
                "FI": "HW FAIL FS4500 ERROR",
                "DK": "HW-FEJL, FS4500-FEJL",
                "NO": "HW FEIL FS4500 FEIL",
                "LV": "HW Neizdevās FS4500 kļūda",
                "NL": "HW FOUT FS4500 FOUT",
                "CZ": "HW FAIL FS4500 ERROR",
                "PL": "HW FAIL FS4500 ERROR",
                "EE": "HW FAIL FS4500 ERROR",
                "PT": "HW FALHAR FS4500 ERROR",
                "ES": "HW FAIL FS4500 ERROR",
                "IT": "HW FAIL FS4500 ERROR",
                "LT": "HW FAIL FS4500 ERROR",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "882": {
                "GB": "HW FAIL FS4500 AD SUPL",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS VERSORGUNG",
                "FR": "DÉFAIL. MAT. ALIM. FS4500 AD",
                "FI": "HW FAIL FS4500 AD SUPL",
                "DK": "HW-FEJL, FS4500 AD SUPL",
                "NO": "HW FEIL FS4500 AD FORS",
                "LV": "HW Neizdevās FS4500 AD SUPL",
                "NL": "HW FOUT FS4500 BIJ SUPL",
                "CZ": "HW FAIL FS4500 AD SUPL",
                "PL": "HW FAIL FS4500 AD SUPL",
                "EE": "HW FAIL FS4500 AD SUPL",
                "PT": "HW FALHAR FS4500 AD SUPL",
                "ES": "HW FAIL FS4500 AD SUPL",
                "IT": "HW FAIL FS4500 AD SUPL",
                "LT": "HW FAIL FS4500 AD SUPL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "883": {
                "GB": "HW FAIL FS4500 AD MUX",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS MUX",
                "FR": "DÉFAIL. MAT. MUX FS4500 AD",
                "FI": "HW FAIL FS4500 AD MUX",
                "DK": "HW-FEJL, FS4500 AD MUX",
                "NO": "HW FEIL FS4500 AD MUX",
                "LV": "HW Neizdevās FS4500 AD MUX",
                "NL": "HW FOUT FS4500 BIJ MUX",
                "CZ": "HW FAIL FS4500 AD MUX",
                "PL": "HW FAIL FS4500 AD MUX",
                "EE": "HW FAIL FS4500 AD MUX",
                "PT": "HW FALHAR FS4500 AD MUX",
                "ES": "HW FAIL FS4500 AD MUX",
                "IT": "HW FAIL FS4500 AD MUX",
                "LT": "HW FAIL FS4500 AD MUX",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "884": {
                "GB": "HW SW FAIL TE DATA INTEGRITY",
                "SE": "HW SW FAIL TE DATA INTEGRITY",
                "DE": "HW SW FEHLER TE DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. MAT. INTÉGRITÉ DONNÉES TE",
                "FI": "HW SW FAIL TE DATA INTEGRITY",
                "DK": "HW SW-FEJL, TE-DATAINTEGRITET",
                "NO": "HW SW FEIL TE DATAINTEGRITET",
                "LV": "HW SW Neizdevās TE DATA INTEGRITY",
                "NL": "HW SW FOUT TE DATA-INTEGRITEIT",
                "CZ": "HW SW FAIL TE DATA INTEGRITY",
                "PL": "HW SW FAIL TE DATA INTEGRITY",
                "EE": "HW SW FAIL TE DATA INTEGRITY",
                "PT": "HW SW FALHAR TE DATA INTEGRITY",
                "ES": "HW SW FAIL TE DATA INTEGRITY",
                "IT": "HW SW FAIL TE DATA INTEGRITY",
                "LT": "HW SW FAIL TE DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "885": {
                "GB": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "SE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DE": "SW FEHLER SICHERHEITSFUNKT DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. INTÉGRITÉ DONNÉES FONC. SÉCU.",
                "FI": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DK": "SW-FEJL, SIKKERHEDSFUNK, DATAINTEGRITET",
                "NO": "SW FEIL SIKKERHETSFUNK DATAINTEGRITET",
                "LV": "SW Neizdevās SAFETY FUNC DATA INTEGRITY",
                "NL": "SW FOUT VEILIGHEID FUNC DATA INTEGRITEIT",
                "CZ": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PL": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "EE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PT": "SW FALHA SAFETY FUNC DATA INTEGRITY",
                "ES": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "IT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "LT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "891": {
                "GB": "J1939-76, SDG-diagnostic from CCU",
                "SE": "J1939-76, SDG-diagnostic from CCU",
                "DE": "J1939-76, SDG-Diagnose von CCU",
                "FR": "J1939-76, diagnostic SDG de CCU",
                "FI": "J1939-76, SDG-diagnostiikka CCU:sta",
                "DK": "J1939-76, SDG-diagnostik fra CCU",
                "NO": "J1939-76, SDG-diagnose fra CCU",
                "LV": "J1939-76, SDG-diagnostika no CCU",
                "NL": "J1939-76, SDG-diagnostiek van CCU",
                "CZ": "J1939-76, SDG-diagnostika z CCU",
                "PL": "J1939-76, SDG-diagnostic from CCU",
                "EE": "J1939-76, SDG-diagnostika CCU-st",
                "PT": "J1939-76, Diagnóstico SDG da CCU",
                "ES": "J1939-76, SDG-diagnóstico del CCU",
                "IT": "J1939-76, SDG-diagnostica da CCU",
                "LT": "J1939-76, SDG diagnostika iš CCU",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "892": {
                "GB": "J1939-76, SDG-diagnostic from HMI",
                "SE": "J1939-76, SDG-diagnostic from HMI",
                "DE": "J1939-76, SDG-Diagnose von HMI",
                "FR": "J1939-76, diagnostic SDG d’IHM",
                "FI": "J1939-76, SDG-diagnostiikka HMI:stä",
                "DK": "J1939-76, SDG-diagnostik fra HMI",
                "NO": "J1939-76, SDG-diagnose fra HMI",
                "LV": "J1939-76, SDG-diagnostika no HMI",
                "NL": "J1939-76, SDG-diagnostiek van HMI",
                "CZ": "J1939-76, SDG-diagnostika z HMI",
                "PL": "J1939-76, SDG-diagnostic from HMI",
                "EE": "J1939-76, SDG-diagnostika HMI-st",
                "PT": "J1939-76, Diagnóstico SDG da HMI",
                "ES": "J1939-76, SDG-diagnóstico del HMI",
                "IT": "J1939-76, SDG-diagnostica da HMI",
                "LT": "J1939-76, SDG diagnostika iš HMI",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "901": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "902": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "903": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "904": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "905": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "906": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "907": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "908": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "909": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "910": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "921": {
                "GB": "Faulty parameter settings detected",
                "SE": "Fel på parameterinställningar detekterad",
                "DE": "Fehlerhafte Parametereinstellungen erkannt",
                "FR": "Paramètres erronés détectés",
                "FI": "Virheellisiä parametriasetuksia havaittu",
                "DK": "Fejl registreret i parameterindstillinger",
                "NO": "Feil parameterinnstilling registrert",
                "LV": "Konstatēti kļūdaini parametru iestatījumi",
                "NL": "Foutieve parameterinstellingen gedetecteerd",
                "CZ": "Bylo zjištěno chybné nastavení parametrů",
                "PL": "Wykryto błędne ustawienia parametrów",
                "EE": "Tuvastati vigased parameetriseaded",
                "PT": "Detetadas definições de parâmetros incorretas",
                "ES": "Detectado: Configuración de parámetros defectuosa",
                "IT": "Rilevate impostazioni di parametro errate",
                "LT": "Aptikti klaidingi parametrų nustatymai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "922": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "923": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "931": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "932": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "933": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "941": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "942": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "943": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "944": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "945": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "946": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "947": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "948": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "949": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "950": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "961": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "962": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "963": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "971": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "972": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "981": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "982": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "983": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "984": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "985": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "991": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "992": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "993": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "994": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "995": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "996": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "997": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            }
        },
        "3": {
            "1": {
                "GB": "Low supply voltage",
                "SE": "Låg matningsspänning",
                "DE": "Niedrige Speisespannung",
                "FR": "Basse tension d’aliment.",
                "FI": "Matala syöttöjännite",
                "DK": "Lav forsyningsspænding",
                "NO": "Lav forsyningsspenning",
                "LV": "Zems barošanas spriegums",
                "NL": "Lage voedingsspanning",
                "CZ": "Nízké napájecí napětí",
                "PL": "Niskie napięcie zasilania",
                "EE": "Madal toitepinge",
                "PT": "Tensão alimentação baixa",
                "ES": "Tensión de alimentación baja",
                "IT": "Bassa tens. di alimentaz.",
                "LT": "Žema maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "2": {
                "GB": "High supply voltage",
                "SE": "hög matningsspänning",
                "DE": "Hohe Speisespannung",
                "FR": "Haute tension d’aliment.",
                "FI": "Korkea syöttöjännite",
                "DK": "Høj forsyningsspænding",
                "NO": "Høy forsyningsspenning",
                "LV": "Augsts barošanas sprieg.",
                "NL": "Hoge voedingsspanning",
                "CZ": "Vysoké napájecí napětí",
                "PL": "Wys. napięcie zasilania",
                "EE": "Kõrge toitepinge",
                "PT": "Tensão alimentação alta",
                "ES": "Tensión de alimentación alta",
                "IT": "Alta tens. di alimentaz.",
                "LT": "Aukšta maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "3": {
                "GB": "Incompatible SW versions",
                "SE": "Inkompatibel mjukvara",
                "DE": "SW-Versionen inkompatibel",
                "FR": "Versions de logiciel incompatibles",
                "FI": "Kelpaamattomat SW-versiot",
                "DK": "Inkompatible SW-versioner",
                "NO": "Inkompatible programvareversjoner",
                "LV": "Nesaderīgas programmat. versijas",
                "NL": "Incompatibele sw-versies",
                "CZ": "Nekompatibilní verze SW",
                "PL": "Niezgodne wersje SW",
                "EE": "Ühildumatud SW-versioonid",
                "PT": "Versões SW incompatíveis",
                "ES": "Incompatibles versiones SW",
                "IT": "Versioni SW incompatibili",
                "LT": "Nesuderinamos pr. įrangos versijos",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "4": {
                "GB": "Mismatching parameter pair",
                "SE": "Parameterpar stämmer inte överens",
                "DE": "Nicht übereinstimmend Parameterpaar",
                "FR": "Paire de paramètres non concordante",
                "FI": "Yhteensopimaton parametripari",
                "DK": "Ikke-matchende parameterpar",
                "NO": "Parameterpar som ikke samsvarer",
                "LV": "Neatbilstošs parametru pāris",
                "NL": "Mismatchend parameterpaar",
                "CZ": "Neshoda páru parametrů",
                "PL": "Niedopasowanie pary parametrów",
                "EE": "Kokkusobimatu parameetrite paar",
                "PT": "Par de parâmetros incompatível",
                "ES": "Parámetros no coinciden",
                "IT": "Coppia di parametri non corrisp.",
                "LT": "Nesutampančių parametrų pora",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "5": {
                "GB": "Firmware update in progress",
                "SE": "Mjukvaruuppdatering pågår",
                "DE": "Firmware-Update wird ausgeführt",
                "FR": "Mise à jour du firmware en cours",
                "FI": "Laiteohjelmistoa päivitetään",
                "DK": "Firmwareopdatering er i gang",
                "NO": "Fastvareoppdatering pågår",
                "LV": "Notiek aparātprogrammat.atjaunināš.",
                "NL": "Firmware-update bezig",
                "CZ": "Probíhá aktualizace firmwaru",
                "PL": "Aktualizacja firmware w toku",
                "EE": "Püsivara värskendus pooleli",
                "PT": "Atualização de firmware em curso",
                "ES": "Actualización de programa en curso",
                "IT": "Aggiornamento firmware in corso",
                "LT": "Vykdomas pr. įrangos atnaujinimas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "6": {
                "GB": "System restart needed",
                "SE": "Systemet behöver startas om",
                "DE": "Systemneustart erforderlich",
                "FR": "Le système doit être redémarré",
                "FI": "Uudelleenkäynnistys tarpeen",
                "DK": "System genstart nødvendig",
                "NO": "Systemet må startes på nytt",
                "LV": "Nepieciešama sistēmas atsāknēšana",
                "NL": "System reboot nodig",
                "CZ": "Nutné restartovat systém",
                "PL": "Wymagany restart systemu",
                "EE": "On vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. do sistema",
                "ES": "Es necesario reiniciar el sistema",
                "IT": "Riavvio del sistema necessario",
                "LT": "Reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "7": {
                "GB": "System restart needed after SW update",
                "SE": "Systemet behöver startas om efter mjukvaruuppdatering",
                "DE": "Nach dem SW-Update ist ein Neustart des Systems erforderlich",
                "FR": "Redémarrage du système requis après la mise à jour logicielle",
                "FI": "Ohjelmistopäivityksen jälkeen järjestelmä on käynnistettävä uudelleen",
                "DK": "Systemgenstart nødvendig efter SW opdatering",
                "NO": "Systemet må startes på nytt når SW er oppdatert",
                "LV": "Pēc SW atjaunināšanas nepieciešama sistēmas pārstartēšana",
                "NL": "Het systeem moet na SW-update opnieuw opstarten worden",
                "CZ": "Po aktualizaci SW je nutné restartovat systém",
                "PL": "Wymagany restart syst. po aktualizacji SW",
                "EE": "Pärast tarkvarauuendust on vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. após a atualização de SW",
                "ES": "Es necesario reiniciar el sistema después de actualizar SW",
                "IT": "Riavvio del sistema necessario dopo l'aggiornamento del SW",
                "LT": "Po programinės įrangos atnaujinimo reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "8": {
                "GB": "Roller active at start",
                "SE": "Rulle aktiv vid start",
                "DE": "Rolle beim Start aktiv",
                "FR": "Roller actif au démarrage",
                "FI": "Rulla aktiivinen käynnistettäessä",
                "DK": "Rulle aktiv ved start",
                "NO": "Rulle er aktiv fra start",
                "LV": "Rullītis darbojas, ieslēdzot sistēmu",
                "NL": "Rol actief bij start",
                "CZ": "Váleček aktivní při startu",
                "PL": "Rolka aktywna przy rozruchu",
                "EE": "Rull aktiivne alguses",
                "PT": "Rolo ativo no início",
                "ES": "Rótula activa en el inicio",
                "IT": "Selettore attivo all’avvio",
                "LT": "Paleidimo metu ratukas yra aktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "9": {
                "GB": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "SE": "Reservdel ansluten.\nUppdatera programvara och parametrar via Rototilt Connect appen",
                "DE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FI": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "DK": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NO": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LV": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "CZ": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "EE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "ES": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "KR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "JP": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IS": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App"
            },
            "11": {
                "GB": "Rotor sensor circuit, no pulses",
                "SE": "Rotationssensor, inga pulser",
                "DE": "Rotorsensorkreis, keine Impulse",
                "FR": "Circuit capteur rotor, pas d’impulsions",
                "FI": "Kiertoanturipiiri, ei pulsseja",
                "DK": "Rotorsensorkredsløb, ingen impulser",
                "NO": "Rotorsensorkrets, ingen pulser",
                "LV": "Rotora sensora ķēde, nav impulsu",
                "NL": "Sensorcircuit rotor, geen pulsen",
                "CZ": "Obvod senzor rotoru, bez impulzů",
                "PL": "Obwód czujnika wirnika, brak impulsów",
                "EE": "Rootori anduri ahel, impulsid puuduvad",
                "PT": "Circuito sensor rotor, sem impulsos",
                "ES": "Circuito del sensor del rotor, sin pulsaciónes",
                "IT": "Circuito sensore rotore, nessun impulso",
                "LT": "Rotoriaus jut. grandinė, impulsų nėra",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "12": {
                "GB": "Index sensor circuit, error",
                "SE": "Indexsensor, inga pulser",
                "DE": "Indexsensorkreis, Fehler",
                "FR": "Circuit capteur index, erreur",
                "FI": "Indeksianturipiiri, virhe",
                "DK": "Indekssensorkredsløb, fejl",
                "NO": "Indekssensorkrets, feil",
                "LV": "Indeksa sensora ķēde, kļūda",
                "NL": "Index sensorcircuit, fout",
                "CZ": "Obvod senzor indexu, chyba",
                "PL": "Obwód czujnika indeksującego, błąd",
                "EE": "Indeksi anduri ahel, viga",
                "PT": "Índice de circuito do sensor, erro",
                "ES": "Circuito del sensor del índice, error",
                "IT": "Circuito sensore indice, errore",
                "LT": "Indekso jutiklio grandinė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "13": {
                "GB": "Auto setup failed",
                "SE": "Autosetup fallerade",
                "DE": "Auto-Setup fehlgeschlagen",
                "FR": "Échec config. auto",
                "FI": "Autom. asetus epäonn.",
                "DK": "Autoopsætning fik fejl",
                "NO": "Feil under autooppsett",
                "LV": "Autom.iestatīš. neizdevās",
                "NL": "Auto setup mislukt",
                "CZ": "Auto nastavení selhalo",
                "PL": "Błąd auto konfiguracji",
                "EE": "Autom. seadist. nurjus",
                "PT": "Falha config. automática",
                "ES": "Auto setup ha fallado",
                "IT": "Config. autom. non riusc.",
                "LT": "Aut. nustatymas nepavyko",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "28": {
                "GB": "Coupler blocked due to buzzer failure",
                "SE": "Fel på varningssummer, låsfunktion blockerad",
                "DE": "Koppler aufgrund eines Summerausfalls blockiert",
                "FR": "Attache bloquée car défaillance du ronfleur",
                "FI": "Kytkin estetty summerivian vuoksi",
                "DK": "Fæste blokeret på grund af summerfejl",
                "NO": "Feste er blokkert på grunn av summerfeil",
                "LV": "Savienotājs bloķēts zummera atteices dēļ",
                "NL": "Koppeling geblokkeerd door zoemerstoring",
                "CZ": "Spojka zablokována kvůli poruše bzučáku",
                "PL": "Łącznik zablokowany z powodu awarii brzęczyka",
                "EE": "Haakeseadis on sumisti rikke tõttu blokeeritud",
                "PT": "Acoplador bloqueado devido a falha no vibrador",
                "ES": "Acoplador bloqueado, fallo del zumbador",
                "IT": "Attacco bloccato per guasto della cicalina",
                "LT": "Jungtis užblokuota dėl zirzeklio gedimo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "31": {
                "GB": "Safety lever activated at start",
                "SE": "Manöverspärr är aktiverad vid uppstart",
                "DE": "Bediensperre beim Start aktiviert",
                "FR": "Levier d'arrêt activé au démarrage",
                "FI": "Käynnistys aktivoi toimintojen eston",
                "DK": "Manøvrespærring aktiveret ved start",
                "NO": "Manøversperre er aktivert ved start",
                "LV": "Palaides laikā droš. svira aktīva",
                "NL": "Veiligheidshendel geactiveerd bij start",
                "CZ": "Bezpečnost páka aktivní při startu",
                "PL": "Dźwignia bezp. aktyw. przy rozruchu",
                "EE": "Turvahoob käivitamisel aktiivne",
                "PT": "Alavanca segurança ativa no início",
                "ES": "Palanca de seguridad activado al inicio",
                "IT": "Leva di sicurezza attiva all’avvio",
                "LT": "Saugos svirtis aktyvi paleidžiant",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "32": {
                "GB": "Safety lever inactive",
                "SE": "Manöverspärr är inaktiv.",
                "DE": "Bediensperre inaktiv",
                "FR": "Levier d'arrêt inactif",
                "FI": "Toimintojen esto ei käytössä",
                "DK": "Manøvrespærring inaktiv",
                "NO": "Manøversperre er inaktiv",
                "LV": "Drošības svira neaktīvs",
                "NL": "Veiligheidshendel inactief",
                "CZ": "Pojistná páka neaktivní",
                "PL": "Dźwignia bezp. nieczynny",
                "EE": "Turvahoova inaktiivne",
                "PT": "Alavancar segurança inativa",
                "ES": "Palanca de seguridad inactivado",
                "IT": "Leva di sicurezza inattiva",
                "LT": "Saugos svirtis neaktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "33": {
                "GB": "Machine Coupler blocked",
                "SE": "Maskinlåstet är blockerat ",
                "DE": "Baggerschnellwechsler blockiert",
                "FR": "Attache rapide machine bloquée",
                "FI": "Pikaliitin estetty",
                "DK": "Hurtigskifte blokeret",
                "NO": "Maskinfestet er blokkert",
                "LV": "Spraudsavienotājs bloķēts",
                "NL": "Machinesnelwissel geblokkeerd",
                "CZ": "Spojka stroje zablokována",
                "PL": "Szybkozłącze koparki zablokowane aż do restartu",
                "EE": "Masina haakeseadis blokeeritud",
                "PT": "Acoplador da máquina bloqueado",
                "ES": "Acoplador de máquina bloqueado",
                "IT": "Attacco macchina bloccato",
                "LT": "Mašinos mova užblokuota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "34": {
                "GB": "Machine control system off-line",
                "SE": "Maskinkontrollsystem offline",
                "DE": "Maschinensteuersystem offline",
                "FR": "Système de contrôle machine hors ligne",
                "FI": "Koneenohjausjärjestelmään ei yhteyttä",
                "DK": "Maskinstyresystem offline",
                "NO": "Maskinens styresystem er frakoblet",
                "LV": "Mašīnas vadības sistēma offline",
                "NL": "Machinebesturingssyst. offline",
                "CZ": "Systém řízení stroje off-line",
                "PL": "Maszyna system sterowania offline",
                "EE": "Masina juhtimissüsteem offline",
                "PT": "Sistema de controle da máquina off-line",
                "ES": "Control de sistema de maquina offline",
                "IT": "Sistema di controllo della macchina offline",
                "LT": "Mašinos valdymo sistema neprisijungusi",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "41": {
                "GB": "Left analog joystick, error",
                "SE": "Vänster analog joystick, error",
                "DE": "Linker Analog-Joystick, Fehler",
                "FR": "Joystick analogique gauche, erreur",
                "FI": "Vasen analoginen ohjain, virhe",
                "DK": "Venstre analoge joystick, fejl",
                "NO": "Venstre analoge styrespak, feil",
                "LV": "Kreisā analogā vadības svira, kļūda",
                "NL": "Linker analoge joystick, fout",
                "CZ": "Levý analogový joystick, chyba",
                "PL": "Lewy uchwyt analog, error",
                "EE": "Vasak analoogjuhtkang, viga",
                "PT": "Joystick analógico esquerdo, erro",
                "ES": "Joystick analógico izquierdo, error",
                "IT": "Joystick analogico sinistro, errore",
                "LT": "Kair. analog. vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "42": {
                "GB": "Right analog joystick, error",
                "SE": "Höger analog joystick, error",
                "DE": "Rechter Analog-Joystick, Fehler",
                "FR": "Joystick analogique droit, erreur",
                "FI": "Oikea analoginen ohjain, virhe",
                "DK": "Højre analoge joystick, fejl",
                "NO": "Høyre analoge styrespak, feil",
                "LV": "Labā analogā vadības svira, kļūda",
                "NL": "Rechter analoge joystick, fout",
                "CZ": "Pravý analogový joystick, chyba",
                "PL": "Prawy uchwyt analog, error",
                "EE": "Parem analoogjuhtkang, viga",
                "PT": "Joystick analógico direito, erro",
                "ES": "Joystick analógico derecho, error",
                "IT": "Joystick analogico destro, errore",
                "LT": "Dešin. analoginė vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "43": {
                "GB": "Left analog joystick, uncalibrated",
                "SE": "Vänster analog joystick, okalibrerad",
                "DE": "Linker Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique gauche, non étalonné",
                "FI": "Vasen analoginen ohjain, ei kalibroitu",
                "DK": "Venstre analoge joystick, ikke kalibreret",
                "NO": "Venstre analoge styrespak, ikke kalibrert",
                "LV": "Kreisā analogā vadības svira, nekalibrēta",
                "NL": "Linker analoge joystick, niet gekalibreerd",
                "CZ": "Levý analogový joystick, nekalibrovaný",
                "PL": "Lewy uchwyt analog, nieskalibrow.",
                "EE": "Vasak analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico esquerdo, não calibrado",
                "ES": "Joystick analógico izquierdo, sin calibrar",
                "IT": "Joystick analogico sinistro, non calibrato",
                "LT": "Kair. analog.vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "44": {
                "GB": "Right analog joystick, uncalibrated",
                "SE": "Höger analog joystick, okalibrerad",
                "DE": "Rechter Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique droit, non étalonné",
                "FI": "Oikea analoginen ohjain, ei kalibroitu",
                "DK": "Højre analoge joystick, ikke kalibreret",
                "NO": "Høyre analoge styrespak, ikke kalibrert",
                "LV": "Labā analogā vadības svira, nekalibrēta",
                "NL": "Rechter analoge joystick, niet gekalibreerd",
                "CZ": "Pravý analogový joystick, nekalibrovaný",
                "PL": "Prawy joystick analogowy, brak kalibracji",
                "EE": "Parem analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico direito, não calibrado",
                "ES": "Joystick analógico derecho, sin calibrar",
                "IT": "Joystick analogico destro, non calibrato",
                "LT": "Dešin. analoginė vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "51": {
                "GB": "Communication timeout, external machine CAN-bus",
                "SE": "Kommunikations-Timeout, bärarmaskin CAN-bus",
                "DE": "Timeout Kommunikation, externe Maschinen und CAN-Bus",
                "FR": "Délai de communication expiré, bus CAN machine externe",
                "FI": "Viestinnän aikakatkaisu, ulkoisen koneen CAN-väylä",
                "DK": "Kommunikationstimeout, ekstern maskine-CAN-bus",
                "NO": "Tidsavbrudd for kommunikasjon, ekstern maskin CAN-buss",
                "LV": "Sakaru taimauts, ārējās mašīnas CAN kopne",
                "NL": "Communicatie time-out, externe machine CAN-bus",
                "CZ": "Časový limit komunikace, externí sběrnice CAN stroje",
                "PL": "Limit czasu komunikacji, magistrala CAN maszyny zewnętrznej",
                "EE": "Side ajalõpp, välise masina CAN-siin",
                "PT": "Tempo de comunicação esgotado, barramento CAN da máquina externa",
                "ES": "Comunicación interrumpido, CAN bus de máquina externa",
                "IT": "Timeout di comunicazione, bus CAN macchina esterna",
                "LT": "Ryšio skirtasis laikas, išorinio įrenginio CAN magistralė",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "52": {
                "GB": "External machine system not in working mode",
                "SE": "Bärarmaskin är inte i rätt driftmode",
                "DE": "Externes Maschinensystem nicht im Betriebsmodus",
                "FR": "Système machine externe pas en mode de fonctionnement",
                "FI": "Ulkoisen koneen järjestelmä ei toimintatilassa",
                "DK": "Eksternt maskinsystem ikke i arbejdstilstand",
                "NO": "Eksternt maskinsystem er ikke i arbeidsmodus",
                "LV": "Ārējās mašīnas sistēma nav darba režīmā",
                "NL": "Extern machinesysteem niet in de werkmodus",
                "CZ": "Externí systém stroje není v pracovním režimu",
                "PL": "Układ maszyny zewnętrznej nie jest w trybie roboczym",
                "EE": "Välise masina süsteem ei ole töörežiimis",
                "PT": "O sistema de máquina externa não está em modo de trabalho",
                "ES": "Externo sistema de máquina no en modo de trabajo",
                "IT": "Sistema macchina est. non in modalità esercizio",
                "LT": "Išorinė įrenginio sistema neveikia",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "53": {
                "GB": "External machine software interface error",
                "SE": "Inkompatibelt mjukvaruinterface mot bärarmaskin",
                "DE": "Externer Softwareschnittstellen-Fehler Maschine",
                "FR": "Erreur d’interface logicielle machine externe",
                "FI": "Ulkoisen koneen ohjelmistoliittymän virhe",
                "DK": "Grænsefladefejl i ekstern maskinsoftware",
                "NO": "Grensesnittfeil for ekstern maskinprogramvare",
                "LV": "Ārējās mašīnas programmatūras interfeisa kļūda",
                "NL": "Fout in interface externe machinesoftware",
                "CZ": "Chyba rozhraní externího softwaru stroje",
                "PL": "Błąd interfejsu oprogramowania maszyny zewnętrznej",
                "EE": "Välise masina tarkvaraliidese viga",
                "PT": "Erro de interface de software de máquina externa",
                "ES": "Interface error, software de máquina externa",
                "IT": "Errore interfaccia software della macchina esterna",
                "LT": "Išorinės įrenginio pr. įrangos sąsajos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "54": {
                "GB": "Conflicting CAN messages detected on CAN-bus to external machine",
                "SE": "Inkompatibla CAN-meddelanden upptäcka på CAN-bus mot extern maskin.",
                "DE": "Konflikt bei CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Conflit de messages CAN détecté sur bus CAN vers machine externe",
                "FI": "Ristiriitaisia CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Modstridende CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Konflikt i CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti konfliktējoši CAN ziņojumi",
                "NL": "Conflicterende CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Konfliktní zprávy CAN zjištěné na sběrnici CAN s externím počítačem",
                "PL": "Sprzeczne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad vastuolulised sõnumid",
                "PT": "Detetado conflito mensagens CAN no barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN conflictivos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN in conflitto sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje aptikti nesuderinami CAN pranešimai išoriniam įreng.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "55": {
                "GB": "Incorrect CAN messages detected on CAN-bus to external machine",
                "SE": "Felaktiga CAN-meddelanden upptäcka på CAN-bus mot extern maskin",
                "DE": "Falsche CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Message incorrect CAN détecté sur bus CAN vers machine externe",
                "FI": "Virheellisiä CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Fejl på CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Feil på CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti nepareizi CAN ziņojumi",
                "NL": "Fouten in CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Na sběrnici CAN k externímu počítači zjištěny nesprávné zprávy CAN",
                "PL": "Błędne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad valed sõnumid",
                "PT": "Detetadas mensagens CAN incorretas barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN erróneos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN errati sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje išoriniam įreng. aptikti neteisingi CAN pranešimai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "71": {
                "GB": "Input mapping invalid",
                "SE": "Insignalsmappning, error",
                "DE": "Eingangsmapping ungültig",
                "FR": "Mappage d’entrée non valide",
                "FI": "Syöttömapitus ei kelpaa",
                "DK": "Kortlægning af input ugyldig",
                "NO": "Ugyldig inndatatilordning",
                "LV": "Ieejas kartēšana nav derīga",
                "NL": "Invoertoewijzing ongeldig",
                "CZ": "Mapování vstupu neplatné",
                "PL": "Mapowanie wejścia nieprawidłowe",
                "EE": "Sisendi kaardistamine kehtetu",
                "PT": "Mapeamento de entrada inválido",
                "ES": "Señal de entrada no válida",
                "IT": "Mapping ingressi non valida",
                "LT": "Įvesties susiejimas netinkamas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "72": {
                "GB": "Parameter setting error",
                "SE": "Felaktig parameter inställning",
                "DE": "Fehler bei der Parametrierung",
                "FR": "Erreur de paramétrage",
                "FI": "Parametrin asetusvirhe",
                "DK": "Parameterindstillingsfejl",
                "NO": "Feil under parameterinnstilling",
                "LV": "Parametru iestatīšanas kļūda",
                "NL": "Fout parameter instellen",
                "CZ": "Chyba nastavení parametrů",
                "PL": "Błąd ustawiania parametrów",
                "EE": "Parameetri seadistamise viga",
                "PT": "Erro de configuração de parâmetros",
                "ES": "Error de configuraciónes del parametro",
                "IT": "Errore impostazione dei parametri",
                "LT": "Parametrų nustatymo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "81": {
                "GB": "Activate double feeder",
                "SE": "Aktivera dubbelshunt",
                "DE": "Doppelfeeder aktivieren",
                "FR": "Activer double alimentateur",
                "FI": "Aktivoi kaksoissyöttö",
                "DK": "Aktivér dobbelt feeder",
                "NO": "Aktiver dobbel mating",
                "LV": "Aktivizēt Dub. Padevēja",
                "NL": "Dubbele feeder activeren",
                "CZ": "Aktivoi dvojit podavač",
                "PL": "Uruch. podwójny bocznik",
                "EE": "Aktiveeri To. ettean.",
                "PT": "Ativar aliment duplo",
                "ES": "Activar alimentador double",
                "IT": "Attiv. doppio alim.",
                "LT": "Suaktyvinti Dvig. tiektuvo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "82": {
                "GB": "Inactivate double feeder",
                "SE": "Inaktivera dubbelshunt",
                "DE": "Doppelfeeder deaktivieren",
                "FR": "Désactiver double alimentateur",
                "FI": "Sammuta kaksoissyöttö",
                "DK": "Inaktiver dobbelt feeder",
                "NO": "Deaktiver dobbel mating",
                "LV": "Deaktivēt dubulto padevēju",
                "NL": "Dubbele feeder deactiveren",
                "CZ": "Deaktivace dvojitého podavače",
                "PL": "Wyłącz podajnik podwójny",
                "EE": "Inaktiveerige topeltetteandur",
                "PT": "Desativar alimentador duplo",
                "ES": "Inactivar alimentador double",
                "IT": "Disattivare doppio alimentatore",
                "LT": "Išjungti dvigubą tiektuvą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "83": {
                "GB": "Double feeder active",
                "SE": "Dubbelshunt är aktiv",
                "DE": "Doppelfeeder aktiv",
                "FR": "Double alimentateur actif",
                "FI": "Kaksoissyöttö aktiivinen",
                "DK": "Dobbelt feeder aktiv",
                "NO": "Dobbel mating aktivert",
                "LV": "Dub. padevēja rež.",
                "NL": "Dubbele feeder actief",
                "CZ": "Dvojit podavač aktivní",
                "PL": "podwójny bocznik aktyw.",
                "EE": "To. ettean. aktiivsed",
                "PT": "Aliment duplo ativos",
                "ES": "Alimentador double activo",
                "IT": "Doppio alim. attivi",
                "LT": "Dvig. tiektuvo aktyvūs",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "91": {
                "GB": "HCU off-line",
                "SE": "HCU, offline",
                "DE": "HCU offline",
                "FR": "HCU hors ligne",
                "FI": "HCU ei yhteyttä",
                "DK": "HCU offline",
                "NO": "HCU frakoblet",
                "LV": "HCU offline",
                "NL": "HCU offline",
                "CZ": "HCU offline",
                "PL": "HCU offline",
                "EE": "HCU offline",
                "PT": "HCU offline",
                "ES": "HCU offline",
                "IT": "HCU offline",
                "LT": "HCU neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "101": {
                "GB": "Short circuit, PWM 01",
                "SE": "Kabelfel, kortsluning, PWM 01",
                "DE": "Kurzschluss, PWM 01",
                "FR": "Court-circuit, PWM 01",
                "FI": "Oikosulku, PWM 01",
                "DK": "Kortslutning, PWM 01",
                "NO": "Kortslutning, PWM 01",
                "LV": "Īssavienojums, PWM 01",
                "NL": "Kortsluiting, PWM 01",
                "CZ": "Zkrat, PWM 01",
                "PL": "Zwarcie, PWM 01",
                "EE": "Lühis, PWM 01",
                "PT": "Curto-circuito, PWM 01",
                "ES": "Cortocircuito, PWM 01",
                "IT": "Cortocircuito, PWM 01",
                "LT": "Trumpasis jungimas, PWM 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "102": {
                "GB": "Short circuit, PWM 02",
                "SE": "Kabelfel, kortsluning, PWM 02",
                "DE": "Kurzschluss, PWM 02",
                "FR": "Court-circuit, PWM 02",
                "FI": "Oikosulku, PWM 02",
                "DK": "Kortslutning, PWM 02",
                "NO": "Kortslutning, PWM 02",
                "LV": "Īssavienojums, PWM 02",
                "NL": "Kortsluiting, PWM 02",
                "CZ": "Zkrat, PWM 02",
                "PL": "Zwarcie, PWM 02",
                "EE": "Lühis, PWM 02",
                "PT": "Curto-circuito, PWM 02",
                "ES": "Cortocircuito, PWM 02",
                "IT": "Cortocircuito, PWM 02",
                "LT": "Trumpasis jungimas, PWM 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "103": {
                "GB": "Short circuit, PWM 03",
                "SE": "Kabelfel, kortsluning, PWM 03",
                "DE": "Kurzschluss, PWM 03",
                "FR": "Court-circuit, PWM 03",
                "FI": "Oikosulku, PWM 03",
                "DK": "Kortslutning, PWM 03",
                "NO": "Kortslutning, PWM 03",
                "LV": "Īssavienojums, PWM 03",
                "NL": "Kortsluiting, PWM 03",
                "CZ": "Zkrat, PWM 03",
                "PL": "Zwarcie, PWM 03",
                "EE": "Lühis, PWM 03",
                "PT": "Curto-circuito, PWM 03",
                "ES": "Cortocircuito, PWM 03",
                "IT": "Cortocircuito, PWM 03",
                "LT": "Trumpasis jungimas, PWM 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "104": {
                "GB": "Short circuit, PWM 04",
                "SE": "Kabelfel, kortsluning, PWM 04",
                "DE": "Kurzschluss, PWM 04",
                "FR": "Court-circuit, PWM 04",
                "FI": "Oikosulku, PWM 04",
                "DK": "Kortslutning, PWM 04",
                "NO": "Kortslutning, PWM 04",
                "LV": "Īssavienojums, PWM 04 (Extra3 L)",
                "NL": "Kortsluiting, PWM 04",
                "CZ": "Zkrat, PWM 04",
                "PL": "Zwarcie, PWM 04",
                "EE": "Lühis, PWM 04",
                "PT": "Curto-circuito, PWM 04",
                "ES": "Cortocircuito, PWM 04",
                "IT": "Cortocircuito, PWM 04",
                "LT": "Trumpasis jungimas, PWM 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "105": {
                "GB": "Short circuit, PWM 05",
                "SE": "Kabelfel, kortsluning, PWM 05",
                "DE": "Kurzschluss, PWM 05",
                "FR": "Court-circuit, PWM 05",
                "FI": "Oikosulku, PWM 05",
                "DK": "Kortslutning, PWM 05",
                "NO": "Kortslutning, PWM 05",
                "LV": "Īssavienojums, PWM 05",
                "NL": "Kortsluiting, PWM 05",
                "CZ": "Zkrat, PWM 05",
                "PL": "Zwarcie, PWM 05",
                "EE": "Lühis, PWM 05",
                "PT": "Curto-circuito, PWM 05",
                "ES": "Cortocircuito, PWM 05",
                "IT": "Cortocircuito, PWM 05",
                "LT": "Trumpasis jungimas, PWM 05",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "106": {
                "GB": "Short circuit, PWM 06",
                "SE": "Kabelfel, kortsluning, PWM 06",
                "DE": "Kurzschluss, PWM 06",
                "FR": "Court-circuit, PWM 06",
                "FI": "Oikosulku, PWM 06",
                "DK": "Kortslutning, PWM 06",
                "NO": "Kortslutning, PWM 06",
                "LV": "Īssavienojums, PWM 06",
                "NL": "Kortsluiting, PWM 06",
                "CZ": "Zkrat, PWM 06",
                "PL": "Zwarcie, PWM 06",
                "EE": "Lühis, PWM 06",
                "PT": "Curto-circuito, PWM 06",
                "ES": "Cortocircuito, PWM 06",
                "IT": "Cortocircuito, PWM 06",
                "LT": "Trumpasis jungimas, PWM 06",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "111": {
                "GB": "Open circuit, PWM 01",
                "SE": "Kabelbrott, PWM 01",
                "DE": "Schaltkreis offen, PWM 01",
                "FR": "Circuit ouvert, PWM 01",
                "FI": "Avoin virtapiiri, PWM 01",
                "DK": "Åbent kredsløb, PWM 01",
                "NO": "Kortslutning, PWM 01",
                "LV": "Pārtraukta ķēde, PWM 01",
                "NL": "Open circuit, PWM 01",
                "CZ": "Přerušený obvod, PWM 01",
                "PL": "Obwód otwarty, PWM 01",
                "EE": "Tühijooksupinge, PWM 01",
                "PT": "Abrir circuito, PWM 01",
                "ES": "Circuito abierto, PWM 01",
                "IT": "Circuito aperto, PWM 01",
                "LT": "Atvira grandinė, PWM 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "112": {
                "GB": "Open circuit, PWM 02",
                "SE": "Kabelbrott, PWM 02",
                "DE": "Schaltkreis offen, PWM 02",
                "FR": "Circuit ouvert, PWM 02",
                "FI": "Avoin virtapiiri, PWM 02",
                "DK": "Åbent kredsløb, PWM 02",
                "NO": "Kortslutning, PWM 02",
                "LV": "Pārtraukta ķēde, PWM 02",
                "NL": "Open circuit, PWM 02",
                "CZ": "Přerušený obvod, PWM 02",
                "PL": "Obwód otwarty, PWM 02",
                "EE": "Tühijooksupinge, PWM 02",
                "PT": "Abrir circuito, PWM 02",
                "ES": "Circuito abierto, PWM 02",
                "IT": "Circuito aperto, PWM 02",
                "LT": "Atvira grandinė, PWM 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "113": {
                "GB": "Open circuit, PWM 03",
                "SE": "Kabelbrott, PWM 03",
                "DE": "Schaltkreis offen, PWM 03",
                "FR": "Circuit ouvert, PWM 03",
                "FI": "Avoin virtapiiri, PWM 03",
                "DK": "Åbent kredsløb, PWM 03",
                "NO": "Kortslutning, PWM 03",
                "LV": "Pārtraukta ķēde, PWM 03",
                "NL": "Open circuit, PWM 03",
                "CZ": "Přerušený obvod, PWM 03",
                "PL": "Obwód otwarty, PWM 03",
                "EE": "Tühijooksupinge, PWM 03",
                "PT": "Abrir circuito, PWM 03",
                "ES": "Circuito abierto, PWM 03",
                "IT": "Circuito aperto, PWM 03",
                "LT": "Atvira grandinė, PWM 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "114": {
                "GB": "Open circuit, PWM 04",
                "SE": "Kabelbrott, PWM 04",
                "DE": "Schaltkreis offen, PWM 04",
                "FR": "Circuit ouvert, PWM 04",
                "FI": "Avoin virtapiiri, PWM 04",
                "DK": "Åbent kredsløb, PWM 04",
                "NO": "Kortslutning, PWM 04",
                "LV": "Pārtraukta ķēde, PWM 04",
                "NL": "Open circuit, PWM 04",
                "CZ": "Přerušený obvod, PWM 04",
                "PL": "Obwód otwarty, PWM 04",
                "EE": "Tühijooksupinge, PWM 04",
                "PT": "Abrir circuito, PWM 04",
                "ES": "Circuito abierto, PWM 04",
                "IT": "Circuito aperto, PWM 04",
                "LT": "Atvira grandinė, PWM 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "115": {
                "GB": "Open circuit, PWM 05",
                "SE": "Kabelbrott, PWM 05",
                "DE": "Schaltkreis offen, PWM 05",
                "FR": "Circuit ouvert, PWM 05",
                "FI": "Avoin virtapiiri, PWM 05",
                "DK": "Åbent kredsløb, PWM 05",
                "NO": "Kortslutning, PWM 05",
                "LV": "Pārtraukta ķēde, PWM 05",
                "NL": "Open circuit, PWM 05",
                "CZ": "Přerušený obvod, PWM 05",
                "PL": "Obwód otwarty, PWM 05",
                "EE": "Tühijooksupinge, PWM 05",
                "PT": "Abrir circuito, PWM 05",
                "ES": "Circuito abierto, PWM 05",
                "IT": "Circuito aperto, PWM 05",
                "LT": "Atvira grandinė, PWM 05",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "116": {
                "GB": "Open circuit, PWM 06",
                "SE": "Kabelbrott, PWM 06",
                "DE": "Schaltkreis offen, PWM 06",
                "FR": "Circuit ouvert, PWM 06",
                "FI": "Avoin virtapiiri, PWM 06",
                "DK": "Åbent kredsløb, PWM 06",
                "NO": "Kortslutning, PWM 06",
                "LV": "Pārtraukta ķēde, PWM 06",
                "NL": "Open circuit, PWM 06",
                "CZ": "Přerušený obvod, PWM 06",
                "PL": "Obwód otwarty, PWM 06",
                "EE": "Tühijooksupinge, PWM 06",
                "PT": "Abrir circuito, PWM 06",
                "ES": "Circuito abierto, PWM 06",
                "IT": "Circuito aperto, PWM 06",
                "LT": "Atvira grandinė, PWM 06",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "121": {
                "GB": "Foregin Voltage, PWM 01",
                "SE": "Främmandespänning, PWM 01",
                "DE": "Fremdspannung, PWM 01",
                "FR": "Tension étrangère, PWM 01",
                "FI": "Ulkoinen jännite, PWM 01",
                "DK": "Spænding udefra, PWM 01",
                "NO": "Ukjent spenning, PWM 01",
                "LV": "Svešspriegums, PWM 01",
                "NL": "Externe spanning, PWM 01",
                "CZ": "Cizí napětí, PWM 01",
                "PL": "Napięcie obce, PWM 01",
                "EE": "Võõrpinge, PWM 01",
                "PT": "Tensão desconhecida, PWM 01",
                "ES": "Voltage externo, PWM 01",
                "IT": "Tensione esterna, PWM 01",
                "LT": "Išorinė įtampa, PWM 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "122": {
                "GB": "Foregin Voltage, PWM 02",
                "SE": "Främmandespänning, PWM 02",
                "DE": "Fremdspannung, PWM 02",
                "FR": "Tension étrangère, PWM 02",
                "FI": "Ulkoinen jännite, PWM 02",
                "DK": "Spænding udefra, PWM 02",
                "NO": "Ukjent spenning, PWM 02",
                "LV": "Svešspriegums, PWM 02",
                "NL": "Externe spanning, PWM 02",
                "CZ": "Cizí napětí, PWM 02",
                "PL": "Napięcie obce, PWM 02",
                "EE": "Võõrpinge, PWM 02",
                "PT": "Tensão desconhecida, PWM 02",
                "ES": "Voltage externo, PWM 02",
                "IT": "Tensione esterna, PWM 02",
                "LT": "Išorinė įtampa, PWM 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "123": {
                "GB": "Foregin Voltage, PWM 03",
                "SE": "Främmandespänning, PWM 03",
                "DE": "Fremdspannung, PWM 03",
                "FR": "Tension étrangère, PWM 03",
                "FI": "Ulkoinen jännite, PWM 03",
                "DK": "Spænding udefra, PWM 03",
                "NO": "Ukjent spenning, PWM 03",
                "LV": "Svešspriegums, PWM 03",
                "NL": "Externe spanning, PWM 03",
                "CZ": "Cizí napětí, PWM 03",
                "PL": "Napięcie obce, PWM 03",
                "EE": "Võõrpinge, PWM 03",
                "PT": "Tensão desconhecida, PWM 03",
                "ES": "Voltage externo, PWM 03",
                "IT": "Tensione esterna, PWM 03",
                "LT": "Išorinė įtampa, PWM 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "124": {
                "GB": "Foregin Voltage, PWM 04",
                "SE": "Främmandespänning, PWM 04",
                "DE": "Fremdspannung, PWM 04",
                "FR": "Tension étrangère, PWM 04",
                "FI": "Ulkoinen jännite, PWM 04",
                "DK": "Spænding udefra, PWM 04",
                "NO": "Ukjent spenning, PWM 04",
                "LV": "Svešspriegums, PWM 04",
                "NL": "Externe spanning, PWM 04",
                "CZ": "Cizí napětí, PWM 04",
                "PL": "Napięcie obce, PWM 04",
                "EE": "Võõrpinge, PWM 04",
                "PT": "Tensão desconhecida, PWM 04",
                "ES": "Voltage externo, PWM 04",
                "IT": "Tensione esterna, PWM 04",
                "LT": "Išorinė įtampa, PWM 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "125": {
                "GB": "Foregin Voltage, PWM 05",
                "SE": "Främmandespänning, PWM 05",
                "DE": "Fremdspannung, PWM 05",
                "FR": "Tension étrangère, PWM 05",
                "FI": "Ulkoinen jännite, PWM 05",
                "DK": "Spænding udefra, PWM 05",
                "NO": "Ukjent spenning, PWM 05",
                "LV": "Svešspriegums, PWM 05",
                "NL": "Externe spanning, PWM 05",
                "CZ": "Cizí napětí, PWM 05",
                "PL": "Napięcie obce, PWM 05",
                "EE": "Võõrpinge, PWM 05",
                "PT": "Tensão desconhecida, PWM 05",
                "ES": "Voltage externo, PWM 05",
                "IT": "Tensione esterna, PWM 05",
                "LT": "Išorinė įtampa, PWM 05",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "126": {
                "GB": "Foregin Voltage, PWM 06",
                "SE": "Främmandespänning, PWM 06",
                "DE": "Fremdspannung, PWM 06",
                "FR": "Tension étrangère, PWM 06",
                "FI": "Ulkoinen jännite, PWM 06",
                "DK": "Spænding udefra, PWM 06",
                "NO": "Ukjent spenning, PWM 06",
                "LV": "Svešspriegums, PWM 06",
                "NL": "Externe spanning, PWM 06",
                "CZ": "Cizí napětí, PWM 06",
                "PL": "Napięcie obce, PWM 06",
                "EE": "Võõrpinge, PWM 06",
                "PT": "Tensão desconhecida, PWM 06",
                "ES": "Voltage externo, PWM 06",
                "IT": "Tensione esterna, PWM 06",
                "LT": "Išorinė įtampa, PWM 06",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "131": {
                "GB": "Short to GND, PWM 01",
                "SE": "Kortslutning mot jord, PWM 01",
                "DE": "Kurzschl gg Masse, PWM 01",
                "FR": "Court vers GND, PWM 01",
                "FI": "Oikosulku GND:hen, PWM 01",
                "DK": "Kortslutning GND, PWM 01",
                "NO": "Korts. til jord, PWM 01",
                "LV": "Īsslēgums ar masu (GND), PWM 01",
                "NL": "Kortsluiting aarde, PWM 01",
                "CZ": "Zkrat k GND, PWM 01",
                "PL": "Zwarcie do GND, PWM 01",
                "EE": "Lühike GNDsse, PWM 01",
                "PT": "Curto-circ. GND, PWM 01",
                "ES": "Cortocircuito a masa, PWM 01",
                "IT": "Cortocirc. v. GND, PWM 01",
                "LT": "Trumpas. su GND, PWM 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "132": {
                "GB": "Short to GND, PWM 02",
                "SE": "Kortslutning mot jord, PWM 02",
                "DE": "Kurzschl gg Masse, PWM 02",
                "FR": "Court vers GND, PWM 02",
                "FI": "Oikosulku GND:hen, PWM 02",
                "DK": "Kortslutning GND, PWM 02",
                "NO": "Korts. til jord, PWM 02",
                "LV": "Īsslēgums ar masu GND, PWM 02",
                "NL": "Kortsluiting aarde, PWM 02",
                "CZ": "Zkrat k GND, PWM 02",
                "PL": "Zwarcie do GND, PWM 02",
                "EE": "Lühike GNDsse, PWM 02",
                "PT": "Curto-circ. GND, PWM 02",
                "ES": "Cortocircuito a masa, PWM 02",
                "IT": "Cortocirc. v. GND, PWM 02",
                "LT": "Trumpasis jung. su GND, PWM 02",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "133": {
                "GB": "Short to GND, PWM 03",
                "SE": "Kortslutning mot jord, PWM 03",
                "DE": "Kurzschl gg Masse, PWM 03",
                "FR": "Court vers GND, PWM 03",
                "FI": "Oikosulku GND:hen, PWM 03",
                "DK": "Kortslutning GND, PWM 03",
                "NO": "Korts. til jord, PWM 03",
                "LV": "Īsslēgums ar masu GND, PWM 03",
                "NL": "Kortsluiting aarde, PWM 03",
                "CZ": "Zkrat k GND, PWM 03",
                "PL": "Zwarcie do GND, PWM 03",
                "EE": "Lühike GNDsse, PWM 03",
                "PT": "Curto-circ. GND, PWM 03",
                "ES": "Cortocircuito a masa, PWM 03",
                "IT": "Cortocirc. v. GND, PWM 03",
                "LT": "Trumpasis jung. su GND, PWM 03",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "134": {
                "GB": "Short to GND, PWM 04",
                "SE": "Kortslutning mot jord, PWM 04",
                "DE": "Kurzschl gg Masse, PWM 04",
                "FR": "Court vers GND, PWM 04",
                "FI": "Oikosulku GND:hen, PWM 04",
                "DK": "Kortslutning GND, PWM 04",
                "NO": "Korts. til jord, PWM 04",
                "LV": "Īsslēgums ar masu GND, PWM 04",
                "NL": "Kortsluiting aarde, PWM 04",
                "CZ": "Zkrat k GND, PWM 04",
                "PL": "Zwarcie do GND, PWM 04",
                "EE": "Lühike GNDsse, PWM 04",
                "PT": "Curto-circ. GND, PWM 04",
                "ES": "Cortocircuito a masa, PWM 04",
                "IT": "Cortocirc. v. GND, PWM 04",
                "LT": "Trumpasis jung. su GND, PWM 04",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "135": {
                "GB": "Short to GND, PWM 05",
                "SE": "Kortslutning mot jord, PWM 05",
                "DE": "Kurzschl gg Masse, PWM 05",
                "FR": "Court vers GND, PWM 05",
                "FI": "Oikosulku GND:hen, PWM 05",
                "DK": "Kortslutning GND, PWM 05",
                "NO": "Korts. til jord, PWM 05",
                "LV": "Īsslēgums ar masu GND, PWM 05",
                "NL": "Kortsluiting aarde, PWM 05",
                "CZ": "Zkrat k GND, PWM 05",
                "PL": "zwarcie do GND, PWM 05",
                "EE": "Lühike GNDsse, PWM 05",
                "PT": "Curto-circ. GND, PWM 05",
                "ES": "Cortocircuito a masa, PWM 05",
                "IT": "Cortocirc. v. GND, PWM 05",
                "LT": "Trumpasis jung. su GND, PWM 05",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "136": {
                "GB": "Short to GND, PWM 06",
                "SE": "Kortslutning mot jord, PWM 06",
                "DE": "Kurzschl gg Masse, PWM 06",
                "FR": "Court vers GND, PWM 06",
                "FI": "Oikosulku GND:hen, PWM 06",
                "DK": "Kortslutning GND, PWM 06",
                "NO": "Korts. til jord, PWM 06",
                "LV": "Īsslēgums ar masu GND, PWM 06",
                "NL": "Kortsluiting aarde, PWM 06",
                "CZ": "Zkrat k GND, PWM 06",
                "PL": "Zwarcie do GND, PWM 06",
                "EE": "Lühike GNDsse, PWM 06",
                "PT": "Curto-circ. GND, PWM 06",
                "ES": "Cortocircuito a masa, PWM 06",
                "IT": "Cortocirc. v. GND, PWM 06",
                "LT": "Trumpasis jung. su GND, PWM 06",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "201": {
                "GB": "Short circuit, digital output 01",
                "SE": "Kabelfel, kortslutning, digital utgång 01",
                "DE": "Kurzschluss, Digitalausgang 01",
                "FR": "Court-circuit, sortie numérique 01",
                "FI": "Oikosulku, digitaalinen lähtö 01",
                "DK": "Kortslutning, digital udgang 01",
                "NO": "Kortslutning, digital utgang 01",
                "LV": "Īssavienojums, digitālā izeja 01",
                "NL": "Kortsluiting, digitale uitgang 01",
                "CZ": "Zkrat, digitální výstup 01",
                "PL": "Zwarcie, wyjście cyfrowe 01",
                "EE": "Lühis, digitaalne väljund 01",
                "PT": "Curto-circuito, saída digital 01",
                "ES": "Cortocircuito, salida digital 01",
                "IT": "Cortocircuito, uscita digitale 01",
                "LT": "Trumpasis jungimas, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "211": {
                "GB": "Open circuit, digital output 01",
                "SE": "Kabelbrott, digital utgång 01",
                "DE": "Schaltkreis offen, Digitalausgang 01",
                "FR": "Circuit ouvert, sortie numérique 01",
                "FI": "Avoin virtapiiri, digitaalinen lähtö 01",
                "DK": "Åbent kredsløb, digital udgang 01",
                "NO": "Åpen krets, digital utgang 01",
                "LV": "Pārtraukta ķēde, digitālā izeja 01",
                "NL": "Open circuit, digitale uitgang 01",
                "CZ": "Přerušený obvod, digitální výstup 01",
                "PL": "Obwód otwarty, wyjście cyfrowe 01",
                "EE": "Tühijooksupinge, digitaalne väljund 01",
                "PT": "Abrir circuito, saída digital 01",
                "ES": "Circuito abierto, salida digital 01",
                "IT": "Circuito aperto, uscita digitale 01",
                "LT": "Atvira grandinė, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "221": {
                "GB": "Foregin Voltage, digital output 01",
                "SE": "Främmandespänning, digital utgång 01",
                "DE": "Fremdspannung, Digitalausgang 01",
                "FR": "Tension étrangère, sortie numérique 01",
                "FI": "Ulkoinen jännite, digitaalinen lähtö 01",
                "DK": "Spænding udefra, digital udgang 01",
                "NO": "Ukjent spenning, digital utgang 01",
                "LV": "Svešspriegums, digitālā izeja 01",
                "NL": "Externe spanning, digitale uitgang 01",
                "CZ": "Cizí napětí, digitální výstup 01",
                "PL": "Napięcie obce, wyjście cyfrowe 01",
                "EE": "Võõrpinge, digitaalne väljund 01",
                "PT": "Tensão desconhecida, saída digital 01",
                "ES": "Voltage externo, salida digital 01",
                "IT": "Tensione esterna, uscita digitale 01",
                "LT": "Išorinė įtampa, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "231": {
                "GB": "Short to GND, digital output 01",
                "SE": "Kortslutning mot jord, digital utgång 01",
                "DE": "Kurzschluss gg. Masse, Digitalausgang 01",
                "FR": "Court vers GND, sortie numérique 01",
                "FI": "Oikosulku GND:hen, digitaalinen lähtö 01",
                "DK": "Kortslutning til GND, digital udgang 01",
                "NO": "Kortslutning til jord, digital utgang 01",
                "LV": "Īsslēgums ar masu (GND), digitālā izeja 01",
                "NL": "Kortsluiting aarde, digitale uitgang 01",
                "CZ": "Zkrat k GND, digitální výstup 01",
                "PL": "Zwarcie do GND, wyjście cyfrowe 01",
                "EE": "Lühike GNDsse, digitaalne väljund 01",
                "PT": "Curto-circ. GND, saída digital 01",
                "ES": "Cortocircuito a masa, salida digital 01",
                "IT": "Cortocirc. v. GND, uscita digitale 01",
                "LT": "Trumpas. su GND, skaitmeninė išvestis 01",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "301": {
                "GB": "Short circuit, sensor supply 1",
                "SE": "Kortslutning, sensor 1",
                "DE": "Kurzschluss, Sensorversorgung 1",
                "FR": "Court-circuit, alimentation capteur 1",
                "FI": "Oikosulku, anturin syöttö 1",
                "DK": "Kortslutning, sensorforsyning 1",
                "NO": "Kortslutning, sensortilførsel 1",
                "LV": "Īsslēgums, sensora barošana 1",
                "NL": "Kortsluiting, sensorvoeding 1",
                "CZ": "Zkrat, napájení senzor 1",
                "PL": "Zwarcie, czujnik zasil. 1",
                "EE": "Lühis, anduri toide 1",
                "PT": "Curto-circuito, sensor de alimentação 1",
                "ES": "Cortocircuito, sensor de alim. 1",
                "IT": "Cortocircuito, alimentazione sensore 1",
                "LT": "Trumpasis jungimas, jutiklio tiekimas 1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "311": {
                "GB": "Open circuit, sensor supply 1",
                "SE": "Kabelbrott, sensor 1",
                "DE": "Schaltkreis offen, Sensorversorgung 1",
                "FR": "Circuit ouvert, alimentation capteur 1",
                "FI": "Avoin virtapiiri, anturin syöttö 1",
                "DK": "Åbent kredsløb, sensorforsyning 1",
                "NO": "Åpen krets, sensortilførsel 1",
                "LV": "Pārtraukta ķēde, sensora barošana 1",
                "NL": "Open circuit, sensorvoeding 1",
                "CZ": "Přerušený obvod, napájení senzor 1",
                "PL": "Obwód otwarty, czujnik zasil. 1",
                "EE": "Tühijooksupinge, anduri toide 1",
                "PT": "Abrir circuito, sensor de alim. 1",
                "ES": "Circuito abierto. sensor de alim. 1",
                "IT": "Circuito aperto, alim. sensore 1",
                "LT": "Atvira grandinė, jutiklio tiekimas 1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "497": {
                "GB": "MIC40, CAN bus timeout",
                "SE": "MIC40, CAN bus timeout",
                "DE": "MIC40, CAN bus timeout",
                "FR": "MIC40, CAN bus timeout",
                "FI": "MIC40, CAN bus timeout",
                "DK": "MIC40, CAN bus timeout",
                "NO": "MIC40, CAN bus timeout",
                "LV": "MIC40, CAN bus timeout",
                "NL": "MIC40, CAN bus timeout",
                "CZ": "MIC40, CAN bus timeout",
                "PL": "MIC40, CAN bus timeout",
                "EE": "MIC40, CAN bus timeout",
                "PT": "MIC40, CAN bus timeout",
                "ES": "MIC40, CAN bus timeout",
                "IT": "MIC40, CAN bus timeout",
                "LT": "MIC40, CAN bus timeout",
                "KR": "MIC40, CAN bus timeout",
                "JP": "MIC40, CAN bus timeout",
                "IS": "MIC40, CAN bus timeout"
            },
            "498": {
                "GB": "MIC40, API mismatch",
                "SE": "MIC40, API mismatch",
                "DE": "MIC40, API mismatch",
                "FR": "MIC40, API mismatch",
                "FI": "MIC40, API mismatch",
                "DK": "MIC40, API mismatch",
                "NO": "MIC40, API mismatch",
                "LV": "MIC40, API mismatch",
                "NL": "MIC40, API mismatch",
                "CZ": "MIC40, API mismatch",
                "PL": "MIC40, API mismatch",
                "EE": "MIC40, API mismatch",
                "PT": "MIC40, API mismatch",
                "ES": "MIC40, API mismatch",
                "IT": "MIC40, API mismatch",
                "LT": "MIC40, API mismatch",
                "KR": "MIC40, API mismatch",
                "JP": "MIC40, API mismatch",
                "IS": "MIC40, API mismatch"
            },
            "499": {
                "GB": "MIC40, CAN bus tampering",
                "SE": "MIC40, CAN bus tampering",
                "DE": "MIC40, CAN bus tampering",
                "FR": "MIC40, CAN bus tampering",
                "FI": "MIC40, CAN bus tampering",
                "DK": "MIC40, CAN bus tampering",
                "NO": "MIC40, CAN bus tampering",
                "LV": "MIC40, CAN bus tampering",
                "NL": "MIC40, CAN bus tampering",
                "CZ": "MIC40, CAN bus tampering",
                "PL": "MIC40, CAN bus tampering",
                "EE": "MIC40, CAN bus tampering",
                "PT": "MIC40, CAN bus tampering",
                "ES": "MIC40, CAN bus tampering",
                "IT": "MIC40, CAN bus tampering",
                "LT": "MIC40, CAN bus tampering",
                "KR": "MIC40, CAN bus tampering",
                "JP": "MIC40, CAN bus tampering",
                "IS": "MIC40, CAN bus tampering"
            },
            "501": {
                "GB": "Service, 8h",
                "SE": "Service, 8h",
                "DE": "Service, 8h",
                "FR": "Entretien, 8 h",
                "FI": "Huolto, 8h",
                "DK": "Service, 8t",
                "NO": "Service, 8 t",
                "LV": "Apkope, 8h",
                "NL": "Service, 8u",
                "CZ": "Servis, 8 h",
                "PL": "Usługa, 8h",
                "EE": "Hooldus, 8h",
                "PT": "Serviço, 8h",
                "ES": "Servicio, 8 h",
                "IT": "Assistenza, 8h",
                "LT": "Tech. priež., 8 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "502": {
                "GB": "Service, 40h",
                "SE": "Service, 40h",
                "DE": "Service, 40h",
                "FR": "Entretien, 40 h",
                "FI": "Huolto, 40h",
                "DK": "Service, 40t",
                "NO": "Service, 40 t",
                "LV": "Apkope,40h",
                "NL": "Service, 40 u",
                "CZ": "Servis, 40 h",
                "PL": "Usługa, 40h",
                "EE": "Hooldus, 40h",
                "PT": "Serviço, 40h",
                "ES": "Servicio, 40 h",
                "IT": "Assistenza, 40h",
                "LT": "Tech. priež., 40 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "503": {
                "GB": "Service, 160h",
                "SE": "Service, 160h",
                "DE": "Service, 160h",
                "FR": "Entretien, 160 h",
                "FI": "Huolto, 160h",
                "DK": "Service, 160t",
                "NO": "Service, 160 t",
                "LV": "Apkope, 160h",
                "NL": "Service, 160 u",
                "CZ": "Servis, 160 h",
                "PL": "Usługa, 160h",
                "EE": "Hooldus, 160h",
                "PT": "Serviço, 160h",
                "ES": "Servicio, 160 h",
                "IT": "Assistenza, 160h",
                "LT": "Tech. priež., 160 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "504": {
                "GB": "Service, 500/1000h",
                "SE": "Service, 500/1000h",
                "DE": "Service, 500/1000h",
                "FR": "Entretien, 500/1000 h",
                "FI": "Huolto, 500/1000h",
                "DK": "Service, 500/1000t",
                "NO": "Service, 500/1000 t",
                "LV": "Apkope, 500/1000h",
                "NL": "Service, 500/1000 u",
                "CZ": "Servis, 500/1000 h",
                "PL": "Usługa, 500/1000 h",
                "EE": "Hooldus, 500/1000h",
                "PT": "Serviço, 500/1000h",
                "ES": "Servicio, 500/ 1000 h",
                "IT": "Assistenza, 500/1000h",
                "LT": "Tech. priež., 500/1000 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "600": {
                "GB": "Left joystick, off-line",
                "SE": "Vänster joystick, offline",
                "DE": "Linker Joystick, offline",
                "FR": "Joystick gauche, hors ligne",
                "FI": "Vasen ohjain, ei yhteyttä",
                "DK": "Venstre joystick, offline",
                "NO": "Venstre styrespak, frakoblet",
                "LV": "Kr. vadības svira, off-line",
                "NL": "Linker joystick, offline",
                "CZ": "Levý joystick, off-line",
                "PL": "Lewy uchwyt, offline",
                "EE": "Vasak juhtkang, offline",
                "PT": "Joystick esquerdo, offline",
                "ES": "Mando Izquierdo, offline",
                "IT": "Joystick sinistro, offline",
                "LT": "Kair. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "601": {
                "GB": "Left joystick, error on roller: LR1",
                "SE": "Vänster joystick, rullfel: LR1",
                "DE": "Linker Joystick, Fehler an Rolle: LR1",
                "FR": "Joystick gauche, erreur sur roller : LR1",
                "FI": "Vasen ohjain, virhe rullassa: LR1",
                "DK": "Venstre joystick, fejl på rulle: LR1",
                "NO": "Venstre styrespak, feil på rulle: LR1",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR1",
                "NL": "Linker joystick, fout roller: LR1",
                "CZ": "Levý joystick, chyba na válci: LR1",
                "PL": "Lewy uchwyt, error na rolka: LR1",
                "EE": "Vasak juhtkang, rulli viga: LR1",
                "PT": "Joystick esquerdo, erro no rolo: LR1",
                "ES": "Mando izquierdo, error de rótula LR1",
                "IT": "Joystick sinistro, errore sul selettore: LR1",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "602": {
                "GB": "Left joystick, error on roller: LR2",
                "SE": "Vänster joystick, rullfel: LR2",
                "DE": "Linker Joystick, Fehler an Rolle: LR2",
                "FR": "Joystick gauche, erreur sur roller : LR2",
                "FI": "Vasen ohjain, virhe rullassa: LR2",
                "DK": "Venstre joystick, fejl på rulle: LR2",
                "NO": "Venstre styrespak, feil på rulle: LR2",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR2",
                "NL": "Linker joystick, fout roller: LR2",
                "CZ": "Levý joystick, chyba na válci: LR2",
                "PL": "Lewy uchwyt, error na rolka: LR2",
                "EE": "Vasak juhtkang, rulli viga: LR2",
                "PT": "Joystick esquerdo, erro no rolo: LR2",
                "ES": "Mando izquierdo, error de rótula LR2",
                "IT": "Joystick sinistro, errore sul selettore: LR2",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "603": {
                "GB": "Left joystick, error on roller: LR3",
                "SE": "Vänster joystick, rullfel: LR3",
                "DE": "Linker Joystick, Fehler an Rolle: LR3",
                "FR": "Joystick gauche, erreur sur roller : LR3",
                "FI": "Vasen ohjain, virhe rullassa: LR3",
                "DK": "Venstre joystick, fejl på rulle: LR3",
                "NO": "Venstre styrespak, feil på rulle: LR3",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR3",
                "NL": "Linker joystick, fout roller: LR3",
                "CZ": "Levý joystick, chyba na válci: LR3",
                "PL": "Lewy uchwyt, error na rolka: LR3",
                "EE": "Vasak juhtkang, rulli viga: LR3",
                "PT": "Joystick esquerdo, erro no rolo: LR3",
                "ES": "Mando izquierdo, error de rótula LR3",
                "IT": "Joystick sinistro, errore sul selettore: LR3",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "604": {
                "GB": "Left joystick, error on roller: LR4",
                "SE": "Vänster joystick, rullfel: LR4",
                "DE": "Linker Joystick, Fehler an Rolle: LR4",
                "FR": "Joystick gauche, erreur sur roller : LR4",
                "FI": "Vasen ohjain, virhe rullassa: LR4",
                "DK": "Venstre joystick, fejl på rulle: LR4",
                "NO": "Venstre styrespak, feil på rulle: LR4",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR4",
                "NL": "Linker joystick, fout roller: LR4",
                "CZ": "Levý joystick, chyba na válci: LR4",
                "PL": "Lewy uchwyt, error na rolka: LR4",
                "EE": "Vasak juhtkang, rulli viga: LR4",
                "PT": "Joystick esquerdo, erro no rolo: LR4",
                "ES": "Mando izquierdo, error de rótula LR4",
                "IT": "Joystick sinistro, errore sul selettore: LR4",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "611": {
                "GB": "Left joystick, error on button: LS1",
                "SE": "Vänster joystick, knappfel: LS1",
                "DE": "Linker Joystick, Fehler an Taste: LS1",
                "FR": "Joystick gauche, erreur sur bouton : LS1",
                "FI": "Vasen ohjain, virhe painikkeessa: LS1",
                "DK": "Venstre joystick, fejl på knap: LS1",
                "NO": "Venstre styrespak, feil på knapp: LS1",
                "LV": "Kreisā vadības svira, pogas kļūda: LS1",
                "NL": "Linker joystick, fout op knop: LS1",
                "CZ": "Levý joystick, chyba na tlačítku: LS1",
                "PL": "Lewy uchwyt, error na przycisku: LS1",
                "EE": "Vasak juhtkang, viga nupul: LS1",
                "PT": "Joystick esquerdo, erro no botão: LS1",
                "ES": "Mando izquierdo, error de botón: LS1",
                "IT": "Joystick sinistro, errore sul pulsante: LS1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "612": {
                "GB": "Left joystick, error on button: LS2",
                "SE": "Vänster joystick, knappfel: LS2",
                "DE": "Linker Joystick, Fehler an Taste: LS2",
                "FR": "Joystick gauche, erreur sur bouton : LS2",
                "FI": "Vasen ohjain, virhe painikkeessa: LS2",
                "DK": "Venstre joystick, fejl på knap: LS2",
                "NO": "Venstre styrespak, feil på knapp: LS2",
                "LV": "Kreisā vadības svira, pogas kļūda: LS2",
                "NL": "Linker joystick, fout op knop: LS2",
                "CZ": "Levý joystick, chyba na tlačítku: LS2",
                "PL": "Lewy uchwyt, error na przycisku: LS2",
                "EE": "Vasak juhtkang, viga nupul: LS2",
                "PT": "Joystick esquerdo, erro no botão: LS2",
                "ES": "Mando izquierdo, error de botón: LS2",
                "IT": "Joystick sinistro, errore sul pulsante: LS2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "613": {
                "GB": "Left joystick, error on button: LS3",
                "SE": "Vänster joystick, knappfel: LS3",
                "DE": "Linker Joystick, Fehler an Taste: LS3",
                "FR": "Joystick gauche, erreur sur bouton : LS3",
                "FI": "Vasen ohjain, virhe painikkeessa: LS3",
                "DK": "Venstre joystick, fejl på knap: LS3",
                "NO": "Venstre styrespak, feil på knapp: LS3",
                "LV": "Kreisā vadības svira, pogas kļūda: LS3",
                "NL": "Linker joystick, fout op knop: LS3",
                "CZ": "Levý joystick, chyba na tlačítku: LS3",
                "PL": "Lewy uchwyt, error na przycisku: LS3",
                "EE": "Vasak juhtkang, viga nupul: LS3",
                "PT": "Joystick esquerdo, erro no botão: LS3",
                "ES": "Mando izquierdo, error de botón: LS3",
                "IT": "Joystick sinistro, errore sul pulsante: LS3",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "614": {
                "GB": "Left joystick, error on button: LS4",
                "SE": "Vänster joystick, knappfel: LS4",
                "DE": "Linker Joystick, Fehler an Taste: LS4",
                "FR": "Joystick gauche, erreur sur bouton : LS4",
                "FI": "Vasen ohjain, virhe painikkeessa: LS4",
                "DK": "Venstre joystick, fejl på knap: LS4",
                "NO": "Venstre styrespak, feil på knapp: LS4",
                "LV": "Kreisā vadības svira, pogas kļūda: LS4",
                "NL": "Linker joystick, fout op knop: LS4",
                "CZ": "Levý joystick, chyba na tlačítku: LS4",
                "PL": "Lewy uchwyt, error na przycisku: LS4",
                "EE": "Vasak juhtkang, viga nupul: LS4",
                "PT": "Joystick esquerdo, erro no botão: LS4",
                "ES": "Mando izquierdo, error de botón: LS4",
                "IT": "Joystick sinistro, errore sul pulsante: LS4",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "615": {
                "GB": "Left joystick, error on button: LS5",
                "SE": "Vänster joystick, knappfel: LS5",
                "DE": "Linker Joystick, Fehler an Taste: LS5",
                "FR": "Joystick gauche, erreur sur bouton : LS5",
                "FI": "Vasen ohjain, virhe painikkeessa: LS5",
                "DK": "Venstre joystick, fejl på knap: LS5",
                "NO": "Venstre styrespak, feil på knapp: LS5",
                "LV": "Kreisā vadības svira, pogas kļūda: LS5",
                "NL": "Linker joystick, fout op knop: LS5",
                "CZ": "Levý joystick, chyba na tlačítku: LS5",
                "PL": "Lewy uchwyt, error na przycisku: LS5",
                "EE": "Vasak juhtkang, viga nupul: LS5",
                "PT": "Joystick esquerdo, erro no botão: LS5",
                "ES": "Mando izquierdo, error de botón: LS5",
                "IT": "Joystick sinistro, errore sul pulsante: LS5",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "616": {
                "GB": "Left joystick, error on button: LS6",
                "SE": "Vänster joystick, knappfel: LS6",
                "DE": "Linker Joystick, Fehler an Taste: LS6",
                "FR": "Joystick gauche, erreur sur bouton : LS6",
                "FI": "Vasen ohjain, virhe painikkeessa: LS6",
                "DK": "Venstre joystick, fejl på knap: LS6",
                "NO": "Venstre styrespak, feil på knapp: LS6",
                "LV": "Kreisā vadības svira, pogas kļūda: LS6",
                "NL": "Linker joystick, fout op knop: LS6",
                "CZ": "Levý joystick, chyba na tlačítku: LS6",
                "PL": "Lewy uchwyt, error na przycisku: LS6",
                "EE": "Vasak juhtkang, viga nupul: LS6",
                "PT": "Joystick esquerdo, erro no botão: LS6",
                "ES": "Mando izquierdo, error de botón: LS6",
                "IT": "Joystick sinistro, errore sul pulsante: LS6",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "617": {
                "GB": "Left joystick, error on button: LS7",
                "SE": "Vänster joystick, knappfel: LS7",
                "DE": "Linker Joystick, Fehler an Taste: LS7",
                "FR": "Joystick gauche, erreur sur bouton : LS7",
                "FI": "Vasen ohjain, virhe painikkeessa: LS7",
                "DK": "Venstre joystick, fejl på knap: LS7",
                "NO": "Venstre styrespak, feil på knapp: LS7",
                "LV": "Kreisā vadības svira, pogas kļūda: LS7",
                "NL": "Linker joystick, fout op knop: LS7",
                "CZ": "Levý joystick, chyba na tlačítku: LS7",
                "PL": "Lewy uchwyt, error na przycisku: LS7",
                "EE": "Vasak juhtkang, viga nupul: LS7",
                "PT": "Joystick esquerdo, erro no botão: LS7",
                "ES": "Mando izquierdo, error de botón: LS7",
                "IT": "Joystick sinistro, errore sul pulsante: LS7",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "618": {
                "GB": "Left joystick, error on button: LS8",
                "SE": "Vänster joystick, knappfel: LS8",
                "DE": "Linker Joystick, Fehler an Taste: LS8",
                "FR": "Joystick gauche, erreur sur bouton : LS8",
                "FI": "Vasen ohjain, virhe painikkeessa: LS8",
                "DK": "Venstre joystick, fejl på knap: LS8",
                "NO": "Venstre styrespak, feil på knapp: LS8",
                "LV": "Kreisā vadības svira, pogas kļūda: LS8",
                "NL": "Linker joystick, fout op knop: LS8",
                "CZ": "Levý joystick, chyba na tlačítku: LS8",
                "PL": "Lewy uchwyt, error na przycisku: LS8",
                "EE": "Vasak juhtkang, viga nupul: LS8",
                "PT": "Joystick esquerdo, erro no botão: LS8",
                "ES": "Mando izquierdo, error de botón: LS8",
                "IT": "Joystick sinistro, errore sul pulsante: LS8",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "619": {
                "GB": "Left joystick, error on button: LFNR1",
                "SE": "Vänster joystick, knappfel: LFNR1",
                "DE": "Linker Joystick, Fehler an Taste: LFNR1",
                "FR": "Joystick gauche, erreur sur bouton : LFNR1",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR1",
                "DK": "Venstre joystick, fejl på knap: LFNR1",
                "NO": "Venstre styrespak, feil på knapp: LFNR1",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR1",
                "NL": "Linker joystick, fout op knop: LFNR1",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR1",
                "PL": "Lewy joystick, błąd na przycisku: LFNR1",
                "EE": "Vasak juhtkang, viga nupul: LFNR1",
                "PT": "Joystick esquerdo, erro no botão: LFNR1",
                "ES": "Mando izquierdo, error de botón: LFNR1",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "620": {
                "GB": "Left joystick, error on button: LFNR2",
                "SE": "Vänster joystick, knappfel: LFNR2",
                "DE": "Linker Joystick, Fehler an Taste: LFNR2",
                "FR": "Joystick gauche, erreur sur bouton : LFNR2",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR2",
                "DK": "Venstre joystick, fejl på knap: LFNR2",
                "NO": "Venstre styrespak, feil på knapp: LFNR2",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR2",
                "NL": "Linker joystick, fout op knop: LFNR2",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR2",
                "PL": "Lewy uchwyt, error na przycisku: LFNR2",
                "EE": "Vasak juhtkang, viga nupul: LFNR2",
                "PT": "Joystick esquerdo, erro no botão: LFNR2",
                "ES": "Mando izquierdo, error de botón: LFNR2",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "631": {
                "GB": "Left joystick, CAN1 problem",
                "SE": "Vänster joystick, CAN1 problem",
                "DE": "Linker Joystick, CAN1-Problem",
                "FR": "Joystick gauche, problème CAN1",
                "FI": "Vasen ohjain, CAN1-ongelma",
                "DK": "Venstre joystick, CAN1-problem",
                "NO": "Venstre styrespak, CAN1-problem",
                "LV": "Kreisā vadības svira, CAN1 problēma",
                "NL": "Linker joystick, CAN1 probleem",
                "CZ": "Levý joystick, problém CAN1",
                "PL": "Lewy joystick, problem z CAN1",
                "EE": "Vasak juhtkang, CAN1 probleem",
                "PT": "Joystick esquerdo, problema CAN1",
                "ES": "Mando izquierdo, error de CAN1:",
                "IT": "Joystick sinistro, problema CAN1",
                "LT": "Kair. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "632": {
                "GB": "Left joystick, CRC error flash1",
                "SE": "Vänster joystick, CRC-fel flash1",
                "DE": "Linker Joystick, CRC-Fehler Blink1",
                "FR": "Joystick gauche, erreur CRC flash1",
                "FI": "Vasen ohjain, CRC-virhe valo1",
                "DK": "Venstre joystick, CRC-fejlblink1",
                "NO": "Venstre styrespak, CRC-feil flash1",
                "LV": "Kreisā vadības svira, CRC kļūda flash1",
                "NL": "Linker joystick, CRC fout flash1",
                "CZ": "Levý joystick, CRC chyba flash1",
                "PL": "Lewy uchwyt, CRC error flash1",
                "EE": "Vasak juhtkang, CRC viga flash1",
                "PT": "Joystick esquerdo, CRC erro flash1",
                "ES": "Mando izquierdo, error de CAN1, flash1",
                "IT": "Joystick sinistro, CRC errore flash1",
                "LT": "Kair. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "633": {
                "GB": "Left joystick, CAN2 problem",
                "SE": "Vänster joystick, CAN2 problem",
                "DE": "Linker Joystick, CAN2-Problem",
                "FR": "Joystick gauche, problème CAN2",
                "FI": "Vasen ohjain, CAN2-ongelma",
                "DK": "Venstre joystick, CAN2-problem",
                "NO": "Venstre styrespak, CAN2-problem",
                "LV": "Kreisā vadības svira, CAN2 problēma",
                "NL": "Linker joystick, CAN2 probleem",
                "CZ": "Levý joystick, problém CAN2",
                "PL": "Lewy uchwyt, CAN2 problem",
                "EE": "Vasak juhtkang, CAN2 probleem",
                "PT": "Joystick esquerdo, problema CAN2",
                "ES": "Mando izquierdo, error de CAN2",
                "IT": "Joystick sinistro, problema CAN2",
                "LT": "Kair. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "634": {
                "GB": "Left joystick, CRC error flash2",
                "SE": "Vänster joystick, CRC-fel flash2",
                "DE": "Linker Joystick, CRC-Fehler Blink2",
                "FR": "Joystick gauche, erreur CRC flash2",
                "FI": "Vasen ohjain, CRC-virhe valo2",
                "DK": "Venstre joystick, CRC-fejlblink2",
                "NO": "Venstre styrespak, CRC-feil flash2",
                "LV": "Kreisā vadības svira, CRC kļūda flash2",
                "NL": "Linker joystick, CRC fout flash2",
                "CZ": "Levý joystick, CRC chyba flash2",
                "PL": "Lewy uchwyt, CRC error flash2",
                "EE": "Vasak juhtkang, CRC viga flash2",
                "PT": "Joystick esquerdo, CRC erro flash2",
                "ES": "Mando izquierdo, error de CAN1, flash2",
                "IT": "Joystick sinistro, CRC errore flash2",
                "LT": "Kair. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "649": {
                "GB": "Left joystick, internal error",
                "SE": "Vänster joystick, internt fel",
                "DE": "Linker Joystick, interner Fehler",
                "FR": "Joystick gauche, erreur interne",
                "FI": "Vasen ohjain, sisäinen virhe",
                "DK": "Venstre joystick, intern fejl",
                "NO": "Venstre styrespak, intern feil",
                "LV": "Kr. vadības svira, iekšējās kļūda",
                "NL": "Linker joystick, interne fout",
                "CZ": "Levý joystick, interní chyba",
                "PL": "Lewy uchwyt, internal error",
                "EE": "Vasak juhtkang, sisemine viga",
                "PT": "Joystick esquerdo, erro interno",
                "ES": "Mando izquierdo, error interno",
                "IT": "Joystick sinistro, errore interno",
                "LT": "Kair. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "650": {
                "GB": "Right joystick, off-line",
                "SE": "Höger joystick, offline",
                "DE": "Rechter Joystick, offline",
                "FR": "Joystick droit, hors ligne",
                "FI": "Oikea ohjain, ei yhteyttä",
                "DK": "Højre joystick, offline",
                "NO": "Høyre styrespak, frakoblet",
                "LV": "La. vadības svira, off-line",
                "NL": "Rechter joystick, off-line",
                "CZ": "Pravý joystick, off-line",
                "PL": "Prawy uchwyt, offline",
                "EE": "Parem juhtkang, offline",
                "PT": "Joystick direito, offline",
                "ES": "Mando derecho, inactivado",
                "IT": "Joystick destro, offline",
                "LT": "Dešin. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "651": {
                "GB": "Right joystick, error on roller: RR1",
                "SE": "Höger joystick, rullfel: RR1",
                "DE": "Rechter Joystick, Fehler an Rolle: RR1",
                "FR": "Joystick droit, erreur sur roller : RR1",
                "FI": "Oikea ohjain, virhe rullassa: RR1",
                "DK": "Højre joystick, fejl på rulle: RR1",
                "NO": "Høyre styrespak, feil på rulle: RR1",
                "LV": "Labā vadības svira, ritenīša kļūda: RR1",
                "NL": "Rechter joystick, fout op roller: RR1",
                "CZ": "Pravý joystick, chyba na válci: RR1",
                "PL": "Prawy uchwyt, error na rolka: RR1",
                "EE": "Parem juhtkang, rulli viga: RR1",
                "PT": "Joystick direito, erro no rolo: RR1",
                "ES": "Mando derecho, error de rótula: RR1",
                "IT": "Joystick destro, errore sul selettore: RR1",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "652": {
                "GB": "Right joystick, error on roller: RR2",
                "SE": "Höger joystick, rullfel: RR2",
                "DE": "Rechter Joystick, Fehler an Rolle: RR2",
                "FR": "Joystick droit, erreur sur roller : RR2",
                "FI": "Oikea ohjain, virhe rullassa: RR2",
                "DK": "Højre joystick, fejl på rulle: RR2",
                "NO": "Høyre styrespak, feil på rulle: RR2",
                "LV": "Labā vadības svira, ritenīša kļūda: RR2",
                "NL": "Rechter joystick, fout op roller: RR2",
                "CZ": "Pravý joystick, chyba na válci: RR2",
                "PL": "Prawy uchwyt, error na rolka: RR2",
                "EE": "Parem juhtkang, rulli viga: RR2",
                "PT": "Joystick direito, erro no rolo: RR2",
                "ES": "Mando derecho, error de rótula: RR2",
                "IT": "Joystick destro, errore sul selettore: RR2",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "653": {
                "GB": "Right joystick, error on roller: RR3",
                "SE": "Höger joystick, rullfel: RR3",
                "DE": "Rechter Joystick, Fehler an Rolle: RR3",
                "FR": "Joystick droit, erreur sur roller : RR3",
                "FI": "Oikea ohjain, virhe rullassa: RR3",
                "DK": "Højre joystick, fejl på rulle: RR3",
                "NO": "Høyre styrespak, feil på rulle: RR3",
                "LV": "Labā vadības svira, ritenīša kļūda: RR3",
                "NL": "Rechter joystick, fout op roller: RR3",
                "CZ": "Pravý joystick, chyba na válci: RR3",
                "PL": "Prawy uchwyt, error na rolka: RR3",
                "EE": "Parem juhtkang, rulli viga: RR3",
                "PT": "Joystick direito, erro no rolo: RR3",
                "ES": "Mando derecho, error de rótula: RR3",
                "IT": "Joystick destro, errore sul selettore: RR3",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "654": {
                "GB": "Right joystick, error on roller: RR4",
                "SE": "Höger joystick, rullfel: RR4",
                "DE": "Rechter Joystick, Fehler an Rolle: RR4",
                "FR": "Joystick droit, erreur sur roller : RR4",
                "FI": "Oikea ohjain, virhe rullassa: RR4",
                "DK": "Højre joystick, fejl på rulle: RR4",
                "NO": "Høyre styrespak, feil på rulle: RR4",
                "LV": "Labā vadības svira, ritenīša kļūda: RR4",
                "NL": "Rechter joystick, fout op roller: RR4",
                "CZ": "Pravý joystick, chyba na válci: RR4",
                "PL": "Prawy uchwyt, error na rolka: RR4",
                "EE": "Parem juhtkang, rulli viga: RR4",
                "PT": "Joystick direito, erro no rolo: RR4",
                "ES": "Mando derecho, error de rótula: RR4",
                "IT": "Joystick destro, errore sul selettore: RR4",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "661": {
                "GB": "Right joystick, error on button: RS1",
                "SE": "Höger joystick, knappfel: RS1",
                "DE": "Rechter Joystick, Fehler an Taste: RS1",
                "FR": "Joystick droit, erreur sur bouton : RS1",
                "FI": "Oikea ohjain, virhe painikkeessa: RS1",
                "DK": "Højre joystick, fejl på knap: RS1",
                "NO": "Høyre styrespak, feil på knapp: RS1",
                "LV": "Labā vadības svira, pogas kļūda: RS1",
                "NL": "Rechter joystick, fout op knop: RS1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS1",
                "PL": "Prawy uchwyt, error na Przycisk: RS1",
                "EE": "Parem juhtkang, viga nupul: RS1",
                "PT": "Joystick direito, erro no botão: RS1",
                "ES": "Mando derecho, error de botón: RS1",
                "IT": "Joystick destro, errore sul pulsante: RS1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "662": {
                "GB": "Right joystick, error on button: RS2",
                "SE": "Höger joystick, knappfel: RS2",
                "DE": "Rechter Joystick, Fehler an Taste: RS2",
                "FR": "Joystick droit, erreur sur bouton : RS2",
                "FI": "Oikea ohjain, virhe painikkeessa: RS2",
                "DK": "Højre joystick, fejl på knap: RS2",
                "NO": "Høyre styrespak, feil på knapp: RS2",
                "LV": "Labā vadības svira, pogas kļūda: RS2",
                "NL": "Rechter joystick, fout op knop: RS2",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS2",
                "PL": "Prawy uchwyt, error na Przycisk: RS2",
                "EE": "Parem juhtkang, viga nupul: RS2",
                "PT": "Joystick direito, erro no botão: RS2",
                "ES": "Mando derecho, error de botón: RS2",
                "IT": "Joystick destro, errore sul pulsante: RS2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "663": {
                "GB": "Right joystick, error on button: RS3",
                "SE": "Höger joystick, knappfel: RS3",
                "DE": "Rechter Joystick, Fehler an Taste: RS3",
                "FR": "Joystick droit, erreur sur bouton : RS3",
                "FI": "Oikea ohjain, virhe painikkeessa: RS3",
                "DK": "Højre joystick, fejl på knap: RS3",
                "NO": "Høyre styrespak, feil på knapp: RS3",
                "LV": "Labā vadības svira, pogas kļūda: RS3",
                "NL": "Rechter joystick, fout op knop: RS3",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS3",
                "PL": "Prawy uchwyt, error na Przycisk: RS3",
                "EE": "Parem juhtkang, viga nupul: RS3",
                "PT": "Joystick direito, erro no botão: RS3",
                "ES": "Mando derecho, error de botón: RS3",
                "IT": "Joystick destro, errore sul pulsante: RS3",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "664": {
                "GB": "Right joystick, error on button: RS4",
                "SE": "Höger joystick, knappfel: RS4",
                "DE": "Rechter Joystick, Fehler an Taste: RS4",
                "FR": "Joystick droit, erreur sur bouton : RS4",
                "FI": "Oikea ohjain, virhe painikkeessa: RS4",
                "DK": "Højre joystick, fejl på knap: RS4",
                "NO": "Høyre styrespak, feil på knapp: RS4",
                "LV": "Labā vadības svira, pogas kļūda: RS4",
                "NL": "Rechter joystick, fout op knop: RS4",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS4",
                "PL": "Prawy uchwyt, error na Przycisk: RS4",
                "EE": "Parem juhtkang, viga nupul: RS4",
                "PT": "Joystick direito, erro no botão: RS4",
                "ES": "Mando derecho, error de botón: RS4",
                "IT": "Joystick destro, errore sul pulsante: RS4",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "665": {
                "GB": "Right joystick, error on button: RS5",
                "SE": "Höger joystick, knappfel: RS5",
                "DE": "Rechter Joystick, Fehler an Taste: RS5",
                "FR": "Joystick droit, erreur sur bouton : RS5",
                "FI": "Oikea ohjain, virhe painikkeessa: RS5",
                "DK": "Højre joystick, fejl på knap: RS5",
                "NO": "Høyre styrespak, feil på knapp: RS5",
                "LV": "Labā vadības svira, pogas kļūda: RS5",
                "NL": "Rechter joystick, fout op knop: RS5",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS5",
                "PL": "Prawy uchwyt, error na Przycisk: RS5",
                "EE": "Parem juhtkang, viga nupul: RS5",
                "PT": "Joystick direito, erro no botão: RS5",
                "ES": "Mando derecho, error de botón: RS5",
                "IT": "Joystick destro, errore sul pulsante: RS5",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "666": {
                "GB": "Right joystick, error on button: RS6",
                "SE": "Höger joystick, knappfel: RS6",
                "DE": "Rechter Joystick, Fehler an Taste: RS6",
                "FR": "Joystick droit, erreur sur bouton : RS6",
                "FI": "Oikea ohjain, virhe painikkeessa: RS6",
                "DK": "Højre joystick, fejl på knap: RS6",
                "NO": "Høyre styrespak, feil på knapp: RS6",
                "LV": "Labā vadības svira, pogas kļūda: RS6",
                "NL": "Rechter joystick, fout op knop: RS6",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS6",
                "PL": "Prawy uchwyt, error na Przycisk: RS6",
                "EE": "Parem juhtkang, viga nupul: RS6",
                "PT": "Joystick direito, erro no botão: RS6",
                "ES": "Mando derecho, error de botón: RS6",
                "IT": "Joystick destro, errore sul pulsante: RS6",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "667": {
                "GB": "Right joystick, error on button: RS7",
                "SE": "Höger joystick, knappfel: RS7",
                "DE": "Rechter Joystick, Fehler an Taste: RS7",
                "FR": "Joystick droit, erreur sur bouton : RS7",
                "FI": "Oikea ohjain, virhe painikkeessa: RS7",
                "DK": "Højre joystick, fejl på knap: RS7",
                "NO": "Høyre styrespak, feil på knapp: RS7",
                "LV": "Labā vadības svira, pogas kļūda: RS7",
                "NL": "Rechter joystick, fout op knop: RS7",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS7",
                "PL": "Prawy uchwyt, error na Przycisk: RS7",
                "EE": "Parem juhtkang, viga nupul: RS7",
                "PT": "Joystick direito, erro no botão: RS7",
                "ES": "Mando derecho, error de botón: RS7",
                "IT": "Joystick destro, errore sul pulsante: RS7",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "668": {
                "GB": "Right joystick, error on button: RS8",
                "SE": "Höger joystick, knappfel: RS8",
                "DE": "Rechter Joystick, Fehler an Taste: RS8",
                "FR": "Joystick droit, erreur sur bouton : RS8",
                "FI": "Oikea ohjain, virhe painikkeessa: RS8",
                "DK": "Højre joystick, fejl på knap: RS8",
                "NO": "Høyre styrespak, feil på knapp: RS8",
                "LV": "Labā vadības svira, pogas kļūda: RS8",
                "NL": "Rechter joystick, fout op knop: RS8",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS8",
                "PL": "Prawy uchwyt, error na Przycisk: RS8",
                "EE": "Parem juhtkang, viga nupul: RS8",
                "PT": "Joystick direito, erro no botão: RS8",
                "ES": "Mando derecho, error de botón: RS8",
                "IT": "Joystick destro, errore sul pulsante: RS8",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "669": {
                "GB": "Right joystick, error on button: RFNR1",
                "SE": "Höger joystick, knappfel: RFNR1",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR1",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR1",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR1",
                "PT": "Joystick direito, erro no botão: RFNR1",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "670": {
                "GB": "Right joystick, error on button: RFNR2",
                "SE": "Höger joystick, knappfel: RFNR2",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR2",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR2",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR2",
                "PT": "Joystick direito, erro no botão: RFNR2",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "681": {
                "GB": "Right joystick, CAN1 problem",
                "SE": "Höger joystick, CAN1 problem",
                "DE": "Rechter Joystick, CAN1-Problem",
                "FR": "Joystick droit, problème CAN1",
                "FI": "Oikea ohjain, CAN1-ongelma",
                "DK": "Højre joystick, CAN1-problem",
                "NO": "Høyre styrespak, CAN1-problem",
                "LV": "La. vadības svira, CAN1 problēma",
                "NL": "Rechter joystick, CAN1 probleem",
                "CZ": "Pravý joystick, problém CAN1",
                "PL": "Prawy uchwyt, CAN1 problem",
                "EE": "Parem juhtkang, CAN1 probleem",
                "PT": "Joystick direito, problema de CAN1",
                "ES": "Mando derecho, error de CAN1",
                "IT": "Joystick destro, problema CAN1",
                "LT": "Dešin. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "682": {
                "GB": "Right joystick, CRC error flash1",
                "SE": "Höger joystick, CRC-fel flash1",
                "DE": "Rechter Joystick, CRC-Fehler Blink1",
                "FR": "Joystick droit, erreur CRC flash1",
                "FI": "Oikea ohjain, CRC-virhe valo1",
                "DK": "Højre joystick, CRC-fejlblink1",
                "NO": "Høyre styrespak, CRC-feil flash1",
                "LV": "La. vadības svira, CRC kļūda flash1",
                "NL": "Rechter joystick, CRC fout flash1",
                "CZ": "Pravý joystick, CRC chyba flash1",
                "PL": "Prawy uchwyt, CRC error flash1",
                "EE": "Parem juhtkang, CRC viga flash1",
                "PT": "Joystick direito, CRC erro flash1",
                "ES": "Mando derecho, error de CAN1, flash1",
                "IT": "Joystick destro, CRC errore flash1",
                "LT": "Dešin. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "683": {
                "GB": "Right joystick, CAN2 problem",
                "SE": "Höger joystick, CAN2 problem",
                "DE": "Rechter Joystick, CAN2-Problem",
                "FR": "Joystick droit, problème CAN2",
                "FI": "Oikea ohjain, CAN2-ongelma",
                "DK": "Højre joystick, CAN2-problem",
                "NO": "Høyre styrespak, CAN2-problem",
                "LV": "La. vadības svira, CAN2 problēma",
                "NL": "Rechter joystick, CAN2 probleem",
                "CZ": "Pravý joystick, problém CAN2",
                "PL": "Prawy uchwyt, CAN2 problem",
                "EE": "Parem juhtkang, CAN2 probleem",
                "PT": "Joystick direito, problema de CAN2",
                "ES": "Mando derecho, error de CAN2",
                "IT": "Joystick destro, problema CAN2",
                "LT": "Dešin. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "684": {
                "GB": "Right joystick, CRC error flash2",
                "SE": "Höger joystick, CRC-fel flash2",
                "DE": "Rechter Joystick, CRC-Fehler Blink2",
                "FR": "Joystick droit, erreur CRC flash2",
                "FI": "Oikea ohjain, CRC-virhe valo2",
                "DK": "Højre joystick, CRC-fejlblink2",
                "NO": "Høyre styrespak, CRC-feil flash2",
                "LV": "La. vadības svira, CRC kļūda flash2",
                "NL": "Rechter joystick, CRC fout flash2",
                "CZ": "Pravý joystick, CRC chyba flash2",
                "PL": "Prawy uchwyt, CRC error flash2",
                "EE": "Parem juhtkang, CRC viga flash2",
                "PT": "Joystick direito, CRC erro flash2",
                "ES": "Mando derecho, error de CAN1, flash2",
                "IT": "Joystick destro, CRC errore flash2",
                "LT": "Dešin. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "699": {
                "GB": "Right joystick, internal error",
                "SE": "Höger joystick, internt fel",
                "DE": "Rechter Joystick, interner Fehler",
                "FR": "Joystick droit, erreur interne",
                "FI": "Oikea ohjain, sisäinen virhe",
                "DK": "Højre joystick, intern fejl",
                "NO": "Høyre styrespak, intern feil",
                "LV": "La. vadības svira, iekšējās kļūda",
                "NL": "Rechter joystick, interne fout",
                "CZ": "Pravý joystick, interní chyba",
                "PL": "Prawy uchwyt, internal error",
                "EE": "Parem juhtkang, sisemine viga",
                "PT": "Joystick direito, erro interno",
                "ES": "Mando derecho, error interno",
                "IT": "Joystick destro, errore interno",
                "LT": "Dešin. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "700": {
                "GB": "CAC1, off-line",
                "SE": "CAC1, offline",
                "DE": "CAC1, offline",
                "FR": "CAC1, hors ligne",
                "FI": "CAC1, ei yhteyttä",
                "DK": "CAC1, offline",
                "NO": "CAC1, frakoblet",
                "LV": "CAC1, off-line",
                "NL": "CAC1, off-line",
                "CZ": "CAC1, off-line",
                "PL": "CAC1, off-line",
                "EE": "CAC1, off-line",
                "PT": "CAC1, off-line",
                "ES": "CAC1, offline",
                "IT": "CAC1, off-line",
                "LT": "CAC1 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "701": {
                "GB": "CAC1, error on channel1",
                "SE": "CAC1, fel på kanal1",
                "DE": "CAC1, Fehler an Kanal1",
                "FR": "CAC1, erreur sur canal1",
                "FI": "CAC1, virhe kanavassa1",
                "DK": "CAC1, fejl på kanal1",
                "NO": "CAC1, feil på kanal1",
                "LV": "CAC1, kļūda 1. kanālā",
                "NL": "CAC1, fout op kanaal1",
                "CZ": "CAC1, chyba na kanálu1",
                "PL": "CAC1, błąd na kanale 1",
                "EE": "CAC1, kanal1 viga",
                "PT": "CAC1, erro no canal1",
                "ES": "CAC1, error en canal 1",
                "IT": "CAC1, errore sul canale1",
                "LT": "CAC1, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "702": {
                "GB": "CAC1, error on channel2",
                "SE": "CAC1, fel på kanal2",
                "DE": "CAC1, Fehler an Kanal2",
                "FR": "CAC1, erreur sur canal2",
                "FI": "CAC1, virhe kanavassa2",
                "DK": "CAC1, fejl på kanal2",
                "NO": "CAC1, feil på kanal2",
                "LV": "CAC1, kļūda 2. kanālā",
                "NL": "CAC1, fout op kanaal2",
                "CZ": "CAC1, chyba na kanálu2",
                "PL": "CAC1, error on channel2",
                "EE": "CAC1, kanal2 viga",
                "PT": "CAC1, erro no canal2",
                "ES": "CAC1, error en canal 2",
                "IT": "CAC1, errore sul canale2",
                "LT": "CAC1, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "703": {
                "GB": "CAC1, CAN-bus problem",
                "SE": "CAC1, CAN-bus problem",
                "DE": "CAC1, CAN-Bus-Problem",
                "FR": "CAC1, problème de bus CAN",
                "FI": "CAC1, CAN-väyläongelma",
                "DK": "CAC1, CAN-bus-problem",
                "NO": "CAC1, CAN-bussproblem",
                "LV": "CAC1, CAN kopnes problēma",
                "NL": "CAC1, CAN-bus probleem",
                "CZ": "CAC1, problém se sběrnicí CAN",
                "PL": "CAC1, problem z magistralą CAN",
                "EE": "CAC1, CAN-siini probleem",
                "PT": "CAC1, problema de barramento CAN",
                "ES": "CAC1, error de CAN bus",
                "IT": "CAC1, problema bus CAN",
                "LT": "CAC1, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "704": {
                "GB": "CAC1, internal error",
                "SE": "CAC1, internt fel",
                "DE": "CAC1, interner Fehler",
                "FR": "CAC1, erreur interne",
                "FI": "CAC1, sisäinen virhe",
                "DK": "CAC1, intern fejl",
                "NO": "CAC1, intern feil",
                "LV": "CAC1, iekšējās kļūda",
                "NL": "CAC1, interne fout",
                "CZ": "CAC1, interní chyba",
                "PL": "CAC1, internal error",
                "EE": "CAC1, sisemine viga",
                "PT": "CAC1, erro interno",
                "ES": "CAC1, error interno",
                "IT": "CAC1, Errore interno",
                "LT": "CAC1, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "705": {
                "GB": "CAC1, CAN-bus address conflict",
                "SE": "CAC1, CAN-bus adresskonflikt",
                "DE": "CAC1, Konflikt CAN-Bus-Adresse",
                "FR": "CAC1, conflit d’adresse de bus CAN",
                "FI": "CAC1, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC1, adressekonflikt for CAN-bus",
                "NO": "CAC1, CAN-buss adressekonflikt",
                "LV": "CAC1, CAN kopnes adrešu konflikts",
                "NL": "CAC1, CAN-bus adresconflict",
                "CZ": "CAC1, konflikt adres sběrnice CAN",
                "PL": "CAC1, konflikt adresów magistrali CAN",
                "EE": "CAC1, CAN-siin tegeleb konfliktiga",
                "PT": "CAC1, conflito de endereços barramento CAN",
                "ES": "CAC1, conflicto con dirección de CAN bus",
                "IT": "CAC1, conflitto indirizzi bus CAN",
                "LT": "CAC1, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "720": {
                "GB": "CAC2, off-line",
                "SE": "CAC2, offline",
                "DE": "CAC2, offline",
                "FR": "CAC2, hors ligne",
                "FI": "CAC2, ei yhteyttä",
                "DK": "CAC2, offline",
                "NO": "CAC2, frakoblet",
                "LV": "CAC2, offline",
                "NL": "CAC2, offline",
                "CZ": "CAC2, offline",
                "PL": "CAC2, offline",
                "EE": "CAC2, offline",
                "PT": "CAC2, offline",
                "ES": "CAC2, offline",
                "IT": "CAC2, offline",
                "LT": "CAC2 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "721": {
                "GB": "CAC2, error on channel1",
                "SE": "CAC2, fel på kanal1",
                "DE": "CAC2, Fehler an Kanal1",
                "FR": "CAC2, erreur sur canal1",
                "FI": "CAC2, virhe kanavassa1",
                "DK": "CAC2, fejl på kanal1",
                "NO": "CAC2, feil på kanal1",
                "LV": "CAC2, kļūda 1. kanālā",
                "NL": "CAC2, fout op kanaal1",
                "CZ": "CAC2, chyba na kanálu1",
                "PL": "CAC2, error on channel1",
                "EE": "CAC2, kanal1 viga",
                "PT": "CAC2, erro no canal1",
                "ES": "CAC2, error en canal 1",
                "IT": "CAC2, errore sul canale1",
                "LT": "CAC2, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "722": {
                "GB": "CAC2, error on channel2",
                "SE": "CAC2, fel på kanal2",
                "DE": "CAC2, Fehler an Kanal2",
                "FR": "CAC2, erreur sur canal2",
                "FI": "CAC2, virhe kanavassa2",
                "DK": "CAC2, fejl på kanal2",
                "NO": "CAC2, feil på kanal2",
                "LV": "CAC2, kļūda 2. kanālā",
                "NL": "CAC2, fout op kanaal2",
                "CZ": "CAC2, chyba na kanálu2",
                "PL": "CAC2, error on channel2",
                "EE": "CAC2, kanal2 viga",
                "PT": "CAC2, erro no canal2",
                "ES": "CAC2, error en canal 2",
                "IT": "CAC2, errore sul canale2",
                "LT": "CAC2, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "723": {
                "GB": "CAC2, CAN-bus problem",
                "SE": "CAC2, CAN-bus problem",
                "DE": "CAC2, CAN-Bus-Problem",
                "FR": "CAC2, problème de bus CAN",
                "FI": "CAC2, CAN-väyläongelma",
                "DK": "CAC2, CAN-bus-problem",
                "NO": "CAC2, CAN-bussproblem",
                "LV": "CAC2, CAN kopnes problēma",
                "NL": "CAC2, CAN-bus probleem",
                "CZ": "CAC2, problém se sběrnicí CAN",
                "PL": "CAC2, CAN-bus problem",
                "EE": "CAC2, CAN-siini probleem",
                "PT": "CAC2, problema de barramento CAN",
                "ES": "CAC2, problema con el CAN bus",
                "IT": "CAC2, problema bus CAN",
                "LT": "CAC2, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "724": {
                "GB": "CAC2, internal error",
                "SE": "CAC2, internt fel",
                "DE": "CAC2, interner Fehler",
                "FR": "CAC2, erreur interne",
                "FI": "CAC2, sisäinen virhe",
                "DK": "CAC2, intern fejl",
                "NO": "CAC2, intern feil",
                "LV": "CAC2, iekšējās kļūda",
                "NL": "CAC2, interne fout",
                "CZ": "CAC2, interní chyba",
                "PL": "CAC2, internal error",
                "EE": "CAC2, sisemine viga",
                "PT": "CAC2, erro interno",
                "ES": "CAC2, error interno",
                "IT": "CAC2, Errore interno",
                "LT": "CAC2, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "725": {
                "GB": "CAC2, CAN-bus address conflict",
                "SE": "CAC2, CAN-bus adresskonflikt",
                "DE": "CAC2, Konflikt CAN-Bus-Adresse",
                "FR": "CAC2, conflit d’adresse de bus CAN",
                "FI": "CAC2, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC2, adressekonflikt for CAN-bus",
                "NO": "CAC2, CAN-buss adressekonflikt",
                "LV": "CAC2, CAN kopnes adrešu konflikts",
                "NL": "CAC2, CAN-bus adresconflict",
                "CZ": "CAC2, konflikt adres sběrnice CAN",
                "PL": "CAC2, CAN-bus address conflict",
                "EE": "CAC2, CAN-siin tegeleb konfliktiga",
                "PT": "CAC2, conflito de endereços barramento CAN",
                "ES": "CAC2, conflicto con dirección de CAN bus",
                "IT": "CAC2, conflitto indirizzi bus CAN",
                "LT": "CAC2, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "801": {
                "GB": "HW FAIL I2C EEPROM FAILED",
                "SE": "HW FAIL I2C EEPROM FAILED",
                "DE": "HW FEHLER I2C EEPROM FEHLER",
                "FR": "DÉFAIL. MAT. ÉCHEC EEPROM I2C",
                "FI": "HW FAIL I2C EEPROM FAILED",
                "DK": "HW-FEJL, I2C EEPROM MISLYKKEDES",
                "NO": "HW FEIL I2C EEPROM FEIL",
                "LV": "HW Neizdevās I2C EEPROM FAILED",
                "NL": "HW FOUT I2C EEPROM MISLUKT",
                "CZ": "HW FAIL I2C EEPROM FAILED",
                "PL": "HW FAIL I2C EEPROM FAILED",
                "EE": "HW FAIL I2C EEPROM FAILED",
                "PT": "HW FALHAR I2C EEPROM FAILED",
                "ES": "HW FAIL I2C EEPROM AVERIADO",
                "IT": "HW FAIL I2C EEPROM FAILED",
                "LT": "HW FAIL I2C EEPROM FAILED",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "802": {
                "GB": "HW SW FAIL EEPROM PARAM",
                "SE": "HW SW FAIL EEPROM PARAM",
                "DE": "HW SW FEHLER EEPROM PARAMETER",
                "FR": "DÉFAIL. LOG. MAT. PARAM. EEPROM",
                "FI": "HW SW FAIL EEPROM PARAM",
                "DK": "HW SW-FEJL, EEPROM-PARAM",
                "NO": "HW SW FEIL EEPROM PARAM",
                "LV": "HW SW Neizdevās EEPROM PARAM",
                "NL": "HW SW FOUT EEPROM PARAM",
                "CZ": "HW SW FAIL EEPROM PARAM",
                "PL": "HW SW FAIL EEPROM PARAM",
                "EE": "HW SW FAIL EEPROM PARAM",
                "PT": "HW SW FALHAR EEPROM PARAM",
                "ES": "HW SW FAIL EEPROM PARAM",
                "IT": "HW SW FAIL EEPROM PARAM",
                "LT": "HW SW FAIL EEPROM PARAM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "803": {
                "GB": "HW SW FAIL EEPROM DATA",
                "SE": "HW SW FAIL EEPROM DATA",
                "DE": "HW SW FEHLER EEPROM DATEN",
                "FR": "DÉFAIL. LOG. MAT. DONNÉES EEPROM",
                "FI": "HW SW FAIL EEPROM DATA",
                "DK": "HW SW-FEJL, EEPROM-DATA",
                "NO": "HW SW FEIL EEPROM DATA",
                "LV": "HW SW Neizdevās EEPROM DATA",
                "NL": "HW SW FOUT EEPROM DATA",
                "CZ": "HW SW FAIL EEPROM DATA",
                "PL": "HW SW FAIL EEPROM DATA",
                "EE": "HW SW FAIL EEPROM DATA",
                "PT": "HW SW FALHAR EEPROM DATA",
                "ES": "HW SW FAIL EEPROM DATA",
                "IT": "HW SW FAIL EEPROM DATA",
                "LT": "HW SW FAIL EEPROM DATA",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "804": {
                "GB": "HW SW FAIL I2C ANGLE EEPROM",
                "SE": "HW SW FAIL I2C ANGLE EEPROM",
                "DE": "HW SW FEHLER I2C WINKEL EEPROM",
                "FR": "DÉFAIL. LOG. MAT. EEPROM ANGLE I2C",
                "FI": "HW SW FAIL I2C ANGLE EEPROM",
                "DK": "HW SW-FEJL, I2C-VINKEL, EEPROM",
                "NO": "HW SW FEIL I2C VINKEL EEPROM",
                "LV": "HW SW Neizdevās I2C ANGLE EEPROM",
                "NL": "HW SW FOUT I2C HOEK EEPROM",
                "CZ": "HW SW FAIL I2C ANGLE EEPROM",
                "PL": "HW SW FAIL I2C ANGLE EEPROM",
                "EE": "HW SW FAIL I2C ANGLE EEPROM",
                "PT": "HW SW FALHAR I2C ANGLE EEPROM",
                "ES": "HW SW FAIL I2C ÀNGULO EEPROM",
                "IT": "HW SW FAIL I2C ANGLE EEPROM",
                "LT": "HW SW FAIL I2C ANGLE EEPROM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "880": {
                "GB": "HW SW FAIL TE GENERAL",
                "SE": "HW SW FAIL TE GENERAL",
                "DE": "HW SW FEHLER TE ALLGEMEIN",
                "FR": "DÉFAIL. LOG. MAT. GÉNÉRAL TE",
                "FI": "HW SW FAIL TE GENERAL",
                "DK": "HW SW-FEJL, TE GENERELT",
                "NO": "HW SW FEIL TE GENERELT",
                "LV": "HW SW Neizdevās TE GENERAL",
                "NL": "HW SW FOUT TE ALGEMEEN",
                "CZ": "HW SW FAIL TE GENERAL",
                "PL": "HW SW FAIL TE GENERAL",
                "EE": "HW SW FAIL TE GENERAL",
                "PT": "HW SW FALHAR TE GENERAL",
                "ES": "HW SW FAIL TE GENERAL",
                "IT": "HW SW FAIL TE GENERAL",
                "LT": "HW SW FAIL TE GENERAL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "881": {
                "GB": "HW FAIL FS4500 ERROR",
                "SE": "HW-fel, FS4500 AD SUPL",
                "DE": "HW FEHLER FS4500 FEHLER",
                "FR": "DÉFAIL. MAT. ERREUR FS4500",
                "FI": "HW FAIL FS4500 ERROR",
                "DK": "HW-FEJL, FS4500-FEJL",
                "NO": "HW FEIL FS4500 FEIL",
                "LV": "HW Neizdevās FS4500 kļūda",
                "NL": "HW FOUT FS4500 FOUT",
                "CZ": "HW FAIL FS4500 ERROR",
                "PL": "HW FAIL FS4500 ERROR",
                "EE": "HW FAIL FS4500 ERROR",
                "PT": "HW FALHAR FS4500 ERROR",
                "ES": "HW FAIL FS4500 ERROR",
                "IT": "HW FAIL FS4500 ERROR",
                "LT": "HW FAIL FS4500 ERROR",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "882": {
                "GB": "HW FAIL FS4500 AD SUPL",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS VERSORGUNG",
                "FR": "DÉFAIL. MAT. ALIM. FS4500 AD",
                "FI": "HW FAIL FS4500 AD SUPL",
                "DK": "HW-FEJL, FS4500 AD SUPL",
                "NO": "HW FEIL FS4500 AD FORS",
                "LV": "HW Neizdevās FS4500 AD SUPL",
                "NL": "HW FOUT FS4500 BIJ SUPL",
                "CZ": "HW FAIL FS4500 AD SUPL",
                "PL": "HW FAIL FS4500 AD SUPL",
                "EE": "HW FAIL FS4500 AD SUPL",
                "PT": "HW FALHAR FS4500 AD SUPL",
                "ES": "HW FAIL FS4500 AD SUPL",
                "IT": "HW FAIL FS4500 AD SUPL",
                "LT": "HW FAIL FS4500 AD SUPL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "883": {
                "GB": "HW FAIL FS4500 AD MUX",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS MUX",
                "FR": "DÉFAIL. MAT. MUX FS4500 AD",
                "FI": "HW FAIL FS4500 AD MUX",
                "DK": "HW-FEJL, FS4500 AD MUX",
                "NO": "HW FEIL FS4500 AD MUX",
                "LV": "HW Neizdevās FS4500 AD MUX",
                "NL": "HW FOUT FS4500 BIJ MUX",
                "CZ": "HW FAIL FS4500 AD MUX",
                "PL": "HW FAIL FS4500 AD MUX",
                "EE": "HW FAIL FS4500 AD MUX",
                "PT": "HW FALHAR FS4500 AD MUX",
                "ES": "HW FAIL FS4500 AD MUX",
                "IT": "HW FAIL FS4500 AD MUX",
                "LT": "HW FAIL FS4500 AD MUX",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "884": {
                "GB": "HW SW FAIL TE DATA INTEGRITY",
                "SE": "HW SW FAIL TE DATA INTEGRITY",
                "DE": "HW SW FEHLER TE DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. MAT. INTÉGRITÉ DONNÉES TE",
                "FI": "HW SW FAIL TE DATA INTEGRITY",
                "DK": "HW SW-FEJL, TE-DATAINTEGRITET",
                "NO": "HW SW FEIL TE DATAINTEGRITET",
                "LV": "HW SW Neizdevās TE DATA INTEGRITY",
                "NL": "HW SW FOUT TE DATA-INTEGRITEIT",
                "CZ": "HW SW FAIL TE DATA INTEGRITY",
                "PL": "HW SW FAIL TE DATA INTEGRITY",
                "EE": "HW SW FAIL TE DATA INTEGRITY",
                "PT": "HW SW FALHAR TE DATA INTEGRITY",
                "ES": "HW SW FAIL TE DATA INTEGRITY",
                "IT": "HW SW FAIL TE DATA INTEGRITY",
                "LT": "HW SW FAIL TE DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "885": {
                "GB": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "SE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DE": "SW FEHLER SICHERHEITSFUNKT DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. INTÉGRITÉ DONNÉES FONC. SÉCU.",
                "FI": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DK": "SW-FEJL, SIKKERHEDSFUNK, DATAINTEGRITET",
                "NO": "SW FEIL SIKKERHETSFUNK DATAINTEGRITET",
                "LV": "SW Neizdevās SAFETY FUNC DATA INTEGRITY",
                "NL": "SW FOUT VEILIGHEID FUNC DATA INTEGRITEIT",
                "CZ": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PL": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "EE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PT": "SW FALHA SAFETY FUNC DATA INTEGRITY",
                "ES": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "IT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "LT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "891": {
                "GB": "J1939-76, SDG-diagnostic from CCU",
                "SE": "J1939-76, SDG-diagnostic from CCU",
                "DE": "J1939-76, SDG-Diagnose von CCU",
                "FR": "J1939-76, diagnostic SDG de CCU",
                "FI": "J1939-76, SDG-diagnostiikka CCU:sta",
                "DK": "J1939-76, SDG-diagnostik fra CCU",
                "NO": "J1939-76, SDG-diagnose fra CCU",
                "LV": "J1939-76, SDG-diagnostika no CCU",
                "NL": "J1939-76, SDG-diagnostiek van CCU",
                "CZ": "J1939-76, SDG-diagnostika z CCU",
                "PL": "J1939-76, SDG-diagnostic from CCU",
                "EE": "J1939-76, SDG-diagnostika CCU-st",
                "PT": "J1939-76, Diagnóstico SDG da CCU",
                "ES": "J1939-76, SDG-diagnóstico del CCU",
                "IT": "J1939-76, SDG-diagnostica da CCU",
                "LT": "J1939-76, SDG diagnostika iš CCU",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "892": {
                "GB": "J1939-76, SDG-diagnostic from HMI",
                "SE": "J1939-76, SDG-diagnostic from HMI",
                "DE": "J1939-76, SDG-Diagnose von HMI",
                "FR": "J1939-76, diagnostic SDG d’IHM",
                "FI": "J1939-76, SDG-diagnostiikka HMI:stä",
                "DK": "J1939-76, SDG-diagnostik fra HMI",
                "NO": "J1939-76, SDG-diagnose fra HMI",
                "LV": "J1939-76, SDG-diagnostika no HMI",
                "NL": "J1939-76, SDG-diagnostiek van HMI",
                "CZ": "J1939-76, SDG-diagnostika z HMI",
                "PL": "J1939-76, SDG-diagnostic from HMI",
                "EE": "J1939-76, SDG-diagnostika HMI-st",
                "PT": "J1939-76, Diagnóstico SDG da HMI",
                "ES": "J1939-76, SDG-diagnóstico del HMI",
                "IT": "J1939-76, SDG-diagnostica da HMI",
                "LT": "J1939-76, SDG diagnostika iš HMI",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "901": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "902": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "903": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "904": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "905": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "906": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "907": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "908": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "909": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "910": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "921": {
                "GB": "Faulty parameter settings detected",
                "SE": "Fel på parameterinställningar detekterad",
                "DE": "Fehlerhafte Parametereinstellungen erkannt",
                "FR": "Paramètres erronés détectés",
                "FI": "Virheellisiä parametriasetuksia havaittu",
                "DK": "Fejl registreret i parameterindstillinger",
                "NO": "Feil parameterinnstilling registrert",
                "LV": "Konstatēti kļūdaini parametru iestatījumi",
                "NL": "Foutieve parameterinstellingen gedetecteerd",
                "CZ": "Bylo zjištěno chybné nastavení parametrů",
                "PL": "Wykryto błędne ustawienia parametrów",
                "EE": "Tuvastati vigased parameetriseaded",
                "PT": "Detetadas definições de parâmetros incorretas",
                "ES": "Detectado: Configuración de parámetros defectuosa",
                "IT": "Rilevate impostazioni di parametro errate",
                "LT": "Aptikti klaidingi parametrų nustatymai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "922": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "923": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "931": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "932": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "933": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "941": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "942": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "943": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "944": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "945": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "946": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "947": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "948": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "949": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "950": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "961": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "962": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "963": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "971": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "972": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "981": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "982": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "983": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "984": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "985": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "991": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "992": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "993": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "994": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "995": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "996": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "997": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            }
        },
        "6": {
            "1": {
                "GB": "Low supply voltage",
                "SE": "Låg matningsspänning",
                "DE": "Niedrige Speisespannung",
                "FR": "Basse tension d’aliment.",
                "FI": "Matala syöttöjännite",
                "DK": "Lav forsyningsspænding",
                "NO": "Lav forsyningsspenning",
                "LV": "Zems barošanas spriegums",
                "NL": "Lage voedingsspanning",
                "CZ": "Nízké napájecí napětí",
                "PL": "Niskie napięcie zasilania",
                "EE": "Madal toitepinge",
                "PT": "Tensão alimentação baixa",
                "ES": "Tensión de alimentación baja",
                "IT": "Bassa tens. di alimentaz.",
                "LT": "Žema maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "2": {
                "GB": "High supply voltage",
                "SE": "hög matningsspänning",
                "DE": "Hohe Speisespannung",
                "FR": "Haute tension d’aliment.",
                "FI": "Korkea syöttöjännite",
                "DK": "Høj forsyningsspænding",
                "NO": "Høy forsyningsspenning",
                "LV": "Augsts barošanas sprieg.",
                "NL": "Hoge voedingsspanning",
                "CZ": "Vysoké napájecí napětí",
                "PL": "Wys. napięcie zasilania",
                "EE": "Kõrge toitepinge",
                "PT": "Tensão alimentação alta",
                "ES": "Tensión de alimentación alta",
                "IT": "Alta tens. di alimentaz.",
                "LT": "Aukšta maitinimo įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "3": {
                "GB": "Incompatible SW versions",
                "SE": "Inkompatibel mjukvara",
                "DE": "SW-Versionen inkompatibel",
                "FR": "Versions de logiciel incompatibles",
                "FI": "Kelpaamattomat SW-versiot",
                "DK": "Inkompatible SW-versioner",
                "NO": "Inkompatible programvareversjoner",
                "LV": "Nesaderīgas programmat. versijas",
                "NL": "Incompatibele sw-versies",
                "CZ": "Nekompatibilní verze SW",
                "PL": "Niezgodne wersje SW",
                "EE": "Ühildumatud SW-versioonid",
                "PT": "Versões SW incompatíveis",
                "ES": "Incompatibles versiones SW",
                "IT": "Versioni SW incompatibili",
                "LT": "Nesuderinamos pr. įrangos versijos",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "4": {
                "GB": "Mismatching parameter pair",
                "SE": "Parameterpar stämmer inte överens",
                "DE": "Nicht übereinstimmend Parameterpaar",
                "FR": "Paire de paramètres non concordante",
                "FI": "Yhteensopimaton parametripari",
                "DK": "Ikke-matchende parameterpar",
                "NO": "Parameterpar som ikke samsvarer",
                "LV": "Neatbilstošs parametru pāris",
                "NL": "Mismatchend parameterpaar",
                "CZ": "Neshoda páru parametrů",
                "PL": "Niedopasowanie pary parametrów",
                "EE": "Kokkusobimatu parameetrite paar",
                "PT": "Par de parâmetros incompatível",
                "ES": "Parámetros no coinciden",
                "IT": "Coppia di parametri non corrisp.",
                "LT": "Nesutampančių parametrų pora",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "5": {
                "GB": "Firmware update in progress",
                "SE": "Mjukvaruuppdatering pågår",
                "DE": "Firmware-Update wird ausgeführt",
                "FR": "Mise à jour du firmware en cours",
                "FI": "Laiteohjelmistoa päivitetään",
                "DK": "Firmwareopdatering er i gang",
                "NO": "Fastvareoppdatering pågår",
                "LV": "Notiek aparātprogrammat.atjaunināš.",
                "NL": "Firmware-update bezig",
                "CZ": "Probíhá aktualizace firmwaru",
                "PL": "Aktualizacja firmware w toku",
                "EE": "Püsivara värskendus pooleli",
                "PT": "Atualização de firmware em curso",
                "ES": "Actualización de programa en curso",
                "IT": "Aggiornamento firmware in corso",
                "LT": "Vykdomas pr. įrangos atnaujinimas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "6": {
                "GB": "System restart needed",
                "SE": "Systemet behöver startas om",
                "DE": "Systemneustart erforderlich",
                "FR": "Le système doit être redémarré",
                "FI": "Uudelleenkäynnistys tarpeen",
                "DK": "System genstart nødvendig",
                "NO": "Systemet må startes på nytt",
                "LV": "Nepieciešama sistēmas atsāknēšana",
                "NL": "System reboot nodig",
                "CZ": "Nutné restartovat systém",
                "PL": "Wymagany restart systemu",
                "EE": "On vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. do sistema",
                "ES": "Es necesario reiniciar el sistema",
                "IT": "Riavvio del sistema necessario",
                "LT": "Reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "7": {
                "GB": "System restart needed after SW update",
                "SE": "Systemet behöver startas om efter mjukvaruuppdatering",
                "DE": "Nach dem SW-Update ist ein Neustart des Systems erforderlich",
                "FR": "Redémarrage du système requis après la mise à jour logicielle",
                "FI": "Ohjelmistopäivityksen jälkeen järjestelmä on käynnistettävä uudelleen",
                "DK": "Systemgenstart nødvendig efter SW opdatering",
                "NO": "Systemet må startes på nytt når SW er oppdatert",
                "LV": "Pēc SW atjaunināšanas nepieciešama sistēmas pārstartēšana",
                "NL": "Het systeem moet na SW-update opnieuw opstarten worden",
                "CZ": "Po aktualizaci SW je nutné restartovat systém",
                "PL": "Wymagany restart syst. po aktualizacji SW",
                "EE": "Pärast tarkvarauuendust on vaja süsteem taaskäivitada",
                "PT": "Reinicialização necess. após a atualização de SW",
                "ES": "Es necesario reiniciar el sistema después de actualizar SW",
                "IT": "Riavvio del sistema necessario dopo l'aggiornamento del SW",
                "LT": "Po programinės įrangos atnaujinimo reikia iš naujo paleisti sistemą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "8": {
                "GB": "Roller active at start",
                "SE": "Rulle aktiv vid start",
                "DE": "Rolle beim Start aktiv",
                "FR": "Roller actif au démarrage",
                "FI": "Rulla aktiivinen käynnistettäessä",
                "DK": "Rulle aktiv ved start",
                "NO": "Rulle er aktiv fra start",
                "LV": "Rullītis darbojas, ieslēdzot sistēmu",
                "NL": "Rol actief bij start",
                "CZ": "Váleček aktivní při startu",
                "PL": "Rolka aktywna przy rozruchu",
                "EE": "Rull aktiivne alguses",
                "PT": "Rolo ativo no início",
                "ES": "Rótula activa en el inicio",
                "IT": "Selettore attivo all’avvio",
                "LT": "Paleidimo metu ratukas yra aktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "9": {
                "GB": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "SE": "Reservdel ansluten.\nUppdatera programvara och parametrar via Rototilt Connect appen",
                "DE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "FI": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "DK": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NO": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LV": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "NL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "CZ": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PL": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "EE": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "PT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "ES": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "LT": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "KR": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "JP": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App",
                "IS": "Spare part connected. \nUpdate Software and parameters via the Rototilt Connect App"
            },
            "11": {
                "GB": "Rotor sensor circuit, no pulses",
                "SE": "Rotationssensor, inga pulser",
                "DE": "Rotorsensorkreis, keine Impulse",
                "FR": "Circuit capteur rotor, pas d’impulsions",
                "FI": "Kiertoanturipiiri, ei pulsseja",
                "DK": "Rotorsensorkredsløb, ingen impulser",
                "NO": "Rotorsensorkrets, ingen pulser",
                "LV": "Rotora sensora ķēde, nav impulsu",
                "NL": "Sensorcircuit rotor, geen pulsen",
                "CZ": "Obvod senzor rotoru, bez impulzů",
                "PL": "Obwód czujnika wirnika, brak impulsów",
                "EE": "Rootori anduri ahel, impulsid puuduvad",
                "PT": "Circuito sensor rotor, sem impulsos",
                "ES": "Circuito del sensor del rotor, sin pulsaciónes",
                "IT": "Circuito sensore rotore, nessun impulso",
                "LT": "Rotoriaus jut. grandinė, impulsų nėra",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "12": {
                "GB": "Index sensor circuit, error",
                "SE": "Indexsensor, inga pulser",
                "DE": "Indexsensorkreis, Fehler",
                "FR": "Circuit capteur index, erreur",
                "FI": "Indeksianturipiiri, virhe",
                "DK": "Indekssensorkredsløb, fejl",
                "NO": "Indekssensorkrets, feil",
                "LV": "Indeksa sensora ķēde, kļūda",
                "NL": "Index sensorcircuit, fout",
                "CZ": "Obvod senzor indexu, chyba",
                "PL": "Obwód czujnika indeksującego, błąd",
                "EE": "Indeksi anduri ahel, viga",
                "PT": "Índice de circuito do sensor, erro",
                "ES": "Circuito del sensor del índice, error",
                "IT": "Circuito sensore indice, errore",
                "LT": "Indekso jutiklio grandinė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "13": {
                "GB": "Auto setup failed",
                "SE": "Autosetup fallerade",
                "DE": "Auto-Setup fehlgeschlagen",
                "FR": "Échec config. auto",
                "FI": "Autom. asetus epäonn.",
                "DK": "Autoopsætning fik fejl",
                "NO": "Feil under autooppsett",
                "LV": "Autom.iestatīš. neizdevās",
                "NL": "Auto setup mislukt",
                "CZ": "Auto nastavení selhalo",
                "PL": "Błąd auto konfiguracji",
                "EE": "Autom. seadist. nurjus",
                "PT": "Falha config. automática",
                "ES": "Auto setup ha fallado",
                "IT": "Config. autom. non riusc.",
                "LT": "Aut. nustatymas nepavyko",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "28": {
                "GB": "Coupler blocked due to buzzer failure",
                "SE": "Fel på varningssummer, låsfunktion blockerad",
                "DE": "Koppler aufgrund eines Summerausfalls blockiert",
                "FR": "Attache bloquée car défaillance du ronfleur",
                "FI": "Kytkin estetty summerivian vuoksi",
                "DK": "Fæste blokeret på grund af summerfejl",
                "NO": "Feste er blokkert på grunn av summerfeil",
                "LV": "Savienotājs bloķēts zummera atteices dēļ",
                "NL": "Koppeling geblokkeerd door zoemerstoring",
                "CZ": "Spojka zablokována kvůli poruše bzučáku",
                "PL": "Łącznik zablokowany z powodu awarii brzęczyka",
                "EE": "Haakeseadis on sumisti rikke tõttu blokeeritud",
                "PT": "Acoplador bloqueado devido a falha no vibrador",
                "ES": "Acoplador bloqueado, fallo del zumbador",
                "IT": "Attacco bloccato per guasto della cicalina",
                "LT": "Jungtis užblokuota dėl zirzeklio gedimo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "31": {
                "GB": "Safety lever activated at start",
                "SE": "Manöverspärr är aktiverad vid uppstart",
                "DE": "Bediensperre beim Start aktiviert",
                "FR": "Levier d'arrêt activé au démarrage",
                "FI": "Käynnistys aktivoi toimintojen eston",
                "DK": "Manøvrespærring aktiveret ved start",
                "NO": "Manøversperre er aktivert ved start",
                "LV": "Palaides laikā droš. svira aktīva",
                "NL": "Veiligheidshendel geactiveerd bij start",
                "CZ": "Bezpečnost páka aktivní při startu",
                "PL": "Dźwignia bezp. aktyw. przy rozruchu",
                "EE": "Turvahoob käivitamisel aktiivne",
                "PT": "Alavanca segurança ativa no início",
                "ES": "Palanca de seguridad activado al inicio",
                "IT": "Leva di sicurezza attiva all’avvio",
                "LT": "Saugos svirtis aktyvi paleidžiant",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "32": {
                "GB": "Safety lever inactive",
                "SE": "Manöverspärr är inaktiv.",
                "DE": "Bediensperre inaktiv",
                "FR": "Levier d'arrêt inactif",
                "FI": "Toimintojen esto ei käytössä",
                "DK": "Manøvrespærring inaktiv",
                "NO": "Manøversperre er inaktiv",
                "LV": "Drošības svira neaktīvs",
                "NL": "Veiligheidshendel inactief",
                "CZ": "Pojistná páka neaktivní",
                "PL": "Dźwignia bezp. nieczynny",
                "EE": "Turvahoova inaktiivne",
                "PT": "Alavancar segurança inativa",
                "ES": "Palanca de seguridad inactivado",
                "IT": "Leva di sicurezza inattiva",
                "LT": "Saugos svirtis neaktyvus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "33": {
                "GB": "Machine Coupler blocked",
                "SE": "Maskinlåstet är blockerat ",
                "DE": "Baggerschnellwechsler blockiert",
                "FR": "Attache rapide machine bloquée",
                "FI": "Pikaliitin estetty",
                "DK": "Hurtigskifte blokeret",
                "NO": "Maskinfestet er blokkert",
                "LV": "Spraudsavienotājs bloķēts",
                "NL": "Machinesnelwissel geblokkeerd",
                "CZ": "Spojka stroje zablokována",
                "PL": "Szybkozłącze koparki zablokowane aż do restartu",
                "EE": "Masina haakeseadis blokeeritud",
                "PT": "Acoplador da máquina bloqueado",
                "ES": "Acoplador de máquina bloqueado",
                "IT": "Attacco macchina bloccato",
                "LT": "Mašinos mova užblokuota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "34": {
                "GB": "Machine control system off-line",
                "SE": "Maskinkontrollsystem offline",
                "DE": "Maschinensteuersystem offline",
                "FR": "Système de contrôle machine hors ligne",
                "FI": "Koneenohjausjärjestelmään ei yhteyttä",
                "DK": "Maskinstyresystem offline",
                "NO": "Maskinens styresystem er frakoblet",
                "LV": "Mašīnas vadības sistēma offline",
                "NL": "Machinebesturingssyst. offline",
                "CZ": "Systém řízení stroje off-line",
                "PL": "Maszyna system sterowania offline",
                "EE": "Masina juhtimissüsteem offline",
                "PT": "Sistema de controle da máquina off-line",
                "ES": "Control de sistema de maquina offline",
                "IT": "Sistema di controllo della macchina offline",
                "LT": "Mašinos valdymo sistema neprisijungusi",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "41": {
                "GB": "Left analog joystick, error",
                "SE": "Vänster analog joystick, error",
                "DE": "Linker Analog-Joystick, Fehler",
                "FR": "Joystick analogique gauche, erreur",
                "FI": "Vasen analoginen ohjain, virhe",
                "DK": "Venstre analoge joystick, fejl",
                "NO": "Venstre analoge styrespak, feil",
                "LV": "Kreisā analogā vadības svira, kļūda",
                "NL": "Linker analoge joystick, fout",
                "CZ": "Levý analogový joystick, chyba",
                "PL": "Lewy uchwyt analog, error",
                "EE": "Vasak analoogjuhtkang, viga",
                "PT": "Joystick analógico esquerdo, erro",
                "ES": "Joystick analógico izquierdo, error",
                "IT": "Joystick analogico sinistro, errore",
                "LT": "Kair. analog. vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "42": {
                "GB": "Right analog joystick, error",
                "SE": "Höger analog joystick, error",
                "DE": "Rechter Analog-Joystick, Fehler",
                "FR": "Joystick analogique droit, erreur",
                "FI": "Oikea analoginen ohjain, virhe",
                "DK": "Højre analoge joystick, fejl",
                "NO": "Høyre analoge styrespak, feil",
                "LV": "Labā analogā vadības svira, kļūda",
                "NL": "Rechter analoge joystick, fout",
                "CZ": "Pravý analogový joystick, chyba",
                "PL": "Prawy uchwyt analog, error",
                "EE": "Parem analoogjuhtkang, viga",
                "PT": "Joystick analógico direito, erro",
                "ES": "Joystick analógico derecho, error",
                "IT": "Joystick analogico destro, errore",
                "LT": "Dešin. analoginė vairasvirtė, klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "43": {
                "GB": "Left analog joystick, uncalibrated",
                "SE": "Vänster analog joystick, okalibrerad",
                "DE": "Linker Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique gauche, non étalonné",
                "FI": "Vasen analoginen ohjain, ei kalibroitu",
                "DK": "Venstre analoge joystick, ikke kalibreret",
                "NO": "Venstre analoge styrespak, ikke kalibrert",
                "LV": "Kreisā analogā vadības svira, nekalibrēta",
                "NL": "Linker analoge joystick, niet gekalibreerd",
                "CZ": "Levý analogový joystick, nekalibrovaný",
                "PL": "Lewy uchwyt analog, nieskalibrow.",
                "EE": "Vasak analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico esquerdo, não calibrado",
                "ES": "Joystick analógico izquierdo, sin calibrar",
                "IT": "Joystick analogico sinistro, non calibrato",
                "LT": "Kair. analog.vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "44": {
                "GB": "Right analog joystick, uncalibrated",
                "SE": "Höger analog joystick, okalibrerad",
                "DE": "Rechter Analog-Joystick, nicht kalibriert",
                "FR": "Joystick analogique droit, non étalonné",
                "FI": "Oikea analoginen ohjain, ei kalibroitu",
                "DK": "Højre analoge joystick, ikke kalibreret",
                "NO": "Høyre analoge styrespak, ikke kalibrert",
                "LV": "Labā analogā vadības svira, nekalibrēta",
                "NL": "Rechter analoge joystick, niet gekalibreerd",
                "CZ": "Pravý analogový joystick, nekalibrovaný",
                "PL": "Prawy joystick analogowy, brak kalibracji",
                "EE": "Parem analoogjuhtkang, kalibreerimata",
                "PT": "Joystick analógico direito, não calibrado",
                "ES": "Joystick analógico derecho, sin calibrar",
                "IT": "Joystick analogico destro, non calibrato",
                "LT": "Dešin. analoginė vairasvirtė, nekalibruota",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "51": {
                "GB": "Communication timeout, external machine CAN-bus",
                "SE": "Kommunikations-Timeout, bärarmaskin CAN-bus",
                "DE": "Timeout Kommunikation, externe Maschinen und CAN-Bus",
                "FR": "Délai de communication expiré, bus CAN machine externe",
                "FI": "Viestinnän aikakatkaisu, ulkoisen koneen CAN-väylä",
                "DK": "Kommunikationstimeout, ekstern maskine-CAN-bus",
                "NO": "Tidsavbrudd for kommunikasjon, ekstern maskin CAN-buss",
                "LV": "Sakaru taimauts, ārējās mašīnas CAN kopne",
                "NL": "Communicatie time-out, externe machine CAN-bus",
                "CZ": "Časový limit komunikace, externí sběrnice CAN stroje",
                "PL": "Limit czasu komunikacji, magistrala CAN maszyny zewnętrznej",
                "EE": "Side ajalõpp, välise masina CAN-siin",
                "PT": "Tempo de comunicação esgotado, barramento CAN da máquina externa",
                "ES": "Comunicación interrumpido, CAN bus de máquina externa",
                "IT": "Timeout di comunicazione, bus CAN macchina esterna",
                "LT": "Ryšio skirtasis laikas, išorinio įrenginio CAN magistralė",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "52": {
                "GB": "External machine system not in working mode",
                "SE": "Bärarmaskin är inte i rätt driftmode",
                "DE": "Externes Maschinensystem nicht im Betriebsmodus",
                "FR": "Système machine externe pas en mode de fonctionnement",
                "FI": "Ulkoisen koneen järjestelmä ei toimintatilassa",
                "DK": "Eksternt maskinsystem ikke i arbejdstilstand",
                "NO": "Eksternt maskinsystem er ikke i arbeidsmodus",
                "LV": "Ārējās mašīnas sistēma nav darba režīmā",
                "NL": "Extern machinesysteem niet in de werkmodus",
                "CZ": "Externí systém stroje není v pracovním režimu",
                "PL": "Układ maszyny zewnętrznej nie jest w trybie roboczym",
                "EE": "Välise masina süsteem ei ole töörežiimis",
                "PT": "O sistema de máquina externa não está em modo de trabalho",
                "ES": "Externo sistema de máquina no en modo de trabajo",
                "IT": "Sistema macchina est. non in modalità esercizio",
                "LT": "Išorinė įrenginio sistema neveikia",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "53": {
                "GB": "External machine software interface error",
                "SE": "Inkompatibelt mjukvaruinterface mot bärarmaskin",
                "DE": "Externer Softwareschnittstellen-Fehler Maschine",
                "FR": "Erreur d’interface logicielle machine externe",
                "FI": "Ulkoisen koneen ohjelmistoliittymän virhe",
                "DK": "Grænsefladefejl i ekstern maskinsoftware",
                "NO": "Grensesnittfeil for ekstern maskinprogramvare",
                "LV": "Ārējās mašīnas programmatūras interfeisa kļūda",
                "NL": "Fout in interface externe machinesoftware",
                "CZ": "Chyba rozhraní externího softwaru stroje",
                "PL": "Błąd interfejsu oprogramowania maszyny zewnętrznej",
                "EE": "Välise masina tarkvaraliidese viga",
                "PT": "Erro de interface de software de máquina externa",
                "ES": "Interface error, software de máquina externa",
                "IT": "Errore interfaccia software della macchina esterna",
                "LT": "Išorinės įrenginio pr. įrangos sąsajos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "54": {
                "GB": "Conflicting CAN messages detected on CAN-bus to external machine",
                "SE": "Inkompatibla CAN-meddelanden upptäcka på CAN-bus mot extern maskin.",
                "DE": "Konflikt bei CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Conflit de messages CAN détecté sur bus CAN vers machine externe",
                "FI": "Ristiriitaisia CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Modstridende CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Konflikt i CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti konfliktējoši CAN ziņojumi",
                "NL": "Conflicterende CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Konfliktní zprávy CAN zjištěné na sběrnici CAN s externím počítačem",
                "PL": "Sprzeczne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad vastuolulised sõnumid",
                "PT": "Detetado conflito mensagens CAN no barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN conflictivos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN in conflitto sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje aptikti nesuderinami CAN pranešimai išoriniam įreng.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "55": {
                "GB": "Incorrect CAN messages detected on CAN-bus to external machine",
                "SE": "Felaktiga CAN-meddelanden upptäcka på CAN-bus mot extern maskin",
                "DE": "Falsche CAN-Meldungen auf CAN-Bus an externe Maschine erkannt",
                "FR": "Message incorrect CAN détecté sur bus CAN vers machine externe",
                "FI": "Virheellisiä CAN-viestejä havaittu ulkoisen koneen CAN-väylässä",
                "DK": "Fejl på CAN-meddelelser registreret på CAN-bus til ekstern maskine",
                "NO": "Feil på CAN-meldinger registrert på CAN-buss til ekstern maskin",
                "LV": "CAN kopnē uz ārējo mašīnu konstatēti nepareizi CAN ziņojumi",
                "NL": "Fouten in CAN-berichten gedetecteerd op CAN-bus naar externe machine",
                "CZ": "Na sběrnici CAN k externímu počítači zjištěny nesprávné zprávy CAN",
                "PL": "Błędne komunikaty CAN wykryte na magistrali CAN do maszyny zewn.",
                "EE": "Tuvastati CAN-siinilt välismasinasse saadetavad valed sõnumid",
                "PT": "Detetadas mensagens CAN incorretas barramento CAN para máquina externa",
                "ES": "Detectado: Mensajes CAN erróneos en CAN bus a máquina externa",
                "IT": "Rilevati messaggi CAN errati sul bus CAN verso macchina esterna",
                "LT": "CAN magistralėje išoriniam įreng. aptikti neteisingi CAN pranešimai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "71": {
                "GB": "Input mapping invalid",
                "SE": "Insignalsmappning, error",
                "DE": "Eingangsmapping ungültig",
                "FR": "Mappage d’entrée non valide",
                "FI": "Syöttömapitus ei kelpaa",
                "DK": "Kortlægning af input ugyldig",
                "NO": "Ugyldig inndatatilordning",
                "LV": "Ieejas kartēšana nav derīga",
                "NL": "Invoertoewijzing ongeldig",
                "CZ": "Mapování vstupu neplatné",
                "PL": "Mapowanie wejścia nieprawidłowe",
                "EE": "Sisendi kaardistamine kehtetu",
                "PT": "Mapeamento de entrada inválido",
                "ES": "Señal de entrada no válida",
                "IT": "Mapping ingressi non valida",
                "LT": "Įvesties susiejimas netinkamas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "72": {
                "GB": "Parameter setting error",
                "SE": "Felaktig parameter inställning",
                "DE": "Fehler bei der Parametrierung",
                "FR": "Erreur de paramétrage",
                "FI": "Parametrin asetusvirhe",
                "DK": "Parameterindstillingsfejl",
                "NO": "Feil under parameterinnstilling",
                "LV": "Parametru iestatīšanas kļūda",
                "NL": "Fout parameter instellen",
                "CZ": "Chyba nastavení parametrů",
                "PL": "Błąd ustawiania parametrów",
                "EE": "Parameetri seadistamise viga",
                "PT": "Erro de configuração de parâmetros",
                "ES": "Error de configuraciónes del parametro",
                "IT": "Errore impostazione dei parametri",
                "LT": "Parametrų nustatymo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "81": {
                "GB": "Activate double feeder",
                "SE": "Aktivera dubbelshunt",
                "DE": "Doppelfeeder aktivieren",
                "FR": "Activer double alimentateur",
                "FI": "Aktivoi kaksoissyöttö",
                "DK": "Aktivér dobbelt feeder",
                "NO": "Aktiver dobbel mating",
                "LV": "Aktivizēt Dub. Padevēja",
                "NL": "Dubbele feeder activeren",
                "CZ": "Aktivoi dvojit podavač",
                "PL": "Uruch. podwójny bocznik",
                "EE": "Aktiveeri To. ettean.",
                "PT": "Ativar aliment duplo",
                "ES": "Activar alimentador double",
                "IT": "Attiv. doppio alim.",
                "LT": "Suaktyvinti Dvig. tiektuvo",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "82": {
                "GB": "Inactivate double feeder",
                "SE": "Inaktivera dubbelshunt",
                "DE": "Doppelfeeder deaktivieren",
                "FR": "Désactiver double alimentateur",
                "FI": "Sammuta kaksoissyöttö",
                "DK": "Inaktiver dobbelt feeder",
                "NO": "Deaktiver dobbel mating",
                "LV": "Deaktivēt dubulto padevēju",
                "NL": "Dubbele feeder deactiveren",
                "CZ": "Deaktivace dvojitého podavače",
                "PL": "Wyłącz podajnik podwójny",
                "EE": "Inaktiveerige topeltetteandur",
                "PT": "Desativar alimentador duplo",
                "ES": "Inactivar alimentador double",
                "IT": "Disattivare doppio alimentatore",
                "LT": "Išjungti dvigubą tiektuvą",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "83": {
                "GB": "Double feeder active",
                "SE": "Dubbelshunt är aktiv",
                "DE": "Doppelfeeder aktiv",
                "FR": "Double alimentateur actif",
                "FI": "Kaksoissyöttö aktiivinen",
                "DK": "Dobbelt feeder aktiv",
                "NO": "Dobbel mating aktivert",
                "LV": "Dub. padevēja rež.",
                "NL": "Dubbele feeder actief",
                "CZ": "Dvojit podavač aktivní",
                "PL": "podwójny bocznik aktyw.",
                "EE": "To. ettean. aktiivsed",
                "PT": "Aliment duplo ativos",
                "ES": "Alimentador double activo",
                "IT": "Doppio alim. attivi",
                "LT": "Dvig. tiektuvo aktyvūs",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "91": {
                "GB": "HCU off-line",
                "SE": "HCU, offline",
                "DE": "HCU offline",
                "FR": "HCU hors ligne",
                "FI": "HCU ei yhteyttä",
                "DK": "HCU offline",
                "NO": "HCU frakoblet",
                "LV": "HCU offline",
                "NL": "HCU offline",
                "CZ": "HCU offline",
                "PL": "HCU offline",
                "EE": "HCU offline",
                "PT": "HCU offline",
                "ES": "HCU offline",
                "IT": "HCU offline",
                "LT": "HCU neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "420": {
                "GB": "Warning buzzer doesn't work correctly",
                "SE": "Varningssummer fungerar inte som den ska",
                "DE": "Warnsummer funktioniert nicht ordnungsgemäß",
                "FR": "Fonctionnement incorrect du ronfleur d’avert.",
                "FI": "Varoitussummeri ei toimi oikein",
                "DK": "Advarselssummer fungerer ikke korrekt",
                "NO": "Advarselsummer fungerer ikke på riktig måte",
                "LV": "Brīdinājuma zummers nedarbojas pareizi",
                "NL": "Waarschuwingszoemer werkt niet correct",
                "CZ": "Výstražný bzučák nepracuje správně",
                "PL": "Brzęczyk ostrzegawczy nie działa poprawnie",
                "EE": "Hoiatussumisti ei tööta õigesti",
                "PT": "O vibrador de aviso não funciona corretamente",
                "ES": "Advertencia: el zumbador no funciona correctamente",
                "IT": "La cicalina di avvertenza non funziona correttamente",
                "LT": "Netinkamai veikia įspėjamasis zirzeklis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "421": {
                "GB": "Buzzer test failed at startup",
                "SE": "Buzzertest fallerade vid uppstart",
                "DE": "Summertest beim Start fehlgeschlagen",
                "FR": "Échec du test du ronfleur au démarrage",
                "FI": "Summeritesti epäonnistui käynnistyks.",
                "DK": "Summertest mislykkedes ved opstart",
                "NO": "Feil under summertest ved oppstart",
                "LV": "Palaides laikā zummera tests neizdevās",
                "NL": "Buzzer-test mislukt bij het opstarten",
                "CZ": "Test bzučáku selhal při spuštění",
                "PL": "Test brzęczyka przy rozruchu nie powiódł się",
                "EE": "Sumisti test ebaõnnestus käivitamisel",
                "PT": "O teste de vibrador falhou no início",
                "ES": "Test del zumbador fallado al inicio",
                "IT": "Test della cicalina non riuscito all’avvio",
                "LT": "Paleidimo metu nepavyko patikrinti zirzeklio",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "422": {
                "GB": "Coupler button pressed without first been activated",
                "SE": "Låsfunktionsknapp tryckt utan att först blivit aktiverad",
                "DE": "Kopplertaste gedrückt, ohne vorher aktiviert sein",
                "FR": "Bouton de l’attache enfoncé sans activation préalable",
                "FI": "Kytkinpainiketta painettu ilman aktivointia",
                "DK": "Fæsteknap trykket uden først at være aktiveret",
                "NO": "Festeknapp trykket inn uten å ha vært aktivert",
                "LV": "Nospiesta poga Neaktīvs savienotājs",
                "NL": "Koppelingsknop ingedrukt zonder eerst te zijn geactiveerd",
                "CZ": "Stisknuté tlačítko neaktivní spojky",
                "PL": "Naciśnięto przycisk nieaktywnego łącznika",
                "EE": "Vajutati passiivse haakeseadise nuppu",
                "PT": "Pressionado botão de acoplador inativo",
                "ES": "Botón de acoplador presionado sin haber sido activado",
                "IT": "Disattivare pulsante di attacco premuto",
                "LT": "Paspaustas neaktyvios jungiamosios movos mygtukas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "440": {
                "GB": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "SE": "Ny tiltrotator ansluten\n\n(om reservdel installerats, uppdatera den via Rototilt Connect appen)",
                "DE": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "FR": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "FI": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "DK": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "NO": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "LV": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "NL": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "CZ": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "PL": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "EE": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "PT": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "ES": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "IT": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "LT": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "KR": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "JP": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)",
                "IS": "New tiltrotator connected\n\n(if a spare part installed, initialize it via the Rototilt Connect App)"
            },
            "441": {
                "GB": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "SE": "Okänd CCU ansluten\n\nUppdatera den via Rototilt Connect appen",
                "DE": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "FR": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "FI": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "DK": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "NO": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "LV": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "NL": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "CZ": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "PL": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "EE": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "PT": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "ES": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "IT": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "LT": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "KR": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "JP": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App",
                "IS": "Unknown CCU connected\n\nInitialize it via the Rototilt Connect App"
            },
            "450": {
                "GB": "CCU off-line",
                "SE": "Ingen kommunikation med CCU",
                "DE": "CCU offline",
                "FR": "CCU hors ligne",
                "FI": "CCU ei yhteyttä",
                "DK": "CCU offline",
                "NO": "CCU frakoblet",
                "LV": "CCU off-line",
                "NL": "CCU offline",
                "CZ": "CCU off-line",
                "PL": "CCU offline",
                "EE": "CCU off-line",
                "PT": "CCU off-line",
                "ES": "CCU offline",
                "IT": "CCU off-line",
                "LT": "CCU neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "460": {
                "GB": "Allow connection from: \n%s?",
                "SE": "Tillåt uppkoppling från:\n%s?",
                "DE": "Verbindung zulassen von:\n%s?",
                "FR": "Autoriser la connexion depuis:\n%s?",
                "FI": "Luodaanko yhteys?\n%s",
                "DK": "Tillad forbindelse fra:\n%s?",
                "NO": "Tillat tilkobling fra:\n%s?",
                "LV": "Vai atļaut savienojumu no:\n%s?",
                "NL": "Verbinding toestaan van:\n%s?",
                "CZ": "Povolit připojení od:\n%s?",
                "PL": "Zezwól na połączenie z:\n%s?",
                "EE": "Kas lubada ühendus alates:\n%s?",
                "PT": "Permitir ligação de:\n%s?",
                "ES": "Permitir conexión desde: \n%s?",
                "IT": "Consentire connessione da:\n%s?",
                "LT": "Leisti prisijungti iš:\n%s?",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "497": {
                "GB": "MIC40, CAN bus timeout",
                "SE": "MIC40, CAN bus timeout",
                "DE": "MIC40, CAN bus timeout",
                "FR": "MIC40, CAN bus timeout",
                "FI": "MIC40, CAN bus timeout",
                "DK": "MIC40, CAN bus timeout",
                "NO": "MIC40, CAN bus timeout",
                "LV": "MIC40, CAN bus timeout",
                "NL": "MIC40, CAN bus timeout",
                "CZ": "MIC40, CAN bus timeout",
                "PL": "MIC40, CAN bus timeout",
                "EE": "MIC40, CAN bus timeout",
                "PT": "MIC40, CAN bus timeout",
                "ES": "MIC40, CAN bus timeout",
                "IT": "MIC40, CAN bus timeout",
                "LT": "MIC40, CAN bus timeout",
                "KR": "MIC40, CAN bus timeout",
                "JP": "MIC40, CAN bus timeout",
                "IS": "MIC40, CAN bus timeout"
            },
            "498": {
                "GB": "MIC40, API mismatch",
                "SE": "MIC40, API mismatch",
                "DE": "MIC40, API mismatch",
                "FR": "MIC40, API mismatch",
                "FI": "MIC40, API mismatch",
                "DK": "MIC40, API mismatch",
                "NO": "MIC40, API mismatch",
                "LV": "MIC40, API mismatch",
                "NL": "MIC40, API mismatch",
                "CZ": "MIC40, API mismatch",
                "PL": "MIC40, API mismatch",
                "EE": "MIC40, API mismatch",
                "PT": "MIC40, API mismatch",
                "ES": "MIC40, API mismatch",
                "IT": "MIC40, API mismatch",
                "LT": "MIC40, API mismatch",
                "KR": "MIC40, API mismatch",
                "JP": "MIC40, API mismatch",
                "IS": "MIC40, API mismatch"
            },
            "499": {
                "GB": "MIC40, CAN bus tampering",
                "SE": "MIC40, CAN bus tampering",
                "DE": "MIC40, CAN bus tampering",
                "FR": "MIC40, CAN bus tampering",
                "FI": "MIC40, CAN bus tampering",
                "DK": "MIC40, CAN bus tampering",
                "NO": "MIC40, CAN bus tampering",
                "LV": "MIC40, CAN bus tampering",
                "NL": "MIC40, CAN bus tampering",
                "CZ": "MIC40, CAN bus tampering",
                "PL": "MIC40, CAN bus tampering",
                "EE": "MIC40, CAN bus tampering",
                "PT": "MIC40, CAN bus tampering",
                "ES": "MIC40, CAN bus tampering",
                "IT": "MIC40, CAN bus tampering",
                "LT": "MIC40, CAN bus tampering",
                "KR": "MIC40, CAN bus tampering",
                "JP": "MIC40, CAN bus tampering",
                "IS": "MIC40, CAN bus tampering"
            },
            "501": {
                "GB": "Service, 8h",
                "SE": "Service, 8h",
                "DE": "Service, 8h",
                "FR": "Entretien, 8 h",
                "FI": "Huolto, 8h",
                "DK": "Service, 8t",
                "NO": "Service, 8 t",
                "LV": "Apkope, 8h",
                "NL": "Service, 8u",
                "CZ": "Servis, 8 h",
                "PL": "Usługa, 8h",
                "EE": "Hooldus, 8h",
                "PT": "Serviço, 8h",
                "ES": "Servicio, 8 h",
                "IT": "Assistenza, 8h",
                "LT": "Tech. priež., 8 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "502": {
                "GB": "Service, 40h",
                "SE": "Service, 40h",
                "DE": "Service, 40h",
                "FR": "Entretien, 40 h",
                "FI": "Huolto, 40h",
                "DK": "Service, 40t",
                "NO": "Service, 40 t",
                "LV": "Apkope,40h",
                "NL": "Service, 40 u",
                "CZ": "Servis, 40 h",
                "PL": "Usługa, 40h",
                "EE": "Hooldus, 40h",
                "PT": "Serviço, 40h",
                "ES": "Servicio, 40 h",
                "IT": "Assistenza, 40h",
                "LT": "Tech. priež., 40 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "503": {
                "GB": "Service, 160h",
                "SE": "Service, 160h",
                "DE": "Service, 160h",
                "FR": "Entretien, 160 h",
                "FI": "Huolto, 160h",
                "DK": "Service, 160t",
                "NO": "Service, 160 t",
                "LV": "Apkope, 160h",
                "NL": "Service, 160 u",
                "CZ": "Servis, 160 h",
                "PL": "Usługa, 160h",
                "EE": "Hooldus, 160h",
                "PT": "Serviço, 160h",
                "ES": "Servicio, 160 h",
                "IT": "Assistenza, 160h",
                "LT": "Tech. priež., 160 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "504": {
                "GB": "Service, 500/1000h",
                "SE": "Service, 500/1000h",
                "DE": "Service, 500/1000h",
                "FR": "Entretien, 500/1000 h",
                "FI": "Huolto, 500/1000h",
                "DK": "Service, 500/1000t",
                "NO": "Service, 500/1000 t",
                "LV": "Apkope, 500/1000h",
                "NL": "Service, 500/1000 u",
                "CZ": "Servis, 500/1000 h",
                "PL": "Usługa, 500/1000 h",
                "EE": "Hooldus, 500/1000h",
                "PT": "Serviço, 500/1000h",
                "ES": "Servicio, 500/ 1000 h",
                "IT": "Assistenza, 500/1000h",
                "LT": "Tech. priež., 500/1000 val.",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "600": {
                "GB": "Left joystick, off-line",
                "SE": "Vänster joystick, offline",
                "DE": "Linker Joystick, offline",
                "FR": "Joystick gauche, hors ligne",
                "FI": "Vasen ohjain, ei yhteyttä",
                "DK": "Venstre joystick, offline",
                "NO": "Venstre styrespak, frakoblet",
                "LV": "Kr. vadības svira, off-line",
                "NL": "Linker joystick, offline",
                "CZ": "Levý joystick, off-line",
                "PL": "Lewy uchwyt, offline",
                "EE": "Vasak juhtkang, offline",
                "PT": "Joystick esquerdo, offline",
                "ES": "Mando Izquierdo, offline",
                "IT": "Joystick sinistro, offline",
                "LT": "Kair. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "601": {
                "GB": "Left joystick, error on roller: LR1",
                "SE": "Vänster joystick, rullfel: LR1",
                "DE": "Linker Joystick, Fehler an Rolle: LR1",
                "FR": "Joystick gauche, erreur sur roller : LR1",
                "FI": "Vasen ohjain, virhe rullassa: LR1",
                "DK": "Venstre joystick, fejl på rulle: LR1",
                "NO": "Venstre styrespak, feil på rulle: LR1",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR1",
                "NL": "Linker joystick, fout roller: LR1",
                "CZ": "Levý joystick, chyba na válci: LR1",
                "PL": "Lewy uchwyt, error na rolka: LR1",
                "EE": "Vasak juhtkang, rulli viga: LR1",
                "PT": "Joystick esquerdo, erro no rolo: LR1",
                "ES": "Mando izquierdo, error de rótula LR1",
                "IT": "Joystick sinistro, errore sul selettore: LR1",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "602": {
                "GB": "Left joystick, error on roller: LR2",
                "SE": "Vänster joystick, rullfel: LR2",
                "DE": "Linker Joystick, Fehler an Rolle: LR2",
                "FR": "Joystick gauche, erreur sur roller : LR2",
                "FI": "Vasen ohjain, virhe rullassa: LR2",
                "DK": "Venstre joystick, fejl på rulle: LR2",
                "NO": "Venstre styrespak, feil på rulle: LR2",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR2",
                "NL": "Linker joystick, fout roller: LR2",
                "CZ": "Levý joystick, chyba na válci: LR2",
                "PL": "Lewy uchwyt, error na rolka: LR2",
                "EE": "Vasak juhtkang, rulli viga: LR2",
                "PT": "Joystick esquerdo, erro no rolo: LR2",
                "ES": "Mando izquierdo, error de rótula LR2",
                "IT": "Joystick sinistro, errore sul selettore: LR2",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "603": {
                "GB": "Left joystick, error on roller: LR3",
                "SE": "Vänster joystick, rullfel: LR3",
                "DE": "Linker Joystick, Fehler an Rolle: LR3",
                "FR": "Joystick gauche, erreur sur roller : LR3",
                "FI": "Vasen ohjain, virhe rullassa: LR3",
                "DK": "Venstre joystick, fejl på rulle: LR3",
                "NO": "Venstre styrespak, feil på rulle: LR3",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR3",
                "NL": "Linker joystick, fout roller: LR3",
                "CZ": "Levý joystick, chyba na válci: LR3",
                "PL": "Lewy uchwyt, error na rolka: LR3",
                "EE": "Vasak juhtkang, rulli viga: LR3",
                "PT": "Joystick esquerdo, erro no rolo: LR3",
                "ES": "Mando izquierdo, error de rótula LR3",
                "IT": "Joystick sinistro, errore sul selettore: LR3",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "604": {
                "GB": "Left joystick, error on roller: LR4",
                "SE": "Vänster joystick, rullfel: LR4",
                "DE": "Linker Joystick, Fehler an Rolle: LR4",
                "FR": "Joystick gauche, erreur sur roller : LR4",
                "FI": "Vasen ohjain, virhe rullassa: LR4",
                "DK": "Venstre joystick, fejl på rulle: LR4",
                "NO": "Venstre styrespak, feil på rulle: LR4",
                "LV": "Kreisā vadības svira, ritenīša kļūda: LR4",
                "NL": "Linker joystick, fout roller: LR4",
                "CZ": "Levý joystick, chyba na válci: LR4",
                "PL": "Lewy uchwyt, error na rolka: LR4",
                "EE": "Vasak juhtkang, rulli viga: LR4",
                "PT": "Joystick esquerdo, erro no rolo: LR4",
                "ES": "Mando izquierdo, error de rótula LR4",
                "IT": "Joystick sinistro, errore sul selettore: LR4",
                "LT": "Kair. vairasvirtė, ratuko klaida: LR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "611": {
                "GB": "Left joystick, error on button: LS1",
                "SE": "Vänster joystick, knappfel: LS1",
                "DE": "Linker Joystick, Fehler an Taste: LS1",
                "FR": "Joystick gauche, erreur sur bouton : LS1",
                "FI": "Vasen ohjain, virhe painikkeessa: LS1",
                "DK": "Venstre joystick, fejl på knap: LS1",
                "NO": "Venstre styrespak, feil på knapp: LS1",
                "LV": "Kreisā vadības svira, pogas kļūda: LS1",
                "NL": "Linker joystick, fout op knop: LS1",
                "CZ": "Levý joystick, chyba na tlačítku: LS1",
                "PL": "Lewy uchwyt, error na przycisku: LS1",
                "EE": "Vasak juhtkang, viga nupul: LS1",
                "PT": "Joystick esquerdo, erro no botão: LS1",
                "ES": "Mando izquierdo, error de botón: LS1",
                "IT": "Joystick sinistro, errore sul pulsante: LS1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "612": {
                "GB": "Left joystick, error on button: LS2",
                "SE": "Vänster joystick, knappfel: LS2",
                "DE": "Linker Joystick, Fehler an Taste: LS2",
                "FR": "Joystick gauche, erreur sur bouton : LS2",
                "FI": "Vasen ohjain, virhe painikkeessa: LS2",
                "DK": "Venstre joystick, fejl på knap: LS2",
                "NO": "Venstre styrespak, feil på knapp: LS2",
                "LV": "Kreisā vadības svira, pogas kļūda: LS2",
                "NL": "Linker joystick, fout op knop: LS2",
                "CZ": "Levý joystick, chyba na tlačítku: LS2",
                "PL": "Lewy uchwyt, error na przycisku: LS2",
                "EE": "Vasak juhtkang, viga nupul: LS2",
                "PT": "Joystick esquerdo, erro no botão: LS2",
                "ES": "Mando izquierdo, error de botón: LS2",
                "IT": "Joystick sinistro, errore sul pulsante: LS2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "613": {
                "GB": "Left joystick, error on button: LS3",
                "SE": "Vänster joystick, knappfel: LS3",
                "DE": "Linker Joystick, Fehler an Taste: LS3",
                "FR": "Joystick gauche, erreur sur bouton : LS3",
                "FI": "Vasen ohjain, virhe painikkeessa: LS3",
                "DK": "Venstre joystick, fejl på knap: LS3",
                "NO": "Venstre styrespak, feil på knapp: LS3",
                "LV": "Kreisā vadības svira, pogas kļūda: LS3",
                "NL": "Linker joystick, fout op knop: LS3",
                "CZ": "Levý joystick, chyba na tlačítku: LS3",
                "PL": "Lewy uchwyt, error na przycisku: LS3",
                "EE": "Vasak juhtkang, viga nupul: LS3",
                "PT": "Joystick esquerdo, erro no botão: LS3",
                "ES": "Mando izquierdo, error de botón: LS3",
                "IT": "Joystick sinistro, errore sul pulsante: LS3",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "614": {
                "GB": "Left joystick, error on button: LS4",
                "SE": "Vänster joystick, knappfel: LS4",
                "DE": "Linker Joystick, Fehler an Taste: LS4",
                "FR": "Joystick gauche, erreur sur bouton : LS4",
                "FI": "Vasen ohjain, virhe painikkeessa: LS4",
                "DK": "Venstre joystick, fejl på knap: LS4",
                "NO": "Venstre styrespak, feil på knapp: LS4",
                "LV": "Kreisā vadības svira, pogas kļūda: LS4",
                "NL": "Linker joystick, fout op knop: LS4",
                "CZ": "Levý joystick, chyba na tlačítku: LS4",
                "PL": "Lewy uchwyt, error na przycisku: LS4",
                "EE": "Vasak juhtkang, viga nupul: LS4",
                "PT": "Joystick esquerdo, erro no botão: LS4",
                "ES": "Mando izquierdo, error de botón: LS4",
                "IT": "Joystick sinistro, errore sul pulsante: LS4",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "615": {
                "GB": "Left joystick, error on button: LS5",
                "SE": "Vänster joystick, knappfel: LS5",
                "DE": "Linker Joystick, Fehler an Taste: LS5",
                "FR": "Joystick gauche, erreur sur bouton : LS5",
                "FI": "Vasen ohjain, virhe painikkeessa: LS5",
                "DK": "Venstre joystick, fejl på knap: LS5",
                "NO": "Venstre styrespak, feil på knapp: LS5",
                "LV": "Kreisā vadības svira, pogas kļūda: LS5",
                "NL": "Linker joystick, fout op knop: LS5",
                "CZ": "Levý joystick, chyba na tlačítku: LS5",
                "PL": "Lewy uchwyt, error na przycisku: LS5",
                "EE": "Vasak juhtkang, viga nupul: LS5",
                "PT": "Joystick esquerdo, erro no botão: LS5",
                "ES": "Mando izquierdo, error de botón: LS5",
                "IT": "Joystick sinistro, errore sul pulsante: LS5",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "616": {
                "GB": "Left joystick, error on button: LS6",
                "SE": "Vänster joystick, knappfel: LS6",
                "DE": "Linker Joystick, Fehler an Taste: LS6",
                "FR": "Joystick gauche, erreur sur bouton : LS6",
                "FI": "Vasen ohjain, virhe painikkeessa: LS6",
                "DK": "Venstre joystick, fejl på knap: LS6",
                "NO": "Venstre styrespak, feil på knapp: LS6",
                "LV": "Kreisā vadības svira, pogas kļūda: LS6",
                "NL": "Linker joystick, fout op knop: LS6",
                "CZ": "Levý joystick, chyba na tlačítku: LS6",
                "PL": "Lewy uchwyt, error na przycisku: LS6",
                "EE": "Vasak juhtkang, viga nupul: LS6",
                "PT": "Joystick esquerdo, erro no botão: LS6",
                "ES": "Mando izquierdo, error de botón: LS6",
                "IT": "Joystick sinistro, errore sul pulsante: LS6",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "617": {
                "GB": "Left joystick, error on button: LS7",
                "SE": "Vänster joystick, knappfel: LS7",
                "DE": "Linker Joystick, Fehler an Taste: LS7",
                "FR": "Joystick gauche, erreur sur bouton : LS7",
                "FI": "Vasen ohjain, virhe painikkeessa: LS7",
                "DK": "Venstre joystick, fejl på knap: LS7",
                "NO": "Venstre styrespak, feil på knapp: LS7",
                "LV": "Kreisā vadības svira, pogas kļūda: LS7",
                "NL": "Linker joystick, fout op knop: LS7",
                "CZ": "Levý joystick, chyba na tlačítku: LS7",
                "PL": "Lewy uchwyt, error na przycisku: LS7",
                "EE": "Vasak juhtkang, viga nupul: LS7",
                "PT": "Joystick esquerdo, erro no botão: LS7",
                "ES": "Mando izquierdo, error de botón: LS7",
                "IT": "Joystick sinistro, errore sul pulsante: LS7",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "618": {
                "GB": "Left joystick, error on button: LS8",
                "SE": "Vänster joystick, knappfel: LS8",
                "DE": "Linker Joystick, Fehler an Taste: LS8",
                "FR": "Joystick gauche, erreur sur bouton : LS8",
                "FI": "Vasen ohjain, virhe painikkeessa: LS8",
                "DK": "Venstre joystick, fejl på knap: LS8",
                "NO": "Venstre styrespak, feil på knapp: LS8",
                "LV": "Kreisā vadības svira, pogas kļūda: LS8",
                "NL": "Linker joystick, fout op knop: LS8",
                "CZ": "Levý joystick, chyba na tlačítku: LS8",
                "PL": "Lewy uchwyt, error na przycisku: LS8",
                "EE": "Vasak juhtkang, viga nupul: LS8",
                "PT": "Joystick esquerdo, erro no botão: LS8",
                "ES": "Mando izquierdo, error de botón: LS8",
                "IT": "Joystick sinistro, errore sul pulsante: LS8",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "619": {
                "GB": "Left joystick, error on button: LFNR1",
                "SE": "Vänster joystick, knappfel: LFNR1",
                "DE": "Linker Joystick, Fehler an Taste: LFNR1",
                "FR": "Joystick gauche, erreur sur bouton : LFNR1",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR1",
                "DK": "Venstre joystick, fejl på knap: LFNR1",
                "NO": "Venstre styrespak, feil på knapp: LFNR1",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR1",
                "NL": "Linker joystick, fout op knop: LFNR1",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR1",
                "PL": "Lewy joystick, błąd na przycisku: LFNR1",
                "EE": "Vasak juhtkang, viga nupul: LFNR1",
                "PT": "Joystick esquerdo, erro no botão: LFNR1",
                "ES": "Mando izquierdo, error de botón: LFNR1",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR1",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "620": {
                "GB": "Left joystick, error on button: LFNR2",
                "SE": "Vänster joystick, knappfel: LFNR2",
                "DE": "Linker Joystick, Fehler an Taste: LFNR2",
                "FR": "Joystick gauche, erreur sur bouton : LFNR2",
                "FI": "Vasen ohjain, virhe painikkeessa: LFNR2",
                "DK": "Venstre joystick, fejl på knap: LFNR2",
                "NO": "Venstre styrespak, feil på knapp: LFNR2",
                "LV": "Kreisā vadības svira, pogas kļūda: LFNR2",
                "NL": "Linker joystick, fout op knop: LFNR2",
                "CZ": "Levý joystick, chyba na tlačítku: LFNR2",
                "PL": "Lewy uchwyt, error na przycisku: LFNR2",
                "EE": "Vasak juhtkang, viga nupul: LFNR2",
                "PT": "Joystick esquerdo, erro no botão: LFNR2",
                "ES": "Mando izquierdo, error de botón: LFNR2",
                "IT": "Joystick sinistro, errore sul pulsante: LFNR2",
                "LT": "Kair. vairasvirtė, klaida įjungimo mygtuke: LFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "631": {
                "GB": "Left joystick, CAN1 problem",
                "SE": "Vänster joystick, CAN1 problem",
                "DE": "Linker Joystick, CAN1-Problem",
                "FR": "Joystick gauche, problème CAN1",
                "FI": "Vasen ohjain, CAN1-ongelma",
                "DK": "Venstre joystick, CAN1-problem",
                "NO": "Venstre styrespak, CAN1-problem",
                "LV": "Kreisā vadības svira, CAN1 problēma",
                "NL": "Linker joystick, CAN1 probleem",
                "CZ": "Levý joystick, problém CAN1",
                "PL": "Lewy joystick, problem z CAN1",
                "EE": "Vasak juhtkang, CAN1 probleem",
                "PT": "Joystick esquerdo, problema CAN1",
                "ES": "Mando izquierdo, error de CAN1:",
                "IT": "Joystick sinistro, problema CAN1",
                "LT": "Kair. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "632": {
                "GB": "Left joystick, CRC error flash1",
                "SE": "Vänster joystick, CRC-fel flash1",
                "DE": "Linker Joystick, CRC-Fehler Blink1",
                "FR": "Joystick gauche, erreur CRC flash1",
                "FI": "Vasen ohjain, CRC-virhe valo1",
                "DK": "Venstre joystick, CRC-fejlblink1",
                "NO": "Venstre styrespak, CRC-feil flash1",
                "LV": "Kreisā vadības svira, CRC kļūda flash1",
                "NL": "Linker joystick, CRC fout flash1",
                "CZ": "Levý joystick, CRC chyba flash1",
                "PL": "Lewy uchwyt, CRC error flash1",
                "EE": "Vasak juhtkang, CRC viga flash1",
                "PT": "Joystick esquerdo, CRC erro flash1",
                "ES": "Mando izquierdo, error de CAN1, flash1",
                "IT": "Joystick sinistro, CRC errore flash1",
                "LT": "Kair. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "633": {
                "GB": "Left joystick, CAN2 problem",
                "SE": "Vänster joystick, CAN2 problem",
                "DE": "Linker Joystick, CAN2-Problem",
                "FR": "Joystick gauche, problème CAN2",
                "FI": "Vasen ohjain, CAN2-ongelma",
                "DK": "Venstre joystick, CAN2-problem",
                "NO": "Venstre styrespak, CAN2-problem",
                "LV": "Kreisā vadības svira, CAN2 problēma",
                "NL": "Linker joystick, CAN2 probleem",
                "CZ": "Levý joystick, problém CAN2",
                "PL": "Lewy uchwyt, CAN2 problem",
                "EE": "Vasak juhtkang, CAN2 probleem",
                "PT": "Joystick esquerdo, problema CAN2",
                "ES": "Mando izquierdo, error de CAN2",
                "IT": "Joystick sinistro, problema CAN2",
                "LT": "Kair. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "634": {
                "GB": "Left joystick, CRC error flash2",
                "SE": "Vänster joystick, CRC-fel flash2",
                "DE": "Linker Joystick, CRC-Fehler Blink2",
                "FR": "Joystick gauche, erreur CRC flash2",
                "FI": "Vasen ohjain, CRC-virhe valo2",
                "DK": "Venstre joystick, CRC-fejlblink2",
                "NO": "Venstre styrespak, CRC-feil flash2",
                "LV": "Kreisā vadības svira, CRC kļūda flash2",
                "NL": "Linker joystick, CRC fout flash2",
                "CZ": "Levý joystick, CRC chyba flash2",
                "PL": "Lewy uchwyt, CRC error flash2",
                "EE": "Vasak juhtkang, CRC viga flash2",
                "PT": "Joystick esquerdo, CRC erro flash2",
                "ES": "Mando izquierdo, error de CAN1, flash2",
                "IT": "Joystick sinistro, CRC errore flash2",
                "LT": "Kair. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "649": {
                "GB": "Left joystick, internal error",
                "SE": "Vänster joystick, internt fel",
                "DE": "Linker Joystick, interner Fehler",
                "FR": "Joystick gauche, erreur interne",
                "FI": "Vasen ohjain, sisäinen virhe",
                "DK": "Venstre joystick, intern fejl",
                "NO": "Venstre styrespak, intern feil",
                "LV": "Kr. vadības svira, iekšējās kļūda",
                "NL": "Linker joystick, interne fout",
                "CZ": "Levý joystick, interní chyba",
                "PL": "Lewy uchwyt, internal error",
                "EE": "Vasak juhtkang, sisemine viga",
                "PT": "Joystick esquerdo, erro interno",
                "ES": "Mando izquierdo, error interno",
                "IT": "Joystick sinistro, errore interno",
                "LT": "Kair. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "650": {
                "GB": "Right joystick, off-line",
                "SE": "Höger joystick, offline",
                "DE": "Rechter Joystick, offline",
                "FR": "Joystick droit, hors ligne",
                "FI": "Oikea ohjain, ei yhteyttä",
                "DK": "Højre joystick, offline",
                "NO": "Høyre styrespak, frakoblet",
                "LV": "La. vadības svira, off-line",
                "NL": "Rechter joystick, off-line",
                "CZ": "Pravý joystick, off-line",
                "PL": "Prawy uchwyt, offline",
                "EE": "Parem juhtkang, offline",
                "PT": "Joystick direito, offline",
                "ES": "Mando derecho, inactivado",
                "IT": "Joystick destro, offline",
                "LT": "Dešin. vairasvirtė neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "651": {
                "GB": "Right joystick, error on roller: RR1",
                "SE": "Höger joystick, rullfel: RR1",
                "DE": "Rechter Joystick, Fehler an Rolle: RR1",
                "FR": "Joystick droit, erreur sur roller : RR1",
                "FI": "Oikea ohjain, virhe rullassa: RR1",
                "DK": "Højre joystick, fejl på rulle: RR1",
                "NO": "Høyre styrespak, feil på rulle: RR1",
                "LV": "Labā vadības svira, ritenīša kļūda: RR1",
                "NL": "Rechter joystick, fout op roller: RR1",
                "CZ": "Pravý joystick, chyba na válci: RR1",
                "PL": "Prawy uchwyt, error na rolka: RR1",
                "EE": "Parem juhtkang, rulli viga: RR1",
                "PT": "Joystick direito, erro no rolo: RR1",
                "ES": "Mando derecho, error de rótula: RR1",
                "IT": "Joystick destro, errore sul selettore: RR1",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "652": {
                "GB": "Right joystick, error on roller: RR2",
                "SE": "Höger joystick, rullfel: RR2",
                "DE": "Rechter Joystick, Fehler an Rolle: RR2",
                "FR": "Joystick droit, erreur sur roller : RR2",
                "FI": "Oikea ohjain, virhe rullassa: RR2",
                "DK": "Højre joystick, fejl på rulle: RR2",
                "NO": "Høyre styrespak, feil på rulle: RR2",
                "LV": "Labā vadības svira, ritenīša kļūda: RR2",
                "NL": "Rechter joystick, fout op roller: RR2",
                "CZ": "Pravý joystick, chyba na válci: RR2",
                "PL": "Prawy uchwyt, error na rolka: RR2",
                "EE": "Parem juhtkang, rulli viga: RR2",
                "PT": "Joystick direito, erro no rolo: RR2",
                "ES": "Mando derecho, error de rótula: RR2",
                "IT": "Joystick destro, errore sul selettore: RR2",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "653": {
                "GB": "Right joystick, error on roller: RR3",
                "SE": "Höger joystick, rullfel: RR3",
                "DE": "Rechter Joystick, Fehler an Rolle: RR3",
                "FR": "Joystick droit, erreur sur roller : RR3",
                "FI": "Oikea ohjain, virhe rullassa: RR3",
                "DK": "Højre joystick, fejl på rulle: RR3",
                "NO": "Høyre styrespak, feil på rulle: RR3",
                "LV": "Labā vadības svira, ritenīša kļūda: RR3",
                "NL": "Rechter joystick, fout op roller: RR3",
                "CZ": "Pravý joystick, chyba na válci: RR3",
                "PL": "Prawy uchwyt, error na rolka: RR3",
                "EE": "Parem juhtkang, rulli viga: RR3",
                "PT": "Joystick direito, erro no rolo: RR3",
                "ES": "Mando derecho, error de rótula: RR3",
                "IT": "Joystick destro, errore sul selettore: RR3",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "654": {
                "GB": "Right joystick, error on roller: RR4",
                "SE": "Höger joystick, rullfel: RR4",
                "DE": "Rechter Joystick, Fehler an Rolle: RR4",
                "FR": "Joystick droit, erreur sur roller : RR4",
                "FI": "Oikea ohjain, virhe rullassa: RR4",
                "DK": "Højre joystick, fejl på rulle: RR4",
                "NO": "Høyre styrespak, feil på rulle: RR4",
                "LV": "Labā vadības svira, ritenīša kļūda: RR4",
                "NL": "Rechter joystick, fout op roller: RR4",
                "CZ": "Pravý joystick, chyba na válci: RR4",
                "PL": "Prawy uchwyt, error na rolka: RR4",
                "EE": "Parem juhtkang, rulli viga: RR4",
                "PT": "Joystick direito, erro no rolo: RR4",
                "ES": "Mando derecho, error de rótula: RR4",
                "IT": "Joystick destro, errore sul selettore: RR4",
                "LT": "Dešin. vairasvirtė, ratuko klaida: RR4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "661": {
                "GB": "Right joystick, error on button: RS1",
                "SE": "Höger joystick, knappfel: RS1",
                "DE": "Rechter Joystick, Fehler an Taste: RS1",
                "FR": "Joystick droit, erreur sur bouton : RS1",
                "FI": "Oikea ohjain, virhe painikkeessa: RS1",
                "DK": "Højre joystick, fejl på knap: RS1",
                "NO": "Høyre styrespak, feil på knapp: RS1",
                "LV": "Labā vadības svira, pogas kļūda: RS1",
                "NL": "Rechter joystick, fout op knop: RS1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS1",
                "PL": "Prawy uchwyt, error na Przycisk: RS1",
                "EE": "Parem juhtkang, viga nupul: RS1",
                "PT": "Joystick direito, erro no botão: RS1",
                "ES": "Mando derecho, error de botón: RS1",
                "IT": "Joystick destro, errore sul pulsante: RS1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "662": {
                "GB": "Right joystick, error on button: RS2",
                "SE": "Höger joystick, knappfel: RS2",
                "DE": "Rechter Joystick, Fehler an Taste: RS2",
                "FR": "Joystick droit, erreur sur bouton : RS2",
                "FI": "Oikea ohjain, virhe painikkeessa: RS2",
                "DK": "Højre joystick, fejl på knap: RS2",
                "NO": "Høyre styrespak, feil på knapp: RS2",
                "LV": "Labā vadības svira, pogas kļūda: RS2",
                "NL": "Rechter joystick, fout op knop: RS2",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS2",
                "PL": "Prawy uchwyt, error na Przycisk: RS2",
                "EE": "Parem juhtkang, viga nupul: RS2",
                "PT": "Joystick direito, erro no botão: RS2",
                "ES": "Mando derecho, error de botón: RS2",
                "IT": "Joystick destro, errore sul pulsante: RS2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "663": {
                "GB": "Right joystick, error on button: RS3",
                "SE": "Höger joystick, knappfel: RS3",
                "DE": "Rechter Joystick, Fehler an Taste: RS3",
                "FR": "Joystick droit, erreur sur bouton : RS3",
                "FI": "Oikea ohjain, virhe painikkeessa: RS3",
                "DK": "Højre joystick, fejl på knap: RS3",
                "NO": "Høyre styrespak, feil på knapp: RS3",
                "LV": "Labā vadības svira, pogas kļūda: RS3",
                "NL": "Rechter joystick, fout op knop: RS3",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS3",
                "PL": "Prawy uchwyt, error na Przycisk: RS3",
                "EE": "Parem juhtkang, viga nupul: RS3",
                "PT": "Joystick direito, erro no botão: RS3",
                "ES": "Mando derecho, error de botón: RS3",
                "IT": "Joystick destro, errore sul pulsante: RS3",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS3",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "664": {
                "GB": "Right joystick, error on button: RS4",
                "SE": "Höger joystick, knappfel: RS4",
                "DE": "Rechter Joystick, Fehler an Taste: RS4",
                "FR": "Joystick droit, erreur sur bouton : RS4",
                "FI": "Oikea ohjain, virhe painikkeessa: RS4",
                "DK": "Højre joystick, fejl på knap: RS4",
                "NO": "Høyre styrespak, feil på knapp: RS4",
                "LV": "Labā vadības svira, pogas kļūda: RS4",
                "NL": "Rechter joystick, fout op knop: RS4",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS4",
                "PL": "Prawy uchwyt, error na Przycisk: RS4",
                "EE": "Parem juhtkang, viga nupul: RS4",
                "PT": "Joystick direito, erro no botão: RS4",
                "ES": "Mando derecho, error de botón: RS4",
                "IT": "Joystick destro, errore sul pulsante: RS4",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS4",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "665": {
                "GB": "Right joystick, error on button: RS5",
                "SE": "Höger joystick, knappfel: RS5",
                "DE": "Rechter Joystick, Fehler an Taste: RS5",
                "FR": "Joystick droit, erreur sur bouton : RS5",
                "FI": "Oikea ohjain, virhe painikkeessa: RS5",
                "DK": "Højre joystick, fejl på knap: RS5",
                "NO": "Høyre styrespak, feil på knapp: RS5",
                "LV": "Labā vadības svira, pogas kļūda: RS5",
                "NL": "Rechter joystick, fout op knop: RS5",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS5",
                "PL": "Prawy uchwyt, error na Przycisk: RS5",
                "EE": "Parem juhtkang, viga nupul: RS5",
                "PT": "Joystick direito, erro no botão: RS5",
                "ES": "Mando derecho, error de botón: RS5",
                "IT": "Joystick destro, errore sul pulsante: RS5",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS5",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "666": {
                "GB": "Right joystick, error on button: RS6",
                "SE": "Höger joystick, knappfel: RS6",
                "DE": "Rechter Joystick, Fehler an Taste: RS6",
                "FR": "Joystick droit, erreur sur bouton : RS6",
                "FI": "Oikea ohjain, virhe painikkeessa: RS6",
                "DK": "Højre joystick, fejl på knap: RS6",
                "NO": "Høyre styrespak, feil på knapp: RS6",
                "LV": "Labā vadības svira, pogas kļūda: RS6",
                "NL": "Rechter joystick, fout op knop: RS6",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS6",
                "PL": "Prawy uchwyt, error na Przycisk: RS6",
                "EE": "Parem juhtkang, viga nupul: RS6",
                "PT": "Joystick direito, erro no botão: RS6",
                "ES": "Mando derecho, error de botón: RS6",
                "IT": "Joystick destro, errore sul pulsante: RS6",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS6",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "667": {
                "GB": "Right joystick, error on button: RS7",
                "SE": "Höger joystick, knappfel: RS7",
                "DE": "Rechter Joystick, Fehler an Taste: RS7",
                "FR": "Joystick droit, erreur sur bouton : RS7",
                "FI": "Oikea ohjain, virhe painikkeessa: RS7",
                "DK": "Højre joystick, fejl på knap: RS7",
                "NO": "Høyre styrespak, feil på knapp: RS7",
                "LV": "Labā vadības svira, pogas kļūda: RS7",
                "NL": "Rechter joystick, fout op knop: RS7",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS7",
                "PL": "Prawy uchwyt, error na Przycisk: RS7",
                "EE": "Parem juhtkang, viga nupul: RS7",
                "PT": "Joystick direito, erro no botão: RS7",
                "ES": "Mando derecho, error de botón: RS7",
                "IT": "Joystick destro, errore sul pulsante: RS7",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS7",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "668": {
                "GB": "Right joystick, error on button: RS8",
                "SE": "Höger joystick, knappfel: RS8",
                "DE": "Rechter Joystick, Fehler an Taste: RS8",
                "FR": "Joystick droit, erreur sur bouton : RS8",
                "FI": "Oikea ohjain, virhe painikkeessa: RS8",
                "DK": "Højre joystick, fejl på knap: RS8",
                "NO": "Høyre styrespak, feil på knapp: RS8",
                "LV": "Labā vadības svira, pogas kļūda: RS8",
                "NL": "Rechter joystick, fout op knop: RS8",
                "CZ": "Pravý  joystick, chyba na tlačítku: RS8",
                "PL": "Prawy uchwyt, error na Przycisk: RS8",
                "EE": "Parem juhtkang, viga nupul: RS8",
                "PT": "Joystick direito, erro no botão: RS8",
                "ES": "Mando derecho, error de botón: RS8",
                "IT": "Joystick destro, errore sul pulsante: RS8",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RS8",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "669": {
                "GB": "Right joystick, error on button: RFNR1",
                "SE": "Höger joystick, knappfel: RFNR1",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR1",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR1",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR1",
                "PT": "Joystick direito, erro no botão: RFNR1",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR1",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "670": {
                "GB": "Right joystick, error on button: RFNR2",
                "SE": "Höger joystick, knappfel: RFNR2",
                "DE": "Rechter Joystick, Fehler an Taste: RFNR1",
                "FR": "Joystick droit, erreur sur bouton : RFNR1",
                "FI": "Oikea ohjain, virhe painikkeessa: RFNR1",
                "DK": "Højre joystick, fejl på knap: RFNR1",
                "NO": "Høyre styrespak, feil på knapp: RFNR1",
                "LV": "Labā vadības svira, pogas kļūda: RFNR2",
                "NL": "Rechter joystick, fout op knop: RFNR1",
                "CZ": "Pravý  joystick, chyba na tlačítku: RFNR2",
                "PL": "Prawy uchwyt, error na Przycisk: RFNR1",
                "EE": "Parem juhtkang, viga nupul: RFNR2",
                "PT": "Joystick direito, erro no botão: RFNR2",
                "ES": "Mando derecho, error de botón: RFNR1",
                "IT": "Joystick destro, errore sul pulsante: RFNR2",
                "LT": "Dešin. vairasvirtė, klaida įjungimo mygtuke: RFNR2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "681": {
                "GB": "Right joystick, CAN1 problem",
                "SE": "Höger joystick, CAN1 problem",
                "DE": "Rechter Joystick, CAN1-Problem",
                "FR": "Joystick droit, problème CAN1",
                "FI": "Oikea ohjain, CAN1-ongelma",
                "DK": "Højre joystick, CAN1-problem",
                "NO": "Høyre styrespak, CAN1-problem",
                "LV": "La. vadības svira, CAN1 problēma",
                "NL": "Rechter joystick, CAN1 probleem",
                "CZ": "Pravý joystick, problém CAN1",
                "PL": "Prawy uchwyt, CAN1 problem",
                "EE": "Parem juhtkang, CAN1 probleem",
                "PT": "Joystick direito, problema de CAN1",
                "ES": "Mando derecho, error de CAN1",
                "IT": "Joystick destro, problema CAN1",
                "LT": "Dešin. vairasvirtė, CAN1 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "682": {
                "GB": "Right joystick, CRC error flash1",
                "SE": "Höger joystick, CRC-fel flash1",
                "DE": "Rechter Joystick, CRC-Fehler Blink1",
                "FR": "Joystick droit, erreur CRC flash1",
                "FI": "Oikea ohjain, CRC-virhe valo1",
                "DK": "Højre joystick, CRC-fejlblink1",
                "NO": "Høyre styrespak, CRC-feil flash1",
                "LV": "La. vadības svira, CRC kļūda flash1",
                "NL": "Rechter joystick, CRC fout flash1",
                "CZ": "Pravý joystick, CRC chyba flash1",
                "PL": "Prawy uchwyt, CRC error flash1",
                "EE": "Parem juhtkang, CRC viga flash1",
                "PT": "Joystick direito, CRC erro flash1",
                "ES": "Mando derecho, error de CAN1, flash1",
                "IT": "Joystick destro, CRC errore flash1",
                "LT": "Dešin. vairasvirtė, CRC klaida flash1",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "683": {
                "GB": "Right joystick, CAN2 problem",
                "SE": "Höger joystick, CAN2 problem",
                "DE": "Rechter Joystick, CAN2-Problem",
                "FR": "Joystick droit, problème CAN2",
                "FI": "Oikea ohjain, CAN2-ongelma",
                "DK": "Højre joystick, CAN2-problem",
                "NO": "Høyre styrespak, CAN2-problem",
                "LV": "La. vadības svira, CAN2 problēma",
                "NL": "Rechter joystick, CAN2 probleem",
                "CZ": "Pravý joystick, problém CAN2",
                "PL": "Prawy uchwyt, CAN2 problem",
                "EE": "Parem juhtkang, CAN2 probleem",
                "PT": "Joystick direito, problema de CAN2",
                "ES": "Mando derecho, error de CAN2",
                "IT": "Joystick destro, problema CAN2",
                "LT": "Dešin. vairasvirtė, CAN2 problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "684": {
                "GB": "Right joystick, CRC error flash2",
                "SE": "Höger joystick, CRC-fel flash2",
                "DE": "Rechter Joystick, CRC-Fehler Blink2",
                "FR": "Joystick droit, erreur CRC flash2",
                "FI": "Oikea ohjain, CRC-virhe valo2",
                "DK": "Højre joystick, CRC-fejlblink2",
                "NO": "Høyre styrespak, CRC-feil flash2",
                "LV": "La. vadības svira, CRC kļūda flash2",
                "NL": "Rechter joystick, CRC fout flash2",
                "CZ": "Pravý joystick, CRC chyba flash2",
                "PL": "Prawy uchwyt, CRC error flash2",
                "EE": "Parem juhtkang, CRC viga flash2",
                "PT": "Joystick direito, CRC erro flash2",
                "ES": "Mando derecho, error de CAN1, flash2",
                "IT": "Joystick destro, CRC errore flash2",
                "LT": "Dešin. vairasvirtė, CRC klaida flash2",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "699": {
                "GB": "Right joystick, internal error",
                "SE": "Höger joystick, internt fel",
                "DE": "Rechter Joystick, interner Fehler",
                "FR": "Joystick droit, erreur interne",
                "FI": "Oikea ohjain, sisäinen virhe",
                "DK": "Højre joystick, intern fejl",
                "NO": "Høyre styrespak, intern feil",
                "LV": "La. vadības svira, iekšējās kļūda",
                "NL": "Rechter joystick, interne fout",
                "CZ": "Pravý joystick, interní chyba",
                "PL": "Prawy uchwyt, internal error",
                "EE": "Parem juhtkang, sisemine viga",
                "PT": "Joystick direito, erro interno",
                "ES": "Mando derecho, error interno",
                "IT": "Joystick destro, errore interno",
                "LT": "Dešin. vairasvirtė, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "700": {
                "GB": "CAC1, off-line",
                "SE": "CAC1, offline",
                "DE": "CAC1, offline",
                "FR": "CAC1, hors ligne",
                "FI": "CAC1, ei yhteyttä",
                "DK": "CAC1, offline",
                "NO": "CAC1, frakoblet",
                "LV": "CAC1, off-line",
                "NL": "CAC1, off-line",
                "CZ": "CAC1, off-line",
                "PL": "CAC1, off-line",
                "EE": "CAC1, off-line",
                "PT": "CAC1, off-line",
                "ES": "CAC1, offline",
                "IT": "CAC1, off-line",
                "LT": "CAC1 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "701": {
                "GB": "CAC1, error on channel1",
                "SE": "CAC1, fel på kanal1",
                "DE": "CAC1, Fehler an Kanal1",
                "FR": "CAC1, erreur sur canal1",
                "FI": "CAC1, virhe kanavassa1",
                "DK": "CAC1, fejl på kanal1",
                "NO": "CAC1, feil på kanal1",
                "LV": "CAC1, kļūda 1. kanālā",
                "NL": "CAC1, fout op kanaal1",
                "CZ": "CAC1, chyba na kanálu1",
                "PL": "CAC1, błąd na kanale 1",
                "EE": "CAC1, kanal1 viga",
                "PT": "CAC1, erro no canal1",
                "ES": "CAC1, error en canal 1",
                "IT": "CAC1, errore sul canale1",
                "LT": "CAC1, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "702": {
                "GB": "CAC1, error on channel2",
                "SE": "CAC1, fel på kanal2",
                "DE": "CAC1, Fehler an Kanal2",
                "FR": "CAC1, erreur sur canal2",
                "FI": "CAC1, virhe kanavassa2",
                "DK": "CAC1, fejl på kanal2",
                "NO": "CAC1, feil på kanal2",
                "LV": "CAC1, kļūda 2. kanālā",
                "NL": "CAC1, fout op kanaal2",
                "CZ": "CAC1, chyba na kanálu2",
                "PL": "CAC1, error on channel2",
                "EE": "CAC1, kanal2 viga",
                "PT": "CAC1, erro no canal2",
                "ES": "CAC1, error en canal 2",
                "IT": "CAC1, errore sul canale2",
                "LT": "CAC1, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "703": {
                "GB": "CAC1, CAN-bus problem",
                "SE": "CAC1, CAN-bus problem",
                "DE": "CAC1, CAN-Bus-Problem",
                "FR": "CAC1, problème de bus CAN",
                "FI": "CAC1, CAN-väyläongelma",
                "DK": "CAC1, CAN-bus-problem",
                "NO": "CAC1, CAN-bussproblem",
                "LV": "CAC1, CAN kopnes problēma",
                "NL": "CAC1, CAN-bus probleem",
                "CZ": "CAC1, problém se sběrnicí CAN",
                "PL": "CAC1, problem z magistralą CAN",
                "EE": "CAC1, CAN-siini probleem",
                "PT": "CAC1, problema de barramento CAN",
                "ES": "CAC1, error de CAN bus",
                "IT": "CAC1, problema bus CAN",
                "LT": "CAC1, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "704": {
                "GB": "CAC1, internal error",
                "SE": "CAC1, internt fel",
                "DE": "CAC1, interner Fehler",
                "FR": "CAC1, erreur interne",
                "FI": "CAC1, sisäinen virhe",
                "DK": "CAC1, intern fejl",
                "NO": "CAC1, intern feil",
                "LV": "CAC1, iekšējās kļūda",
                "NL": "CAC1, interne fout",
                "CZ": "CAC1, interní chyba",
                "PL": "CAC1, internal error",
                "EE": "CAC1, sisemine viga",
                "PT": "CAC1, erro interno",
                "ES": "CAC1, error interno",
                "IT": "CAC1, Errore interno",
                "LT": "CAC1, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "705": {
                "GB": "CAC1, CAN-bus address conflict",
                "SE": "CAC1, CAN-bus adresskonflikt",
                "DE": "CAC1, Konflikt CAN-Bus-Adresse",
                "FR": "CAC1, conflit d’adresse de bus CAN",
                "FI": "CAC1, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC1, adressekonflikt for CAN-bus",
                "NO": "CAC1, CAN-buss adressekonflikt",
                "LV": "CAC1, CAN kopnes adrešu konflikts",
                "NL": "CAC1, CAN-bus adresconflict",
                "CZ": "CAC1, konflikt adres sběrnice CAN",
                "PL": "CAC1, konflikt adresów magistrali CAN",
                "EE": "CAC1, CAN-siin tegeleb konfliktiga",
                "PT": "CAC1, conflito de endereços barramento CAN",
                "ES": "CAC1, conflicto con dirección de CAN bus",
                "IT": "CAC1, conflitto indirizzi bus CAN",
                "LT": "CAC1, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "720": {
                "GB": "CAC2, off-line",
                "SE": "CAC2, offline",
                "DE": "CAC2, offline",
                "FR": "CAC2, hors ligne",
                "FI": "CAC2, ei yhteyttä",
                "DK": "CAC2, offline",
                "NO": "CAC2, frakoblet",
                "LV": "CAC2, offline",
                "NL": "CAC2, offline",
                "CZ": "CAC2, offline",
                "PL": "CAC2, offline",
                "EE": "CAC2, offline",
                "PT": "CAC2, offline",
                "ES": "CAC2, offline",
                "IT": "CAC2, offline",
                "LT": "CAC2 neprisijungus",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "721": {
                "GB": "CAC2, error on channel1",
                "SE": "CAC2, fel på kanal1",
                "DE": "CAC2, Fehler an Kanal1",
                "FR": "CAC2, erreur sur canal1",
                "FI": "CAC2, virhe kanavassa1",
                "DK": "CAC2, fejl på kanal1",
                "NO": "CAC2, feil på kanal1",
                "LV": "CAC2, kļūda 1. kanālā",
                "NL": "CAC2, fout op kanaal1",
                "CZ": "CAC2, chyba na kanálu1",
                "PL": "CAC2, error on channel1",
                "EE": "CAC2, kanal1 viga",
                "PT": "CAC2, erro no canal1",
                "ES": "CAC2, error en canal 1",
                "IT": "CAC2, errore sul canale1",
                "LT": "CAC2, klaida 2 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "722": {
                "GB": "CAC2, error on channel2",
                "SE": "CAC2, fel på kanal2",
                "DE": "CAC2, Fehler an Kanal2",
                "FR": "CAC2, erreur sur canal2",
                "FI": "CAC2, virhe kanavassa2",
                "DK": "CAC2, fejl på kanal2",
                "NO": "CAC2, feil på kanal2",
                "LV": "CAC2, kļūda 2. kanālā",
                "NL": "CAC2, fout op kanaal2",
                "CZ": "CAC2, chyba na kanálu2",
                "PL": "CAC2, error on channel2",
                "EE": "CAC2, kanal2 viga",
                "PT": "CAC2, erro no canal2",
                "ES": "CAC2, error en canal 2",
                "IT": "CAC2, errore sul canale2",
                "LT": "CAC2, klaida 1 kanale",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "723": {
                "GB": "CAC2, CAN-bus problem",
                "SE": "CAC2, CAN-bus problem",
                "DE": "CAC2, CAN-Bus-Problem",
                "FR": "CAC2, problème de bus CAN",
                "FI": "CAC2, CAN-väyläongelma",
                "DK": "CAC2, CAN-bus-problem",
                "NO": "CAC2, CAN-bussproblem",
                "LV": "CAC2, CAN kopnes problēma",
                "NL": "CAC2, CAN-bus probleem",
                "CZ": "CAC2, problém se sběrnicí CAN",
                "PL": "CAC2, CAN-bus problem",
                "EE": "CAC2, CAN-siini probleem",
                "PT": "CAC2, problema de barramento CAN",
                "ES": "CAC2, problema con el CAN bus",
                "IT": "CAC2, problema bus CAN",
                "LT": "CAC2, CAN magistralės problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "724": {
                "GB": "CAC2, internal error",
                "SE": "CAC2, internt fel",
                "DE": "CAC2, interner Fehler",
                "FR": "CAC2, erreur interne",
                "FI": "CAC2, sisäinen virhe",
                "DK": "CAC2, intern fejl",
                "NO": "CAC2, intern feil",
                "LV": "CAC2, iekšējās kļūda",
                "NL": "CAC2, interne fout",
                "CZ": "CAC2, interní chyba",
                "PL": "CAC2, internal error",
                "EE": "CAC2, sisemine viga",
                "PT": "CAC2, erro interno",
                "ES": "CAC2, error interno",
                "IT": "CAC2, Errore interno",
                "LT": "CAC2, vidinė klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "725": {
                "GB": "CAC2, CAN-bus address conflict",
                "SE": "CAC2, CAN-bus adresskonflikt",
                "DE": "CAC2, Konflikt CAN-Bus-Adresse",
                "FR": "CAC2, conflit d’adresse de bus CAN",
                "FI": "CAC2, CAN-väylän osoitteessa ristiriita",
                "DK": "CAC2, adressekonflikt for CAN-bus",
                "NO": "CAC2, CAN-buss adressekonflikt",
                "LV": "CAC2, CAN kopnes adrešu konflikts",
                "NL": "CAC2, CAN-bus adresconflict",
                "CZ": "CAC2, konflikt adres sběrnice CAN",
                "PL": "CAC2, CAN-bus address conflict",
                "EE": "CAC2, CAN-siin tegeleb konfliktiga",
                "PT": "CAC2, conflito de endereços barramento CAN",
                "ES": "CAC2, conflicto con dirección de CAN bus",
                "IT": "CAC2, conflitto indirizzi bus CAN",
                "LT": "CAC2, CAN magistralės adreso konfliktas",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "801": {
                "GB": "HW FAIL I2C EEPROM FAILED",
                "SE": "HW FAIL I2C EEPROM FAILED",
                "DE": "HW FEHLER I2C EEPROM FEHLER",
                "FR": "DÉFAIL. MAT. ÉCHEC EEPROM I2C",
                "FI": "HW FAIL I2C EEPROM FAILED",
                "DK": "HW-FEJL, I2C EEPROM MISLYKKEDES",
                "NO": "HW FEIL I2C EEPROM FEIL",
                "LV": "HW Neizdevās I2C EEPROM FAILED",
                "NL": "HW FOUT I2C EEPROM MISLUKT",
                "CZ": "HW FAIL I2C EEPROM FAILED",
                "PL": "HW FAIL I2C EEPROM FAILED",
                "EE": "HW FAIL I2C EEPROM FAILED",
                "PT": "HW FALHAR I2C EEPROM FAILED",
                "ES": "HW FAIL I2C EEPROM AVERIADO",
                "IT": "HW FAIL I2C EEPROM FAILED",
                "LT": "HW FAIL I2C EEPROM FAILED",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "802": {
                "GB": "HW SW FAIL EEPROM PARAM",
                "SE": "HW SW FAIL EEPROM PARAM",
                "DE": "HW SW FEHLER EEPROM PARAMETER",
                "FR": "DÉFAIL. LOG. MAT. PARAM. EEPROM",
                "FI": "HW SW FAIL EEPROM PARAM",
                "DK": "HW SW-FEJL, EEPROM-PARAM",
                "NO": "HW SW FEIL EEPROM PARAM",
                "LV": "HW SW Neizdevās EEPROM PARAM",
                "NL": "HW SW FOUT EEPROM PARAM",
                "CZ": "HW SW FAIL EEPROM PARAM",
                "PL": "HW SW FAIL EEPROM PARAM",
                "EE": "HW SW FAIL EEPROM PARAM",
                "PT": "HW SW FALHAR EEPROM PARAM",
                "ES": "HW SW FAIL EEPROM PARAM",
                "IT": "HW SW FAIL EEPROM PARAM",
                "LT": "HW SW FAIL EEPROM PARAM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "803": {
                "GB": "HW SW FAIL EEPROM DATA",
                "SE": "HW SW FAIL EEPROM DATA",
                "DE": "HW SW FEHLER EEPROM DATEN",
                "FR": "DÉFAIL. LOG. MAT. DONNÉES EEPROM",
                "FI": "HW SW FAIL EEPROM DATA",
                "DK": "HW SW-FEJL, EEPROM-DATA",
                "NO": "HW SW FEIL EEPROM DATA",
                "LV": "HW SW Neizdevās EEPROM DATA",
                "NL": "HW SW FOUT EEPROM DATA",
                "CZ": "HW SW FAIL EEPROM DATA",
                "PL": "HW SW FAIL EEPROM DATA",
                "EE": "HW SW FAIL EEPROM DATA",
                "PT": "HW SW FALHAR EEPROM DATA",
                "ES": "HW SW FAIL EEPROM DATA",
                "IT": "HW SW FAIL EEPROM DATA",
                "LT": "HW SW FAIL EEPROM DATA",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "804": {
                "GB": "HW SW FAIL I2C ANGLE EEPROM",
                "SE": "HW SW FAIL I2C ANGLE EEPROM",
                "DE": "HW SW FEHLER I2C WINKEL EEPROM",
                "FR": "DÉFAIL. LOG. MAT. EEPROM ANGLE I2C",
                "FI": "HW SW FAIL I2C ANGLE EEPROM",
                "DK": "HW SW-FEJL, I2C-VINKEL, EEPROM",
                "NO": "HW SW FEIL I2C VINKEL EEPROM",
                "LV": "HW SW Neizdevās I2C ANGLE EEPROM",
                "NL": "HW SW FOUT I2C HOEK EEPROM",
                "CZ": "HW SW FAIL I2C ANGLE EEPROM",
                "PL": "HW SW FAIL I2C ANGLE EEPROM",
                "EE": "HW SW FAIL I2C ANGLE EEPROM",
                "PT": "HW SW FALHAR I2C ANGLE EEPROM",
                "ES": "HW SW FAIL I2C ÀNGULO EEPROM",
                "IT": "HW SW FAIL I2C ANGLE EEPROM",
                "LT": "HW SW FAIL I2C ANGLE EEPROM",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "880": {
                "GB": "HW SW FAIL TE GENERAL",
                "SE": "HW SW FAIL TE GENERAL",
                "DE": "HW SW FEHLER TE ALLGEMEIN",
                "FR": "DÉFAIL. LOG. MAT. GÉNÉRAL TE",
                "FI": "HW SW FAIL TE GENERAL",
                "DK": "HW SW-FEJL, TE GENERELT",
                "NO": "HW SW FEIL TE GENERELT",
                "LV": "HW SW Neizdevās TE GENERAL",
                "NL": "HW SW FOUT TE ALGEMEEN",
                "CZ": "HW SW FAIL TE GENERAL",
                "PL": "HW SW FAIL TE GENERAL",
                "EE": "HW SW FAIL TE GENERAL",
                "PT": "HW SW FALHAR TE GENERAL",
                "ES": "HW SW FAIL TE GENERAL",
                "IT": "HW SW FAIL TE GENERAL",
                "LT": "HW SW FAIL TE GENERAL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "881": {
                "GB": "HW FAIL FS4500 ERROR",
                "SE": "HW-fel, FS4500 AD SUPL",
                "DE": "HW FEHLER FS4500 FEHLER",
                "FR": "DÉFAIL. MAT. ERREUR FS4500",
                "FI": "HW FAIL FS4500 ERROR",
                "DK": "HW-FEJL, FS4500-FEJL",
                "NO": "HW FEIL FS4500 FEIL",
                "LV": "HW Neizdevās FS4500 kļūda",
                "NL": "HW FOUT FS4500 FOUT",
                "CZ": "HW FAIL FS4500 ERROR",
                "PL": "HW FAIL FS4500 ERROR",
                "EE": "HW FAIL FS4500 ERROR",
                "PT": "HW FALHAR FS4500 ERROR",
                "ES": "HW FAIL FS4500 ERROR",
                "IT": "HW FAIL FS4500 ERROR",
                "LT": "HW FAIL FS4500 ERROR",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "882": {
                "GB": "HW FAIL FS4500 AD SUPL",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS VERSORGUNG",
                "FR": "DÉFAIL. MAT. ALIM. FS4500 AD",
                "FI": "HW FAIL FS4500 AD SUPL",
                "DK": "HW-FEJL, FS4500 AD SUPL",
                "NO": "HW FEIL FS4500 AD FORS",
                "LV": "HW Neizdevās FS4500 AD SUPL",
                "NL": "HW FOUT FS4500 BIJ SUPL",
                "CZ": "HW FAIL FS4500 AD SUPL",
                "PL": "HW FAIL FS4500 AD SUPL",
                "EE": "HW FAIL FS4500 AD SUPL",
                "PT": "HW FALHAR FS4500 AD SUPL",
                "ES": "HW FAIL FS4500 AD SUPL",
                "IT": "HW FAIL FS4500 AD SUPL",
                "LT": "HW FAIL FS4500 AD SUPL",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "883": {
                "GB": "HW FAIL FS4500 AD MUX",
                "SE": "HW FAIL FS4500 AD MUX",
                "DE": "HW FEHLER FS4500 ZUS MUX",
                "FR": "DÉFAIL. MAT. MUX FS4500 AD",
                "FI": "HW FAIL FS4500 AD MUX",
                "DK": "HW-FEJL, FS4500 AD MUX",
                "NO": "HW FEIL FS4500 AD MUX",
                "LV": "HW Neizdevās FS4500 AD MUX",
                "NL": "HW FOUT FS4500 BIJ MUX",
                "CZ": "HW FAIL FS4500 AD MUX",
                "PL": "HW FAIL FS4500 AD MUX",
                "EE": "HW FAIL FS4500 AD MUX",
                "PT": "HW FALHAR FS4500 AD MUX",
                "ES": "HW FAIL FS4500 AD MUX",
                "IT": "HW FAIL FS4500 AD MUX",
                "LT": "HW FAIL FS4500 AD MUX",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "884": {
                "GB": "HW SW FAIL TE DATA INTEGRITY",
                "SE": "HW SW FAIL TE DATA INTEGRITY",
                "DE": "HW SW FEHLER TE DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. MAT. INTÉGRITÉ DONNÉES TE",
                "FI": "HW SW FAIL TE DATA INTEGRITY",
                "DK": "HW SW-FEJL, TE-DATAINTEGRITET",
                "NO": "HW SW FEIL TE DATAINTEGRITET",
                "LV": "HW SW Neizdevās TE DATA INTEGRITY",
                "NL": "HW SW FOUT TE DATA-INTEGRITEIT",
                "CZ": "HW SW FAIL TE DATA INTEGRITY",
                "PL": "HW SW FAIL TE DATA INTEGRITY",
                "EE": "HW SW FAIL TE DATA INTEGRITY",
                "PT": "HW SW FALHAR TE DATA INTEGRITY",
                "ES": "HW SW FAIL TE DATA INTEGRITY",
                "IT": "HW SW FAIL TE DATA INTEGRITY",
                "LT": "HW SW FAIL TE DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "885": {
                "GB": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "SE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DE": "SW FEHLER SICHERHEITSFUNKT DATENINTEGRITÄT",
                "FR": "DÉFAIL. LOG. INTÉGRITÉ DONNÉES FONC. SÉCU.",
                "FI": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "DK": "SW-FEJL, SIKKERHEDSFUNK, DATAINTEGRITET",
                "NO": "SW FEIL SIKKERHETSFUNK DATAINTEGRITET",
                "LV": "SW Neizdevās SAFETY FUNC DATA INTEGRITY",
                "NL": "SW FOUT VEILIGHEID FUNC DATA INTEGRITEIT",
                "CZ": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PL": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "EE": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "PT": "SW FALHA SAFETY FUNC DATA INTEGRITY",
                "ES": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "IT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "LT": "SW FAIL SAFETY FUNC DATA INTEGRITY",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "891": {
                "GB": "J1939-76, SDG-diagnostic from CCU",
                "SE": "J1939-76, SDG-diagnostic from CCU",
                "DE": "J1939-76, SDG-Diagnose von CCU",
                "FR": "J1939-76, diagnostic SDG de CCU",
                "FI": "J1939-76, SDG-diagnostiikka CCU:sta",
                "DK": "J1939-76, SDG-diagnostik fra CCU",
                "NO": "J1939-76, SDG-diagnose fra CCU",
                "LV": "J1939-76, SDG-diagnostika no CCU",
                "NL": "J1939-76, SDG-diagnostiek van CCU",
                "CZ": "J1939-76, SDG-diagnostika z CCU",
                "PL": "J1939-76, SDG-diagnostic from CCU",
                "EE": "J1939-76, SDG-diagnostika CCU-st",
                "PT": "J1939-76, Diagnóstico SDG da CCU",
                "ES": "J1939-76, SDG-diagnóstico del CCU",
                "IT": "J1939-76, SDG-diagnostica da CCU",
                "LT": "J1939-76, SDG diagnostika iš CCU",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "892": {
                "GB": "J1939-76, SDG-diagnostic from HMI",
                "SE": "J1939-76, SDG-diagnostic from HMI",
                "DE": "J1939-76, SDG-Diagnose von HMI",
                "FR": "J1939-76, diagnostic SDG d’IHM",
                "FI": "J1939-76, SDG-diagnostiikka HMI:stä",
                "DK": "J1939-76, SDG-diagnostik fra HMI",
                "NO": "J1939-76, SDG-diagnose fra HMI",
                "LV": "J1939-76, SDG-diagnostika no HMI",
                "NL": "J1939-76, SDG-diagnostiek van HMI",
                "CZ": "J1939-76, SDG-diagnostika z HMI",
                "PL": "J1939-76, SDG-diagnostic from HMI",
                "EE": "J1939-76, SDG-diagnostika HMI-st",
                "PT": "J1939-76, Diagnóstico SDG da HMI",
                "ES": "J1939-76, SDG-diagnóstico del HMI",
                "IT": "J1939-76, SDG-diagnostica da HMI",
                "LT": "J1939-76, SDG diagnostika iš HMI",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "901": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "902": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "903": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "904": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "905": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "906": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "907": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "908": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "909": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "910": {
                "GB": "TE error detected",
                "SE": "TE fel detekterat",
                "DE": "TE-Fehler erkannt",
                "FR": "Erreur TE détectée",
                "FI": "TE-virhe havaittu",
                "DK": "TE-fejl registreret",
                "NO": "TE-feil registrert",
                "LV": "Konstatēta TE kļūda",
                "NL": "TE-fout gedetecteerd",
                "CZ": "Zjištěna TE chyba",
                "PL": "TE error detected",
                "EE": "Tuvastati TE error",
                "PT": "Detetadas TE erro",
                "ES": "Error de TE detectado",
                "IT": "Errore TE rilevato",
                "LT": "Aptikta TE klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "921": {
                "GB": "Faulty parameter settings detected",
                "SE": "Fel på parameterinställningar detekterad",
                "DE": "Fehlerhafte Parametereinstellungen erkannt",
                "FR": "Paramètres erronés détectés",
                "FI": "Virheellisiä parametriasetuksia havaittu",
                "DK": "Fejl registreret i parameterindstillinger",
                "NO": "Feil parameterinnstilling registrert",
                "LV": "Konstatēti kļūdaini parametru iestatījumi",
                "NL": "Foutieve parameterinstellingen gedetecteerd",
                "CZ": "Bylo zjištěno chybné nastavení parametrů",
                "PL": "Wykryto błędne ustawienia parametrów",
                "EE": "Tuvastati vigased parameetriseaded",
                "PT": "Detetadas definições de parâmetros incorretas",
                "ES": "Detectado: Configuración de parámetros defectuosa",
                "IT": "Rilevate impostazioni di parametro errate",
                "LT": "Aptikti klaidingi parametrų nustatymai",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "922": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "923": {
                "GB": "Logging error detected",
                "SE": "Loggningsfel detekterat",
                "DE": "Protokollierungsfehler erkannt",
                "FR": "Erreur de journalisation détectée",
                "FI": "Kirjautumisvirhe havaittu",
                "DK": "Logfejl registreret",
                "NO": "Loggefeil registrert",
                "LV": "Konstatēta reģistrēšanas kļūda",
                "NL": "Logboekfout gedetecteerd",
                "CZ": "Byla zjištěna chyba protokolování",
                "PL": "Wykryto błąd zapisu danych",
                "EE": "Tuvastati logimisviga",
                "PT": "Erro de registo detetado",
                "ES": "Error detectado, registro",
                "IT": "Rilevato errore di registrazione",
                "LT": "Aptikta registravimo klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "931": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "932": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "933": {
                "GB": "External software error detected",
                "SE": "Externt mjukvarufel detekterat",
                "DE": "Externer Softwarefehler erkannt",
                "FR": "Erreur logicielle externe détectée",
                "FI": "Ulkoinen ohjelmistovirhe havaittu",
                "DK": "Ekstern softwarefejl registreret",
                "NO": "Ekstern programvarefeil registrert",
                "LV": "Konstatēta ārējā programmatūras kļūda",
                "NL": "Externe softwarefout gedetecteerd",
                "CZ": "Zjištěna externí chyba SW",
                "PL": "Wykryto błąd SW zewnętrznego",
                "EE": "Tuvastati välise tarkvara viga",
                "PT": "Detetada erro de software externa",
                "ES": "Detectado: error externo del software",
                "IT": "Errore software esterna rilevata",
                "LT": "Aptikta išorinės pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "941": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "942": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "943": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "944": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "945": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "946": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "947": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "948": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "949": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "950": {
                "GB": "Internal software error detected",
                "SE": "Internt mjukvarufel detekterat",
                "DE": "Interner Softwarefehler erkannt",
                "FR": "Erreur logicielle interne détectée",
                "FI": "Sisäinen ohjelmistovirhe havaittu",
                "DK": "Intern softwarefejl registreret",
                "NO": "Intern programvarefeil registrert",
                "LV": "Konstatēta iekšējās programmatūras kļūda",
                "NL": "Interne softwarefout gedetecteerd",
                "CZ": "Zjištěna interní chyba softwaru",
                "PL": "Wykryto wewnętrzny błąd oprogramowania",
                "EE": "Tuvastati sisemine tarkvaratõrge",
                "PT": "Detetado erro interno de software",
                "ES": "Detectado: error interno del software",
                "IT": "Errore software interno rilevato",
                "LT": "Aptikta vidinė programinės įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "961": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "962": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "963": {
                "GB": "Foreign voltage detected on IO-pin",
                "SE": "Främmande spänning detekterad på IO-kontaktpinne",
                "DE": "Fremdspannung am IO-Pin erkannt",
                "FR": "Tension étrangère détectée sur broche ES",
                "FI": "Ulkoinen jännite havaittu IO-navassa",
                "DK": "Spænding udefra registreret på IO-ben",
                "NO": "Ukjent spenning registrert på IO-stift",
                "LV": "Uz ievadizvades tapiņas konstatēts svešspriegums",
                "NL": "Externe spanning gedetecteerd op IO-pin",
                "CZ": "Na kolíku I/O detekováno cizí napětí",
                "PL": "Wykryto obce napięcie na pinie IO",
                "EE": "Tuvastati IO-tihvti võõrpinge",
                "PT": "Tensão desconhecida detetada no pino IO",
                "ES": "Voltage externo detectado en IO-pin",
                "IT": "Tensione esterna rilevata su perno IO",
                "LT": "IO kontakte aptikta išorinė įtampa",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "971": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "972": {
                "GB": "OEM interface problem",
                "SE": "OEM interface problem",
                "DE": "OEM-Schnittstellenproblem",
                "FR": "Problème d’interface OEM",
                "FI": "OEM-rajapintaongelma",
                "DK": "Problem i OEM-grænseflade",
                "NO": "Problem med OEM-grensesnitt",
                "LV": "OEM interfeisa problēma",
                "NL": "OEM-interface probleem",
                "CZ": "Problém s rozhraním OEM",
                "PL": "Problem z interfejsem OEM",
                "EE": "OEM-liidese probleem",
                "PT": "Problema de interface OEM",
                "ES": "OEM interface problem",
                "IT": "Problema interfaccia OEM",
                "LT": "OEM sąsajos problema",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "981": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "982": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "983": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "984": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "985": {
                "GB": "Critical software error detected",
                "SE": "Kritiskt mjukvarufel detekterat",
                "DE": "Kritischer Softwarefehler erkannt",
                "FR": "Erreur logicielle critique détectée",
                "FI": "Kriittinen ohjelmistovirhe havaittu",
                "DK": "Kritisk softwarefejl registreret",
                "NO": "Kritisk programvarefeil registrert",
                "LV": "Konstatēta kritiska programmat. kļūda",
                "NL": "Kritieke softwarefout gedetecteerd",
                "CZ": "Zjištěna kritická SW chyba",
                "PL": "Wykryto krytyczny błąd SW",
                "EE": "Tuvastati kriitiline tarkvaraviga",
                "PT": "Detetada erro crítica de software",
                "ES": "Detectado: error grave del software",
                "IT": "Errore software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos klaida",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "991": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "992": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "993": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "994": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "995": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "996": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            },
            "997": {
                "GB": "Critical software exception detected",
                "SE": "Kritisk mjukvaru-exception detekterat",
                "DE": "Kritische Softwareausnahme erkannt",
                "FR": "Exception logicielle critique détectée",
                "FI": "Kriittinen ohjelmistopoikkeama havaittu",
                "DK": "Kritisk softwareundtagelse registreret",
                "NO": "Kritisk programvareunntak registrert",
                "LV": "Konstatēts kritisks programmat. izņēmums",
                "NL": "Kritieke software-uitzondering gedetecteerd",
                "CZ": "Zjištěna výjimka kritického softwaru",
                "PL": "Wykryto krytyczny wyjątek oprogramowania",
                "EE": "Tuvastati tarkvara oluline erand",
                "PT": "Detetada exceção crítica de software",
                "ES": "Detectado: exepción grave del software",
                "IT": "Eccezione software critica rilevata",
                "LT": "Aptikta kritinė pr. įrangos išimtis",
                "KR": "",
                "JP": "",
                "IS": ""
            }
        }
    }
}