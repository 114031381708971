import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { IApp } from '@interfaces/app.interface';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app', resettable: true })
export class AppStore extends Store<IApp> {
  constructor() {
    super({});
  }
}
