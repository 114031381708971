import { Injectable } from '@angular/core';

import { Query } from '@datorama/akita';
import { IDefinition, IDefinitionState } from '@interfaces/definition.interface';

import { DefinitionsStore } from './definitions.store';

@Injectable({ providedIn: 'root' })
export class DefinitionsQuery extends Query<IDefinitionState> {
  constructor(protected store: DefinitionsStore) {
    super(store);
  }

  unitConfigs$ = this.select((state) => state.unitConfigs);

  public latestFile(ecuId: number, compVersion: number): IDefinition {
    
    const files = this.store.getValue().unitConfigs.filter((x) => x.ecuId === ecuId && x.compVersion === compVersion);
    
    if (files.length > 0) {
      return files.reduce((a, b) => (a.buildVersion > b.buildVersion ? a : b));
    } else {
      return null;
    }
  }

  public compatibleFile(ecuId: number, buildNumber: number, compVersion: number): IDefinition {
    const file = this.store.getValue().unitConfigs.find((x) => x.ecuId === ecuId && x.buildNumber === buildNumber);
    if (file) {
      return file
    } else {
      return this.latestFile(ecuId, compVersion);
    }
  }

 
  public file(ecuId: number, compVersion: number): IDefinition {
    return this.store.getValue().unitConfigs.find((x) => x.ecuId === ecuId && x.compVersion === compVersion);
  }

  public fileAsObservable(ecuId: number, compVersion: number) {
    return this.select((state) => state.unitConfigs.find((x) => x.ecuId === ecuId && x.compVersion === compVersion));
  }
}
