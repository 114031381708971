export const WORKER_TOPIC = {
  connectionRequestChanged: 'connectionRequestChanged',
  clearExistingAlarms: 'clearExistingAlarms',
  clearWorkerState: 'clearWorkerState',
  removePeriodicAlarm: 'removePeriodicAlarm',
  handlePendingAlarmLog: 'handlePendingAlarmLog',
  handleClearAlarmLog: 'handleClearAlarmLog',
  stopAutoCalibrate: 'stopAutoCalibration',
  autoCalibrate: 'autoCalibrate',
  addDefinition: 'addDefinition',
  setupSubscriptions: 'setupSubscriptions',
  stopSubscriptions: 'stopSubscriptions',
  apiConnect: 'apiConnect',
  handleResult: 'handleResult',
  test: 'test',
  readParameters: 'readParameters',
  readStringParameter: 'readStringParameter',
  writeParameter: 'writeParameter',
  writeStringParameter: 'writeStringParameter',
  sendToDevice: 'sendToDevice',
  fetchDefinition: 'fetchDefinition',
  parameter: 'parameter',
  stringParameter: 'stringParameter',
  periodic: 'periodic',
  periodicAlarm: 'periodicAlarm',
  sendFirmware: 'sendFirmware',
  runFirmwareUpgrade: 'runFirmwareUpgrade',
  fwStatus: 'fwStatus',
  sendFirmareUpdate: 'sendFirmareUpdate',

  // BluetoothLE
  isEnabled: 'isEnabled',
  hasPermission: 'hasPermission',
  requestPermission: 'requestPermission',
  isLocationEnabled: 'isLocationEnabled',
  requestLocation: 'requestLocation',
  startScan: 'startScan',
  isScanning: 'isScanning',
  stopScan: 'stopScan',
  subscribe: 'subscribe',
  unsubscribe: 'unsubscribe',
  initialize: 'initialize',
  connect: 'connect',
  close: 'close',
  mtu: 'mtu',
  isConnected: 'isConnected',
  discover: 'discover',
  write: 'write',
  services: 'services',
  characteristics: 'characteristics',
  descriptors: 'descriptors',
  requestConnectionPriority: 'requestConnectionPriority',
  connectToDevice: 'connectToDevice',
  startSubscribeConnection: 'startSubscribeConnection',
  runDiscover: 'runDiscover',

  // BluetoothLE to app
  scanResult: 'scanResult',

  // Alarm log
  getAlarmLog: 'getAlarmLog',
  recieveAlarmLog: 'recieveAlarmLog',
  clearAlarmLog: 'clearAlarmLog',
  alarmlogCleared: 'alarmlogCleared',
  changeFunction: 'changeFunction',
  clearAlarm: 'clearAlarm',
  alarmCleared: 'alarmCleared',
  ecuDisconnect: 'ecuDisconnect',
};
