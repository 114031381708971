import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { IRemoteUser } from '@interfaces/remote-user.interface';

function createInitialRemoteUserState(): IRemoteUser {
  return {
    isActive: false,
    isOffline: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'remoteUser', resettable: true })
export class RemoteUserStore extends Store<IRemoteUser> {
  constructor() {
    super(createInitialRemoteUserState());
  }
}
