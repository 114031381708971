import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { arrayAdd, arrayRemove, arrayUpdate, arrayUpsert } from '@datorama/akita';
// import { doc, getFirestore, updateDoc } from "@firebase/firestore"

import { IAlarm } from '@interfaces/alarm.interface';
import { IAlarmLog } from '@interfaces/alarm-log.interface';
import { WorkerMessage } from '@shared/worker/models/worker-message.model';
import { WORKER_TOPIC } from '@shared/worker/models/worker-topic.constants';
import { WorkerService } from '@shared/worker/worker.service';

import { CONFIG } from '@shared/constants/config';
import { DeviceQuery } from '../device/device.query';

import { AlarmQuery } from './alarm.query';
import { AlarmStore } from './alarm.store';
import { first, map, skipWhile, take, takeUntil, takeWhile } from 'rxjs/operators';
import { AckAlarmResponseEvent, AlarmlogChangedEvent, ClearAlarmResponseEvent } from 'connectapi/src/ApiMessage/apiResponseTypes';
import { UserQuery } from '../user/user.query';
import { IRequest } from '@shared/interfaces/request.interface';
import { alarms } from '@shared/constants/mock';
import { RemoteSupportService } from '@shared/services/remote-support.service';
import { timer } from 'rxjs';
import { RemoteUserQuery } from '../remote-user/remote-user.query';
import { ToastService } from '@shared/services/toast.service';
import { ConnectApiService } from '@shared/services/connectapi.service';
import { stat } from 'fs';

//import * as firebase from 'firebase';

import { Config } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { alarmTranslations } from '@shared/constants/alarm-translations';

@Injectable({
  providedIn: 'root',
})
export class AlarmService {
  constructor(
    private workerService: WorkerService,
    private alarmStore: AlarmStore,
    private alarmQuery: AlarmQuery,
    private deviceQuery: DeviceQuery,
    private ngZone: NgZone,
    private angularFirestore: AngularFirestore,
    private userQuery: UserQuery,
    private remoteSupportService: RemoteSupportService,
    private remoteUserQuery: RemoteUserQuery,
    private toastService: ToastService,
    private translateService: TranslateService
    // private connectApiService: ConnectApiService
  ) { }

  public resetAlarmData(): void {
    this.ngZone.run(() => this.alarmStore.update(() => ({
      alarms: [],
      alarmLog: [],
      latestAlarmToBeDismissed: [],
      latestAlarmLogToBeCleared: [],
      pendingAlarmClear: [],
      pendingAlarmLogClear: [],
      pendingAlarmLog: 0,
      pendingGetLog: 0
    })));
  }

  public updatePendingAlarmLog(add: boolean): void {
    this.ngZone.run(() => {
      this.alarmStore.update(state => ({
        pendingAlarmLog: add ? state.pendingAlarmLog + 1 : state.pendingAlarmLog - 1
      }))
    });
  }

  // public updateClearAlarmLog(add: boolean): void {
  //   this.ngZone.run(() => {
  //     this.alarmStore.update(state => ({
  //       pendingClearAlarmLog: add ? state.pendingClearAlarmLog + 1 : state.pendingClearAlarmLog - 1
  //     }))
  //   });
  // }

  public updateAlarmService(alarm: IAlarm): void {
    var newAlarm: IAlarm = {...alarm, comment: this.getAlarmDescription(alarm)}
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        alarms: arrayUpsert(state.alarms, newAlarm.errorCodeId, newAlarm),
      }));
    });
  }

  private getEcuNameFromEcuId(ecuID: number): string {
    switch (ecuID) {
      case 1:
        return 'TCU';

      case 2:
        return 'CCU';

      case 3:
        return 'HCU';

      case 4:
        return 'JLE';

      case 5:
        return 'JRI';

      case 6:
        return 'HMI';

      case 7:
        return 'CAC1';

      case 8:
        return 'CAC2';

      case 9:
      return 'DCU';
      
      default:
        return 'Unknown';
    }
  }

  public removeSpecificAlarm(alarm: { alarmId: number, ecuId: number }): void {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        alarms: state.alarms.filter(a => a.errorCodeId !== alarm.alarmId)
      }));
    });
  }

  public removeActiveAlarmsForEcu(ecuId: number): void {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        alarms: state.alarms.filter(alarm => alarm.ecuId !== ecuId),
      }));
    });
  }

  public setAlarmToOld(alarm: IAlarm): void {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        alarms: arrayUpdate(state.alarms, alarm.errorCodeId, { isOldAlarm: true }),
      }));
    });
  }

  public setAlarms(alarms: IAlarm[]): void {
    this.ngZone.run(() => {
      this.alarmStore.update(() => ({
        alarms: alarms,
      }));
    });
  }

  public updateAlarmLog(alarmLog: IAlarmLog[], ecuId?: number): void {
    for (const alarm of alarmLog) {
      var newAlarm: IAlarmLog = {...alarm, alarmName: this.getAlarmLogName(alarm)}
      this.ngZone.run(() => {
        this.alarmStore.update((state) => ({
          alarmLog: arrayUpsert(state.alarmLog, newAlarm.alarmCode, newAlarm, 'alarmCode'),
        }));
      });

      // this.angularFirestore
      //   .collection(CONFIG.firebaseCollection.devices)
      //   .doc(address)
      //   .collection(CONFIG.firebaseCollection.alarmLog)
      //   .doc(`${alarm.ecuId}-${alarm.alarmCode}`)
      //   .set(alarm);
    }

    // this.angularFirestore
    // .collection(CONFIG.firebaseCollection.devices)
    // .doc(address)
    // .collection(CONFIG.firebaseCollection.alarms)
    // .doc(CONFIG.firebaseCollection.alarmLog)
    // .set({alarms: alarmLog})
  }

  // private resetAlarmLogInCloud() {
  //   const address = this.deviceQuery.currentDevice?.address;
  //   this.angularFirestore
  //   .collection(CONFIG.firebaseCollection.devices)
  //   .doc(address)
  //   .collection(CONFIG.firebaseCollection.alarms)
  //   .doc(CONFIG.firebaseCollection.alarmLog)
  //   .set({alarms: []});
  // }

  public clearLogState(): void {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({ ...state, alarmLog: [] }));
    });
  }

  public refreshAlarms(alarms: IAlarm[]): void {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({ ...state, alarms: alarms }));
    });
  }

  public clearLog(clearType: 'deep' | 'temp', requestUid?: string): void {
    const clearLogMessage = new WorkerMessage(WORKER_TOPIC.clearAlarmLog, clearType);
    this.workerService.doWork(clearLogMessage);

    // TODO: Only takes first clear. in real life you get one alarmlogClearResponse for every ECUid so if one ECUid fails to clear it doesnt show
    this.alarmQuery.latestAlarmLogToBeCleared$.pipe(first(x => x.length >= this.deviceQuery.hardwareVersions.length || x.length >= 3))
      .subscribe(logClear => {
        logClear.forEach((log) => {
          //console.log(logClear);

          const status = log['alarmStatus'];
          if (status > 2) {
            this.toastService.warningToast('toasts.alarm log clear fail', true, {ecuName: this.getEcuNameFromEcuId(log['ecuId'] as number), status: status});
          } else {
            this.clearLogState();
            this.toastService.successToast('toasts.alarm log clear', true, 'toasts.alarm log', {ecuName: this.getEcuNameFromEcuId(log['ecuId'] as number)});
            // if (clearType === 'deep') {
            //   this.resetAlarmLogInCloud();
            // }
          }

          this.alarmStore.update((state) => ({ 
            latestAlarmLogToBeCleared: arrayRemove(state.latestAlarmLogToBeCleared, log['ecuId'], 'ecuId')
          }));
        })

        if (requestUid) {
          this.confirmAlarmLogCleared(logClear, requestUid);
        }

      });

    setTimeout(() => {
      const setupMessage = new WorkerMessage(WORKER_TOPIC.getAlarmLog, {});
      this.workerService.doWork(setupMessage);
    }, 500);
  }

  public removeAlarm(alarm: IAlarm, requestId?: string): void {
    const sendAlarmMessage = new WorkerMessage(WORKER_TOPIC.clearAlarm, {
      ecuId: alarm.ecuId,
      alarmCode: alarm.errorCodeId,
    });
    this.workerService.doWork(sendAlarmMessage);
    // this.connectApiService.clearAlarm(alarm);
    let alarmsArray = [...this.alarmQuery.alarms];
    const alarmToBeRemoved = alarmsArray.findIndex((element) => element.errorCodeId === alarm.errorCodeId);

    if (alarmToBeRemoved !== -1) {
      this.alarmQuery.latestAlarmToBeDismissed$.pipe(first(alarmToBeDismissed => alarmToBeDismissed.length > 0))
        .subscribe(theAlarm => {
          if (theAlarm.length > 0 && theAlarm[0]['alarmStatus'] <= 2) {
            // alarmsArray.splice(alarmToBeRemoved, 1);
            // this.alarmStore.update({ latestAlarmToBeDismissed: [] })
            this.alarmStore.update((state) => {
              return {
                ...state,
                latestAlarmToBeDismissed: [],
                // alarms: arrayRemove(state.alarms, alarm.errorCodeId, 'errorCodeId')
              }
            })
          }

          if (requestId) {
            this.confirmAlarmCleared(alarm, theAlarm[0], requestId);
          }
        })
    }

    // this.ngZone.run(() => {
    //   this.alarmStore.update((state) => ({ ...state, alarms: alarmsArray }));
    // });
  }

  public addAlarmToBeCleared(alarm: IAlarm, requestUid: string) {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        pendingAlarmClear: arrayUpsert(state.pendingAlarmClear, alarm.errorCodeId, { ...alarm, requestUid: requestUid }),
      }));
    });
  }

  public addClearAlarmLog(clearType: 'deep' | 'temp', requestUid: string) {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        pendingAlarmLogClear: arrayAdd(state.pendingAlarmLogClear, { clearType: clearType, requestUid: requestUid }),
      }));
    });
  }

  public addGetAlarmLog() {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        pendingGetLog: 1
      }));
    });
  }

  public removeClearAlarmLog() {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        pendingAlarmLogClear: []
      }));
    });
  }

  public removeGetAlarmLog() {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        pendingGetLog: 0
      }));
    });
  }

  public removeAlarmToBeCleared(alarm: IAlarm) {
    this.ngZone.run(() => {
      this.alarmStore.update((state) => ({
        pendingAlarmClear: arrayRemove(state.pendingAlarmClear, alarm.errorCodeId, 'errorCodeId'),
      }));
    });
  }

  private confirmAlarmCleared(alarm: IAlarm, alarmResponse: AckAlarmResponseEvent, requestId: string) {
    const uperatorId = this.userQuery.userUid;
    this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(uperatorId)
      .collection(CONFIG.firebaseCollection.remoteRequests)
      .doc<IRequest>(requestId)
      .update({ clearedAlarm: { alarm: alarm, alarmResponse: alarmResponse } })
  }

  public confirmAlarmLogCleared(alarmResponse: ClearAlarmResponseEvent[], requestId: string) {
    const uperatorId = this.userQuery.userUid;
    this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(uperatorId)
      .collection(CONFIG.firebaseCollection.remoteRequests)
      .doc<IRequest>(requestId)
      .update({ clearedAlarmLog: alarmResponse })
  }

  public sendRemoveAlarmRequest(alarm: IAlarm) {
    const operatorId = this.remoteUserQuery.connectedUser.uid;
    this.remoteSupportService.sendClearAlarm(alarm).then((reqDocument) => {
      const timer$ = timer(10000);

      setTimeout(() => {
        this.remoteSupportService
          .subscribeToRequest(operatorId, reqDocument.id)
          .pipe(takeUntil(timer$))
          .subscribe((request) => {
            //console.log(request);
            
            var alarmClearStatus = request.clearedAlarm;
            if (alarmClearStatus.alarmResponse['alarmStatus'] !== undefined && alarmClearStatus.alarmResponse['alarmStatus'] >= 0) {
              if (alarm.errorCodeId !== alarmClearStatus.alarm.errorCodeId && alarmClearStatus.alarmResponse['alarmStatus'] <= 2) {
                this.toastService.dangerToast('toasts.alarm clear mismatch', true);
              } else if (alarmClearStatus.alarmResponse['alarmStatus'] > 2) {
                this.toastService.warningToast('toasts.alarm clear remote fail', true, {errorCodeId: alarm.errorCodeId, status: alarmClearStatus.alarmResponse['alarmStatus']});
              } else if (alarmClearStatus.alarmResponse['alarmStatus'] <= 2) {
                this.toastService.successToast('toasts.alarm clear remote', true, 'toasts.alarm', {errorCodeId: alarm.errorCodeId});
              }
            } else {
            }
            //console.log(request);

          });
      }, 1000)
    });
  }

  public sendClearAlarmLogRequest(clearType: 'deep' | 'temp') {
    const operatorId = this.remoteUserQuery.connectedUser.uid;
    this.remoteSupportService.sendClearAlarmLog(clearType).then((reqDocument) => {
      const timer$ = timer(5000);

      setTimeout(() => {
        this.remoteSupportService
          .subscribeToRequest(operatorId, reqDocument.id)
          .pipe(takeUntil(timer$))
          .subscribe((request) => {
            //console.log(request);
            
            // var alarmClearLogStatus = request.clearedAlarmLog['data'];
            request.clearedAlarmLog.forEach(alarmClearLogStatus => {
              if (alarmClearLogStatus && alarmClearLogStatus['alarmStatus'] !== undefined && alarmClearLogStatus['alarmStatus'] >= 0) {
                if (alarmClearLogStatus['alarmStatus'] <= 2) {
                  this.toastService.successToast('toasts.alarm log clear', true, 'toasts.alarm log', {ecuName: this.getEcuNameFromEcuId(alarmClearLogStatus['ecuId'] as number)});
                } else {
                  this.toastService.warningToast('toasts.alarm log clear fail', true, {ecuName: this.getEcuNameFromEcuId(alarmClearLogStatus['ecuId'] as number), status: alarmClearLogStatus['alarmStatus']});
                }
              }
            })
           
            //console.log(request);

          });
      }, 1000)
    });
  }

  public sendGetAlarmLogRequest() {
    // const operatorId = this.remoteUserQuery.connectedUser.uid;
    this.remoteSupportService.sendGetAlarmLog().then((reqDocument) => {
      // const timer$ = timer(5000);

      // setTimeout(() => {
      //   this.remoteSupportService
      //     .subscribeToRequest(operatorId, reqDocument.id)
      //     .pipe(takeUntil(timer$))
      //     .subscribe((request) => {
      //       // var alarmClearLogStatus = request.clearedAlarmLog['data'];
      //       request.clearedAlarmLog.forEach(alarmClearLogStatus => {
      //         if (alarmClearLogStatus && alarmClearLogStatus['alarmStatus'] !== undefined && alarmClearLogStatus['alarmStatus'] >= 0) {
      //           if (alarmClearLogStatus['alarmStatus'] <= 2) {
      //             this.toastService.successToast(`The alarm log in ${this.getEcuNameFromEcuId(alarmClearLogStatus['ecuId'] as number)} has been cleared`);
      //           } else {
      //             this.toastService.warningToast(`The alarm log for ${this.getEcuNameFromEcuId(alarmClearLogStatus['ecuId'] as number)} failed to clear, Status: ${alarmClearLogStatus['alarmStatus']}`);
      //           }
      //         }
      //       })
           
      //       console.log(request);

      //     });
      // }, 1000)
    });
  }

  // private confirmAlarmCleared(alarm: IAlarm, alarmResponse: AckAlarmResponseEvent, requestId: string) {
  //   const uperatorId = this.userQuery.userUid;
  //   this.angularFirestore
  //     .collection(CONFIG.firebaseCollection.users)
  //     .doc(uperatorId)
  //     .collection(CONFIG.firebaseCollection.remoteRequests)
  //     .doc<IRequest>(requestId)
  //     .update({ clearedAlarm: { alarm: alarm, alarmResponse: alarmResponse } })
  // }

  // public sendRemoveAlarmRequest(alarm: IAlarm) {
  //   const operatorId = this.remoteUserQuery.connectedUser.uid;
  //   this.remoteSupportService.sendClearAlarm(alarm).then((reqDocument) => {
  //     const timer$ = timer(5000);

  //     setTimeout(() => {
  //       this.remoteSupportService
  //         .subscribeToRequest(operatorId, reqDocument.id)
  //         .pipe(takeUntil(timer$))
  //         .subscribe((request) => {
  //           var alarmClearStatus = request.clearedAlarm;
  //           if (alarmClearStatus.alarmResponse['alarmStatus']) {
  //             if (alarm.errorCodeId !== alarmClearStatus.alarm.errorCodeId && alarmClearStatus.alarmResponse['alarmStatus'] <= 2) {
  //               this.toastService.dangerToast(`The alarm that was cleared did not match the alarm that was requested to clear`)
  //             } else if (alarmClearStatus.alarmResponse['alarmStatus'] > 2) {
  //               this.toastService.warningToast(`The alarm with id: ${alarm.errorCodeId} failed to clear with status ${alarmClearStatus.alarmResponse['alarmStatus']}`)
  //             } else if (alarmClearStatus.alarmResponse['alarmStatus'] <= 2) {
  //               this.toastService.successToast(`The alarm with id: ${alarm.errorCodeId} succeeded to clear`);
  //             }
  //           }
  //           console.log(request);

  //         });
  //     }, 1000)
  //   });
  // }

  // public sendClearAlarmLogRequest() {
  //   const operatorId = this.remoteUserQuery.connectedUser.uid;
  //   this.remoteSupportService.sendClearAlarmLog().then((reqDocument) => {
  //     const timer$ = timer(5000);

  //     setTimeout(() => {
  //       this.remoteSupportService
  //         .subscribeToRequest(operatorId, reqDocument.id)
  //         .pipe(takeUntil(timer$))
  //         .subscribe((request) => {
  //           var alarmClearLogStatus = request.clearedAlarmLog;
  //           if (alarmClearLogStatus['alarmStatus']) {
  //             if (alarmClearLogStatus['alarmStatus'] <= 2) {
  //               this.toastService.successToast(`Alarm log clear succeeded!`);
  //             } else {
  //               this.toastService.warningToast(`Alarm log clear failed with status ${alarmClearLogStatus['alarmStatus']}`)
  //             }
  //           }
  //           console.log(request);

  //         });
  //     }, 1000)
  //   });
  // }

  public getAlarmDescription(alarm: IAlarm) {
    let curlang = 'GB'
    this.translateService.currentLang === 'sv' ? (curlang = 'SE') : (curlang = 'GB');
    var node = alarmTranslations['alarm names'][alarm.ecuId][alarm.errorCodeId - (1000*alarm.ecuId)];
    if (node) {
      return node[curlang];
    } else {
      return "Couldn't find error in definition";
    }
  }

  public getAlarmLogName(alarm: IAlarmLog) {
    let curlang = 'GB'
    this.translateService.currentLang === 'sv' ? (curlang = 'SE') : (curlang = 'GB');
    var node = alarmTranslations['alarm names'][alarm.ecuId][alarm.alarmCode - (1000*alarm.ecuId)];
    if (node) {
      return node[curlang];
    } else {
      return "Couldn't find error in definition";
    }
  }
}
