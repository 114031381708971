import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { IApp } from '@interfaces/app.interface';
import { AppStore } from './app.store';

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<IApp> {
  constructor(protected store: AppStore) {
    super(store);
  }

  appState$ = this.select();

  environment$ = this.select((state) => state.environment);

  isCapacitor$ = this.select((state) => state.isCapacitor);

  isOperator$ = this.select((state) => state.isOperator);

  bleEnabled$ = this.select((state) => state?.bleEnabled);

  footer$ = this.select((state) => state.footer);

  footerTCS$ = this.select((state) => state.footerTCS);

  fetchingDefinition$ = this.select((state) => state.fetchingDefinition);

  get isCapacitor() {
    return !!this.getValue().isCapacitor;
  }

  get fetchingDefinition() {
    return this.getValue().fetchingDefinition;
  }

  get bleEnabled() {
    return this.getValue().bleEnabled;
  }

  get platform() {
    return this.getValue().platform;
  }
}
