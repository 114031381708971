export const REMOTESUPPORT: IAlertRemoteSupport[] = [
  {
    type: 'remote-support',
    header: 'alerts.remote-support.header',
    message: 'alerts.remote-support.message',
  },
  {
    type: 'download-firmware',
    header: 'alerts.remote-firmware-upgrade.header',
    message: 'alerts.remote-firmware-upgrade.message',
  },
  {
    type: 'update-parameters',
    header: 'alerts.remote-update-parameters.header',
    message: 'alerts.remote-update-parameters.message',
  },
  {
    type: 'auto-calibrate',
    header: 'alerts.remote-auto-calibrate.header',
    message: 'alerts.remote-auto-calibrate.message',
  },
  {
    type: 'live-data',
    header: 'alerts.live-data.header',
    message: 'alerts.live-data.message',
  }
];

interface IAlertRemoteSupport {
  type: 'remote-support' | 'download-firmware' | 'update-parameters' | 'auto-calibrate' | 'live-data';
  header: string;
  message: string;
}
