import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { ICurrentUser, IUser } from '@interfaces/user.interface';

import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<IUser> {
  constructor(protected store: UserStore) {
    super(store);
  }

  userState$ = this.select();

  userOnline$ = this.select((state) => state.currentUser.state);

  currentUser$ = this.select((state) => state.currentUser);

  name$ = this.select(
    (state) => state.currentUser.firstName + ' ' + state.currentUser.lastName || state.currentUser.email
  );

  role$ = this.select((state) => state.currentUser.role);

  approved$ = this.select((state) => state.currentUser.approved);

  isOperator$ = this.select((state) => state.currentUser.role === 'operator');

  isDeveloper$ = this.select((state) => state.currentUser.role === 'developer');

  isSuperAdmin$ = this.select((state) => state.currentUser.role === 'superadmin');
  
  isLocalAdmin$ = this.select((state) => state.currentUser.role === 'localadmin');

  isAdmin$ = this.select((state) => state.currentUser.role === 'admin');

  company$ = this.select((state) => state.currentUser.company);

  serviceToolHasBleConnection$ = this.select((state) => state.serviceToolHasBleConnection);
  autoCalibrationStarted$ = this.select((state) => state.autoCalibrationStarted);
  notConnectedToDevice$ = this.select((state) => state.notConnectedToDevice);

  dataTableBugFix$ = this.select((state) => state.dataTableBugFix);

  get serviceToolHasBleConnection(): boolean {
    return this.getValue().serviceToolHasBleConnection;
  }
  get autoCalibrationStarted(): boolean {
    return this.getValue().autoCalibrationStarted;
  }
  get notConnectedToDevice(): boolean {
    return this.getValue().notConnectedToDevice;
  }
  get userUid(): string {
    return this.getValue().uid;
  }

  get name(): string {
    const displayName = `${this.getValue().currentUser.firstName} ${this.getValue().currentUser.lastName}`;
    return displayName || this.getValue().currentUser.email;
  }

  get role(): 'operator' | 'developer' | 'service' | 'superadmin' | 'admin' | 'localadmin'{
    return this.getValue().currentUser.role;
  }

  get isOperator(): boolean {
    return this.getValue().currentUser.role === 'operator';
  }

  
  get isDeveloper(): boolean {
    return this.getValue().currentUser.role === 'developer';
  }

  get currentUser(): ICurrentUser {
    return this.getValue().currentUser;
  }
}
