
import { IProfile } from '@interfaces/profile.interface';

export const PROFILEDIRECTIONIDS: number[] = [2109, 2110, 2111, 2112];

export const POINTPARAMETERS: number[] = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029]

export const STRINGREADPARAMETERIDS: { [key: string]: IParameterLink[] } = {
  stringResources: [
    { ecuId: 6, parameterId: 6301 },
    { ecuId: 6, parameterId: 6302 },
    { ecuId: 6, parameterId: 6303 },
    { ecuId: 6, parameterId: 6304 },
    { ecuId: 6, parameterId: 6305 },
    { ecuId: 6, parameterId: 6306 },
    { ecuId: 6, parameterId: 6307 },
    { ecuId: 6, parameterId: 6308 },
    { ecuId: 6, parameterId: 6309 },
    { ecuId: 6, parameterId: 6310 },
    { ecuId: 6, parameterId: 6311 },
    { ecuId: 6, parameterId: 6312 },
    { ecuId: 6, parameterId: 6313 },
    { ecuId: 6, parameterId: 6314 },
    { ecuId: 6, parameterId: 6315 },
    { ecuId: 6, parameterId: 6316 },
    { ecuId: 6, parameterId: 6317 },
    { ecuId: 6, parameterId: 6318 },
    { ecuId: 6, parameterId: 6319 },
    { ecuId: 6, parameterId: 6320 },
    { ecuId: 6, parameterId: 6321 },
    { ecuId: 6, parameterId: 6322 },
    { ecuId: 6, parameterId: 6323 },
    { ecuId: 6, parameterId: 6324 },
    { ecuId: 6, parameterId: 6325 },
    { ecuId: 6, parameterId: 6326 },
    { ecuId: 6, parameterId: 6327 },
    { ecuId: 6, parameterId: 6328 },
    { ecuId: 6, parameterId: 6329 },
    { ecuId: 6, parameterId: 6330 },
  ],
  profileStringResources: [
    { ecuId: 6, parameterId: 6331 },
    { ecuId: 6, parameterId: 6332 },
    { ecuId: 6, parameterId: 6333 },
    { ecuId: 6, parameterId: 6334 },
    { ecuId: 6, parameterId: 6335 },
  ]
}

export const READPARAMETERIDS: { [key: string]: IParameterLink[] } = {
  calibrationRotation: [
    { ecuId: 1, parameterId: 1021 },
    { ecuId: 1, parameterId: 1022 },
    { ecuId: 1, parameterId: 1023 },
    { ecuId: 1, parameterId: 1024 },

    { ecuId: 1, parameterId: 1026 },
    { ecuId: 1, parameterId: 1027 },
    
    { ecuId: 1, parameterId: 1028 },
    { ecuId: 1, parameterId: 1029 },
    { ecuId: 1, parameterId: 1093 },
    { ecuId: 1, parameterId: 1084 },
    
  ],
  calibrationTilt: [
    { ecuId: 1, parameterId: 1031 },
    { ecuId: 1, parameterId: 1032 },
    { ecuId: 1, parameterId: 1033 },
    { ecuId: 1, parameterId: 1034 },
    { ecuId: 1, parameterId: 1036 },
    { ecuId: 1, parameterId: 1037 },
    { ecuId: 1, parameterId: 1038 },
    { ecuId: 1, parameterId: 1039 },
    { ecuId: 2, parameterId: 2031 },
    { ecuId: 1, parameterId: 1092 },
    { ecuId: 1, parameterId: 1094 }
  ],
  calibrationExtra: [
    { ecuId: 1, parameterId: 1041 },
    { ecuId: 1, parameterId: 1042 },
    { ecuId: 1, parameterId: 1043 },
    { ecuId: 1, parameterId: 1044 },
    { ecuId: 1, parameterId: 1046 },
    { ecuId: 1, parameterId: 1047 },
    { ecuId: 1, parameterId: 1048 },
    { ecuId: 1, parameterId: 1049 },
    { ecuId: 2, parameterId: 2032 }
  ],
  calibrationExtra2: [
    { ecuId: 1, parameterId: 1051 },
    { ecuId: 1, parameterId: 1052 },
    { ecuId: 1, parameterId: 1053 },
    { ecuId: 1, parameterId: 1054 },
    { ecuId: 1, parameterId: 1056 },
    { ecuId: 1, parameterId: 1057 },
    { ecuId: 1, parameterId: 1058 },
    { ecuId: 1, parameterId: 1059 },
    { ecuId: 2, parameterId: 2033 }
  ],
  calibrationExtra3: [
    { ecuId: 1, parameterId: 1061 },
    { ecuId: 1, parameterId: 1062 },
    { ecuId: 1, parameterId: 1063 },
    { ecuId: 1, parameterId: 1064 },
    { ecuId: 1, parameterId: 1066 },
    { ecuId: 1, parameterId: 1067 },
    { ecuId: 1, parameterId: 1068 },
    { ecuId: 1, parameterId: 1069 },
    { ecuId: 2, parameterId: 2037 }
    
  ],
  calibrationHcu1: [
    { ecuId: 2, parameterId: 2143 },
    { ecuId: 2, parameterId: 2144 },
    { ecuId: 2, parameterId: 2145 },
    { ecuId: 2, parameterId: 2146 },
    { ecuId: 2, parameterId: 2147 },
    { ecuId: 2, parameterId: 2148 },
    { ecuId: 2, parameterId: 2149 },
    { ecuId: 2, parameterId: 2150 },
   
  ],
  calibrationHcu2: [
    { ecuId: 2, parameterId: 2151 },
    { ecuId: 2, parameterId: 2152 },
    { ecuId: 2, parameterId: 2153 },
    { ecuId: 2, parameterId: 2154 },
    { ecuId: 2, parameterId: 2155 },
    { ecuId: 2, parameterId: 2156 },
    { ecuId: 2, parameterId: 2157 },
    { ecuId: 2, parameterId: 2158 },
    
  ],
  calibrationSecureLock: [
    { ecuId: 1, parameterId: 1076 },
    { ecuId: 1, parameterId: 1077 },
    { ecuId: 1, parameterId: 1078 },
    { ecuId: 1, parameterId: 1080 }
  ],
  calibrationMachineSettings: [
    { ecuId: 2, parameterId: 2134 },
    { ecuId: 2, parameterId: 2135 },
    { ecuId: 2, parameterId: 2136 },
    { ecuId: 2, parameterId: 2051 },
    { ecuId: 2, parameterId: 2052 },
    { ecuId: 2, parameterId: 2053 },
    { ecuId: 2, parameterId: 2054 },
    { ecuId: 2, parameterId: 2047 },
    { ecuId: 2, parameterId: 2048 },
    { ecuId: 2, parameterId: 2049 },
    { ecuId: 2, parameterId: 2050 },
    { ecuId: 1, parameterId: 1101 },
    { ecuId: 1, parameterId: 1095 },
    { ecuId: 2, parameterId: 2133 },
    { ecuId: 2, parameterId: 2113 },
    { ecuId: 1, parameterId: 1081 },
    { ecuId: 1, parameterId: 1073 },
    { ecuId: 2, parameterId: 2114 },
    { ecuId: 2, parameterId: 2115 },
    { ecuId: 2, parameterId: 2034 }
  ],
  calibrationFeeder: [
    { ecuId: 2, parameterId: 2035 },
    { ecuId: 2, parameterId: 2036 },
    { ecuId: 2, parameterId: 2021 },
    { ecuId: 2, parameterId: 2022 },
    { ecuId: 2, parameterId: 2023 },
    { ecuId: 2, parameterId: 2024 },
    { ecuId: 2, parameterId: 2025 },
    { ecuId: 2, parameterId: 2026 },
    { ecuId: 2, parameterId: 2027 },
    { ecuId: 2, parameterId: 2028 },
    { ecuId: 2, parameterId: 2029 },
    { ecuId: 2, parameterId: 2044 },
    { ecuId: 2, parameterId: 2163 },
    { ecuId: 2, parameterId: 2164 },
    { ecuId: 1, parameterId: 1925 },
    { ecuId: 2, parameterId: 2163 },
    { ecuId: 2, parameterId: 2164 },
  ],

  systemInformationids: [
    { ecuId: 1, parameterId: 1120 },
    { ecuId: 1, parameterId: 1074 },
    { ecuId: 1, parameterId: 1001 },
    { ecuId: 1, parameterId: 1007 },
    { ecuId: 1, parameterId: 1008 },
    { ecuId: 2, parameterId: 2137}
  ],

  hardwareSerialNumbers: [
    { ecuId: 1, parameterId: 1008 },
    { ecuId: 2, parameterId: 2008 },
  ],

  operationalInformationids: [
    { ecuId: 1, parameterId: 1912 },
    { ecuId: 1, parameterId: 1913 },
    { ecuId: 1, parameterId: 1914 },
    { ecuId: 1, parameterId: 1915 },
    { ecuId: 1, parameterId: 1916 },
    { ecuId: 1, parameterId: 1911 },
    { ecuId: 1, parameterId: 1917 }
  ],

  serviceAlarmIds: [
    { ecuId: 1, parameterId: 1918 },
    { ecuId: 1, parameterId: 1919 },
    { ecuId: 1, parameterId: 1920 },
    { ecuId: 1, parameterId: 1921 }
  ],

  selectedProfile: [{ ecuId: 2, parameterId: 2084 }],

  autocalibrationIds: [
    { ecuId: 1, parameterId: 1090 },
    { ecuId: 1, parameterId: 1091 }
  ],

  // Profiles
  // General, Rotation, Tilt, Extra, Extra 2, Double fe,
  profile1: [
    { ecuId: 2, parameterId: 2085 },
    { ecuId: 2, parameterId: 2089 },
    { ecuId: 2, parameterId: 2093 },
    { ecuId: 2, parameterId: 2097 },
    { ecuId: 2, parameterId: 2101 },
    { ecuId: 2, parameterId: 2105 },
    { ecuId: 2, parameterId: PROFILEDIRECTIONIDS[0] }
  ],
  profile2: [
    { ecuId: 2, parameterId: 2086 },
    { ecuId: 2, parameterId: 2090 },
    { ecuId: 2, parameterId: 2094 },
    { ecuId: 2, parameterId: 2098 },
    { ecuId: 2, parameterId: 2102 },
    { ecuId: 2, parameterId: 2106 },
    { ecuId: 2, parameterId: PROFILEDIRECTIONIDS[1] }
  ],
  profile3: [
    { ecuId: 2, parameterId: 2087 },
    { ecuId: 2, parameterId: 2091 },
    { ecuId: 2, parameterId: 2095 },
    { ecuId: 2, parameterId: 2099 },
    { ecuId: 2, parameterId: 2103 },
    { ecuId: 2, parameterId: 2107 },
    { ecuId: 2, parameterId: PROFILEDIRECTIONIDS[2] }
  ],
  profile4: [
    { ecuId: 2, parameterId: 2088 },
    { ecuId: 2, parameterId: 2092 },
    { ecuId: 2, parameterId: 2096 },
    { ecuId: 2, parameterId: 2100 },
    { ecuId: 2, parameterId: 2104 },
    { ecuId: 2, parameterId: 2108 },
    { ecuId: 2, parameterId: PROFILEDIRECTIONIDS[3] }
  ],
  handle: [
    { ecuId: 2, parameterId: 2065 },
    { ecuId: 2, parameterId: 2160 },
    { ecuId: 2, parameterId: 2161 }
  ],
  functions: [
    { ecuId: 2, parameterId: 2066 },
    { ecuId: 2, parameterId: 2067 },
    { ecuId: 2, parameterId: 2068 },
    { ecuId: 2, parameterId: 2069 },
    { ecuId: 2, parameterId: 2070 },
    { ecuId: 2, parameterId: 2071 },
    { ecuId: 2, parameterId: 2072 },
    { ecuId: 2, parameterId: 2073 },
    { ecuId: 2, parameterId: 2074 },
    { ecuId: 2, parameterId: 2075 },
    { ecuId: 2, parameterId: 2076 },
    { ecuId: 2, parameterId: 2077 },
    { ecuId: 2, parameterId: 2078 },
    { ecuId: 2, parameterId: 2079 },
    { ecuId: 2, parameterId: 2080 },
    { ecuId: 2, parameterId: 2081 },

    { ecuId: 9, parameterId: 9021 },
    { ecuId: 9, parameterId: 9022 },
    { ecuId: 9, parameterId: 9023 },

    { ecuId: 9, parameterId: 9024 },
    { ecuId: 9, parameterId: 9025 },
    { ecuId: 9, parameterId: 9026 },

    { ecuId: 9, parameterId: 9027 },
    { ecuId: 9, parameterId: 9028 },
    { ecuId: 9, parameterId: 9029 },

    { ecuId: 9, parameterId: 9030 },
    { ecuId: 9, parameterId: 9031 },
    { ecuId: 9, parameterId: 9032 },

    { ecuId: 9, parameterId: 9033 },
    { ecuId: 9, parameterId: 9034 },
    { ecuId: 9, parameterId: 9035 },

    { ecuId: 9, parameterId: 9036 },
    { ecuId: 9, parameterId: 9037 },
    { ecuId: 9, parameterId: 9038 },

    { ecuId: 9, parameterId: 9039 },
    { ecuId: 9, parameterId: 9040 },
    { ecuId: 9, parameterId: 9041 },

    { ecuId: 9, parameterId: 9042 },
    { ecuId: 9, parameterId: 9043 },
    { ecuId: 9, parameterId: 9044 },

    { ecuId: 9, parameterId: 9045 },
    { ecuId: 9, parameterId: 9046 },
    { ecuId: 9, parameterId: 9047 },

    { ecuId: 9, parameterId: 9048 },
    { ecuId: 9, parameterId: 9049 },
    { ecuId: 9, parameterId: 9050 },

    { ecuId: 9, parameterId: 9051 },
    { ecuId: 9, parameterId: 9052 },
    { ecuId: 9, parameterId: 9053 },

    { ecuId: 9, parameterId: 9054 },
    { ecuId: 9, parameterId: 9055 },
    { ecuId: 9, parameterId: 9056 },
  ],
  handleStringConfig: [
    { ecuId: 6, parameterId: 6108 },
    { ecuId: 6, parameterId: 6109 },
    { ecuId: 6, parameterId: 6110 },
    { ecuId: 6, parameterId: 6111 },
    { ecuId: 6, parameterId: 6112 },
    { ecuId: 6, parameterId: 6113 },
    { ecuId: 6, parameterId: 6114 },
    { ecuId: 6, parameterId: 6115 },
    { ecuId: 6, parameterId: 6116 },
    { ecuId: 6, parameterId: 6117 },
    { ecuId: 6, parameterId: 6118 },
    { ecuId: 6, parameterId: 6119 },
    { ecuId: 6, parameterId: 6120 },
    { ecuId: 6, parameterId: 6121 },
    { ecuId: 6, parameterId: 6122 },
    { ecuId: 6, parameterId: 6123 },
    { ecuId: 6, parameterId: 6124 },
    { ecuId: 6, parameterId: 6125 },
    { ecuId: 6, parameterId: 6126 },
    { ecuId: 6, parameterId: 6127 },
    { ecuId: 6, parameterId: 6128 },
    { ecuId: 6, parameterId: 6129 },
    { ecuId: 6, parameterId: 6130 },
    { ecuId: 6, parameterId: 6131 },
    { ecuId: 6, parameterId: 6132 },
    { ecuId: 6, parameterId: 6133 },
    { ecuId: 6, parameterId: 6134 },
    { ecuId: 6, parameterId: 6135 },
    { ecuId: 6, parameterId: 6136 },
    { ecuId: 6, parameterId: 6137 },
    { ecuId: 6, parameterId: 6138 },
    { ecuId: 6, parameterId: 6139 },
    { ecuId: 6, parameterId: 6140 },
    { ecuId: 6, parameterId: 6141 },
    { ecuId: 6, parameterId: 6142 },
    { ecuId: 6, parameterId: 6143 }
  ],
  HmiRelatedParameters: [
    { ecuId: 6, parameterId: 6001 },
    { ecuId: 6, parameterId: 6003 },
    { ecuId: 6, parameterId: 6004 },
    { ecuId: 6, parameterId: 6007 },
    { ecuId: 6, parameterId: 6008 },
  ],
  sparePartFlags: [
    { ecuId: 1, parameterId: 1151 },
    { ecuId: 2, parameterId: 2162 },
    { ecuId: 6, parameterId: 6107 },
  ],
  cacFeeders1: [
    { ecuId: 2, parameterId: 2057 },
    { ecuId: 2, parameterId: 2058 },
    { ecuId: 2, parameterId: 2059 },
    { ecuId: 2, parameterId: 2060 },
    { ecuId: 2, parameterId: 2163 },
    { ecuId: 2, parameterId: 2164 },
    { ecuId: 2, parameterId: 2165 },
    { ecuId: 2, parameterId: 2166 },
  ],
  cacFeeders2: [
    { ecuId: 2, parameterId: 2061 },
    { ecuId: 2, parameterId: 2062 },
    { ecuId: 2, parameterId: 2063 },
    { ecuId: 2, parameterId: 2064 },
    { ecuId: 2, parameterId: 2167 },
    { ecuId: 2, parameterId: 2168 },
    { ecuId: 2, parameterId: 2169 },
    { ecuId: 2, parameterId: 2170 },
  ]
};

export const PERIODICPARAMETERIDS: { [key: string]: IParameterLink[] } = {
  functions: [{ ecuId: 2, parameterId: 2013 }],
  calibrationRotation: [
    { ecuId: 2, parameterId: 2001 },
    { ecuId: 1, parameterId: 1011 },
    { ecuId: 1, parameterId: 1015 },
    { ecuId: 1, parameterId: 1009 },
    { ecuId: 1, parameterId: 1018 }
  ],
  calibrationTilt: [
    { ecuId: 2, parameterId: 2002 },
    { ecuId: 1, parameterId: 1012 },
    { ecuId: 1, parameterId: 1016 },
    { ecuId: 1, parameterId: 1038 },
  ],
  calibrationExtra: [
    { ecuId: 2, parameterId: 2003 },
    { ecuId: 1, parameterId: 1013 }
  ],
  calibrationExtra2: [
    { ecuId: 2, parameterId: 2004 },
    { ecuId: 1, parameterId: 1014 }
  ],
  calibrationExtra3: [
    { ecuId: 2, parameterId: 2054 },
    { ecuId: 1, parameterId: 1045 }
  ],
  calibrationSecureLock: [
    { ecuId: 1, parameterId: 1041 },
    { ecuId: 1, parameterId: 1033 },
    
  ],
  calibrationMachineSettings: [
    { ecuId: 2, parameterId: 2041 },
    { ecuId: 1, parameterId: 1033 },
    { ecuId: 1, parameterId: 1046 },
    { ecuId: 2, parameterId: 2008 },
    { ecuId: 1, parameterId: 1019 },
  ],
  calibrationFeeder: [
    { ecuId: 1, parameterId: 1020 },
    { ecuId: 1, parameterId: 1019 },
    { ecuId: 1, parameterId: 1052 },
    { ecuId: 2, parameterId: 2044 },
    

  ],
  tcsInputOutputSettings: [
    { ecuId: 1, parameterId: 1054 },
    { ecuId: 1, parameterId: 1055 },
    { ecuId: 1, parameterId: 1056 },
    { ecuId: 1, parameterId: 1057 }
  ],
  calibrationHcu1:[
    { ecuId: 2, parameterId: 2007 },
    { ecuId: 3, parameterId: 3008 },
   ],
   calibrationHcu2:[
    { ecuId: 2, parameterId: 2008 },
    { ecuId: 3, parameterId: 3009 },
   ],
   cacFeeders1:[
    { ecuId: 1, parameterId: 1111 },
    { ecuId: 1, parameterId: 1001 },
   ],
   cacFeeders2:[
    { ecuId: 2, parameterId: 2555 },
    { ecuId: 3, parameterId: 3333 },
   ],
  startOperatorAngles: [
    { ecuId: 1, parameterId: 1016 },
    { ecuId: 1, parameterId: 1017 },
    { ecuId: 1, parameterId: 1015 }
  ],

  system: [
    { ecuId: 1, parameterId: 1001 }
  ],

  feederCurve: [
    { ecuId: 2, parameterId: 2042 }
  ],

  autocalibrationIds: [
    { ecuId: 1, parameterId: 1010 },
    { ecuId: 1, parameterId: 1009 },
    { ecuId: 1, parameterId: 1025 },
    { ecuId: 1, parameterId: 1019 }
  ],

  canBusInfo: [
    { ecuId: 6, parameterId: 6015 },
    { ecuId: 6, parameterId: 6016 },
    { ecuId: 6, parameterId: 6017 },
    { ecuId: 6, parameterId: 6018 }
  ],
  
};

export const PROFILE: IProfile[] = [
  {
    name: 'Operator profile 0',
    profileId: 0,
    pids: [],
    stringParameterResource: 6331
  },
  {
    name: 'Operator profile 1',
    profileId: 1,
    pids: READPARAMETERIDS.profile1,
    stringParameterResource: 6332
  },
  {
    name: 'Operator profile 2',
    profileId: 2,
    pids: READPARAMETERIDS.profile2,
    stringParameterResource: 6333
  },
  {
    name: 'Operator profile 3',
    profileId: 3,
    pids: READPARAMETERIDS.profile3,
    stringParameterResource: 6334
  },
  {
    name: 'Operator profile 4',
    profileId: 4,
    pids: READPARAMETERIDS.profile4,
    stringParameterResource: 6335
  },
];

export const PROFILE_STRING_PARAMS = PROFILE.map(x => x.stringParameterResource);

export const SPARE_PART_FLAGS = [1151, 2162, 6107];

export const UNITS_WITH_DEFINITION_FILES = [1, 2, 3, 6,9];




export const PARAMETERFORMNAME = {
  // Calibration rotation
  1021: 'minR',
  1022: 'maxR',
  1023: 'rampRUp',
  1024: 'rampRDown',
  1026: 'minL',
  1027: 'maxL',
  1028: 'rampLUp',
  1029: 'rampLDown',
  1093: 'reductionSettingCombinedMovement',
  1084: 'rotoSensorPulses',
  1081: 'pulses',
  1073: 'rps',
  // Calibration tilt
  1031: 'minR',
  1032: 'maxR',
  1033: 'rampRUp',
  1034: 'rampRDown',
  1036: 'minL',
  1037: 'maxL',
  1038: 'rampLUp',
  1039: 'rampLDown',
  2031: 'feeder',
  1092: 'tiltBal',
  1094: 'reductionSettingCombinedMovement',
  // Calibration extra
  1041: 'minR',
  1042: 'maxR',
  1043: 'rampRUp',
  1044: 'rampRDown',
  1046: 'minL',
  1047: 'maxL',
  1048: 'rampLUp',
  1049: 'rampLDown',
  2032: 'feeder',
  // Calibration extra2
  1051: 'minR',
  1052: 'maxR',
  1053: 'rampRUp',
  1054: 'rampRDown',
  1056: 'minL',
  1057: 'maxL',
  1058: 'rampLUp',
  1059: 'rampLDown',
  2033: 'feeder',
  // Calibration extra3
  1061: 'minR',
  1062: 'maxR',
  1063: 'rampRUp',
  1064: 'rampRDown',
  1066: 'minL',
  1067: 'maxL',
  1068: 'rampLUp',
  1069: 'rampLDown',
  2034: 'canConnection',
  //Calibration securelock
  1076: 'calibratedWedgePosition',
  1077: 'deadbandPlus',
  1078: 'deadbandMinus',
  1080: 'couplerEnabled',
  //Calibration machineSettings
  2134: 'calibratedWedgePosition',
  2135: 'deadbandPlus',
  2136: 'deadbandMinus',
  2051: 'minR',
  2052: 'maxR',
  2053: 'rampRUp',
  2054: 'rampRDown',
  2047: 'minL',
  2048: 'maxL',
  2049: 'rampLUp',
  2050: 'rampLDown',
  1101: 'ils',
  1095: 'hfc',
  // 2138: 'thumb',
  2133: 'machineCoupler',
  2113: 'machineDumpValve',
  2114: 'hammerDa',
  2115: 'freeReturn',
  // Calibration feeder
  2035: 'rampInc',
  2036: 'rampDec',
  2021: 'point1',
  2022: 'point2',
  2023: 'point3',
  2024: 'point4',
  2025: 'point5',
  2026: 'point6',
  2027: 'point7',
  2028: 'point8',
  2029: 'point9',
  2044: 'limitValues',
  1925:'pulseCycles',
  // Calibration HCU1
  2143: 'minR',
  2144: 'maxR',
  2145: 'rampRUp',
  2146: 'rampRDown',
  2147: 'minL',
  2148: 'maxL',
  2149: 'rampLUp',
  2150: 'rampLDown',
  // Calibration HCU2
  2151: 'minR',
  2152: 'maxR',
  2153: 'rampRUp',
  2154: 'rampRDown',
  2155: 'minL',
  2156: 'maxL',
  2157: 'rampLUp',
  2158: 'rampLDown',
  // CAC1 
  2163: 'offsetLower1A',
  2164: 'offsetUpper1A',
  2165: 'offsetLower1B',
  2166: 'offsetUpper1B',

  2057: 'deadbandPos1A',
  2058: 'deadbandNeg1A',
  2059: 'deadbandPos1B',
  2060: 'deadbandNeg1B',

// CAC2 
  2167: 'offsetLower2A',
  2168: 'offsetUpper2A',
  2169: 'offsetLower2B',
  2170: 'offsetUpper2B',

  
  2061: 'deadbandPos2A',
  2062: 'deadbandNeg2A',
  2063: 'deadbandPos2B',
  2064: 'deadbandNeg2B',
};


export const PERIODICPARAMETERFORMNAME = {

  // Calibration rotation
  2001: 'input',
  1011: 'output',
  1015: 'rotorAngle',
  1009: 'rotorTime',
  1018: 'homeSensor',
  // Calibration tilt
  2002: 'input',
  1012: 'output',
  1016: 'tiltAngle',
  1038: 'tiltTime',
  // Calibration extra
  2003: 'input',
  1013: 'output',
  // Calibration extra 2
  2004: 'input',
  1014: 'output',
  // Calibration extra 3
  2054: 'input',
  1045: 'output',
  // Calibration feeder
  1020: 'input',
  1019: 'output',
  1052: 'pressureSenserValue',
  2044: 'limitValues',
  // Calibration secureLock
  1041: 'currentPosition',
  1033: 'armedTimer',
 
  // Calibration machineSettings
  2041: 'currentPosition',
  // Hcu 1
  2007: 'input',
  3008: 'output',
    // Hcu 2
  2008: 'input',
  3009: 'output',
  // Calibration cac1
  2059: 'input',
  7003: 'output',
  2060: 'input2',
  7004: 'output2',
  // Calibration cac2
  2061: 'input',
  8003: 'output',
  2062: 'input2',
  8004: 'output2',
  
  
};

export interface IParameterLink {
  ecuId: number;
  parameterId: number;
}





//added for version2

export const DUALRANGEPARAMETERS = {
// Rotation
 1026: 'rotoCurrentLValueslower',
 1027:'rotoCurrentLValuesUpper',
 1021: 'rotoCurrentRValuesLower',
 1022: 'rotoCurrentRValuesUpper',

 
// Tilt
 1036:'tiltCurrentLValuesLower',
 1037:'tiltCurrentLValuesUpper',
 1031:'tiltCurrentRValuesLower',
 1032:'tiltCurrentRValuesUpper',

 
//Extra
 1046:'extraCurrentLValuesLower',
 1047:'extraCurrentLValuesUpper',
 1041:'extraCurrentRValuesLower',
 1042:'extraCurrentRValuesUpper',
 
 
//Extra2
 1056:'extra2CurrentLValuesLower',
 1057:'extra2CurrentLValuesUpper',
 1051:'extra2CurrentRValuesLower',
 1052:'extra2CurrentRValuesUpper',
 
 
//Extra3
 1066:'extra3CurrentLValuesLower',
 1067:'extra3CurrentLValuesUpper',
 1061:'extra3CurrentRValuesLower',
 1062:'extra3CurrentRValuesUpper',
 
 
//PWM 3/4- hcu1
 2147:'hcu1CurrentLValuesLower',
 2148:'hcu1CurrentLValuesUpper',
 2143:'hcu1CurrentRValuesLower',
 2144:'hcu1CurrentRValuesUpper',
 
 
//pWM 5/6 -hcu2
 2155:'hcu2CurrentLValuesLower',
 2156:'hcu2CurrentLValuesUpper',
 2151:'hcu2CurrentRValuesLower',
 2152:'hcu2CurrentRValuesUpper',
 
 
//Machine Settings
 2047:'msCurrentLValuesLower',
 2048:'msCurrentLValuesUpper',
 2051:'msCurrentRValuesLower',
 2052:'msCurrentRValuesUpper',
 
 

}

