import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { I18nPipe } from '@pipes/i18n.pipe';
import { ModalService } from '@services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private modalService: ModalService,
    private i18nPipe: I18nPipe
  ) {}

  public async successToast(message: string, translate: boolean = false, header?: string, translateParameters?: any, modalToBeClosed?: string, duration?: number): Promise<void> {
    const toast = await this.createToast(message, header, translate, translateParameters, modalToBeClosed, duration);
    toast.cssClass += ' border-toast-black';
    this.presentToast(toast);
  }

  public async dangerToast(message: string, translate: boolean = false, translateParameters?: any): Promise<void> {
    const toast = await this.createToast(message, this.i18nPipe.transform('toasts.error'), translate, translateParameters);
    toast.cssClass += ' border-toast-error';
    this.presentToast(toast);
  }

  public async warningToast(message: string, translate: boolean = false, translateParameters?: any, duration?: number): Promise<void> {
    const toast = await this.createToast(message, this.i18nPipe.transform('toasts.warning'), translate, translateParameters, '', duration);
    toast.cssClass += ' border-toast-warning';
    this.presentToast(toast);
  }

  public async infoToast(message: string, translate: boolean = false, translateParameters?: any): Promise<void> {
    const toast = await this.createToast(message, this.i18nPipe.transform('toasts.info'), translate, translateParameters);
    toast.cssClass += ' border-toast-info';
    this.presentToast(toast);
  }

  private async createToast(mess: string, head: string, translate: boolean, translateParameters?: any, modalToBeClosed?: string, duration?: number): Promise<HTMLIonToastElement> {
    const message = translate ? 
    (translateParameters ? this.i18nPipe.transform(mess, translateParameters) : this.i18nPipe.transform(mess)) : mess;
    const header = translate ? this.i18nPipe.transform(head) : head;

    return await this.toastController.create({
      header,
      message,
      duration: duration ?? 7000,
      position: 'bottom',
      animated: true,
      cssClass: 'border-r-8',
      buttons: [
        {
          text: 'Ok',
          side: 'end',
          role: 'cancel',
          handler: () => {
            if (modalToBeClosed) {
              this.modalService.dismissModal(modalToBeClosed);
            }
          },
        },
      ],
    });
  }

  private async presentToast(toast: HTMLIonToastElement): Promise<void> {
    await toast.present();
  }

  public async dismisstoast() {
    this.toastController.dismiss();
  }
}
