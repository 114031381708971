export const CONFIG = {
  firebaseCollection: {
    previousAlarms: 'previousAlarms',
    activeAlarms: 'activeAlarms',
    users: 'users',
    previousDevices: 'previousDevices',
    remoteRequests: 'remoteRequests',
    devices: 'devices',
    periodicData: 'periodicData',
    parameterData: 'parameterData',
    alarms: 'alarms',
    alarmLog: 'alarmLog',
    services: 'services',
    lastUpdated: 'lastUpdated',
    backupParameters: 'backupParameters',
    logs: 'logs'
  },
};
