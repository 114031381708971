import { Component, Input } from '@angular/core';

import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-system-details',
  templateUrl: './system-details.component.html',
  styleUrls: ['./system-details.component.scss'],
})
export class SystemDetailsComponent {
  @Input()
  public service: any;

  constructor(private modalService: ModalService) {}

  public closeModal() {
    this.modalService.closeDetailsModal();
  }
}
