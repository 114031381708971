// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-badge {
  font-weight: 600 !important;
  position: absolute;
  top: 3px;
  z-index: 2000;
  right: -31px;
  transform: rotate(45deg);
  width: 87px;
  text-transform: uppercase;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyIsIi4uXFwuLlxcLi5cXC4uXFxOZXclMjBmb2xkZXJcXGNvbm5lY3QtMy4wXFxzcmNcXGFwcFxcYXBwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0MsMkJBQUE7RUFDQSxrQkFBQTtFQUNBLFFBQUE7RUFDQSxhQUFBO0VBQ0EsWUFBQTtFQUNBLHdCQUFBO0VBQ0EsV0FBQTtFQUNBLHlCQUFBO0FDQ0QiLCJmaWxlIjoiYXBwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWJhZGdlIHtcclxuXHRmb250LXdlaWdodDogNjAwIWltcG9ydGFudDtcclxuXHRwb3NpdGlvbjogYWJzb2x1dGU7XHJcblx0dG9wOiAzcHg7XHJcblx0ei1pbmRleDogMjAwMDtcclxuXHRyaWdodDogLTMxcHg7XHJcblx0dHJhbnNmb3JtOiByb3RhdGUoNDVkZWcpO1xyXG5cdHdpZHRoOiA4N3B4O1xyXG5cdHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2U7XHJcbn0iLCJpb24tYmFkZ2Uge1xuICBmb250LXdlaWdodDogNjAwICFpbXBvcnRhbnQ7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgdG9wOiAzcHg7XG4gIHotaW5kZXg6IDIwMDA7XG4gIHJpZ2h0OiAtMzFweDtcbiAgdHJhbnNmb3JtOiByb3RhdGUoNDVkZWcpO1xuICB3aWR0aDogODdweDtcbiAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./../../New%20folder/connect-3.0/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,2BAAA;EACA,kBAAA;EACA,QAAA;EACA,aAAA;EACA,YAAA;EACA,wBAAA;EACA,WAAA;EACA,yBAAA;ACCD;ADCA,g9BAAg9B","sourcesContent":["ion-badge {\r\n\tfont-weight: 600!important;\r\n\tposition: absolute;\r\n\ttop: 3px;\r\n\tz-index: 2000;\r\n\tright: -31px;\r\n\ttransform: rotate(45deg);\r\n\twidth: 87px;\r\n\ttext-transform: uppercase;\r\n}","ion-badge {\n  font-weight: 600 !important;\n  position: absolute;\n  top: 3px;\n  z-index: 2000;\n  right: -31px;\n  transform: rotate(45deg);\n  width: 87px;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
