import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ecuName'
})
export class EcuNamePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    return this.getEcuAbbreviation(value);
  }

  private getEcuAbbreviation(EcuID: number): string {
    switch (EcuID) {
      case 1:
        return 'TCU';

      case 2:
        return 'CCU';

      case 3:
        return 'HCU';

      case 4:
        return 'JLE';

      case 5:
        return 'JRI';

      case 6:
        return 'HMI';

      case 7:
        return 'CAC1';

      case 8:
        return 'CAC2';
        
      case 9:
        return 'DCU';

      default:
        return 'Unknown';
    }
  }

}
