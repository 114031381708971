import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { canActivate, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { TokenPasswordComponent } from '@auth/pages/token-password/token-password.component';

const redirectUnauthorized = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedIn = () => redirectLoggedInTo(['']);
// const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./layouts/auth/auth.module').then((m) => m.AuthModule),
    ...canActivate(redirectLoggedIn),
  },
  {
    path: '',
    loadChildren: () => import('./layouts/pending-auth/pending-auth.module').then((m) => m.PendingAuthModule),
    ...canActivate(redirectUnauthorized),
   
  },
  {
    path: 'token',
    component: TokenPasswordComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
