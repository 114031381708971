import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription, fromEvent } from 'rxjs';
import { WorkerMessage } from './models/worker-message.model';

@Injectable()
export class WorkerService {
  workerUpdate$: Observable<WorkerMessage>;
  private worker: Worker;
  private workerSubject: Subject<WorkerMessage>;
  private workerMessageSubscription: Subscription;

  public doWork(workerMessage: WorkerMessage): void {
    if (this.worker) {
      this.worker.postMessage(workerMessage);
    }
  }

  public workerInit(): void {
    try {
      if (!!this.worker === false) {
        this.worker = new Worker(new URL('../../main.worker.ts', import.meta.url), { type: 'module' });
        this.workerSubject = new Subject<WorkerMessage>();
        this.workerUpdate$ = this.workerSubject.asObservable();
        //console.log("STARTING WORKER");
        

        this.workerMessageSubscription = fromEvent(this.worker, 'message').subscribe(
          (response: MessageEvent) => {
            // console.log("WORKER STARTED");
            
            if (this.workerSubject) {
              this.workerSubject.next(WorkerMessage.getInstance(response.data));
            }
          },
          (error) => console.error('WORKER ERROR::', error)
        );
      }
    } catch (exception) {
      console.error(exception);
    }
  }

  public close(): void {
    this.worker?.terminate();
    this.worker = undefined;
    this.workerSubject?.unsubscribe();
    this.workerMessageSubscription?.unsubscribe();
  }
}
