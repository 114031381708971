import { Injectable, NgZone } from '@angular/core';
import { arrayUpdate } from '@datorama/akita';
import { Platform } from '@ionic/angular';
import { environment } from '@env/environment';

import { FOOTER, FOOTERTCS } from '@constants/footer';

import { UserQuery } from '../user/user.query';

import { AppStore } from './app.store';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private appStore: AppStore, 
    private userQuery: UserQuery, 
    private platform: Platform,
    private zone: NgZone) { }

  public initializeAppStore(): void {
    const isCapacitor = this.platform.is('capacitor');
    const platform = this.platform.is('ios') ? 'ios' : this.platform.is('android') ? 'android' : 'web';
    const isOperator = this.userQuery.currentUser.role === 'operator';
    this.appStore.update((state) => {
      return {
        ...state,
        environment: environment.environment,
        platform,
        isCapacitor,
        isOperator,
        bleEnabled: false,
        footer: FOOTER,
        footerTCS:FOOTERTCS,
      };
    });
  }

  public SetFetchingDefinition(set: boolean) {
    this.zone.run(() => {
      this.appStore.update(() => ({fetchingDefinition: set}))
    })
  }

  public updateFooter(id: string, canAccess: boolean): void {
    this.appStore.update((state) => {
      return {
        ...state,
        footer: arrayUpdate(state.footer, id, { canAccess }),
      };
    });
  }

  public updateFooterTCS(id: string, canAccess: boolean): void {
    this.appStore.update((state) => {
      return {
        ...state,
        footerTCS: arrayUpdate(state.footerTCS, id, { canAccess }),
      };
    });
  }

  public updateBleEnabled(bleEnabled: boolean): void {
    this.appStore.update({
      bleEnabled,
    });
  }
}
