import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Timestamp } from '@firebase/firestore';

import { IFirmwareUpgrade, IRequest, IparameterForUpdate, IstringParameterForUpdate } from '@interfaces/request.interface';
import { ICurrentUser } from '@interfaces/user.interface';

import { UserQuery } from '@state/user/user.query';
import { RemoteUserService } from '@state/remote-user/remote-user.service';

import { REMOTESUPPORT } from '@constants/remote-support';
import { CONFIG } from '@constants/config';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';
import { HttpClient } from '@angular/common/http';

import { RemoteUserQuery } from '@state/remote-user/remote-user.query';
import { IAlarmLog } from '@shared/interfaces/alarm-log.interface';
import { IAlarm } from '@shared/interfaces/alarm.interface';
import { AckAlarmResponseEvent, AlarmlogChangedEvent, ClearAlarmResponseEvent } from 'connectapi/src/ApiMessage/apiResponseTypes';
import { AckAlarmResponse } from 'connectapi/src/CAN_Messages/AlarmResponse';
import { alarms } from '@shared/constants/mock';
import { AlarmService } from '@shared/state/alarm/alarm.service';
import { FirestoreService } from './firestore.service';
import { DeviceQuery } from '@state/device/device.query';
import { IonLoaderService } from '@shared/services/loader.service';
import { TcsDashboardComponent } from '@main/pages/tcs-dashboard/tcs-dashboard.component';

@Injectable({
  providedIn: 'root',
})
export class RemoteSupportService {

  public systemType$ = this.deviceQuery.systemType$;
  constructor(
    private alertController: AlertController,
    private angularFirestore: AngularFirestore,
    private storageService: StorageService,
    private userQuery: UserQuery,
    private translateService: TranslateService,
    private remoteUserService: RemoteUserService,
    private router: Router,
    private toastService: ToastService,
    private httpClient: HttpClient,
    private remoteUserQuery: RemoteUserQuery,
    private firestoreService: FirestoreService,
    private deviceQuery: DeviceQuery,
    private ionLoaderService: IonLoaderService,
  ) { }

  /**
   *  Service-tool start
   */
  public initiatePairing(operatorUid: string): Promise<DocumentReference> {
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;
    return this.angularFirestore
      .collection('users')
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'remote-support',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
      });
  }

  public sendFiremware(operatorUid: string, firmwareUpgrade: IFirmwareUpgrade) {
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;
    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'download-firmware',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
        firmwareUpgrade,
      });
  }

  public subscribeToRequest(operatorUid: string, requestUid: string): Observable<IRequest> {
    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection(CONFIG.firebaseCollection.remoteRequests)
      .doc<IRequest>(requestUid)
      .valueChanges()
      .pipe(
        map((data) => ({
          ...data,
          requestDate: data.requestDate && (data.requestDate as Timestamp).toDate(),
          responseDate: data.responseDate && (data.responseDate as Timestamp).toDate(),
        })),
        take(3)
      );
  }

  public async showServiceToolConnectionAlert(request: IRequest, user: ICurrentUser, docId?: string): Promise<void> {
    const requestDate = request.requestDate.toLocaleString(this.translateService.currentLang);
    const header = await this.translateService.instant(`alerts.remote-support.headerService`);
    // const header = `<ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>`;
    const abort = await this.translateService.instant(`alerts.remote-support.abort`);
    const message = await this.translateService.instant(`alerts.remote-support.messageService`,
      {
        userName: user.firstName + " " + user.lastName,
      }
    );

    const body = `
    <div class="text-center">
      <div class="m-auto w-1/5 flex">
        <ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>
      </div>
    
      <p class="text-26pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;

    const alert = await this.alertController.create({
      // header,
      // subHeader: requestDate,
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      id: 'serviceToolConnection',
      buttons: [
        {
          text: abort,
          role: 'abort',
          cssClass: 'fat-alert-button'
        }
      ],
    });
    alert.present();

    alert.onDidDismiss().then((res) => {
      console.log('res: ', res);
      if (res.role === 'abort') {
        this.updateFirestoreRequestWithAborted(user.uid, docId, false, true)
      }
    })
  }

  public async removeServiceToolConnectionAlert(): Promise<void> {
    await this.alertController.dismiss('serviceToolConnection');
  }

  public async removeOperatorServicePairingAlert(): Promise<void> {
    await this.alertController.dismiss('OperatorServiceConnection');
  }

  public async showServiceToolAlert(request: IRequest, user: ICurrentUser): Promise<void> {
    if (!request.aborted) {
      const responseDate = request.requestDate.toLocaleString(this.translateService.currentLang);
      const message = await this.translateService.instant(
        `alerts.${request.type}.${request.response ? 'serviceToolAccepted' : 'serviceToolDeclined'}`,
        {
          userName: user.firstName + " " + user.lastName,
        }
      );
      const redirectText = await this.translateService.instant(`alerts.remote-support.click here`);
      const closeText = await this.translateService.instant(`alerts.remote-support.close`);
      const header = request.response ? await this.translateService.instant(`alerts.remote-support.accepted`) : await this.translateService.instant(`alerts.remote-support.rejected`);

      const body = `
      <div class="text-center">
        <div class="m-auto w-1/5 flex">
          <ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>
        </div>
      
        <p class="text-30pt text-roto-green py-2 font-semibold">${header}</p>
        <p class=" text-20pt text-roto-green">${message}</p>
      </div>`;

      const buttons = [request.response ? { text: redirectText, role: 'redirect' } : { text: closeText, role: 'close' }];
      const alert = await this.alertController.create({
        message: body,
        keyboardClose: false,
        backdropDismiss: false,
        buttons,
      });
      alert.present();

      alert.onDidDismiss().then((res) => {
        console.log('res: ', res);
        if (res.role === 'redirect') {
          // Remote support allowed, redirect to page
          console.log('remote support initialized, redirect to page for user view');
          this.remoteUserService.initialize(user, request);
          this.remoteUserService.subscribeDevice(user);
          //this.ionLoaderService.simpleLoader();
          setTimeout(() => {
                 
            this.systemType$?.subscribe(item => {
              if(item !== 'TCS'){
                this.router.navigate(['system/', item, user.uid, 'service'])
                //this.ionLoaderService.dismissLoader();
              } else if (item === 'TCS'){
                this.router.navigate(['system/',item, user.uid, 'TcsDashboard'])
                //this.ionLoaderService.dismissLoader();
              }
            })
          }, 1000)  
          //this.router.navigate(['/service', user.uid, 'service']);
        }
      });
    }
  }

  public sendUpdateParameterData(parameters: IparameterForUpdate[]): Promise<DocumentReference> {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const operatorUserName = this.remoteUserQuery.connectedUser.firstName + " " + this.remoteUserQuery.connectedUser.lastName;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;

    // this.showServiceParametersUpdated(parameters)

    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'update-parameters',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
        parametersForUpdate: parameters,
        updatedParameters: []
      });
  }

  public sendUpdateStringParameterData(parameters: IstringParameterForUpdate[]): Promise<DocumentReference> {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;
    console.log(operatorUid, 'operatorUid')
    console.log(parameters)
    // this.showServiceParametersUpdated(parameters)

    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'update-string-parameters',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
        stringParametersForUpdate: parameters,
        updatedStringParameters: []
      });



  }



  public sendReadStringParameterData(params: number[]) {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;
    console.log(operatorUid, 'operatorUid')
    if (operatorUid) {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.users)
        .doc(operatorUid)
        .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
        .add({
          requestDate: new Date(),
          type: 'get-parameters',
          responseDate: null,
          responded: false,
          response: null,
          serviceUserUid: userUid,
          serviceUserName: userName,
          parametersToFetch: params
        });
    }
  }

  public subscribeToParameterUpdate(operatorUid: string, requestUid: string): Observable<IRequest> {
    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection(CONFIG.firebaseCollection.remoteRequests)
      .doc<IRequest>(requestUid)
      .valueChanges()
      .pipe(
        map((data) => ({
          ...data,
          requestDate: data.requestDate && (data.requestDate as Timestamp).toDate(),
          responseDate: data.responseDate && (data.responseDate as Timestamp).toDate(),
        }))
      );
  }

  public sendClearAlarm(alarm: IAlarm): Promise<DocumentReference> {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const operatorUserName = this.remoteUserQuery.connectedUser.firstName + " " + this.remoteUserQuery.connectedUser.lastName;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;

    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'clear-alarm',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
        alarmToClear: alarm,
        clearedAlarm: { alarm: {} as IAlarm, alarmResponse: {} as AckAlarmResponseEvent }
      });
  }

  public sendClearAlarmLog(clearType: 'deep' | 'temp'): Promise<DocumentReference> {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;

    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'clear-alarm-log',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
        clearType: clearType,
        clearedAlarmLog: []
      });
  }

  public sendGetAlarmLog(): Promise<DocumentReference> {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;

    return this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'get-alarm-log',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
      });
  }

  public sendStartLiveData(): void {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;

    this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(operatorUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'live-data',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName
      });
  }

  
  public sendStopLiveData(): void {

    this.firestoreService.endLiveData();
   
  }

  public sendAutoCalibrate(parameters: IparameterForUpdate[], stopCalibrate?: boolean): void {
    const operatorUid = this.remoteUserQuery.connectedUser.uid;
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;

    if (stopCalibrate) {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.users)
        .doc(operatorUid)
        .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
        .add({
          requestDate: new Date(),
          type: 'auto-calibrate',
          responseDate: null,
          responded: false,
          response: null,
          serviceUserUid: userUid,
          serviceUserName: userName,
          stopCalibrate: stopCalibrate
        });
    } else {
      this.angularFirestore
        .collection(CONFIG.firebaseCollection.users)
        .doc(operatorUid)
        .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
        .add({
          requestDate: new Date(),
          type: 'auto-calibrate',
          responseDate: null,
          responded: false,
          response: null,
          serviceUserUid: userUid,
          serviceUserName: userName,
          autoCalibrateParameters: parameters
        });
    }
  }

  public async showServiceParametersUpdated(parameters: IparameterForUpdate[]) {
    const message = this.translateService.instant('alerts.remote-update-parameters.message', { parametersUpdated: parameters.length });
    const header = this.translateService.instant('alerts.remote-update-parameters.header');

    const alert = await this.alertController.create({
      header,
      message,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
        }
      ],
    });
    alert.present();
  }

  public async confirmDeepAlarmLogClear(): Promise<any> {
    const header = this.translateService.instant(`alarmlogs.clear alarm log header`);
    const message = this.translateService.instant(`alarmlogs.clear alarm log confirmation`);
    const yes = this.translateService.instant(`general.yes`);
    const no = this.translateService.instant(`general.no`);
    const alert = await this.alertController.create({
      header,
      message,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: no,
          role: 'declined',
        },
        {
          text: yes,
          role: 'accepted',
        },
      ],
    });

    alert.present();

    return alert.onDidDismiss().then((res: { data: {}; role: 'accepted' | 'declined' }) => {
      console.log('res.', res);
      return res.role;
    });
  }

  public async showServiceRemoteSupportStopAlert(userUid: string, request: IRequest): Promise<void> {
    const header = this.translateService.instant(`alerts.remote-support.stopped`);
    const message = this.translateService.instant(`alerts.remote-support.stopped message`, { userName: request.serviceUserName });
    const alert = await this.alertController.create({
      header,
      message,
      cssClass: request.type,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });

    alert.present();
    this.remoteUserService.removeDataSubscriptions();
  }

  /**
   *  Service-tool end
   */

  /**
   * Operator start
   */

  public showServiceSupportStop(serviceUid: string) {
    this.firestoreService.endLiveData();
    this.remoteUserService.setRemoteSessionInActive();
    
    const userUid = this.userQuery.userUid;
    const userName = this.userQuery.name;
    this.angularFirestore
      .collection(CONFIG.firebaseCollection.users)
      .doc(serviceUid)
      .collection<IRequest>(CONFIG.firebaseCollection.remoteRequests)
      .add({
        requestDate: new Date(),
        type: 'remote-support-stop',
        responseDate: null,
        responded: false,
        response: null,
        serviceUserUid: userUid,
        serviceUserName: userName,
      });
      
  }

  public async showOperatorRemoteSupportStopAlert(userUid: string, request: IRequest): Promise<void> {
    const header = this.translateService.instant(`alerts.remote-support.stopped`);
    const message = this.translateService.instant(`alerts.remote-support.stopped message`, { userName: request.serviceUserName });

    const body = `
      <div class="text-center">
        <div class="m-auto w-1/5 flex">
          <ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>
        </div>
      
        <p class="text-30pt text-roto-green py-2 font-semibold">${header}</p>
        <p class=" text-20pt text-roto-green">${message}</p>
      </div>`;

    const alert = await this.alertController.create({
      message: body,
      cssClass: request.type,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });

    alert.present();
    
    this.remoteUserService.resetStoreTotally();
  }

  public async supportStopConfirmation(serviceUid: string): Promise<void> {
    const header = this.translateService.instant(`alerts.remote-support.stop confirm`);
    const message = this.translateService.instant(`alerts.remote-support.stop confirm message`)
    const confirm = this.translateService.instant(`general.yes`);
    const abort = this.translateService.instant(`alerts.remote-support.abort`);

    const body = `
      <div class="text-center">
        <div class="m-auto w-1/5 flex">
          <ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>
        </div>
      
        <p class="text-30pt text-roto-green py-2 font-semibold">${header}</p>
        <p class=" text-20pt text-roto-green">${message}</p>
      </div>`;

    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: confirm,
          role: 'ok',
        },
        {
          text: abort,
          role: 'cancel',
        },
      ],
    });

    alert.present();
    
    alert.onDidDismiss().then((res: { data: {}; role: 'ok' | 'cancel' }) => {
      console.log('res.', res);
      if (res.role === 'ok') {

        this.showServiceSupportStop(serviceUid);
      }
    });
  }

  public async showOperatorRemoteSupportAlert(userUid: string, request: IRequest): Promise<void> {
    if (!request.aborted) {
      const requestRequestDate = request.requestDate.toLocaleString(this.translateService.currentLang);
      console.log('request: ', request);
      const requestData = REMOTESUPPORT.find((data) => data.type === request.type);
      console.log('requestData: ', requestData);
      const header = this.translateService.instant(requestData.header);
      const message = this.translateService.instant(requestData.message, { userName: request.serviceUserName });

      const accept = this.translateService.instant('alerts.accept');
      const decline = this.translateService.instant('alerts.decline');

      const body = `
      <div class="text-center">
        <div class="m-auto w-1/5 flex">
          <ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>
        </div>
      
        <p class="text-30pt text-roto-green py-2 font-semibold">${header}</p>
        <p class=" text-20pt text-roto-green">${message}</p>
      </div>`;

      // OverlayBaseController<AlertOptions, HTMLIonAlertElement>.create(opts?: AlertOptions

      const alert = await this.alertController.create({
        message: body,
        cssClass: request.type,
        keyboardClose: false,
        backdropDismiss: false,
        id: 'OperatorServiceConnection',
        buttons: [
          {
            text: accept,
            role: 'accepted',
            cssClass: 'fat-alert-button'
          },
          {
            text: decline,
            role: 'declined',
            cssClass: 'text-transform-none'
          },
        ],
      });

      alert.present();

      alert.onDidDismiss().then((res: { data: {}; role: 'accepted' | 'declined' }) => {
        console.log('res.', res);
        const response = res.role === 'accepted';
        this.updateFirestoreRequest(userUid, request.uid, response);
        if (res.role === 'accepted') {
          this.remoteUserService.setRemoteSessionActive(request.serviceUserUid);
        }
      });
    } else {
      this.removeOperatorServicePairingAlert();
      this.showPairingAbortedAlert(userUid, request)
    }

  }

  public async showPairingAbortedAlert(userUid: string, request: IRequest): Promise<void> {
    const header = this.translateService.instant(`alerts.remote-support.aborted`);
    const message = this.translateService.instant(`alerts.remote-support.aborted message`, { userName: request.serviceUserName });

    const body = `
      <div class="text-center">
        <div class="m-auto w-1/5 flex">
          <ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>
        </div>
      
        <p class="text-30pt text-roto-green py-2 font-semibold">${header}</p>
        <p class=" text-20pt text-roto-green">${message}</p>
      </div>`;
    const alert = await this.alertController.create({
      message: body,
      cssClass: request.type,
      keyboardClose: false,
      backdropDismiss: false,
      id: 'OperatorServiceConnection',
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
          cssClass: 'no-button-borders'
        },
      ],
    });

    alert.present();

    alert.onDidDismiss().then(() => {
      this.updateFirestoreRequest(userUid, request.uid, false);
      // this.updateFirestoreRequestWithAborted(userUid, request.uid, false, true)
    });
  }

  public async showOperatorFirmwareDownloadAlert(userUid: string, request: IRequest): Promise<void> {
    // const requestRequestDate = request.requestDate.toLocaleString(this.translateService.currentLang);
    // console.log('show modal here');
    const requestData = REMOTESUPPORT.find((data) => data.type === request.type);
    // console.log('requestData: ', requestData);
    const header = this.translateService.instant(requestData.header);
    const message = this.translateService.instant(requestData.message, {
      userName: request.serviceUserName,
      version: request.firmwareUpgrade.version,
    });

    const body = `<div class="text-center">
        <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
        <p class=" text-20pt text-roto-green">${message}</p>
      </div>`;

    const download = this.translateService.instant('alerts.download');
    const later = this.translateService.instant('alerts.later');
    const alert = await this.alertController.create({
      message: body,
      cssClass: request.type,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: download,
          role: 'download',
          cssClass: 'fat-alert-button'
        },
        {
          text: later,
          role: 'later',
        },
      ],
    });

    alert.present();

    alert.onDidDismiss().then(async (res: { data: {}; role: 'download' | 'later' }) => {
      console.log('alert res: ', res);
      switch (res.role) {
        case 'download':
          const response = await this.storageService.beginDownload(request.firmwareUpgrade.fullPath);
          if (response) {
            this.updateFirestoreRequest(userUid, request.uid, response);
          }
          break;
        case 'later':
          this.toastService.infoToast('toasts.restart app download', true);
          break;
        default:
          break;
      }
    });
  }

  public async showOperatorParameterUpdateAlert(request: IRequest): Promise<void> {
    const requestRequestDate = request.requestDate.toLocaleString(this.translateService.currentLang);
    const requestData = REMOTESUPPORT.find((data) => data.type === request.type);
    const message = this.translateService.instant(requestData.message, { parametersUpdated: request.parametersForUpdate.length });
    const header = this.translateService.instant(requestData.header);

    const alert = await this.alertController.create({
      header,
      subHeader: requestRequestDate,
      message,
      cssClass: request.type,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
          cssClass: 'no-button-borders'
        }
      ],
    });
    alert.present();
  }

  public async showOperatorLiveDataAlert(request: IRequest): Promise<void> {
    const requestRequestDate = request.requestDate.toLocaleString(this.translateService.currentLang);
    const requestData = REMOTESUPPORT.find((data) => data.type === request.type);
    const message = this.translateService.instant(requestData.message, { userName: request.serviceUserName });
    const header = this.translateService.instant(requestData.header);

    const body = `<div class="text-center">
        <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
        <p class=" text-20pt text-roto-green">${message}</p>
      </div>`;

    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
          cssClass: 'no-button-borders'
        }
      ],
    });
    alert.present();
  }

  public async alertOperatorOnAutoCalibrate(request: IRequest, stopAutoCalibrate?: boolean): Promise<void> {
    const requestRequestDate = request.requestDate.toLocaleString(this.translateService.currentLang);
    const requestData = REMOTESUPPORT.find((data) => data.type === request.type);
    let message;
    let header;
    if (stopAutoCalibrate) {
      message = this.translateService.instant('alerts.remote-stop-auto-calibrate.message', { userName: request.serviceUserName });
      header = this.translateService.instant('alerts.remote-stop-auto-calibrate.header');
    } else {
      message = this.translateService.instant(requestData.message, { userName: request.serviceUserName });
      header = this.translateService.instant(requestData.header);
    }

    const body = `<div class="text-center">
      <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;

    const alert = await this.alertController.create({
      message: body,
      cssClass: request.type,
      keyboardClose: false,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
        }
      ],
    });
    alert.present();
  }

  public updateFirestoreRequest(operatorUid: string, requestUid: string, response: boolean): void {
    this.angularFirestore
      .collection('users')
      .doc(operatorUid)
      .collection(CONFIG.firebaseCollection.remoteRequests)
      .doc<IRequest>(requestUid)
      .update({
        responded: true,
        response,
        responseDate: new Date(),
      });
  }

  public updateFirestoreRequestWithAborted(operatorUid: string, requestUid: string, response: boolean, aborted: boolean): void {
    this.angularFirestore
      .collection('users')
      .doc(operatorUid)
      .collection(CONFIG.firebaseCollection.remoteRequests)
      .doc<IRequest>(requestUid)
      .update({
        responded: false,
        response,
        responseDate: new Date(),
        aborted: aborted
      });
  }
  /**
   * Operator end
   */
}