import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ICurrentDevice,  IParameterData, stringparametertemp } from '@interfaces/device.interface';
import { IPeriodicData } from '@interfaces/device.interface';

import { IRemoteUser } from '@interfaces/remote-user.interface';
import { ICurrentUser } from '@interfaces/user.interface';
import { IPreviousDevice } from '@interfaces/device.interface';

import { RemoteUserStore } from './remote-user.store';

@Injectable({ providedIn: 'root' })
export class RemoteUserQuery extends Query<IRemoteUser> {
  constructor(protected store: RemoteUserStore) {
    super(store);
  }

  isActive$ = this.select((state) => state.isActive);

  connectedUser$ = this.select((state) => state.connectedUser);

  connectedUserUid$ = this.select((state) => state.connectedUser?.uid);

  device$ = this.select((state) => state.device);

  lastConnected$ = this.select((state) => state.device?.lastConnected);

  previousDevices = this.select((state) => state.previousDevices);

  remoteRequest$ = this.select((state) => state.remoteRequest);

  parameterdata$ = this.select((state) => state.parameterData);

  stringParameterData$ = this.select((state) => state.stringParameterData);

  periodicData$ = this.select((state) => state.periodicData);

  autoCalibrationStarted$ = this.select((state) => state.autoCalibrationStarted);

  pendingParameters$ = this.select((state) => state.pendingParameters);

  connectionIsApproved$ = this.select((state) => state.connectionIsApproved);
  
  pendingStringParameters$ = this.select((state) => state.pendingStringParameters);

  isOffline$ = this.select((state) => state.isOffline);

  get isActive(): boolean {
    return !!this.getValue().isActive;
  }

  get isOffline(): boolean {
    return !!this.getValue().isOffline;
  }
  get stringParameterData(): stringparametertemp[] {
    return this.getValue().stringParameterData;
  }

  get parameterData(): IParameterData[] {
    return this.getValue().parameterData;
  }

  get connectedUser(): ICurrentUser {
    return this.getValue().connectedUser;
  }

  get device(): IPreviousDevice | ICurrentDevice {
    return this.getValue().device;
  }

  get periodicData(): IPeriodicData[] {
    return this.getValue().periodicData;
  }

  get autoCalibrationStatus(): boolean {
    return this.getValue().autoCalibrationStarted;
  }

  specificPeriodicData$(fieldId: number): Observable<IPeriodicData> {
    return this.select(
      (state) => state.periodicData?.length > 0 && state.periodicData.find((data) => data.fieldId === fieldId)
    );
  }

  specificParameterData$(parameterId: number): Observable<IParameterData> {
    return this.select(
      (state) => state.parameterData?.length > 0 && state.parameterData.find((data) => data.parameterId === parameterId)
    );
  }

  public specificStringParameterData(parameterId: number): stringparametertemp {
    return this.getValue().stringParameterData.find((data) => data.paramId === parameterId);
  }

  public specificParameterData(parameterId: number): IParameterData {
     //console.log(this.parameterData)

     return this.getValue().parameterData.find((data) => data.parameterId === parameterId);
  }

  public specificPeriodicData(fieldId: number): IPeriodicData {
    return this.getValue().periodicData.find((data) => data.fieldId === fieldId);
  }
}
