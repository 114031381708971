import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IDevice } from '@interfaces/device.interface';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'device', resettable: true })
export class DeviceStore extends Store<IDevice> {
  constructor() {
    super({});
  }
}
