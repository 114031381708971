
import { ReadParameterCommand } from "../ApiMessage/apiCommandTypes";
import { ParameterChangedEvent } from "../ApiMessage/apiResponseTypes";
import { ParamResponseResult } from "../CAN_Messages/CanMessages";
import { Parameter, PeriodicParameter, PeriodicDataDefinition, ParameterDefinition } from "./ParameterDefinition";

type PGN = number;
type ParameterID = number;
type FieldID = number;
type Timestamp = number;

export class ParameterHandler {

  private initResponseMap: Map<number, ParameterChangedEvent | null>;
  private parameterReadInProgress: boolean = false;
  private readonly PARAMETER_TIMEOUT_MS = 4000;
  private readonly PARAMETER_ERROR_VALUE = -1;
  private static instance: ParameterHandler;

  private parameterTimeoutMap: Map<ParameterID, Timestamp[]>;
  private parameterMap: Map<ParameterID, Parameter>;
  private pgnPeriodicMsgMap: Map<PGN, Map<FieldID, PeriodicParameter>>;

  private constructor() {
    this.parameterTimeoutMap = new Map<ParameterID, Timestamp[]>();
    this.parameterMap = new Map<ParameterID, Parameter>();
    this.pgnPeriodicMsgMap = new Map<PGN, Map<FieldID, PeriodicParameter>>();
    this.initResponseMap = new Map<number, ParameterChangedEvent | null>();
  }

  public static GetInstance(): ParameterHandler {
    if (this.instance) {
      return this.instance;
    } else {
      this.instance = new ParameterHandler();
      return this.instance;
    }
  }

  get ParameterTimeoutMap(): Map<number, number[]> {
    return this.parameterTimeoutMap;
  }

  public clearParameterTimeoutMap() {
    this.parameterTimeoutMap.clear();
  }

  public GetParameter(paramId: ParameterID): Parameter | undefined {
    return this.parameterMap.get(paramId);
  }

  public GetUnitPeriodicMessagesMap(pgn: PGN): Map<FieldID, PeriodicParameter> | undefined {
    return this.pgnPeriodicMsgMap.get(pgn);
  }

  public GetPgnPeriodicMessageFieldIdsMap(pgn: PGN, fieldId: FieldID): PeriodicParameter | undefined {
    return this.pgnPeriodicMsgMap.get(pgn)?.get(fieldId);
  }

  public SetParameterMap(parameterMap: Map<ParameterID, Parameter>): void {
    this.parameterMap = parameterMap;
  }

  public SetPgnPeriodicMessagesMap(periodicParamsMap: Map<PGN, Map<FieldID, PeriodicParameter>>): void {
    this.pgnPeriodicMsgMap = periodicParamsMap;
  }

  get ParameterMap(): Map<ParameterID, Parameter> {
    return this.parameterMap;
  }

  set ParameterMap(parMap: Map<number, Parameter>) {
    this.parameterMap = parMap;
  }

  get UnitPeriodicMessagesMap(): Map<number, Map<number, PeriodicParameter>> {
    return this.pgnPeriodicMsgMap;
  }

  set UnitPeriodicMessagesMap(periodicMsgMap: Map<number, Map<number, PeriodicParameter>>) {
    this.pgnPeriodicMsgMap = periodicMsgMap;
  }

  public ExtractUnitDefinitionData(ecuId: number, paramDefinition: ParameterDefinition, periodicDefinition: PeriodicDataDefinition): void {
    for (const param of paramDefinition.parameterList) {
      param.ecuId = ecuId;
      this.parameterMap.set(param.ParameterId, param);
    }

    for (const param of periodicDefinition.periodicDataFieldList) {
      param.ecuId = ecuId;
      let periodicMap = this.pgnPeriodicMsgMap.get(param.PGN);
      if (periodicMap) {
        periodicMap.set(param.FieldId, param);
      } else {
        periodicMap = new Map<FieldID, PeriodicParameter>();
        periodicMap.set(param.FieldId, param);
        this.pgnPeriodicMsgMap.set(param.PGN, periodicMap);
      }
    }
  }

  public addReadRequest(initCommand: ReadParameterCommand) {
    console.trace();
    this.parameterReadInProgress = true;
    initCommand.ParameterIds.forEach((pid) => {
      this.initResponseMap.set(pid, null);
    });
  }

  public parameterReadRequestIsPending(ParameterId: number):boolean {
    let result = true;
    if(!this.parameterReadInProgress){
      result = false;
    }
    if(!this.initResponseMap.has(ParameterId)){
      result = false;
    }  
    return result;
  }



  public checkReadResponses(): ParameterChangedEvent[] | null {
    let initCompleted = false;
    let resultArray: ParameterChangedEvent[] = [];
    if (this.parameterReadInProgress) {
      initCompleted = true;
      this.initResponseMap.forEach((value: ParameterChangedEvent | null, key: number) => {
        if (value == null) {
          initCompleted = false;
        } else {
          resultArray.push(value);
        }
      });
    }
    if (initCompleted) {
      this.parameterReadInProgress=false;
      this.initResponseMap.clear();
      return resultArray;
    } else {
      return null;
    }
  }

  public setParameterTimeout(parameterID: number, timestampMs: number) {
    // TODO: Sätta ett maximum på utestående frågor
    if (this.parameterTimeoutMap.has(parameterID)) {
      this.parameterTimeoutMap.get(parameterID)?.push(timestampMs);
    } else {
      this.parameterTimeoutMap.set(parameterID, [timestampMs]);
    }
  }

  public parameterResponseReceived(respEvent: ParameterChangedEvent) {
    let responseParamID = respEvent.ParameterId;
    this.removeParameterTimeout(responseParamID);
    if (this.parameterReadInProgress) {
      this.initResponseMap.set(responseParamID, respEvent);
    }
  }

  private removeParameterTimeout(parameterID: number) {
    const parameterRequestList = this.parameterTimeoutMap.get(parameterID);
    if (parameterRequestList) {
      parameterRequestList.shift();
    }
  }

  public checkParameterTimeout(elapsedMs: number): ParameterChangedEvent[] {
    let parameterTimeoutList: ParameterChangedEvent[] = [];
    for (const parameterID of this.parameterTimeoutMap.keys()) {
      let parameterTimestampsQueue = this.parameterTimeoutMap.get(parameterID);
      if (parameterTimestampsQueue) {
        for (const parameterTimestamp of parameterTimestampsQueue) {
          if (elapsedMs - parameterTimestamp > this.PARAMETER_TIMEOUT_MS) {
            const timeoutEvent = new ParameterChangedEvent(parameterID, this.PARAMETER_ERROR_VALUE, ParamResponseResult.TIMEOUT);
            this.parameterResponseReceived(timeoutEvent);
            parameterTimestampsQueue.shift();
            parameterTimeoutList.push(timeoutEvent);
          }
        }
      }
    }

    return parameterTimeoutList;
  }
}
