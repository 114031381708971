import { Component } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';
import { FirmwareQuery } from '@shared/state/firmware/firmware.query';

@Component({
  selector: 'app-firmware-upgrade',
  templateUrl: './firmware-upgrade.component.html',
})
export class FirmwareUpgradeComponent {
  public upgradeProgress$ = this.firmwareQuery.upgradeProgress$;

  constructor(private firmwareQuery: FirmwareQuery, private modalService: ModalService) {}

  public closeModal(): void {
    this.modalService.closeFirmwareUpgradeModal();
  }
}
