import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IFirebaseToken } from '@interfaces/token-password.interface';

import { AuthService } from '@services/auth.service';
import { ToastService } from '@services/toast.service';
//import * as firebase from 'firebase';

import { getAuth, applyActionCode, Auth } from 'firebase/auth';

@Component({
  selector: 'app-token-password',
  templateUrl: './token-password.component.html',
})
export class TokenPasswordComponent {
  public tokenPasswordForm: UntypedFormGroup;

  public firebaseToken: IFirebaseToken;

  public reqHandeled = false;

  public emailVerificationMailHasExpired = false;

  private auth: Auth;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    this.tokenPasswordForm = formBuilder.group({
      newPassword: ['', Validators.required],
    });

    this.auth = getAuth(); // Initialize Firebase Auth

    // Catch url token
    route.queryParams.subscribe(async (firebaseToken: IFirebaseToken) => {
     // console.log('firebaseToken: ', firebaseToken);

      this.firebaseToken = firebaseToken;
      if (!firebaseToken || !firebaseToken.oobCode) {
        this.router.navigateByUrl("/");
      }
      if (firebaseToken && firebaseToken.oobCode && !this.reqHandeled) {
        await this.handleVerifyEmail(firebaseToken.oobCode)
        this.reqHandeled = true;
      }
    });
  }

  private async handleVerifyEmail( actionCode: string) {

    try {
      await applyActionCode(this.auth, actionCode);
      // Email address has been verified. Add any additional logic you want here.
      // TODO: Display a confirmation message to the user.
      // You could also provide the user with a link back to the app.
  
      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    } catch (error) {
      this.emailVerificationMailHasExpired = true;
      // Handle errors here (e.g., invalid or expired code).
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    }
/*
    return await auth.applyActionCode(actionCode).then((resp) => {
      // Email address has been verified.
      
      
      // TODO: Display a confirmation message to the user.
      // You could also provide the user with a link back to the app.
  
      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
      this.emailVerificationMailHasExpired = true;
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    });*/
  }

  public changePassword(): void {
    const newPassword = (this.tokenPasswordForm.value as { newPassword: string }).newPassword;

    this.authService
      .confirmPasswordReset(this.firebaseToken.oobCode, newPassword)
      .then(() => {
        this.toastService.successToast('auth.password changed', true, 'auth.change password');
        this.router.navigate(['/']);
      })
      .catch((err) => {
        console.log('err: ', err);
        this.toastService.warningToast('auth.errors.' + err.code, true);
      });
  }

  public goToSignIn(): void {
    this.authService.signOut();
    // this.router.navigate(['auth/signin']);
  }
}
