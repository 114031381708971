import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { INewPassword } from '@shared/interfaces/new-password.interface';
import { UserService } from '@shared/state/user/user.service';
import { Platform } from '@ionic/angular';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public notMobileApp:boolean = false;
  public updatePasswordForm: UntypedFormGroup;
  public loading = false;
  public errorMessage = '';
  public wrongPassword = false;
  public misMatchPassword = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
    private platform: Platform,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.updatePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required],
    });

    this.checkPlatform()
  }

  public updatePassword() {
    this.preUpdate();
    this.loading = true;
    this.errorMessage = '';
    const data = this.updatePasswordForm.value as INewPassword;
    if (data.confirmNewPassword === data.newPassword) {
      this.userService.changePassword(data).then(res => {
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        if (err === 'wrong password') {
          this.wrongPassword = true;
          this.errorMessage = this.translateService.instant('auth.wrong password');
        }
        else if (err === 'passwords does not match') {
          this.misMatchPassword = true;
          this.errorMessage = this.translateService.instant('auth.errors.password-no-match');
        } 
      });
    } else {
      this.loading = false;
      this.misMatchPassword = true;
      this.errorMessage = this.translateService.instant('auth.errors.password-no-match');
    }
  }

  public goBack() {
    this.router.navigate(['/start'])
  }

  private preUpdate() {
    this.errorMessage = '';
    this.loading = true;
    this.wrongPassword = false;
    this.misMatchPassword = false;
  }

   
  private checkPlatform(): void {
    
    
    if (this.platform.is('capacitor')) {
      this.notMobileApp= false
      
    } else {
      
      this.notMobileApp= true

    }
  } 

 
  public closeChangePasswordModal() {
    this.modalService.closeChangePasswordModal();
   
  }

}
