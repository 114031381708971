import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ResetPasswordComponent } from '@auth/pages/reset-password/reset-password.component';
import { SignUpComponent } from '@auth/pages/sign-up/sign-up.component';

import { IAlarm } from '@interfaces/alarm.interface';
import { IHelpModal } from '@interfaces/help-modal.interface';

import { AlarmViewComponent } from '@main/pages/alarm-view/alarm-view.component';
import { AlarmLogComponent } from '@main/pages/alarm-log/alarm-log.component';
import { BleDevicesComponent } from '@main/pages/ble-devices/ble-devices.component';
import { SystemDetailsComponent } from '@main/pages/system-details/system-details.component';
import { CalibrationModalComponent } from '@main/pages/calibration-modal/calibration-modal.component';
import { HelpModalComponent } from '@main/pages/help-modal/help-modal.component';
import { IDynamicModal } from '@shared/interfaces/dynamic-modal.interface';
import { DynamicModalComponent } from '@main/pages/dynamic-modal/dynamic-modal.component';
import { FirmwareUpgradeComponent } from '@main/pages/firmware-upgrade/firmware-upgrade.component';
import { IAlarmLog } from '@shared/interfaces/alarm-log.interface';
import { IParameterData } from '@shared/interfaces/device.interface';
import { SparePartModalComponent } from '@main/pages/spare-part-modal/spare-part-modal.component';
import { MyProfileComponent } from '@main/pages/my-profile/my-profile.component';
import { ChangePasswordComponent } from '@main/pages/change-password/change-password.component'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalController: ModalController) {}

  // Auth start
  public createResetPasswordModal() {
    const modal = this.modalController.create({
      id: 'ResetPasswordComponent',
      component: ResetPasswordComponent,
      cssClass: 'auto-height',
    });
    this.presentModal(modal);
  }
  // Auth end

  // Alarm start
  public openAlarm(alarm: IAlarm | IAlarmLog): void {
    const modal = this.modalController.create({
      component: AlarmViewComponent,
      id: 'AlarmViewComponent',
      cssClass: 'auto-height',
      componentProps: { alarm },
    });
    this.presentModal(modal);
  }

  public openAlarmLog(): void {
    const modal = this.modalController.create({
      id: 'AlarmLogComponent',
      component: AlarmLogComponent,
      cssClass: 'auto-height',
    });
    this.presentModal(modal);
  }
  // Alarm end

  // Connection start
  public openBleDevice(): void {
    const modal = this.modalController.create({
      id: 'BleDevicesComponent',
      component: BleDevicesComponent,
    });
    this.presentModal(modal);
  }
  // Connection end

  // Dynamic modal
  public openDynamicModal(content: IDynamicModal) {
    const modal = this.modalController.create({
      id: 'DynamicModalComponent',
      component: DynamicModalComponent,
      cssClass: 'auto-height',
      componentProps: { content },
    });
    this.presentModal(modal);
  }

  public dismissModal(id: string): void {
    this.modalController.dismiss({}, '', id);
  }

  private presentModal(modal: Promise<HTMLIonModalElement>) {
    modal.then((modalRes) => modalRes.present());
  }

  public async openDetailsModal(service: any): Promise<any> {
    const modal = await this.modalController.create({
      id: 'SystemDetailsComponent',
      component: SystemDetailsComponent,
      cssClass: 'auto-height',
      componentProps: { service },
    });
    modal.present();
  }

  public async closeDetailsModal(): Promise<void> {
    await this.modalController.dismiss('SystemDetailsComponent');
  }

  public async openCalibrationModal(calibrationType: 'auto' | 'manual'): Promise<any> {
    const modal = await this.modalController.create({
      id: 'CalibrationModalComponent',
      component: CalibrationModalComponent,
      cssClass: 'auto-height',
      componentProps: { calibrationType },
    });
    modal.present();
  }

  public async closeCalibrationModal(): Promise<void> {
    await this.modalController.dismiss('CalibrationModalComponent');
  }

  public async openHelpModal(content: IHelpModal): Promise<void> {
    const modal = await this.modalController.create({
      id: 'HelpModalComponent',
      component: HelpModalComponent,
      cssClass: 'auto-height',
      componentProps: { content },
    });
    modal.present();
  }

  public async openSparePartBackupModal(parameter: IParameterData) {
    const modal = await this.modalController.create({
      id: 'SparePartModalComponent',
      component: SparePartModalComponent,
      cssClass: 'auto-height',
      backdropDismiss: false,
      componentProps: { parameter },
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  public async closeHelpModal(): Promise<void> {
    await this.modalController.dismiss('HelpModalComponent');
  }

  public async openFirmwareUpgradeModal(): Promise<void> {
    const modal = await this.modalController.create({
      id: 'FirmwareUpgradeComponent',
      component: FirmwareUpgradeComponent,
    });
    modal.present();
  }

  public async closeFirmwareUpgradeModal(): Promise<void> {
    await this.modalController.dismiss('FirmwareUpgradeComponent');
  }

  public async openSettingsModal(): Promise<void> {
    console.log("modal service")
    const modal = await this.modalController.create({
      id: 'MyProfileComponent',
      component: MyProfileComponent,
      cssClass: 'full-height',
    });
    modal.present();
  }

  public async closeSettingsModal(): Promise<void> {
    await this.modalController.dismiss('MyProfileComponent');
  }

  public async openChangePasswordModal(): Promise<void> {
    
    const modal = await this.modalController.create({
      id: 'ChangePasswordComponent',
      component: ChangePasswordComponent,
      
    });
    modal.present();
  }

  public async closeChangePasswordModal(): Promise<void> {
    await this.modalController.dismiss('ChangePasswordComponent');
  }

}
