import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalService } from '@services/modal.service';
import { RemoteUserQuery } from '@shared/state/remote-user/remote-user.query';

import { RemoteSupportService } from '@shared/services/remote-support.service';
import { DeviceService } from '@shared/state/device/device.service';
import { RemoteUserService } from '@shared/state/remote-user/remote-user.service';
import { UserService } from '@shared/state/user/user.service';
import {  takeUntil,  first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IParameterData, IPeriodicData } from '@shared/interfaces/device.interface';
import { IparameterForUpdate } from '@shared/interfaces/request.interface';
import { UserQuery } from '@shared/state/user/user.query';
import { DeviceQuery } from '@shared/state/device/device.query';

@Component({
  selector: 'app-calibration-modal',
  templateUrl: './calibration-modal.component.html',
  styleUrls: ['./calibration-modal.component.scss'],
})
export class CalibrationModalComponent implements OnInit {
  @Input()
  public calibrationType: 'auto' | 'manual';

  public calibrateResponse$: Observable<IPeriodicData>;
  public autoCalibrateParameters: IparameterForUpdate[];

  public parameterData$ = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.parameterData$ : this.remoteUserQuery.parameterdata$;
  public periodicData$ = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.periodicData$ : this.remoteUserQuery.periodicData$;
  public rotationSpeed$ = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.specificPeriodicData$(1009) : this.remoteUserQuery.specificPeriodicData$(1009);
  public tiltSpeed$ = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.specificPeriodicData$(1025) : this.remoteUserQuery.specificPeriodicData$(1025);
  public feederCurrent$ = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.specificPeriodicData$(1019) : this.remoteUserQuery.specificPeriodicData$(1019);
  public startValueFor1090 = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.specificParameterData(1090) : this.remoteUserQuery.specificParameterData(1090);
  public startValueFor1091 = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.specificParameterData(1091) : this.remoteUserQuery.specificParameterData(1091);
  public fid2042$ = this.userQuery.serviceToolHasBleConnection ? this.deviceQuery.specificPeriodicData$(2042) : this.remoteUserQuery.specificPeriodicData$(2042);
  public autoCalibrationStarted$ =this.userQuery.serviceToolHasBleConnection ? this.userQuery.autoCalibrationStarted$ :this.remoteUserQuery.autoCalibrationStarted$;

  autoCalibrationForm = this.fb.group({
    timeRotation: this.fb.group({
      parameterId: this.startValueFor1090?.parameterId,
      ecuId: this.startValueFor1090?.ecuId,
      value: [this.viewValueforParameter(this.startValueFor1090), Validators.compose([
        Validators.pattern('[0-9]\\d*(\\.\\d{1,4})?$'),
        Validators.min(this.calcMinAndMaxValues(this.startValueFor1090.parameter.minValue)),
        Validators.max(this.calcMinAndMaxValues(this.startValueFor1090.parameter.maxValue)),
        Validators.required
      ])]
    }),
    timeTilt: this.fb.group({
      parameterId: this.startValueFor1091.parameterId,
      ecuId: this.startValueFor1091.ecuId,
      value: [this.viewValueforParameter(this.startValueFor1091), Validators.compose([
        Validators.pattern('[0-9]\\d*(\\.\\d{1,4})?$'),
        Validators.min(this.calcMinAndMaxValues(this.startValueFor1091.parameter.minValue)),
        Validators.max(this.calcMinAndMaxValues(this.startValueFor1091.parameter.maxValue)),
        Validators.required
      ])]
    }),
    timeType: this.fb.group({
      parameterId: this.startValueFor1091.parameterId,
      ecuId: this.startValueFor1091.ecuId,
      value: [this.viewValueforParameter(this.startValueFor1091), Validators.compose([
        Validators.pattern('[0-9]\\d*(\\.\\d{1,4})?$'),
        Validators.min(this.calcMinAndMaxValues(this.startValueFor1091.parameter.minValue)),
        Validators.max(this.calcMinAndMaxValues(this.startValueFor1091.parameter.maxValue)),
        Validators.required
      ])]
    }),
  })

  constructor(
    private modalService: ModalService,
    private fb: UntypedFormBuilder,
    private remoteUserQuery: RemoteUserQuery,
    private deviceService: DeviceService,
    private userQuery: UserQuery,
    private deviceQuery: DeviceQuery,
    private remoteSupportService: RemoteSupportService,
    private userService:UserService,
    private remoteUserService: RemoteUserService) {
  }

  ngOnInit(): void {
    this.autoCalibrateParameters = [];

  }

  public closeCalibrationModal() {
    this.modalService.closeCalibrationModal();
    if (this.remoteUserQuery.autoCalibrationStatus === true || this.userQuery.autoCalibrationStarted === true) {
      this.stopAutoCalibrate()
    }
  }

  public viewValueforParameter(param: IParameterData) {
    return ((param?.value + param?.parameter.offset) * param?.parameter.scale).toFixed(1);
  }

  public calcMinAndMaxValues(number: number) {
    return number * 0.1;
  }

  public calcMinAndMaxValuesBeforeSend(number: number) {
    return number * 10;
  }

  public viewValueForPeriodic(param: IPeriodicData) {
    if (param) {
      return ((param?.value + param?.periodicParameter.offset) * param?.periodicParameter.offset).toFixed(1);
    } else {
      return "Parameter undefined";
    }
  }


  public openCalibrationModal(calibrationType: 'auto' | 'manual') {
    this.modalService.openCalibrationModal(calibrationType);
  }

  public stopAutoCalibrate() {
    if (!this.userQuery.serviceToolHasBleConnection){
    this.calibrateResponse$ = this.remoteUserQuery?.specificPeriodicData$(1010);
    this.remoteUserService.setStopAutoCalibration(false);
    this.remoteSupportService.sendAutoCalibrate(null, true);
    } else{
      this.calibrateResponse$ = this.deviceQuery?.specificPeriodicData$(1010);
      this.userService.setStopAutoCalibration(false);
      this.deviceService.stopAutoCalibration(true);
      
    }
  }

  public autoCalibrate() {

    this.calibrateResponse$ = undefined;
   
    const trigger = this.userQuery.serviceToolHasBleConnection ? 
    this.deviceQuery?.specificPeriodicData$(1010): this.remoteUserQuery?.specificPeriodicData$(1010)
    .pipe(first(response => response.value < 9, true));
   
    this.calibrateResponse$ = this.userQuery.serviceToolHasBleConnection ?
    this.deviceQuery?.specificPeriodicData$(1010):this.remoteUserQuery?.specificPeriodicData$(1010)
    .pipe(takeUntil(trigger));
    
    this.autoCalibrateParameters.push({
      parameterId: this.autoCalibrationForm.value.timeRotation.parameterId,
      ecuId: this.autoCalibrationForm.value.timeRotation.ecuId,
      value: this.calcMinAndMaxValuesBeforeSend(this.autoCalibrationForm.value.timeRotation.value)
    });

    // Reset so that you dont have to close the modal if u want to calibrate again
    this.startValueFor1090 = this.autoCalibrationForm.value.timeRotation;

    this.autoCalibrateParameters.push({
      parameterId: this.autoCalibrationForm.value.timeTilt.parameterId,
      ecuId: this.autoCalibrationForm.value.timeTilt.ecuId,
      value: this.calcMinAndMaxValuesBeforeSend(this.autoCalibrationForm.value.timeTilt.value)
    });

    // Reset so that you dont have to close the modal if u want to calibrate again
    this.startValueFor1091 = this.autoCalibrationForm.value.timeTilt;
    
    if (this.autoCalibrateParameters.length > 0) {
      if (!this.userQuery.serviceToolHasBleConnection){
      this.remoteUserService.setStopAutoCalibration(true);
      this.remoteSupportService.sendAutoCalibrate(this.autoCalibrateParameters);
      }else
        {
          this.userService.setStopAutoCalibration(true);
          this.deviceService.addParameterForAutoCalibration(this.autoCalibrateParameters);
      }
    }

    // Reset array
    this.autoCalibrateParameters = [];
  }
}
