import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import {
  BarcodeScanner
} from '@capacitor-mlkit/barcode-scanning';
import { IHardwareVersion, IParameterData, stringparametertemp } from '@shared/interfaces/device.interface';
import { Subscription, of } from 'rxjs';
import { App } from '@capacitor/app';
import { FirestoreService } from '@shared/services/firestore.service';
import { ConnectApiService } from '@shared/services/connectapi.service';
import { IstringParameterForUpdate } from '@shared/interfaces/request.interface';
import { ModalService } from '@shared/services/modal.service';
import { DeviceQuery } from '@shared/state/device/device.query';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@shared/state/device/device.service';
import { skipWhile, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-spare-part-modal',
  templateUrl: './spare-part-modal.component.html',
  styleUrls: ['./spare-part-modal.component.scss']
})
export class SparePartModalComponent implements OnInit, OnDestroy {
  @Input()
  public parameter: IParameterData;

  public ecuName: string = '';

  public qrResult: string = '';

  private backButtonSubscription: Subscription;

  private harwareSubscription: Subscription;

  private parametersAndStringParametersForBackupSubscription: Subscription;

  public readingQrScan: boolean = false;;

  public step: number = 1;

  private prevStep: number;

  public isMobile = false;

  public fetchFactorySettings: boolean;

  private handleStopScanQRBound;

  private writingData = false;

  constructor(
    private modalService: ModalService,
    private platform: Platform,
    private firestoreService: FirestoreService,
    private connectApiService: ConnectApiService,
    private deviceQuery: DeviceQuery,
    private alertController: AlertController,
    private translateService: TranslateService,
    private deviceService: DeviceService
  ) { }

  ngOnInit(): void {
    BarcodeScanner.checkPermissions().then(res => {
      if (res.camera !== 'granted') {
        BarcodeScanner.requestPermissions();
      }
    })
    this.harwareSubscription = this.deviceQuery.hardwareVersions$.subscribe(hardwares => {
      if (!hardwares.find(x => x.ecuId === this.parameter.ecuId)) {
        if (!this.prevStep) {
          this.prevStep = this.step;
        }

        this.lostConnectionToSparePart();
      } else if (this.prevStep) {
        this.step = this.prevStep;
        this.prevStep = undefined;
      }
    });

    this.ecuName = this.getEcuNameFromEcuId(this.parameter.ecuId);

    if (this.platform.is('capacitor')) {
      this.isMobile = true;
      // BarcodeScanner.prepare();
    } else {
      this.isMobile = false;
    }
  }

  public handleGetPrevious() {
    this.step = 2;
  }

  public handleGetBackup() {
    this.fetchFactorySettings = true;
    this.step = 2;
  }

  private lostConnectionToSparePart() {
    this.step = 5;
  }

  public async fetchOldSettings() {
    if (this.platform.is('capacitor')) {
      this.stopScanQR();
    }

    this.step = 3;
    this.firestoreService.fetchBackupParametersForSerialNumber(this.qrResult, this.parameter.ecuId, this.fetchFactorySettings).then(async res => {
      this.firestoreService.logBackup(this.qrResult,
        (this.parameter.ecuId === 6 ?
          this.deviceQuery.parameterData.find(x => x.parameterId === 6008)?.value?.toString() :
          this.qrResult),
        this.getEcuNameFromEcuId(this.parameter.ecuId))
        .then(() => {
          try {
            this.writeParameters(res.parameterData);
          } catch (error) {
            console.error(error)
            this.noUnitFound(this.qrResult);
          }

          setTimeout(() => {
            this.firestoreService.updateFirestoreDevice().then(() => {
              this.firestoreService.setOldDeviceAsSparePart(res.deviceData);
            })
          }, 2000);

        }).catch(error => {
          console.error('COULD NOT LOG DATA', error)
          this.noUnitFound(this.qrResult);
          this.qrResult = '';
          this.step = 2;
        })
    }).catch(err => {
      console.error('COULD NOT FETCH BACKUP PARAMETERS', err)
      this.noUnitFound(this.qrResult);
      this.qrResult = '';
      this.step = 2;
    })
  }

  private async writeParameters(res: any) {
    this.writingData = true;
    this.deviceService.resetBackupParameters();
    if (this.parameter.ecuId === 6) {
      var dataWithStringParameters = res?.data() as { parameterData: IParameterData[], stringParameterData: stringparametertemp[] };

      for (var parameter of dataWithStringParameters?.parameterData) {
        if (parameter.parameterId < 1000) {
          parameter.parameterId = ((6 * 1000) + parameter.parameterId);
        }

        if (parameter.parameterId === 6107) {
          parameter.value = 0;
        }

        if (!parameter?.parameter || parameter?.parameter?.accessType !== 'RO') {
          var param = {
            parameterId: parameter.parameterId,
            ecuId: parameter.ecuId,
            value: parameter.value
          }
          this.deviceService.addParameterForBackup(param);
          this.connectApiService.writeParameterToDevice(parameter);
        }
      }
      await this.sleep(2500);
      for (const parameter of dataWithStringParameters?.stringParameterData) {
        if (parameter.paramId < 1000) {
          parameter.paramId = ((6 * 1000) + parameter.paramId);
        }
        var parameterToWrite: IstringParameterForUpdate = {
          parameterId: parameter.paramId,
          value: parameter.stringValue ?? "",
          ecuId: 6
        }
        if (!parameter.parameter || parameter?.parameter?.accessType !== 'RO' && parameter.paramId !== 6300) {
          var stringParam = {
            parameterId: parameter.paramId,
            ecuId: 6,
            value: parameter.stringValue
          }
          this.deviceService.addParameterForBackup(stringParam);
          this.connectApiService.writeStringParameterToDevice(parameterToWrite);
          await this.sleep(100);
        }
      }
      // DONE
      // setTimeout(() => {
      //   this.step = 4;
      //   this.firestoreService.forceFlag = true;
      // }, 2500);
    } else {
      var dataWithoutStringParameters = res?.data() as { parameterData: IParameterData[] };
      //console.log(dataWithoutStringParameters);
      for (var parameter of dataWithoutStringParameters?.parameterData) {
        if (parameter.parameterId < 1000) {
          parameter.parameterId = ((parameter.parameterId * 1000) + parameter.parameterId);
        }

        if (parameter.parameterId === 1151 || parameter.parameterId === 2162) {
          parameter.value = 0;
        }

        if (!parameter.parameter || parameter?.parameter?.accessType !== 'RO') {
          var param = {
            parameterId: parameter.parameterId,
            ecuId: parameter.ecuId,
            value: parameter.value
          }
          this.deviceService.addParameterForBackup(param);

          this.connectApiService.writeParameterToDevice(parameter);
        }
      }

      // DONE
      // setTimeout(() => {
      //   this.step = 4;
      //   this.firestoreService.forceFlag = true;
      // }, 2500);
    }

    this.parametersAndStringParametersForBackupSubscription = this.deviceQuery.parametersAndStringParametersForBackup$.pipe(
      takeWhile(() => this.writingData)
    ).subscribe(params => {
      if (params && params.length === 0) {
        // DONE
        this.writingData = false;
        setTimeout(() => {
          this.step = 4;
          this.firestoreService.forceFlag = true;
        }, 1500);
      }
    })
  }

  public back() {
    if (this.step > 1) {
      this.fetchFactorySettings = undefined;
      this.step -= 1;
    }
  }

  public async startScanQR() {
    this.handleStopScanQRBound = this.stopScanQR.bind(this);
    document.getElementById('stop-icon').addEventListener('click', this.handleStopScanQRBound);
    this.readingQrScan = true;

    document.querySelector('body').classList.add('barcode-scanner-active');
    document.querySelector('#cancel-qr-container').classList.remove('hidden');

    // BarcodeScanner.hideBackground();

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(1000, () => {
      this.stopScanQR();
    });

    const listener = await BarcodeScanner.addListener(
      'barcodeScanned',
      async result => {
        console.log(result.barcode);
        this.qrResult = result.barcode.displayValue;
        setTimeout(() => {
          this.readingQrScan = false;
          this.fetchOldSettings();
        }, 250);
      },
    );

    await BarcodeScanner.startScan();

    // const result = await BarcodeScanner.startScan();

    // if (result.hasContent) {
    //   // this.qrResult = result.content;
    //   this.qrResult = result.content;
    //   console.log(result.content);
    //   BarcodeScanner.showBackground();
    //   setTimeout(() => {
    //     this.readingQrScan = false;
    //     this.fetchOldSettings();
    //   }, 2500);
    // }

    document.querySelector('body').classList.remove('barcode-scanner-active');
    document.querySelector('#cancel-qr-container').classList.add('hidden');
  };

  public async stopScanQR() {
    this.readingQrScan = false;
    document.querySelector('body').classList.remove('barcode-scanner-active');
    // Remove all listeners
    await BarcodeScanner.removeAllListeners();

    // Stop the barcode scanner
    await BarcodeScanner.stopScan();

    var cancelButton = document.querySelector('#cancel-qr-container');
    if (!cancelButton.classList.contains('hidden')) {
      cancelButton.classList.add('hidden');
    }

    document.getElementById('stop-icon').removeEventListener('click', this.handleStopScanQRBound);
  }

  private getEcuNameFromEcuId(ecuID: number): string {
    switch (ecuID) {
      case 1:
        return 'TCU';

      case 2:
        return 'CCU';

      case 3:
        return 'HCU';

      case 4:
        return 'JLE';

      case 5:
        return 'JRI';

      case 6:
        return 'HMI';

      case 7:
        return 'CAC1';

      case 8:
        return 'CAC2';

      case 9:
      return 'DCU';

      default:
        return 'Unknown';
    }
  }

  private sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  public closeModal() {
    this.deviceService.setIsBackuping(false);
    this.modalService.dismissModal('SparePartModalComponent');
  }

  public reSync() {
    this.fetchOldSettings();
    this.step = 3
  }

  public async noUnitFound(serialNumber: string): Promise<void> {
    const buttonText = await this.translateService.instant(`auth.ok`);
    var header = this.translateService.instant(`spare parts.error`);
    var message = this.translateService.instant(`spare parts.could not find unit`, {
      ecuName: this.ecuName,
      serialNumber: serialNumber
    })

    const body = `<div class="text-center">
    <p class="text-22pt text-roto-green py-2 font-semibold">${header}</p>
      <p class=" text-20pt text-roto-green">${message}</p>
    </div>`;

    const buttons = [{ text: buttonText, role: 'close', cssClass: 'no-button-borders' }];
    const alert = await this.alertController.create({
      message: body,
      keyboardClose: false,
      backdropDismiss: false,
      buttons,
    });
    alert.present();

    alert.onDidDismiss().then((res) => {
      //console.log('res: ', res);
      if (res.role === 'close') {
        alert.dismiss();
      }
    });
  }

  ngOnDestroy(): void {
    this.backButtonSubscription?.unsubscribe();
    this.harwareSubscription?.unsubscribe();
  }
}
