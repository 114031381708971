import { Component } from '@angular/core';

import { ModalService } from '@services/modal.service';
import { IDynamicModal } from '@shared/interfaces/dynamic-modal.interface';

@Component({
  selector: 'app-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss'],
})
export class DynamicModalComponent {

  constructor(private modalService: ModalService) {}

  public async openDynamicModal(content: IDynamicModal): Promise<void> {
    this.modalService.openDynamicModal(content);
  }

  public closeModal() {
    this.modalService.dismissModal('DynamicModalComponent');
  }
}
