import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'i18n' })
export class I18nPipe extends TranslatePipe implements PipeTransform {
  constructor(translate: TranslateService, ref: ChangeDetectorRef) {
    super(translate, ref);
  }

  transform(text: string, translateParameters?): string {
    return super.transform(text.toLowerCase(), translateParameters);
  }
}
