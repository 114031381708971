import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IAlarmLog } from '@shared/interfaces/alarm-log.interface';
import { IAlarm, IAlarmState } from '@shared/interfaces/alarm.interface';
import { AckAlarmResponseEvent } from 'connectapi/src/ApiMessage/apiResponseTypes';

import { AlarmStore } from './alarm.store';

@Injectable({ providedIn: 'root' })
export class AlarmQuery extends Query<IAlarmState> {
  constructor(protected store: AlarmStore) {
    super(store);
  }

  alarms$ = this.select((state) => state.alarms.sort((a,b) => a.ecuId === b.ecuId ? a.errorCodeId - b.errorCodeId : a.ecuId - b.ecuId));

  alarmLog$ = this.select((state) => state.alarmLog.sort((a,b) => a.ecuId === b.ecuId ? a.alarmCode - b.alarmCode : a.ecuId - b.ecuId));

  pendingAlarmLog$ = this.select((state) => state.pendingAlarmLog);

  latestAlarmToBeDismissed$ = this.select((state) => state.latestAlarmToBeDismissed);

  latestAlarmLogToBeCleared$ = this.select((state) => state.latestAlarmLogToBeCleared);

  pendingAlarmClear$ = this.select((state) => state.pendingAlarmClear)

  pendingAlarmLogClear$ = this.select((state) => state.pendingAlarmLogClear);

  pendingGetLog$ = this.select((state) => state.pendingGetLog);

  // pendingClearAlarmLog$ = this.select((state) => state.pendingClearAlarmLog);

  public get alarms(): IAlarm[] {
    return this.getValue().alarms;
  }

  public get alarmLog(): IAlarmLog[] {
    return this.getValue().alarmLog;
  }

  public get latestAlarmToBeDismissed(): AckAlarmResponseEvent[] {
    return this.getValue().latestAlarmToBeDismissed;
  }
}
