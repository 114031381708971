import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { IFirmwareFile, IFirmwareState, IUpgradeProgress } from '@interfaces/firmware.interface';
import { FirmwareStore } from './firmware.store';

@Injectable({ providedIn: 'root' })
export class FirmwareQuery extends Query<IFirmwareState> {
  constructor(protected store: FirmwareStore) {
    super(store);
  }

  firmwares$ = this.select((state) => state.firmwares);

  upgradeProgress$ = this.select((state) => state.upgradeProgress);

  ongoingUpgrade$ = this.select((state) => state.ongoingUpgrade);

  // DENNA OCH KEEPGOING FINNS FÖR ATT KUNNA KÖRA AUTOUPPDATERINGAR UNDER TESTFASEN
  numberOfUpdates$ = this.select((state) => state.numberOfUpdates);

  keepGoing$ = this.select((state) => state.keepGoing);

  get firmware(): IFirmwareFile {
    if (this.getValue().firmwares?.length > 0) {
      return this.getValue().firmwares[0];
    } else {
      return {} as IFirmwareFile
    }
  }

  get upgradeProgress(): IUpgradeProgress[] {
    return this.getValue().upgradeProgress;
  }

  get keepGoing(): boolean {
    return this.getValue().keepGoing;
  }

  get ongoingUpgrade(): boolean {
    return this.getValue().ongoingUpgrade;
  }
}
