import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { IAlarmState } from '@interfaces/alarm.interface';
import { AckAlarmResponseEvent } from 'connectapi/src/ApiMessage/apiResponseTypes';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'alarm', resettable: true })
export class AlarmStore extends Store<IAlarmState> {
  constructor() {
    super({
      alarms: [],
      alarmLog: [],
      pendingAlarmLog: 0,
      latestAlarmToBeDismissed: [],
      latestAlarmLogToBeCleared: [],
      pendingAlarmClear: [],
      pendingGetLog: 0
      // pendingClearAlarmLog: 0
    });
  }
}
