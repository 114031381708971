import { ValueConverter } from "../Helpers/ValueConverter";
import { CAN_ADDRESS, ECU_ID, PGN_Name } from './CanEnums';
import { ParameterHandler } from "../ParameterDefinition/ParameterHandler";
import { DType, Parameter } from "../ParameterDefinition/ParameterDefinition";

export enum AlarmMsgCommand {
  ALARM_HEADER = 1,
  ALARM = 2,
}
export enum ConnectionCommandType {
  forceConnection = 1,      //Operator must not accept connection
  requestConnection =2,  //Operator must accept connection

}

export class CanMessageBase {
  private readonly DEFAULT_PRIO = 7;
  private readonly DEFAULT_ADDRESS = 0x00;
  private _pgn: PGN_Name;
  protected _data: number[];
  private _canAddr: CAN_ADDRESS;
  private _canHeader: number[];

  constructor(pgn: PGN_Name, data?: number[], canAddress?: CAN_ADDRESS, prioValue?: number) {
    this._pgn = pgn;
    if (data) {
      this._data = data;
    } else {
      this._data = [];
    }

    if (canAddress) {
      this._canAddr = canAddress;
    } else {
      this._canAddr = this.DEFAULT_ADDRESS;
    }

    let prio = 0;
    if (prioValue) {
      prio = (prioValue & 0x07) << 2;
    } else {
      prio = this.DEFAULT_PRIO << 2;
    }

    this._canHeader = this.createJ1939Header(pgn, this._canAddr, prio);
  }

  private createJ1939Header(pgn: PGN_Name, canAddr: number, prioValue: number): number[] {
    const headerVal = (prioValue << 24) | (pgn << 8) | (canAddr & 0xff);
    return ValueConverter.U32ToArray(headerVal);
  }

  get CanHeader(): number[] {
    return this._canHeader;
  }

  get PGN(): PGN_Name {
    return this._pgn;
  }

  set PGN(pgn: PGN_Name) {
    this._pgn = pgn;
  }

  get Address(): CAN_ADDRESS {
    return this._canAddr;
  }

  get Data(): number[] {
    return this._data;
  }
}

export interface AlarmInfo {
  ActiveAlarm: boolean;
  AckAlarm: boolean;
}

export interface Alarm_Header {
  ActiveAlarms: number;
  AlarmCode1: number;
  AlarmCode2: number;
  AlarmInfo1: AlarmInfo;
  AlarmInfo2: AlarmInfo;
}

export interface Alarm {
  AlarmCode3: number;
  AlarmCode4: number;
  AlarmCode5: number;
  AlarmInfo3: AlarmInfo;
  AlarmInfo4: AlarmInfo;
  AlarmInfo5: AlarmInfo;
}

export class AlarmMessage extends CanMessageBase {
  private command: AlarmMsgCommand;
  private ecuId: ECU_ID;
  private alarm_header: Alarm_Header;
  private alarm: Alarm;

  constructor(value: number[],address:CAN_ADDRESS ) {
    super(PGN_Name.AlarmMessage, value,address);
    this.command = AlarmMsgCommand.ALARM_HEADER;
    this.ecuId = ECU_ID.TCU; // Crapy code. Ecu ID will be overwritten with correct value when ParseData i called
    this.alarm = {
      AlarmCode3: 0,
      AlarmCode4: 0,
      AlarmCode5: 0,
      AlarmInfo3: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo4: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo5: { ActiveAlarm: false, AckAlarm: false },
    };
    this.alarm_header = {
      AlarmCode1: 0,
      AlarmCode2: 0,
      ActiveAlarms: 0,
      AlarmInfo1: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo2: { ActiveAlarm: false, AckAlarm: false },
    };
    this.ParseData();
  }

  get EcuId(): ECU_ID {
    return this.ecuId;
  }

  set EcuId(id: ECU_ID) {
    if (this._data && this._data.length === 8) {
      //already initialized, just change ecuId
      this._data[0] = (this._data[0] & 0x0f) | ((id & 0x0f) << 4);
    } else {
      //create new array
      this._data = [];
      this._data.length = 8;
      this._data[0] = (id & 0x0f) << 4;
    }
  }

  get AlarmHeader(): Alarm_Header {
    return this.alarm_header;
  }

  get Alarm(): Alarm {
    return this.alarm;
  }

  get Command(): AlarmMsgCommand {
    return this.command;
  }

  get NumActiveAlarms(): number {
    if (this._data && this._data.length === 8 && this.command === AlarmMsgCommand.ALARM_HEADER) {
      return this._data[2];
    }
    return 0;
  }

  public SetAlarmHeaderData(alarmHeader: Alarm_Header, ecuId?: ECU_ID): void {
    this.alarm_header = alarmHeader;
    this.alarm = {
      AlarmCode3: 0,
      AlarmCode4: 0,
      AlarmCode5: 0,
      AlarmInfo3: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo4: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo5: { ActiveAlarm: false, AckAlarm: false },
    };
    this.command = AlarmMsgCommand.ALARM_HEADER;
    if (this._data && this._data.length === 8) {
      const cmdByte = this._data[0];
      this._data = [];
      this._data.push((cmdByte & ~0x0f) | AlarmMsgCommand.ALARM_HEADER);
      if (ecuId) {
        this._data[0] |= (this._data[0] & 0x0f) | ((ecuId & 0x0f) << 4);
      }
    } else {
      this._data = [];
      this._data.push(AlarmMsgCommand.ALARM_HEADER);
      if (ecuId) {
        this._data[0] |= (this._data[0] & 0x0f) | ((ecuId & 0x0f) << 4);
      }
    }
    this._data.push(0);
    this._data.push(alarmHeader.ActiveAlarms & 0xff);
    this._data.push(0);
    this._data = this._data.concat(ValueConverter.U16ToArray(alarmHeader.AlarmCode1), ValueConverter.U16ToArray(alarmHeader.AlarmCode2));
  }

  public SetAlarmData(alarm: Alarm, ecuId?: ECU_ID): void {
    this.alarm = alarm;
    this.alarm_header = {
      AlarmCode1: 0,
      AlarmCode2: 0,
      ActiveAlarms: 0,
      AlarmInfo1: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo2: { ActiveAlarm: false, AckAlarm: false },
    };
    this.command = AlarmMsgCommand.ALARM;

    if (this._data && this._data.length === 8) {
      const cmdByte = this._data[0];
      this._data = [];
      this._data.push((cmdByte & ~0x0f) | AlarmMsgCommand.ALARM);
      if (ecuId) {
        this._data[0] |= (this._data[0] & 0x0f) | ((ecuId & 0x0f) << 4);
      }
    } else {
      this._data = [];
      this._data.push(AlarmMsgCommand.ALARM);
      if (ecuId) {
        this._data[0] |= (this._data[0] & 0x0f) | ((ecuId & 0x0f) << 4);
      }
    }

    this._data.push(0);
    this._data = this._data.concat(
      ValueConverter.U16ToArray(alarm.AlarmCode3),
      ValueConverter.U16ToArray(alarm.AlarmCode4),
      ValueConverter.U16ToArray(alarm.AlarmCode5)
    );
  }

  private ParseData(): void {
    if (this._data && this._data.length > 0) {
      this.ecuId = ((this._data[0] >> 4) & 0x0f) as ECU_ID;
      this.command = (this._data[0] & 0x0f) as AlarmMsgCommand;
    }

    if (this.command === AlarmMsgCommand.ALARM_HEADER) {
      this.alarm_header = this.CreateAlarmHeader(this._data);
    } else if (this.command === AlarmMsgCommand.ALARM) {
      this.alarm = this.CreateAlarm(this._data);
    }
  }

  private CreateAlarmHeader(data: number[] | undefined): Alarm_Header {
    if (data && data.length === 8) {
      const code1 = ValueConverter.ArrayToU16([data[4], data[5]]);
      const code2 = ValueConverter.ArrayToU16([data[6], data[7]]);
      const alm: Alarm_Header = {
        ActiveAlarms: data[2],
        AlarmCode1: code1 & 0x0fff,
        AlarmCode2: code2 & 0x0fff,
        AlarmInfo1: { ActiveAlarm: (code1 & 0x1000) > 0 ? true : false, AckAlarm: (code1 & 0x2000) > 0 ? true : false },
        AlarmInfo2: { ActiveAlarm: (code2 & 0x1000) > 0 ? true : false, AckAlarm: (code2 & 0x2000) > 0 ? true : false },
      };
      return alm;
    }
    return {
      AlarmCode1: 0,
      AlarmCode2: 0,
      ActiveAlarms: 0,
      AlarmInfo1: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo2: { ActiveAlarm: false, AckAlarm: false },
    };
  }

  private CreateAlarm(data: number[]): Alarm {
    if (data.length === 8) {
      const code3 = ValueConverter.ArrayToU16([data[2], data[3]]);
      const code4 = ValueConverter.ArrayToU16([data[4], data[5]]);
      const code5 = ValueConverter.ArrayToU16([data[6], data[7]]);

      const alm: Alarm = {
        AlarmCode3: code3 & 0x0fff,
        AlarmCode4: code4 & 0x0fff,
        AlarmCode5: code5 & 0x0fff,
        AlarmInfo3: { ActiveAlarm: (code3 & 0x1000) > 0 ? true : false, AckAlarm: (code3 & 0x2000) > 0 ? true : false },
        AlarmInfo4: { ActiveAlarm: (code4 & 0x1000) > 0 ? true : false, AckAlarm: (code3 & 0x2000) > 0 ? true : false },
        AlarmInfo5: { ActiveAlarm: (code5 & 0x1000) > 0 ? true : false, AckAlarm: (code3 & 0x2000) > 0 ? true : false },
      };
      return alm;
    }
    return {
      AlarmCode3: 0,
      AlarmCode4: 0,
      AlarmCode5: 0,
      AlarmInfo3: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo4: { ActiveAlarm: false, AckAlarm: false },
      AlarmInfo5: { ActiveAlarm: false, AckAlarm: false },
    };
  }
}

export enum ParamAccessSubCmd {
  ParamValueAccess = 1,
  MinValueAccess = 2,
  MaxValueAccess = 3,
  DefValueAccess = 4,
  StringParamAccess=5,
}

export enum OperationType {
  READ = 0,
  DELAYED_WRITE = 4,
  WRITE = 8,
}

export class ParamRequestMessage extends CanMessageBase {
  private _paramId: number;
  private paramAccessSubCmd: ParamAccessSubCmd;
  private _operationType: OperationType;
  private _paramValue?: number;
  private _parameterHandler: ParameterHandler;

  constructor(
    paramId: number,
    operation: OperationType,
    accessType: ParamAccessSubCmd,
    paramValue?: number
  ) {
    super(PGN_Name.ParamRequest);
    this._operationType = operation;
    this.paramAccessSubCmd = accessType;
    this._paramId = paramId;
    this._parameterHandler = ParameterHandler.GetInstance();
    const param = this._parameterHandler.GetParameter(this._paramId);
    this._paramValue = paramValue ? this.ConvertParamterValue(paramValue, param) : undefined;
    this.CreateData(param?.DataType);
  }

  private ConvertParamterValue(value: number, param: Parameter | undefined): number {
    let paramVal = value;
    if (param) {
      if (param.DataType === DType.S16 || param.DataType === DType.S32 || param.DataType === DType.S8) {
        paramVal = ValueConverter.NumberToInt32(value);
      } else {
        paramVal = ValueConverter.NumberToUint32(value);
      }
    }
    return paramVal;
  }

  private CreateData(paramDtype: DType | undefined): void {
    this._data.push(this._operationType | (this.paramAccessSubCmd << 4));
    this._data.push(0);
    this._data = this._data.concat(ValueConverter.U16ToArray(this._paramId));
    if (this._operationType !== OperationType.READ && this._paramValue) {
      if (paramDtype && (paramDtype === DType.S16 || paramDtype === DType.S32 || paramDtype === DType.S8)) {
        this._data = this._data.concat(ValueConverter.S32ToArray(this._paramValue));
      } else {
        this._data = this._data.concat(ValueConverter.U32ToArray(this._paramValue));
      }
    } else {
      this._data = this._data.concat([0, 0, 0, 0]);
    }
  }

  get Operation(): OperationType {
    return this._operationType;
  }

  get ParameterID(): number {
    return this._paramId;
  }

  get ParameterAccess(): ParamAccessSubCmd {
    return this.paramAccessSubCmd;
  }

  get ParameterValue(): number | undefined {
    return this._paramValue;
  }
}

export class StringParamWriteMessage extends CanMessageBase {
  private _paramId: number;
  private _character1: number;
  private _character2?: number;
  private _parameterHandler: ParameterHandler;
  private _sequenceCounter:number;
  constructor(
    paramId: number,
    sequenceCounter:number,
    character1:number,
    character2?: number
  ) {
    super(PGN_Name.ParamRequest);
    this._paramId = paramId;
    this._parameterHandler = ParameterHandler.GetInstance();
    this._character1 = character1;
    this._character2 = character2;
    this._sequenceCounter = sequenceCounter;
    this.CreateData();
  }
 
  private CreateData(): void {
    this._data.push(OperationType.WRITE | (ParamAccessSubCmd.StringParamAccess << 4));
    this._data.push(this._sequenceCounter);
    this._data = this._data.concat(ValueConverter.U16ToArray(this._paramId));
    /*Always add mandatory character 1 to data*/
    this._data.push((this._character1>>0)&0xFF);
    this._data.push((this._character1>>8)&0xFF);
    if (this._character2) {
      /*Add character 2 if defined (not always the case in last frame of a string)*/
      this._data.push((this._character2>>0)&0xFF);
      this._data.push((this._character2>>8)&0xFF);  
    } else {
      this._data = this._data.concat([0, 0]);
    }
  }
  get ParameterID(): number {
    return this._paramId;
  }
}

export enum ParamResponseResult {
  OK = 0,
  NOT_SUPPOTED = 1,
  UNKNOWN_PARAM = 2,
  OUT_OF_RANGE = 3,
  BUSY = 4,
  ACCESS_NOT_ALLOWED = 5,
  PARAMETERS_CORRUPT=6,
  INCORRECT_DATA_TYPE=7,
  TRANSFER_TIMEOUT=8,
  INCORRECT_SEQUENCE_COUNTER=9,
  TRANSFER_ERROR=10,
  TIMEOUT = 11,
}

export class ParamResponseMessage extends CanMessageBase {
  private _paramId = 0;
  private paramAccessSubCmd: ParamAccessSubCmd = ParamAccessSubCmd.DefValueAccess;
  private _paramResult: ParamResponseResult = ParamResponseResult.OK;
  private _paramValue = 0;

  constructor(data: number[]) {
    super(PGN_Name.ParamResponse, data);
    this.ParseData();
  }

  private ParseData(): void {
    this._paramResult = (this._data[0] & 0x0f) as ParamResponseResult;
    this.paramAccessSubCmd = (this._data[0] >> 4) & (0x0f as ParamAccessSubCmd);
    this._paramId = ValueConverter.ArrayToU16(this._data.slice(2, 4));
    this._paramValue = this.ConvertDataValue(this._data.slice(4));
  }

  private ConvertDataValue(data: number[]): number {
    let value = 0;
    const param = ParameterHandler.GetInstance().GetParameter(this._paramId);
    if (param) {
      if (param.DataType === DType.U16 || param.DataType === DType.U32 || param.DataType === DType.U8 || param.DataType === DType.STR) {
        value = ValueConverter.ArraytoU32(data);
      }
    } else {
      value = ValueConverter.ArraytoU32(data);
    }

    return value;
  }

  get Result(): ParamResponseResult {
    return this._paramResult;
  }

  get ParameterAccess(): ParamAccessSubCmd {
    return this.paramAccessSubCmd;
  }

  get ParameterValue(): number {
    return this._paramValue;
  }

  get ParameterId(): number {
    return this._paramId;
  }
}

export class EcuInfo {
  private ecuInfoPage0: EcuInfoPage0;
  private ecuInfoPage1!: EcuInfoPage1;
  private _connected: boolean;
  private _msTimeStamp: number;
  private _state: EcuInfo.State;

  constructor(ecuInfoPage0 : EcuInfoPage0) {
    this.ecuInfoPage0 = ecuInfoPage0;
    this._connected = false;
    this._msTimeStamp = 0;
    this._state = EcuInfo.State.WAITING_FOR_PAGE1;
  }

  get CanAdress(): number {
    return this.ecuInfoPage0.Address;
  }

  public set EcuInfoPage0 ( ecuInfoPage0: EcuInfoPage0 ){
    this.ecuInfoPage0 = ecuInfoPage0;
  }

  public set EcuInfoPage1 ( ecuInfoPage1: EcuInfoPage1 ){
    this.ecuInfoPage1 = ecuInfoPage1;
  }

  get EcuId(): ECU_ID {
    return this.ecuInfoPage0.EcuId;
  }

  get SysCompVersion(): number {
    return this.ecuInfoPage0.SysCompVersion;
  }

  get HwVersion(): number {
    return this.ecuInfoPage0.HwVersion;
  }

  get BootMode(): boolean {
    return this.ecuInfoPage0.BootMode;
  }

  get SwVersion(): string {
    return this.ecuInfoPage1.SwVersion;
  }

  get BuildNumber(): number {
    return this.ecuInfoPage1.BuildNumber;
  }
  
  get SoftwareVarient(): number {
    return this.ecuInfoPage0.SoftwareVarient;
  }
  get SoftwareSubVarient(): number {
    return this.ecuInfoPage0.SoftwareSubVarient;
  }

  get Experimental(): boolean {
    return this.ecuInfoPage0.Experimental;
  }
  get SparePart(): boolean {
    return this.ecuInfoPage0.SparePart;
  }
  get NotUpgradeable(): boolean {
    return this.ecuInfoPage0.NotUpgradeable;
  }


  public get connected(): boolean {
    return this._connected;
  }
  public set connected(value: boolean) {
    this._connected = value;
  }

  public get msTimeStamp(): number {
    return this._msTimeStamp;
  }
  public set msTimeStamp(value: number) {
    this._msTimeStamp = value;
  }

  public get state(): EcuInfo.State {
    return this._state;
  }
  public set state(state: EcuInfo.State) {
    this._state = state;
  }
}

export namespace EcuInfo {
  export enum State{
    WAITING_FOR_PAGE0,
    WAITING_FOR_PAGE1
  }
  
  export enum Page {
    Page0 = 0,
    Page1 = 1,
  }
}

export class EcuInfoPage0 extends CanMessageBase {
  private readonly ecuId: ECU_ID;
  private readonly bootMode: boolean;
  private readonly sysCompabilityVersion: number;
  private readonly hwVersion: number;
  private readonly experimental: boolean;
  private readonly sparePart: boolean;
  private readonly notUpgradeable: boolean;
  private readonly softwareVarient: number;
  private readonly softwareSubVarient: number;

  // constructor(ecuId: ECU_ID, bootMode: boolean, sysCompabilityVersion: number, appCompatibilityVersion: number, hwVersion: number)
  constructor(canAddr: CAN_ADDRESS, data: number[]) {
    super(PGN_Name.EcuInfo, data, canAddr);
    this.ecuId = (this._data[0] >> 4) as ECU_ID;
    this.bootMode = (this._data[0] & (1<<2)) != 0;
    this.sysCompabilityVersion = this._data[1];
    this.hwVersion = this._data[3] & 0x0f;
    this.experimental = (this._data[4] & 0x02) != 0;
    this.sparePart = (this._data[4] & 0x01) != 0;
    this.notUpgradeable = (this._data[4] & 0x04) != 0;
    this.softwareVarient = this._data[5];
    this.softwareSubVarient = this._data[6];
  }

  get EcuId(): ECU_ID {
    return this.ecuId;
  }

  get SysCompVersion(): number {
    return this.sysCompabilityVersion;
  }

  get HwVersion(): number {
    return this.hwVersion;
  }

  get BootMode(): boolean {
    return this.bootMode;
  }

  get SoftwareVarient(): number {
    return this.softwareVarient;
  }
  get SoftwareSubVarient(): number {
    return this.softwareSubVarient;
  }

  get Experimental(): boolean {
    return this.experimental;
  }
  get SparePart(): boolean {
    return this.sparePart;
  }

  get NotUpgradeable(): boolean {
    return this.notUpgradeable;
  }
}

export class EcuInfoPage1 extends CanMessageBase {
  private readonly swVersion: string;
  private readonly buildNumber: number;

  constructor(canAddr: CAN_ADDRESS, data: number[]) {
    super(PGN_Name.EcuInfo, data, canAddr);
    let lowDigit = this._data[1];
    let midDigit = this._data[2];
    let highDigit = this._data[3];
    this.swVersion = `${highDigit}.${midDigit}.${lowDigit}`;
    this.buildNumber = ValueConverter.ArraytoU32(this._data.slice(4, 8));
  }

  get SwVersion(): string {
    return this.swVersion;
  }

  get BuildNumber(): number {
    return this.buildNumber;
  }
}

export enum FunctionSelectCmdType {
  NoCommand = 0,
  NoFunction = 1,
  Pulse = 2,
  AGC = 3,
  GotoHome = 4,
}

export enum RunModeCommandType {
  NoCommand = 0,
  Normal = 1,
  AutoCalibrate = 2,
  ManualCalibrate = 3,
}

export enum SetCmdType {
  //TODO: Rätta enligt protokoll, bitwise-värden, 0 = NoCommand, ResetHomePos = 1 (Bit pos 0)
  ResetHomePos = 0,
  ResetAngles = 1,
  ManualSetupNext = 4,
} //bit values (0,1,2)

export class HmiCommandMessage extends CanMessageBase {
  private readonly funcSel: FunctionSelectCmdType;
  private readonly runMode: RunModeCommandType;
  private readonly setCmd: SetCmdType;

  constructor(funcSel: FunctionSelectCmdType, runMode: RunModeCommandType, setCmd: SetCmdType) {
    super(PGN_Name.HmiCommand, [0, (funcSel | (runMode << 4)), setCmd, 0, 0, 0, 0, 0]);
    this.funcSel = funcSel;
    this.runMode = runMode;
    this.setCmd = setCmd;
  }

  public get FunctionSelectCmd(): FunctionSelectCmdType {
    return this.funcSel;
  }

  public get RunModeCommand(): RunModeCommandType {
    return this.runMode;
  }

  public get ActiveCommand(): SetCmdType {
    return this.setCmd;
  }

  
}
