import { Component, NgZone } from '@angular/core';
import 'reflect-metadata';
import { Title } from '@angular/platform-browser';

import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';

import { AuthService } from '@services/auth.service';
import { DefinitionsService } from '@state/definitions/definitions.service';
import { FirmwareService } from '@shared/state/firmware/firmware.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public env = environment.environment;

  public isProduction = environment.production;

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private screenOrientation: ScreenOrientation,
    translateService: TranslateService,
    titleService: Title,
    private definitionsService: DefinitionsService,
    private firmwareService: FirmwareService,
    private router: Router, 
    private zone: NgZone
  ) {

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".com").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
    // this.splashScreen.show();
    this.initializeApp();
    translateService.setDefaultLang('en');
    const curLang = translateService.getBrowserLang();
    if (curLang.includes('sv')) {
      translateService.use('sv');
    } else {
      translateService.use('en');
    }

    const title = environment.production ? '' : environment.environment + '-';
    titleService.setTitle(title + 'Rototilt Connect');
  }

  private initializeApp(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        // Enable firebase Crashlytics
        // Lock orientation on android/ios
        const orientation = this.screenOrientation.ORIENTATIONS.PORTRAIT;
        this.screenOrientation.lock(orientation);
      }

      this.definitionsService.loadDefinitions();
      this.firmwareService.loadFirmwares();

      this.statusBar.styleDefault();
      // this.splashScreen.hide();
      setTimeout(() => {
        // this.splashScreen.hide();
      }, 5000);
      this.authService.onAuthStateChanged();
    });
  }
}
