// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'dev',
  appName: "Rototilt Connect-dev",
  firebase: {
    apiKey: 'AIzaSyCL5OrBR8TjYDh01EmbbIh17uyGA6H8SYw',
    authDomain: 'rototiltconnect-dev.firebaseapp.com',
    databaseURL: 'https://rototiltconnect-dev.firebaseio.com',
    projectId: 'rototiltconnect-dev',
    storageBucket: 'rototiltconnect-dev.appspot.com',
    messagingSenderId: '214951418045',
    appId: '1:214951418045:web:74fb3a58414d54d639ffd4',
    measurementId: 'G-HP1265MTC6',
  },
  functions: {
    signup: 'https://europe-west1-rototiltconnect-dev.cloudfunctions.net/registerUser',
    storageDownload: 'https://europe-west1-rototiltconnect-dev.cloudfunctions.net/storageJsonDownload',
    deleteUser: 'https://europe-west1-rototiltconnect-dev.cloudfunctions.net/deleteUser',
    sendEmail: 'https://europe-west1-rototiltconnect-dev.cloudfunctions.net/sendEmail',
    userGotAccepted: 'https://europe-west1-rototiltconnect-dev.cloudfunctions.net/userGotAccepted',
  },
  hostUrl: 'https://connect-dev.rototilt.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
