import { Injectable, NgZone } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getFirestore, doc, getDoc, collection } from 'firebase/firestore';

import { UserQuery } from '@state/user/user.query';
import { ICurrentDevice, IHardwareVersion, IParameterData,  IPreviousDevice, stringparametertemp } from '@interfaces/device.interface';
import { DeviceStore } from './device.store';
import { BLEFUNCTIONS } from '@constants/ble';
//import { Timestamp } from '@firebase/firestore-types';
import { CONFIG } from '@constants/config';
import { ModalService } from '@services/modal.service';
import {  arrayRemove,  arrayUpsert } from '@datorama/akita';
import { IparameterDidUpdate, IparameterForUpdate,  IstringParameterDidUpdate, IstringParameterForUpdate } from '@interfaces/request.interface';
import { FirestoreService } from '@services/firestore.service';
import { ParameterChangedEvent, PeriodicDataChangedEvent, StringParameterChangedEvent } from 'connectapi/src/ApiMessage/apiResponseTypes';
import { IParameterList, IPeriodicDataFieldList } from '@interfaces/definition.interface';

import { getDatabase, ref, get } from 'firebase/database';

//import * as firebase from 'firebase';

//import { PeriodicParameter } from 'connectapi/src/ParameterDefinition/ParameterDefinition';
import { DeviceQuery } from './device.query';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
//import { stat } from 'fs';
import { Subscription, combineLatest, pipe, of } from 'rxjs';
import { PROFILE } from '@shared/constants/parameters';
import { IProfile } from '@shared/interfaces/profile.interface';

import { AlarmQuery } from '../alarm/alarm.query';
import { AlarmStore } from '../alarm/alarm.store';
import { IAlarm } from '@shared/interfaces/alarm.interface';
import { IAlarmLog } from '@shared/interfaces/alarm-log.interface';
//import { state } from '@angular/animations';
// import { ConnectApiService } from '@shared/services/connectapi.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private tcuSerialNumberSubscription: Subscription;
  private ccuSerialNumberSubscription: Subscription;
  private hmiSerialNumberSubscription: Subscription;

  private parameterDataSubscription: Subscription;
  private alarmSubscription: Subscription;
  private alarmLogSubscription: Subscription;
  private currentDevice: string;

  constructor(
    private deviceStore: DeviceStore,
    private angularFirestore: AngularFirestore,
    private userQuery: UserQuery,
    private ngZone: NgZone,
    private modalService: ModalService,
    private firestoreService: FirestoreService,
    private deviceQuery: DeviceQuery,
    private alarmStore: AlarmStore,
    private alarmQuery: AlarmQuery,
    // private connectApiService: ConnectApiService
  ) { }

  public closeModal(): void {
    this.modalService.dismissModal('BleDevicesComponent');
  }

  public initializeDeviceStore(): void {
    //console.log('RESETTING DEVICE STATE');

    this.deviceStore.update({
      isScanning: false,
      isOffline: false,
      isScanningForPreviousDevices: false,
      bluetoothIsActive: false,
      reConnectIn: 0,
      currentDevice: {} as ICurrentDevice,
      connecting: false,
      scanResult: [],
      previousDevices: [],
      devicesInRange: [],
      functions: BLEFUNCTIONS,
      activeFunction: 'shake',
      characteristicsUuid: '',
      serviceUuid: '',
      periodicData: [],
      parameterData: [],
      stringParameterData: [],
      hardwareVersions: [],
      parametersForUpdate: [],
      stringParametersForUpdate: [],
      timedOutParameters: [],
      requestUid: '',
      stringRequestUid: '',
      autoCalibrationParameters: [],
      stopAutoCalibrate: false,
      isOldData: false,
      parametersForUpdateServiceTool: [],
      parametersDidUpdateServiceTool: [],
      stringParametersForUpdateServiceTool: [],
      stringParametersDidUpdateServiceTool: [],
      parametersAndStringParametersForBackup: [],
      isBackuping: false,
      profiles: PROFILE,
      systemType:'', 
    });
    // this.loadPreviousDevices();
  }

  public addScanResult(scanStatus: any): void {
    //console.log('scanStatus: ', scanStatus);
    if (scanStatus.name && ((scanStatus.name as string).toLowerCase().includes('rototilt') || (scanStatus.name as string).toLowerCase().includes('nina'))) {
      this.ngZone.run(() => {
        this.deviceStore.update((state) => {
          // const exists = state.scanResult.find((x) => x.address === scanStatus.address);
          // console.log('ANDROID TEST exists: ', exists);
          // if (exists !== undefined) {
          //   console.log('ANDROID TEST exists: ', exists);
          //   return {
          //     ...state,
          //   };
          // }
          return {
            ...state,
            scanResult: [...state.scanResult, scanStatus],
          };
        });
      });
    }
  }

  public refreshParameters() {
    var parameterData = [...this.deviceQuery.parameterData];
    this.ngZone.run(() => {
      this.deviceStore.update({ parameterData: parameterData });
    });
  }

  public resetScanResult() {
    this.ngZone.run(() => {
      this.deviceStore.update({ scanResult: [] });
    });
  }

  public addInitialName(name: string): void {
    this.ngZone.run(() => {
      this.deviceStore.update({ currentDevice: { name } });
    });
  }

  public addCurrentDevice(currentDevice: ICurrentDevice) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => {
        // const newServices = currentDevice.services.filter((service) => service.uuid === '2456E1B9-26E2-8F83-E744-F34F01E9D701');
        return {
          ...state,
          currentDevice: { ...currentDevice, services: currentDevice.services, lastConnected: new Date() },
          scanResult: [],
        };
      });
      this.firestoreService.startFirestoreLink();
      this.startSerialNumberSubscription();
    });
  }

  public getStringParameters(bool: boolean, paramsToFetch?: number[]) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        ...state,
        getStringParameters: { boolean: bool, params: paramsToFetch }
      }))
    })
  }

  public addParameterForUpdateServiceTool(parameters: IparameterForUpdate[]) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        ...state,
        parametersForUpdateServiceTool: parameters
      }))
    })
  }

  public addStringParameterForUpdateServiceTool(parameters: IstringParameterForUpdate[]) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        ...state,
        stringParametersForUpdateServiceTool: parameters
      }))
    })
  }

  public addParameterDidUpdate(parameter: IparameterDidUpdate, resetState?: boolean) {
    this.ngZone.run(() => {
      if (resetState) {
        this.deviceStore.update((state) => ({
          ...state,
          parametersDidUpdateServiceTool: []
        }))
      } else {
        this.deviceStore.update((state) => ({
          ...state,
          parametersDidUpdateServiceTool: [...state.parametersDidUpdateServiceTool, parameter]
        }))
      }
    })
  }

  public addStringParameterDidUpdate(parameter: IstringParameterDidUpdate, resetState?: boolean) {
    this.ngZone.run(() => {
      if (resetState) {
        this.deviceStore.update((state) => ({
          ...state,
          stringParametersDidUpdateServiceTool: []
        }))
      } else {
        this.deviceStore.update((state) => ({
          ...state,
          stringParametersDidUpdateServiceTool: [...state.stringParametersDidUpdateServiceTool, parameter]
        }))
      }
    })
  }

  private startSerialNumberSubscription() {
    
    this.tcuSerialNumberSubscription = combineLatest([this.deviceQuery.specificParameterData$(1074), this.deviceQuery.specificParameterData$(1008)]).subscribe(x => {
      var pid1074 = x[0];
      var pid1008 = x[1];
      //console.log('pid1074', pid1074, 'pid1008', pid1008);
      // DEBUG FOR LUDVIG'S TCU DEVICE
      if (pid1074?.value > 0 && pid1008 && (pid1008?.value > 0 || pid1074?.value === 231132)) {
        var serialNumberAsString = pid1074.value.toString() + '-' + pid1008.value.toString();
        if (serialNumberAsString) {
          try {
            this.firestoreService.updateLatestTcuConnection(serialNumberAsString)
          } catch (error) {
            console.log('COULD NOT UPDATE CURRENT TCU');
          }

          this.updateTcuSerialNumber(serialNumberAsString);
          setTimeout(async () => {
            await Filesystem.writeFile({
              path: 'previousDevice',
              recursive: true,
              data: JSON.stringify({ ...this.deviceQuery.currentDevice, hardwareVersions: [], periodicData: [] } as IPreviousDevice),
              directory: Directory.Data,
              encoding: Encoding.UTF8,
            }).then(() => console.log('PREVIOUSDEVICE ADDED TO LOCAL'))
              .catch(err => console.log('FAILED TO ADD PREVIOUSDEVICE TO LOCAL', err));
          }, 1000);
        }
      }else {
          try {
            this.firestoreService.updateLatestTcuConnection('')
          } catch (error) {
            console.log('COULD NOT UPDATE CURRENT TCU');
          }
      }
    })

    this.ccuSerialNumberSubscription = combineLatest([this.deviceQuery.specificParameterData$(2137), this.deviceQuery.specificParameterData$(2008)]).subscribe(x => {
      var pid2137 = x[0];
      var pid2008 = x[1];
      //console.log('pid2137', pid2137, 'pid2008', pid2008);
      // DEBUG FOR LUDVIG'S CCU DEVICE
      if (pid2137?.value > 0 && pid2008 && (pid2008?.value > 0 || pid2137?.value === 278872)) {
        var serialNumberAsString = pid2137?.value.toString() + '-' + pid2008?.value.toString();
        if (serialNumberAsString) {
          try {
            this.firestoreService.updateLatestCcuConnection(serialNumberAsString)
          } catch (error) {
           console.log('COULD NOT UPDATE CURRENT CCU');
          }

          this.updateCcuSerialNumber(serialNumberAsString);
          setTimeout(async () => {
            await Filesystem.writeFile({
              path: 'previousDevice',
              recursive: true,
              data: JSON.stringify({ ...this.deviceQuery.currentDevice, hardwareVersions: [], periodicData: [] } as IPreviousDevice),
              directory: Directory.Data,
              encoding: Encoding.UTF8,
            }).then(() => console.log('PREVIOUSDEVICE ADDED TO LOCAL'))
              .catch(err => console.log('FAILED TO ADD PREVIOUSDEVICE TO LOCAL', err));
          }, 1000);
        }
      }
      else {
        try {
          this.firestoreService.updateLatestCcuConnection('')
        } catch (error) {
          console.log('COULD NOT UPDATE CURRENT CCU');
        }
      }
    })

    this.hmiSerialNumberSubscription = this.deviceQuery.specificParameterData$(6008).subscribe(x => {
      if (x?.value > 0) {
        var serialNumberAsString = x.value.toString();
        if (serialNumberAsString) {
          try {
            this.firestoreService.updateLatestHmiConnection(serialNumberAsString)
          } catch (error) {
            //console.log('COULD NOT UPDATE CURRENT CCU');
          }

          this.updateHmiSerialNumber(serialNumberAsString);
          setTimeout(async () => {
            await Filesystem.writeFile({
              path: 'previousDevice',
              recursive: true,
              data: JSON.stringify({ ...this.deviceQuery.currentDevice, hardwareVersions: [], periodicData: [] } as IPreviousDevice),
              directory: Directory.Data,
              encoding: Encoding.UTF8,
            }).then(() => console.log('PREVIOUSDEVICE ADDED TO LOCAL'))
              .catch(err => console.log('FAILED TO ADD PREVIOUSDEVICE TO LOCAL', err));
          }, 1000);
        }
      }
      else {
        try {
          this.firestoreService.updateLatestHmiConnection('')
        } catch (error) {
          //console.log('COULD NOT UPDATE CURRENT HMI');
        }
      }
    })
  }

  public updateTcuSerialNumber(serialNumber: string): void {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => {
        return {
          ...state,
          currentDevice: { ...state.currentDevice, address: serialNumber, tcuAddress: serialNumber, status: 'connected' }
        };
      });
    });
  }

  public updateCcuSerialNumber(serialNumber: string): void {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => {
        return {
          ...state,
          currentDevice: { ...state.currentDevice, ccuAddress: serialNumber, status: 'connected' }
        };
      });
    });
  }

  public updateHmiSerialNumber(serialNumber: string): void {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => {
        return {
          ...state,
          currentDevice: { ...state.currentDevice, hmiAddress: serialNumber, status: 'connected' }
        };
      });
    });
  }

  public removeCurrentDevice() {
    this.ngZone.run(() => {
      this.deviceStore.update({ currentDevice: {} as ICurrentDevice });
    });
  }

  public updateIsScanning(tt: boolean): void {
    this.ngZone.run(() => {
      this.deviceStore.update({ isScanning: tt });
    });
  }
  public updateIsOffline(tt: boolean): void {
    this.ngZone.run(() => {
      this.deviceStore.update({ isOffline: tt });
    });
  }

  public updateIsScanningForPreviousDevices(scanning: boolean) {
    this.ngZone.run(() => {
      this.deviceStore.update({ isScanningForPreviousDevices: scanning });
    });
  }

  public updateIsBluetoothIsActive(active: boolean) {
    this.ngZone.run(() => {
      this.deviceStore.update({ bluetoothIsActive: active });
    });
  }

  public startReconnectTimer(num: number) {
    this.ngZone.run(() => {
      this.deviceStore.update({ reConnectIn: num })
    });
  }

  public updateReconnectTimer() {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        reConnectIn: state.reConnectIn - 1
      }));
    });
  }

  public updateLastConnected(): void {
    var newDate = new Date();
    var uid = this.userQuery.userUid;

    var curDevice = this.deviceQuery.currentDevice;
    var tcuAddress = curDevice?.tcuAddress;
    var ccuAddress = curDevice?.ccuAddress;
    var hmiAddress = curDevice?.hmiAddress;

    curDevice.lastConnected = newDate;
    this.firestoreService.updateFirestoreDevice();

    if (tcuAddress) {
      this.firestoreService.updateLastConnected(tcuAddress, uid, newDate);

      this.deviceStore.update((state) => ({
        previousDevices: arrayUpsert(state.previousDevices, tcuAddress, curDevice, 'address'),
      }));
    }

    if (ccuAddress) {
      this.firestoreService.updateLastConnected(ccuAddress, uid, newDate);

      this.deviceStore.update((state) => ({
        previousDevices: arrayUpsert(state.previousDevices, ccuAddress, curDevice, 'address'),
      }));
    }

    if (hmiAddress) {
      this.firestoreService.updateLastConnected(hmiAddress, uid, newDate);

      this.deviceStore.update((state) => ({
        previousDevices: arrayUpsert(state.previousDevices, hmiAddress, curDevice, 'address'),
      }));
    }

    // if (address) {
    //   this.firestoreService.updateLastConnected(address, uid, newDate);

    //   this.deviceStore.update((state) => ({
    //     previousDevices: arrayUpsert(state.previousDevices, address, curDevice),
    //   }));
    // } else {
    //   this.firestoreService.updateLastConnected(curDevice.address, uid, newDate);

    //   this.deviceStore.update((state) => ({
    //     previousDevices: arrayUpsert(state.previousDevices, curDevice.address, curDevice, 'address'),
    //   }));
    // }
  }

  public updateServiceUuid(uuid: string): void {
    this.ngZone.run(() => {
      this.deviceStore.update({ serviceUuid: uuid });
    });
  }

  public updateCharacteristicsUuid(uuid: string): void {
    this.ngZone.run(() => {
      this.deviceStore.update({ characteristicsUuid: uuid });
    });
  }

  public async updateDeviceData(tcuAddress: string, ccuAddress: string, hmiAddress: string) {



    const dbFirestore = getFirestore();

    const tcuDeviceRef = doc(
      collection(dbFirestore, CONFIG.firebaseCollection.devices, tcuAddress, CONFIG.firebaseCollection.parameterData),
      CONFIG.firebaseCollection.parameterData
    );
    
    const tcuDeviceSnapshot = await getDoc(tcuDeviceRef);

    const ccuDeviceRef = doc(
      collection(dbFirestore, CONFIG.firebaseCollection.devices, ccuAddress, CONFIG.firebaseCollection.parameterData),
      CONFIG.firebaseCollection.parameterData
    );
    const ccuDeviceSnapshot = await getDoc(ccuDeviceRef);

    const hmiDeviceRef = doc(
      collection(dbFirestore, CONFIG.firebaseCollection.devices, hmiAddress, CONFIG.firebaseCollection.parameterData),
      CONFIG.firebaseCollection.parameterData
    );
    
    const hmiDeviceSnapshot = await getDoc(hmiDeviceRef);

    /*const ccuDevice = await this.angularFirestore.collection(CONFIG.firebaseCollection.devices).doc(ccuAddress)
      .collection(CONFIG.firebaseCollection.parameterData)
      .doc(CONFIG.firebaseCollection.parameterData)
      .get().toPromise();*/




    this.deviceStore.update({
      parameterData: [...tcuDeviceSnapshot.data()?.parameterData, ...ccuDeviceSnapshot.data()?.parameterData],
    });

    const db = getDatabase();
    const periodicDataRefTCU = ref(db, `/devices/${tcuAddress}/periodicData`);

    try {
      const snapshot = await get(periodicDataRefTCU);
      if (snapshot.exists()) {
        const currentPeriodicData = this.deviceQuery.periodicData;
        const newPeriodicData = snapshot.val();
        this.deviceStore.update({ periodicData: [...currentPeriodicData, ...newPeriodicData] });
      } else {
        console.log('No data available.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  /*
    firebase.database().ref('/devices/' + tcuAddress + '/periodicData').once('value', (snapshot) => {
      var curPeriodicData = this.deviceQuery.periodicData;
      this.deviceStore.update({ periodicData: [...curPeriodicData, ...snapshot.val()] });
    });*/

    const periodicDataRefCCU = ref(db, `/devices/${ccuAddress}/periodicData`);

    try {
      const snapshot = await get(periodicDataRefCCU);
      if (snapshot.exists()) {
        const currentPeriodicData = this.deviceQuery.periodicData;
        const newPeriodicData = snapshot.val();
        this.deviceStore.update({ periodicData: [...currentPeriodicData, ...newPeriodicData] });
      } else {
        console.log('No data available.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }


    const periodicDataRefHMI = ref(db, `/devices/${hmiAddress}/periodicData`);

    try {
      const snapshot = await get(periodicDataRefHMI);
      if (snapshot.exists()) {
        const currentPeriodicData = this.deviceQuery.periodicData;
        const newPeriodicData = snapshot.val();
        this.deviceStore.update({ periodicData: [...currentPeriodicData, ...newPeriodicData] });
      } else {
        console.log('No data available.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
/*
    firebase.database().ref('/devices/' + ccuAddress + '/periodicData').once('value', (snapshot) => {
      var curPeriodicData = this.deviceQuery.periodicData;
      this.deviceStore.update({ periodicData: [...curPeriodicData, ...snapshot.val()] });
    });*/

  }

  public resetDeviceData() {
    this.ngZone.run(() => {
      this.deviceStore.update({
        parameterData: [],
        periodicData: [],
        hardwareVersions: [],
        currentDevice: {},
        isOldData: false,
        
      });
    });
  }

  // private async loadPreviousDevices(): Promise<void> {
  //   const { userUid } = this.userQuery;
  //   this.angularFirestore
  //     .collection(CONFIG.firebaseCollection.users)
  //     .doc(userUid)
  //     .collection(CONFIG.firebaseCollection.previousDevices)
  //     .get()
  //     .pipe(
  //       map((device) =>
  //         device.docs.map((x) => {
  //           const data = x.data() as IHardwareVersion;
  //           console.log(data);
  //           return {
  //             ...data,
  //             lastConnected: data.lastConnected ? new Date(data.lastConnected) : null,
  //           };
  //         })
  //       ),
  //       map((previousDevices) => previousDevices.sort((a, b) => a.lastConnected?.getTime() - b.lastConnected?.getTime())),
  //       take(1)
  //     )
  //     .subscribe(async (previousDevices) => {
  //       var prevDeviceFromLocal = await this.loadPreviousDevicesFromLocal();
  //       if (Object.keys(prevDeviceFromLocal).length && !previousDevices.find(x => x.address === prevDeviceFromLocal?.address)) {
  //         var convertedPrevDeviceFromLocal = { ...prevDeviceFromLocal, lastConnected: prevDeviceFromLocal.lastConnected ? new Date(prevDeviceFromLocal.lastConnected) : null }
  //         previousDevices.push(convertedPrevDeviceFromLocal);
  //       }
  //       console.log(previousDevices);
  //       this.deviceStore.update((state) => ({
  //         previousDevices: [...state.previousDevices, ...previousDevices]
  //       }));

  //       previousDevices.forEach((prevDevice) => {
  //         firebase.database().ref('/devices/' + prevDevice.address + '/lastConnected').once('value').then(val => {
  //           var date = new Date(val.val());
  //           if (!prevDevice.lastConnected) {
  //             this.deviceStore.update((state) => ({
  //               previousDevices: arrayUpdate(state.previousDevices, prevDevice.address, { lastConnected: date }, 'address'),
  //             }));
  //           }
  //         }).catch(err => {
  //           console.log(err, 'Error while fetching data from realtime database');
  //         })
  //       })
  //     });
  // }

  public setProfiles(profiles: IProfile[]) {
    this.ngZone.run(() => {
      this.deviceStore.update({ profiles: profiles });
    });
  }

  public async loadPreviousDevicesFromLocal() {
    try {
      const file = await Filesystem.readFile({
        path: 'previousDevice',
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      if (file?.data) {
        let previousDevice = {} as IHardwareVersion;
        previousDevice = { ...JSON.parse(file.data as string) };
        return previousDevice;
      } else {
        return {} as IHardwareVersion
      }
    } catch (error) {
      //console.log(error);
      return {} as IHardwareVersion
    }

  }

  public addDeviceInRange(device: IPreviousDevice): void {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        ...state,
        devicesInRange: [...state.devicesInRange, device],
      }));
    });
  }

  public resetPreviousDevices(): void {
    this.deviceStore.update((state) => ({ ...state, devicesInRange: [] }));
  }

  public startConnection(): void {
    this.ngZone.run(() => {
      this.deviceStore.update({ connecting: true });
    });
  }

  public updatePeriodicData(data: PeriodicDataChangedEvent, periodicParameter: IPeriodicDataFieldList): void {
    // this.ngZone.run(() => {
    //   this.store.dispatch(deviceActions.setPeriodicParameter({ periodicData: dataEvent }));
    // });
    const value = { ...data, periodicParameter };
    this.ngZone.runOutsideAngular(() => {
      this.deviceStore.update((state) => ({
        periodicData: arrayUpsert(state.periodicData, data.FieldId, value),
      }));
    });
  }

  public updateParameterValue(data: ParameterChangedEvent, parameterDef: IParameterList) {
    //console.log("updateParameterValue "+ data.ParameterId);
    const value = { ...data, parameter: parameterDef };
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        parameterData: arrayUpsert(state.parameterData, data.ParameterId, value),
      }));
    });
  }

  public setParameters(parameters: IParameterData[]) {
    this.ngZone.run(() => {
      this.deviceStore.update(() => ({
        parameterData: parameters,
      }));
    });
  }

  public upsertTimedOutParameter(data: ParameterChangedEvent) {
    const value = { ...data };
    //console.log('TIMED OUT PARAMETER UPSERT', value);
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        timedOutParameters: arrayUpsert(state.timedOutParameters, data.ParameterId, value, 'parameterId'),
      }));
    });
  }

  public updateStringParameterValue(data: StringParameterChangedEvent, parameterDef: IParameterList) {

    var value: stringparametertemp = {
      stringValue: (data as any).stringValue,
      status: (data as any)?.status,
      eventType: (data as any)?.eventType,
      paramId: (data as any)?.paramId,
      parameter: parameterDef
    };
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        stringParameterData: arrayUpsert(state.stringParameterData, data.ParameterId, value, 'paramId'),
      }));
    });
  }

  public stopConnection(): void {
    this.ngZone.run(() => {
      this.deviceStore.update({ connecting: false });
    });
  }

  public addDefinitionVersion(ecuId: number, ecuName: string, sysType:string,
    swVersion: string, buildNumber: number, 
    compVersion: number, isExperimental: boolean, isUpgradeable:boolean, isSparePart:boolean, softwareVarient:number, softwareSubVarient:number ): void {
    this.deviceStore.update((state) => ({
      hardwareVersions: arrayUpsert(state.hardwareVersions, ecuId, { ecuId, ecuName, swVersion, buildNumber, compVersion, isExperimental, isSparePart,isUpgradeable,softwareSubVarient,softwareVarient}),
    }));

/*
*for TCS*/
    this.ngZone.run(() => {
      this.deviceStore.update({ systemType:sysType });
    });
    
  }

  public removeDefinitionVersion(ecuId: number, swVersion?: string): void {
    this.deviceStore.update((state) => ({
      hardwareVersions: arrayRemove(state.hardwareVersions, ecuId),
    }));
  }

  public addParameterForUpdate(request: any) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        parametersForUpdate: request.parametersForUpdate,
        requestUid: request.uid
      }));
    });
  }

  public addStringParameterForUpdate(request: any) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        stringParametersForUpdate: request.stringParametersForUpdate,
        stringRequestUid: request.uid
      }));
    });
  }

  public stopAutoCalibration(stop: boolean) {
    if (stop) {
      this.ngZone.run(() => {
        this.deviceStore.update((state) => ({
          stopAutoCalibrate: stop
        }));
      });
    }
  }

  public resetAutoCalibrationFlag() {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        stopAutoCalibrate: false
      }));
    });
  }

  public addParameterForAutoCalibration(parameters: any) {
    console.log ('addParameterForAutoCalibration')
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        autoCalibrationParameters: parameters
      }));
      console.log ('addParameterForAutoCalibration', parameters)
    });
  }

  public addParameterForBackup(parameter: { parameterId: number, ecuId: number, value: number | string }) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        parametersAndStringParametersForBackup:
          arrayUpsert(state.parametersAndStringParametersForBackup, parameter.parameterId, parameter, 'parameterId'),
      }));
    });
  }

  public removeParameterForBackup(parameter: { parameterId: number, ecuId: number, value: number | string }) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        parametersAndStringParametersForBackup:
          arrayRemove(state.parametersAndStringParametersForBackup,
            (val) => val.parameterId === parameter.parameterId && val.ecuId === parameter.ecuId
          ),
      }));
    });
  }

  public resetBackupParameters() {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        parametersAndStringParametersForBackup: []
      }));
    });
  }

  public setIsBackuping(isBackuping: boolean) {
    this.ngZone.run(() => {
      this.deviceStore.update((state) => ({
        isBackuping: isBackuping
      }));
    });
  }

  public subscribeOfflineDevice(serialNumber: string, partnerOneAddress: string, partnerTwoAddress: string, lastConnectedAt: Date, type:number): void {

    var sysType:string;

    switch (type) {
      case 0:
        sysType= 'not a system';

      case 1:
        sysType= 'Test';

      case 2:
        //return 'MIC 4.0';
        sysType= 'RCS';

      case 3:
        sysType= 'Standalone';

      case 4:
        sysType= 'MIC 4.0';

      case 5:
        sysType= 'TCS';

      default:
       sysType='Unknown';
    }
    //console.log(lastConnectedAt)
    //console.log(serialNumber)
    this.ngZone.run(() => {
      this.currentDevice = undefined;

      this.deviceStore.update((state) => {
        return {
          ...state,
          currentDevice: { ...state.currentDevice, status: 'offline', lastConnected: lastConnectedAt, uid: serialNumber },
          systemType:sysType
        };
      });


      const data$ = this.angularFirestore
        .collection(CONFIG.firebaseCollection.devices)
        .doc(serialNumber)
        .collection(CONFIG.firebaseCollection.parameterData)
        .doc(CONFIG.firebaseCollection.parameterData)
        .valueChanges()
        .pipe(filter((data) => !!data)); // Filter out undefined values

      const partnerOneData$ = this.angularFirestore
        .collection(CONFIG.firebaseCollection.devices)
        .doc(partnerOneAddress)
        .collection(CONFIG.firebaseCollection.parameterData)
        .doc(CONFIG.firebaseCollection.parameterData)
        .valueChanges()
        .pipe(filter((data) => !!data)); // Filter out undefined values

      const partnerTwoData$ = this.angularFirestore
        .collection(CONFIG.firebaseCollection.devices)
        .doc(partnerTwoAddress)
        .collection(CONFIG.firebaseCollection.parameterData)
        .doc(CONFIG.firebaseCollection.parameterData)
        .valueChanges()
        .pipe(filter((data) => !!data)); // Filter out undefined values


      data$.subscribe(data => console.log('data$', data));
      partnerOneData$.subscribe(data => console.log('partnerOneData$', data));
      partnerTwoData$.subscribe(data => console.log('partnerTwoData$', data));

      // Combine and wait for all observables to complete

      this.parameterDataSubscription = combineLatest([data$, partnerOneData$, partnerTwoData$]).pipe(


        map(([data1, data2, data3]) => {
          //console.log(data1)
          //console.log(data2)
          //console.log(data3)
          // Merge the data from both collections into a single array
          const mergedData = [...data1.parameterData, ...data2.parameterData, ...data3.parameterData];
          return mergedData;
        })
      )
        .subscribe((data: any) => {
          //console.log('offline', data);
          this.deviceStore.update((state) => {
            return {
              ...state,
              parameterData: data,
              stringParameterData: data.find(x => x.ecuId === 6),

            };
          });
        });


      this.alarmSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc<{ alarms: IAlarm[] }>(CONFIG.firebaseCollection.activeAlarms)
      .valueChanges()
      .subscribe((alarms) => {
        //console.log(alarms);
        var oldAlarms = this.alarmQuery.alarms;
        const oldAlarmCounter = oldAlarms.length;
        const newAlarmCounter = alarms.alarms.length;
        if (newAlarmCounter > oldAlarmCounter) {
          var alarmsDif = alarms.alarms.filter(({ errorCodeId: id1 }) => !oldAlarms.some(({ errorCodeId: id2 }) => id2 === id1));
          var dif = newAlarmCounter - oldAlarmCounter;
          let newAlarms = alarmsDif.map(alarm => alarm.errorCodeId.toString());
          // this.toastService.infoToast(`${dif} new alarms, ids: ${newAlarms} `)
        }
        else if (oldAlarmCounter > newAlarmCounter) {
          var alarmsDif = oldAlarms.filter(({ errorCodeId: id1 }) => !alarms.alarms.some(({ errorCodeId: id2 }) => id2 === id1));
          var dif = oldAlarmCounter - newAlarmCounter;
          let newAlarms = alarmsDif.map(alarm => alarm.errorCodeId.toString());
          // this.toastService.infoToast(`${dif} alarms removed, ids: ${newAlarms} `)
        }

          this.alarmStore.update((state) => ({
            ...state, alarms: alarms.alarms.map(x => ({
              ...x,
              timeGenerated: (x.timeGenerated as any).seconds ? new Date((x.timeGenerated as any).seconds * 1000) : x.timeGenerated
            }))
          }));
        });

    this.alarmLogSubscription = this.angularFirestore
      .collection(CONFIG.firebaseCollection.devices)
      .doc(serialNumber)
      .collection(CONFIG.firebaseCollection.alarms)
      .doc(CONFIG.firebaseCollection.alarmLog)
      .valueChanges()
      .subscribe((alarms: any) => {
        //console.log(alarms.alarmLog);

          const alarmLog = (alarms.alarmLog as IAlarmLog[]).map(x => ({
            ...x,
            firstAsDate: (x.firstAsDate as any).seconds ? new Date((x.firstAsDate as any).seconds * 1000) : x.firstAsDate,
            lastAsDate: (x.lastAsDate as any).seconds ? new Date((x.lastAsDate as any).seconds * 1000) : x.lastAsDate
          }))
          this.alarmStore.update((state) => ({ ...state, alarmLog }));
        });

    });
  }

 

  
 

}
