import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IFirmwareState } from '@shared/interfaces/firmware.interface';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'update', resettable: true })
export class FirmwareStore extends Store<IFirmwareState> {
  constructor() {
    super({
      firmwares: [],
      upgradeProgress: [],
      ongoingUpgrade: false,
      numberOfUpdates: 0,
      keepGoing: false
    });
  }
}
