import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { IUser, ICurrentUser } from '@interfaces/user.interface';

function createInitialUserState(): IUser {
  return {
    uid: '',
    currentUser: {} as ICurrentUser,
    serviceToolHasBleConnection: false,
    autoCalibrationStarted: false,
    notConnectedToDevice:false,
    dataTableBugFix: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<IUser> {
  constructor() {
    super(createInitialUserState());
  }
}
