import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {  Subscription } from 'rxjs';

import { AlertController } from '@ionic/angular';

import { DeviceService } from '@state/device/device.service';
import { DeviceQuery } from '@state/device/device.query';


import { ICurrentDevice } from '@interfaces/device.interface';
import { ToastService } from '../toast.service';
import { AlarmService } from '@shared/state/alarm/alarm.service';
import { AlarmQuery } from '@shared/state/alarm/alarm.query';
import { TranslateService } from '@ngx-translate/core';
import { IAlarm } from '@shared/interfaces/alarm.interface';

import { RemoteUserService } from '@shared/state/remote-user/remote-user.service';
import { UserQuery } from '@shared/state/user/user.query';
import { ICurrentUser } from '@shared/interfaces/user.interface';
import { UserService } from '@shared/state/user/user.service';
import { FirmwareQuery } from '@shared/state/firmware/firmware.query';
import { WorkerMessage } from '@shared/worker/models/worker-message.model';
import { WorkerService } from '@shared/worker/worker.service';
import { WORKER_TOPIC } from '@shared/worker/models/worker-topic.constants';
import { FirestoreService } from '../firestore.service';

import { IonLoaderService } from '@shared/services/loader.service';

@Injectable({
    providedIn: 'root',
})
export class BleWebService {
    public gattService: BluetoothRemoteGATTService[];
    public characteristics: BluetoothRemoteGATTCharacteristic[];
    public gattServer: BluetoothRemoteGATTServer;
    public deviceValueSubscription: Subscription;
    private alarmSubscription: Subscription;
    public bluetoothDevice: BluetoothDevice;
    public disconnectedFromUser = false;
    public defaultMainTab = "Users"
    public systemType$ = this.deviceQuery.systemType$;
    constructor(
        private deviceService: DeviceService,
        
        private deviceQuery: DeviceQuery,
        private router: Router,
        private ngZone: NgZone,
        private toastService: ToastService,
        private alarmService: AlarmService,
        private remoteUserService: RemoteUserService,
        private userQuery: UserQuery,
        private userService: UserService,
        private firmwareQuery: FirmwareQuery,
        private workerService: WorkerService,
        private alarmQuery: AlarmQuery,
        private translateService: TranslateService,
        private alertController: AlertController,
        private firestoreService: FirestoreService,
        private ionLoaderService: IonLoaderService,
    ) { }

   

    public subscribeToAlarms() {
        this.alarmSubscription?.unsubscribe();
        this.alarmSubscription = this.alarmQuery.alarms$.subscribe((alarms) => {
            if (alarms.length > 0) {
                alarms.forEach(alarm => {
                    if (alarm && alarm.isOldAlarm === false) {
                        // if (!this.firmwareQuery.ongoingUpgrade && !this.firmwareQuery.keepGoing) {
                        //     this.showNewAlarm(alarm);
                        // }

                        this.alarmService.setAlarmToOld(alarm)
                    }
                })
            }
        });
    }

    private prepareDisconnect(address: string) {
        
        this.deviceService.updateLastConnected();
        this.deviceService.stopConnection();
        this.alarmSubscription?.unsubscribe();
       // this.characteristics[0]?.stopNotifications();
        this.toastService.warningToast('toasts.device disconnected', true);
        this.deviceService.resetDeviceData();
        this.alarmService.resetAlarmData();
        this.remoteUserService.resetStoreTotally();
        const stopSubscription = new WorkerMessage(WORKER_TOPIC.stopSubscriptions, "");
        const clearWorkerState = new WorkerMessage(WORKER_TOPIC.clearWorkerState, "");
        this.workerService.doWork(stopSubscription);
        this.workerService.doWork(clearWorkerState);
        
        
        this.router.navigate(['/reload'], { replaceUrl: true });
       
    }

    public disconnect(address: string) {
        this.disconnectedFromUser = true;
        this.gattServer.disconnect();
        this.prepareDisconnect(address);
        //this.gattServer.disconnect();
    }

    public async showNewAlarm(alarm: IAlarm): Promise<void> {
        //console.log(alarm);

        const dismiss = this.translateService.instant('alerts.dismiss');
        const later = this.translateService.instant('alerts.later');
        const alert = await this.alertController.create({
            header: "New Alarm",
            subHeader: "Name: " + alarm.Name + ", ECU ID: " + alarm.ecuId + ", Error Code ID: " + alarm.errorCodeId,
            message: alarm.comment,
            cssClass: "alert-wrapper",
            keyboardClose: false,
            backdropDismiss: false,
            buttons: alarm.alarmInfoList.AckAlarm ? [
                {
                    text: dismiss,
                    role: 'dismiss',

                },
                {
                    text: later,
                    role: 'later',
                }
            ] :
                [
                    {
                        text: 'OK',
                        role: 'ok',
                    }
                ],
        });
        alert.present();

        alert.onDidDismiss().then(async (res: { data: {}; role: 'dismiss' | 'later' | 'ok' }) => {
            //console.log('alert res: ', res);
            switch (res.role) {
                case 'dismiss':
                    this.alarmService.removeAlarm(alarm);
                    break;
                case 'later':
                    this.toastService.dismisstoast();
                    this.toastService.infoToast('toasts.alarm dismiss later', true);
                    break;
                case 'ok':
                    this.toastService.dismisstoast();
                default:
                    break;
            }
        });
    }

    private delayPromise(delay: number) {
        return new Promise(resolve => {
            setTimeout(resolve, delay);
        });
    }

    public connectToDeviceWeb(server: BluetoothRemoteGATTServer) {
        
        this.deviceService.resetDeviceData();
        this.alarmService.resetAlarmData();
        this.subscribeToAlarms();
        this.deviceService.startConnection();

        setTimeout(() => {
            this.gattServer = server;
            server.device.addEventListener('gattserverdisconnected', (event) => this.handleDisconnect(event));
            this.startSubscribeConnectionWeb(server);
        }, 250);
    }

    private handleDisconnect($event) {
        if (!this.disconnectedFromUser) {
            this.remoteUserService.resetStore();
            //this.connectApiService.resetConnectApi();
            this.toastService.warningToast('toasts.device disconnected', true);
            this.deviceService.resetDeviceData();
            this.alarmService.resetAlarmData();
            //console.log('close here');
            this.defaultMainTab="Users";
            this.deviceValueSubscription?.unsubscribe();
            this.router.navigate(['/start']);
            //console.log($event);
            this.bluetoothDevice = $event?.target;
            this.bluetoothDevice.gatt?.disconnect();
            //console.log("this.this.bluetoothDevice", this.bluetoothDevice)
            //console.log("DISCONNECTED");
            
            this.connectAfterDisconnect();
        }

        this.firestoreService.cancelFirestoreLink();
        this.characteristics = undefined;
        this.userService.serviceToolBleIsActive(false);
        this.deviceService.stopConnection();
    }

    private connectAfterDisconnect() {
        var device = this.bluetoothDevice;
        //console.log("device", this.bluetoothDevice)
        setTimeout(() => {
            try {
                if(this.disconnectedFromUser === false){
                 console.log("CONNECTED");
                this.startSubscribeConnectionWeb(device.gatt);
            }
            } catch (error) {
                console.log(error);
                this.connectAfterDisconnect();
            }
        }, 7000);
    }

    public async startSubscribeConnectionWeb(server: BluetoothRemoteGATTServer): Promise<void> {
        this.disconnectedFromUser = false;
        var gattService: BluetoothRemoteGATTService[] = [];
        var characteristics: BluetoothRemoteGATTCharacteristic[] = [];
        var connectionApproved =server.device?.gatt?.connected ? true : false;

        if (connectionApproved){
            server.connect().then(deviceStatus => {
                if (deviceStatus.connected) {
                   // console.log("CONNECTED");
                    
                    deviceStatus.getPrimaryService('2456e1b9-26e2-8f83-e744-f34f01e9d701')
                        .then(service => {
                            gattService.push(service);
                            //console.log(service);
                            service.getCharacteristic('2456e1b9-26e2-8f83-e744-f34f01e9d703').then(character => {
                                characteristics.push(character)
                                // character.forEach(char => characteristics.push(char));
                                //console.log(character);
                                this.gattService = gattService;
                                this.characteristics = characteristics;
    
                                const newDevice: ICurrentDevice = {
                                    address: deviceStatus.device.id.replaceAll('/', ''),
                                    name: deviceStatus.device.name,
                                    status: 'waiting for serialnumber',
                                    services: '2456e1b9-26e2-8f83-e744-f34f01e9d701',
                                    webServices: '2456e1b9-26e2-8f83-e744-f34f01e9d701',
                                    currentConnectedBluetoothChip: deviceStatus.device.id.replaceAll('/', ''),
                                    bluetoothConnectors: [deviceStatus.device.id.replaceAll('/', '')],
                                    
                                };
                                //console.log(newDevice)
                                this.ngZone.run(() => {
                                    var currentUser: ICurrentUser = {
                                        ...this.userQuery.currentUser,
                                        uid: this.userQuery.userUid
                                    };
                                   
                                    this.deviceService.addCurrentDevice(newDevice);
                                    this.remoteUserService.initializeServiceToolBleConnection(currentUser)
                                    this.userService.serviceToolBleIsActive(true);
                                    this.deviceService.stopConnection();
                                    
                                    
                                    if (!this.firmwareQuery.ongoingUpgrade) {
    
                                      // this.ionLoaderService.simpleLoader();
                                        setTimeout(() => {
                                            this.systemType$?.subscribe(item => {
    
                                                if(item !== 'TCS'){
                                                  this.router.navigate(['system/', item, currentUser.uid, 'service'])
                                                  //this.ionLoaderService.dismissLoader();
                                                } else if (item === 'TCS'){
                                                    this.router.navigate(['system/', item, currentUser.uid, 'TcsDashboard'])
                                                   // this.ionLoaderService.dismissLoader();
                                                  }
                                            })
                                        }, 1000)
                                    }
                                    
                                });
    
                            }).catch(error => {
                                console.error(error);
                                this.deviceService.stopConnection();
                            })
                            // return service.getCharacteristic('2456e1b9-26e2-8f83-e744-f34f01e9d703'); //2456e1b9-26e2-8f83-e744-f34f01e9d704
                        }).catch(err => {
                            console.error(err);
                            this.deviceService.stopConnection();
                        })
                }
                else if (!deviceStatus.connected) {
                    this.deviceService.stopConnection();
                    
                    setTimeout(() => {
                       
                        this.startSubscribeConnectionWeb(deviceStatus);
                    }, 7000);
                }
            }).catch(err => {
                console.log(err, 'ERROR');
    
            })

        } else {
            this.deviceService.stopConnection();
        }
        
        
    }


    public async createWaitForResponseAlert(): Promise<boolean> {
        //this.remoteUserSerivce.setConnectionApproval(undefined);
        //this.connectionIsApprovedSubscription?.unsubscribe();
        this.ionLoaderService.dismissLoader()
        const header = this.translateService.instant(`alerts.remote-support.tilt connection`);
        const message = this.translateService.instant(`alerts.remote-support.waiting for the approval`);
        const buttonText = this.translateService.instant(`pendingauth.cancel`);
    
        const body = `
          <div class="text-center">
            <div class="m-auto w-1/5 flex">
              <ion-img src="/assets/icon/Remote_Service_Cyan.png"></ion-img>
            </div>
          
            <p class="text-26pt text-roto-green py-2 font-semibold">${header}</p>
            <p class=" text-20pt text-roto-green">${message}</p>
          </div>`;
        return new Promise<boolean>((resolve) => {
            this.alertController.create({
                message: body,
                keyboardClose: false,
                backdropDismiss: false,
                id: 'responseConnectionAlert',
                buttons: [
                  {
                    text: buttonText,
                    role: 'cancel',
                    cssClass: 'no-button-borders'
                  },
                ],
              }).then(alert => {
                    alert.present();
                    alert.onDidDismiss().then((res) => {
                    if (res.role === 'cancel') {
                        this.disconnect(this.deviceQuery.currentDevice.currentConnectedBluetoothChip);
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                    });
                 });
        })

    }
    
     
}
