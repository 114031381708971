import { BleErrorType, CommandSpecifier } from "../BleProtocol/BleProtocolPacket";
import { AlarmLogStatusType, AlarmResponseType } from "../CAN_Messages/AlarmResponse";
import { ECU_ID } from "../CAN_Messages/CanEnums";
import { AlarmInfo, EcuInfo, ParamResponseResult } from "../CAN_Messages/CanMessages";


export enum ResponseType {
  EcuConnect = "EcuConnect",
  EcuDisconnect = "EcuDisconnect",
  ParamChanged = "ParameterChanged",
  ParamsChanged = "ParametersChanged",
  StringParamChanged = "StringParameterChanged",
  PeriodicDataChanged = "PeriodicDataChanged",
  UpdateFwStatus = "UpdateFwStatus",
  ActiveAlarmChanged = "ActiveAlarmChanged",
  AlarmLogChanged = "AlarmLogChanged",
  AckAlarmResponse = "AckAlarmResponse",
  ClearAlarmResponse = "ClearAlarmResponse",
  SubscriptionStatusChanged = "SubscriptionStatusChanged",
  ErrorResponse = "ErrorResponse",
  CommunicationStatus = "CommunicationStatus",
  ConnectionRequestResponse = "ConnectionRequestResponse",
}

export enum ConnectionStatusType {
  CONNECTION_DENIED = 0,
  CONNECTION_APPROVED = 1,
  CONNECTION_ALREADY_ACTIVE = 2,
  CONNECTION_STATUS_UNKNOWN = 3,
}

export abstract class ResponseEventBase {
  private ecuId: ECU_ID;
  private responseType: ResponseType;

  constructor(eventType: ResponseType, ecuId?: ECU_ID) {
    this.responseType = eventType;
    if (ecuId) {
      this.ecuId = ecuId;
    } else {
      this.ecuId = ECU_ID.UNDEFINED;
    }
  }

  get EcuId(): ECU_ID {
    return this.ecuId;
  }

  set EcuId(ecuId: ECU_ID) {
    this.ecuId = ecuId;
  }

  get ResponseType(): ResponseType {
    return this.responseType;
  }

  set ResponseType(responseType: ResponseType) {
    this.responseType = responseType;
  }
}


export class ConnectionStatusChangedEvent extends ResponseEventBase {
  private readonly connectionStatus: ConnectionStatusType;
  constructor( status: ConnectionStatusType) {
    super(ResponseType.ConnectionRequestResponse, ECU_ID.BROADCAST);
    this.connectionStatus = status;
    //console.log("Connection status changed: "+status);
  }
  get ConnectionStatus(): ConnectionStatusType {
    return this.connectionStatus;
  }
}


export enum SubscriptionType {
  COMMON = "common",
  PRIO = "prio",
}


export class SubscritionResponse extends ResponseEventBase {
  private subscriptionType: SubscriptionType;
  private numSubs: number;
  private maxSubs: number;

  constructor(subType: SubscriptionType, numsubs: number, maxSubs: number) {
    super(ResponseType.SubscriptionStatusChanged);
    this.numSubs = numsubs;
    this.maxSubs = maxSubs;
    this.subscriptionType = subType;
  }
  

  get NumSubscriptions(): number {
    return this.numSubs;
  }

  set NumSubscriptions(num: number) {
    this.numSubs = num;
  }

  get MaxSubscriptions(): number {
    return this.maxSubs;
  }

  set MaxSubscriptions(num: number) {
    this.maxSubs = num;
  }

  get SubscriptionType(): SubscriptionType {
    return this.subscriptionType;
  }

  set SubscriptionType(value: SubscriptionType) {
    this.subscriptionType = value;
  }
}

export class EcuConnectEvent extends ResponseEventBase {
  // private swVersion: number;
  private readonly bootMode: boolean;
  private readonly sysCompabilityVersion: number;
  private  readonly hwVersion: number;
  private readonly swVersion: string;
  private readonly buildNumber: number;
  private readonly experimental: boolean;
  private readonly sparePart: boolean;
  private readonly notUpgradeable: boolean;
  private readonly softwareVarient: number;
  private readonly softwareSubVarient: number;

  constructor(ecuInfo: EcuInfo) {
    super(ResponseType.EcuConnect);
    this.EcuId = ecuInfo.EcuId;
    this.bootMode = ecuInfo.BootMode;
    this.sysCompabilityVersion = ecuInfo.SysCompVersion;
    this.hwVersion = ecuInfo.HwVersion;
    this.swVersion = ecuInfo.SwVersion;
    this.buildNumber = ecuInfo.BuildNumber;
    this.softwareVarient = ecuInfo.SoftwareVarient;
    this.softwareSubVarient = ecuInfo.SoftwareSubVarient;
    this.experimental = ecuInfo.Experimental;
    this.sparePart = ecuInfo.SparePart;
    this.notUpgradeable = ecuInfo.NotUpgradeable;
   // console.log('connectapi', 'softwareVar', this.softwareVarient, 'sub', this.softwareSubVarient )
    // this.swVersion = swVersion;
  }
  get BootMode(): boolean {
    return this.bootMode;
  }

  get SysCompabilityVersion(): number {
    return this.sysCompabilityVersion;
  }

  get HwVersion(): number {
    return this.hwVersion;
  }

  get SwVersion(): string {
    return this.swVersion;
  }

  get BuildNumber(): number {
    return this.buildNumber;
  }

  get Experimental(): boolean {
    return this.experimental;
  }

  get SparePart(): boolean {
    return this.sparePart;
  }

  get NotUpgradeable(): boolean {
    return this.notUpgradeable;
  }
  
  get SoftwareVariant(): number {
    return this.softwareVarient;
  }

  get SoftwareSubVariant(): number {
    return this.softwareSubVarient;
  }
 
}

export class EcuDisconnectEvent extends ResponseEventBase {
  constructor(ecuId: ECU_ID) {
    super(ResponseType.EcuDisconnect, ecuId);
  }
}
export enum StringParameterEventType {
  READ,
  WRITE,
}
export class StringParameterChangedEvent extends ResponseEventBase {
  /*String value is undefined for write string parameter events*/
  private stringValue:String|undefined;
  private paramId:number;
  private status: ParamResponseResult;
  private eventType:StringParameterEventType;
  constructor(paramId: number, stringValue: String|undefined, status: ParamResponseResult,eventType:StringParameterEventType) {
    super(ResponseType.StringParamChanged);
    this.paramId=paramId;
    this.status = status;
    this.stringValue =stringValue;
    this.eventType=eventType;
  }
  get StringValue():String|undefined{
    return this.stringValue;
  }

  get ParameterId(): number {
    return this.paramId;
  }

  get Status(): number {
    return this.status;
  }

  get EventType(): number {
    return this.eventType;
  }

}
export class ParametersChangedEvent extends ResponseEventBase {
  private parameterChangedEvents: ParameterChangedEvent[];


  constructor(parameterChangedEvents: ParameterChangedEvent[]) {
    super(ResponseType.ParamsChanged);
    this.parameterChangedEvents = parameterChangedEvents;
 
  }

  get ParameterChangedEvents(): ParameterChangedEvent[] {
    return this.parameterChangedEvents;
  }
}

export class ParameterChangedEvent extends ResponseEventBase {
  private value: number;
  private status: ParamResponseResult;
  private parameterId: number;

  constructor(paramId: number, value: number, status: ParamResponseResult) {
    super(ResponseType.ParamChanged);
    this.value = value;
    this.status = status;
    this.parameterId = paramId;
    this.EcuId= Math.floor(paramId/1000);
  }

  get ParameterId(): number {
    return this.parameterId;
  }

  set ParameterId(val: number) {
    this.parameterId = val;
  }

  get Value(): number {
    return this.value;
  }

  set Value(value: number) {
    this.value = value;
  }

  set Status(status: ParamResponseResult) {
    this.status = status;
  }

  get Status(): ParamResponseResult {
    return this.status;
  }
}

export class PeriodicDataChangedEvent extends ResponseEventBase {
  private fieldId: number;
  private value: number;

  constructor(ecuId: number, fieldId: number, value: number) {
    super(ResponseType.PeriodicDataChanged, ecuId);
    this.fieldId = fieldId;
    this.value = value;
  }

  get FieldId(): number {
    return this.fieldId;
  }

  set FieldId(fieldId: number) {
    this.fieldId = fieldId;
  }

  get Value(): number {
    return this.value;
  }

  set Value(value: number) {
    this.value = value;
  }
}

export enum UpgradeEventType {
  IDLE, STARTING, RUNNING, ABORTED, FINISHED, FAILED, BUSY,
}

export class UpgradeFwStatusEvent extends ResponseEventBase {
  private readonly progress: number;
  private readonly status: UpgradeEventType;
  private readonly noOfUpgrades: number;
  private readonly currentUpgradeIdx;
  private readonly message?: string;

  constructor(ecuId: ECU_ID, progress: number, type: UpgradeEventType, noUpgrades: number, currentUpgradeIdx: number, message?: string) {
    super(ResponseType.UpdateFwStatus, ecuId);
    this.progress = progress;
    this.noOfUpgrades = noUpgrades;
    this.currentUpgradeIdx = currentUpgradeIdx;
    this.message = message;
    this.status = type;
  }

  get Status(): UpgradeEventType {
    return this.status;
  }

  get Progress(): number {
    return this.progress;
  }

  get NoOfUpgrades(): number {
    return this.noOfUpgrades;
  }

  get CurrentUpgradeIndex(): number {
    return this.currentUpgradeIdx;
  }

  get Message(): string | undefined {
    return this.message;
  }
}

export class ActiveAlarmsChangedEvent extends ResponseEventBase {
  private alarmList: number[];
  private alarmInfoList: AlarmInfo[];

  constructor(ecuId: ECU_ID, alarms: number[], alarmInfoList: AlarmInfo[]) {
    super(ResponseType.ActiveAlarmChanged, ecuId);
    this.alarmList = alarms;
    this.alarmInfoList = alarmInfoList;
  }

  get Alarms(): number[] {
    return this.alarmList;
  }

  set Alarms(alarms: number[]) {
    this.alarmList = alarms;
  }

  get AlarmsInfo(): AlarmInfo[] {
    return this.alarmInfoList;
  }

  set AlarmsInfo(alarmInfo: AlarmInfo[]) {
    this.alarmInfoList = alarmInfo;
  }
}

export class Alarm {
  private alarmCode: number;
  private tempErased: boolean;
  private noOfAlarms: number;
  private numPowerOns: number;
  private hoursSinceFirst: number;
  private secondsSinceFirst: number;
  private hoursSinceLast: number;
  private secondsSinceLast: number;

  constructor() {
    this.alarmCode = 0;
    this.tempErased = false;
    this.noOfAlarms = 0;
    this.numPowerOns = 0;
    this.hoursSinceFirst = 0;
    this.secondsSinceFirst = 0;
    this.hoursSinceLast = 0;
    this.secondsSinceLast = 0;
  }

  public get AlarmCode(): number {
    return this.alarmCode;
  }

  public set AlarmCode(alarmCode: number) {
    this.alarmCode = alarmCode;
  }

  public get TempErased(): boolean {
    return this.tempErased;
  }

  public set TempErased(tempErased: boolean) {
    this.tempErased = tempErased;
  }

  public get NoOfAlarms(): number {
    return this.noOfAlarms;
  }

  public set NoOfAlarms(noOfAlarms: number) {
    this.noOfAlarms = noOfAlarms;
  }

  public get NumPowerOns(): number {
    return this.numPowerOns;
  }

  public set NumPowerOns(numPowerOns: number) {
    this.numPowerOns = numPowerOns;
  }

  public get HoursSinceFirst(): number {
    return this.hoursSinceFirst;
  }

  public set HoursSinceFirst(hoursSinceFirst: number) {
    this.hoursSinceFirst = hoursSinceFirst;
  }

  public get SecondsSinceFirst(): number {
    return this.secondsSinceFirst;
  }

  public set SecondsSinceFirst(secondsSinceFirst: number) {
    this.secondsSinceFirst = secondsSinceFirst;
  }

  public get HoursSinceLast(): number {
    return this.hoursSinceLast;
  }

  public set HoursSinceLast(hoursSinceLast: number) {
    this.hoursSinceLast = hoursSinceLast;
  }

  public get SecondsSinceLast(): number {
    return this.secondsSinceLast;
  }

  public set SecondsSinceLast(secondsSinceLast: number) {
    this.secondsSinceLast = secondsSinceLast;
  }
}

export class AlarmlogChangedEvent extends ResponseEventBase {
  private readonly alarms?: Alarm[];
  private readonly alarmStatus: AlarmLogStatusType;
 

  constructor(ecuId: ECU_ID, alarmsStatus: AlarmLogStatusType, alarms?: Alarm[]) {
    super(ResponseType.AlarmLogChanged, ecuId);
    this.alarmStatus = alarmsStatus;
    if (alarms) {
      this.alarms = alarms;
    }
  }

  get Alarms(): Alarm[] | undefined {
    return this.alarms;
  }

  get AlarmStatus(): AlarmLogStatusType {
    return this.alarmStatus;
  }
}

export class ClearAlarmResponseEvent extends ResponseEventBase {
  private readonly alarmStatus: AlarmLogStatusType;
 

  constructor(ecuId: ECU_ID, alarmsStatus: AlarmLogStatusType) {
    super(ResponseType.ClearAlarmResponse, ecuId);
    this.alarmStatus = alarmsStatus;
  }
  get AlarmStatus(): AlarmLogStatusType {
    return this.alarmStatus;
  }
}
export class AckAlarmResponseEvent extends ResponseEventBase {
  private readonly alarmStatus: AlarmLogStatusType;
  constructor(ecuId: ECU_ID, alarmsStatus: AlarmLogStatusType) {
    super(ResponseType.AckAlarmResponse, ecuId);
    this.alarmStatus = alarmsStatus;
  }
  get AlarmStatus(): AlarmLogStatusType {
    return this.alarmStatus;
  }
}

export class ErrorResponseEvent extends ResponseEventBase {
  private readonly error: BleErrorType;
  private readonly errorCommand: CommandSpecifier;
  private readonly errorPlLength: number;

  constructor(error: BleErrorType, errorCommand: CommandSpecifier, errorPlLength: number) {
    super(ResponseType.ErrorResponse);
    this.error = error;
    this.errorCommand = errorCommand;
    this.errorPlLength = errorPlLength;
  }

  get ErrorType(): BleErrorType {
    return this.error;
  }

  get ErrorCommand(): CommandSpecifier {
    return this.errorCommand;
  }

  get ErrorCommandPlLength(): number {
    return this.errorPlLength;
  }
}

export class CommunicationStatusResponseEvent extends ResponseEventBase {
  private readonly numFwdFrames: number;
  private readonly numDiscFrames: number;
  private readonly numFwdPrioFrames: number;
  private readonly numDiscPrioFrames: number;

  constructor(numFwdFrames: number, numDiscFrames: number, numFwdPrioFrames: number, numDiscPrioFrames: number) {
    super(ResponseType.CommunicationStatus);
    this.numFwdFrames = numFwdFrames;
    this.numDiscFrames = numDiscFrames;
    this.numFwdPrioFrames = numFwdPrioFrames;
    this.numDiscPrioFrames = numDiscPrioFrames;
  }

  public get NumForwardedFrames(): number {
    return this.numFwdFrames;
  }

  public get NumDiscardedFrames(): number {
    return this.numDiscFrames;
  }

  public get NumForwardedPrioFrames(): number {
    return this.numFwdPrioFrames;
  }

  public get NumDiscardedPrioFrames(): number {
    return this.numDiscPrioFrames;
  }
}
