export enum PGN_Name {
  Commands = 0x00ffa1,
  Movement = 0x00ffa2,
  Status = 0x00ffb1,
  Currents = 0x00ffb2,
  Angles = 0x00ffb3,
  Feeder = 0x00ffb4,
  AlarmMessage = 0x00ffa8,
  EcuInfo = 0x00ffa9,
  AlarmRequest = 0x00ffae,
  ParamRequest = 0x00ffaf,
  AlarmResponse = 0x00ffbe,
  ParamResponse = 0x00ffbf,
  ProgRequest = 0x00fff8, //from service tool
  ProgResponse = 0x00fff9, //from ecu to service tool
  HmiCommand = 0x00ffd1, //HMI -> CCU
  HmiStatus = 0x00ffe1, //CCU -> HMI
  HcuCommand = 0x00ff61,
  HcuPwmCurrent_1_To_6 = 0x00ff62,
  HcuPwmCurrent_2_4_6 = 0x00ff63,
  HcuTxStatus = 0x00ff68,
  HcuTxPwmCurrent_1_To_6 = 0x00ff69,
  HcuTxPwmCurrent_2_4_6 = 0x00ff6a,
  SecureLock = 0x00ffc2,
  ConnectionRequestResponse= 0x00FFD9,
}

export type CAN_ADDRESS = number;
//   TCU = 0xe1,
//   CCU = 0xe2,
//   HCU = 0xe3,
//   JLE = 0xe4,
//   JRI = 0xe5,
//   HMI = 0xe6,
//   CAC1 = 0xe7,
//   CAC2 = 0xe8,
//   TCU_GW = 0xef, //TODO: ├àterst├ñll till 0xec
//   HMI_GW = 0xed,
//   PC = 0xee,
//   JLE_JRI = 0xaa,
//   MULTICAST = 0xff
//

export enum ECU_ID {
  TCU = 1,
  CCU = 2,
  HCU = 3,
  JLE = 4,
  JRI = 5,
  HMI = 6,
  CAC1 = 7,
  CAC2 = 8,
  DCU=9,
  JLE_JRI = 14,
  BROADCAST = 15,
  UNDEFINED = 0xff,
}