import { IRequest } from '@interfaces/request.interface';
import { ICurrentUser } from '@interfaces/user.interface';
import { IAlarm } from '@interfaces/alarm.interface';
import { IHardwareVersion, IParameterData } from '@shared/interfaces/device.interface';

export const connectedUser$: ICurrentUser = {
  "approved": true,
  "company": "Dizparc SE",
  "customerNumber": "13371337",
  "email": "ludvig.bostrom@dizparc.se",
  "uid": "dwrKA1YUCWNitLT3xusWC2ggxHa2",
  "firstName": "Ludvig",
  "hardwareVersions": [
    {
      "buildNumber": 2303101,
      "compVersion": 1,
      "ecuId": 6,
      "ecuName": "HMI",
      "id": 6,
      "swVersion": "0.5.10",
      "isExperimental": false,
      "isUpgradeable": false,
      "isSparePart": false,
      "softwareVarient": 2,
      "softwareSubVarient": 0
    },
    {
      "buildNumber": 2212011,
      "compVersion": 1,
      "ecuId": 5,
      "ecuName": "JRI",
      "id": 5,
      "swVersion": "1.0.1",
      "isExperimental": false,
      "isUpgradeable": false,
      "isSparePart": false,
      "softwareVarient": 2,
      "softwareSubVarient": 0
    },
    {
      "buildNumber": 2212011,
      "compVersion": 1,
      "ecuId": 4,
      "ecuName": "JLE",
      "id": 4,
      "swVersion": "1.0.1",
      "isExperimental": false,
      "isUpgradeable": false,
      "isSparePart": false,
      "softwareVarient": 2,
      "softwareSubVarient": 0
    },
    {
      "buildNumber": 2303131,
      "compVersion": 0,
      "ecuId": 3,
      "ecuName": "HCU",
      "id": 3,
      "swVersion": "0.5.12",
      "isExperimental": false,
      "isUpgradeable": false,
      "isSparePart": false,
      "softwareVarient": 2,
      "softwareSubVarient": 0
    },
    {
      "buildNumber": 2303132,
      "compVersion": 1,
      "ecuId": 2,
      "ecuName": "CCU",
      "id": 2,
      "swVersion": "0.5.12",
      "isExperimental": false,
      "isUpgradeable": false,
      "isSparePart": false,
      "softwareVarient": 2,
      "softwareSubVarient": 0
    },
    {
      "buildNumber": 2303131,
      "compVersion": 1,
      "ecuId": 1,
      "ecuName": "TCU",
      "id": 1,
      "swVersion": "0.5.12",
      "isExperimental": false,
      "isUpgradeable": false,
      "isSparePart": false,
      "softwareVarient": 2,
      "softwareSubVarient": 0
    }
  ],
  "lastName": "Boström",
  "last_changed": new Date("2023-04-05T07:35:15.107Z"),
  "latestCcuConnection": "278872",
  "latestConnection": {
    "address": "231132",
    "bluetoothConnectors": [
      "CC:F9:57:98:7B:3E"
    ],
    "ccuAddress": "278872",
    "currentConnectedBluetoothChip": "CC:F9:57:98:7B:3E",
    "lastConnected": new Date("2023-03-14T10:54:07.565Z"),
    "name": "Rototilt Display Device 3800",
    "status": "connected",
    "tcuAddress": "231132", 
   
  },
  "latestTcuConnection": "231132",
  "phoneNumber": "0725138145",
  "photoUrl": "",
  "role": "operator",
  "state": "offline"
};

export const remoteRequest$: IRequest = {
  serviceUserUid: 'wGHjMoQLSagI5mF125DXU2kW2mV2',
  response: true,
  responded: true,
  requestDate: new Date('2021-01-19T12:26:33.599Z'),
  responseDate: new Date('2021-01-19T12:26:38.618Z'),
  serviceUserName: 'Service Technician',
  type: 'remote-support',
};

export const alarms: IAlarm[] = [
  {
    "Name": "1022",
    "alarmInfoList": {
      "AckAlarm": false,
      "ActiveAlarm": true
    },
    "comment": "Quick Coupler sensor disabled",
    "ecuId": 1,
    "errorCodeId": 1022,
    "id": 1022,
    "isOldAlarm": true,
    "timeGenerated": new Date()
  },
  {
    "Name": "1027",
    "alarmInfoList": {
      "AckAlarm": false,
      "ActiveAlarm": true
    },
    "comment": "Quick Coupler blocked until restart",
    "ecuId": 1,
    "errorCodeId": 1027,
    "id": 1027,
    "isOldAlarm": true,
    "timeGenerated": new Date()
  },
{
    "Name": "2004",
    "alarmInfoList": {
      "AckAlarm": false,
      "ActiveAlarm": true
    },
    "comment": "Mismatching parameter pair",
    "ecuId": 2,
    "errorCodeId": 2004,
    "id": 2004,
    "isOldAlarm": true,
    "timeGenerated": new Date()
  },
  {
    "Name": "2031",
    "alarmInfoList": {
      "AckAlarm": false,
      "ActiveAlarm": true
    },
    "comment": "Safety lever activated at start",
    "ecuId": 2,
    "errorCodeId": 2031,
    "id": 2031,
    "isOldAlarm": true,
    "timeGenerated": new Date()
  },
  {
    "Name": "3126",
    "alarmInfoList": {
      "AckAlarm": false,
      "ActiveAlarm": true
    },
    "comment": "Foregin Voltage, PWM 06",
    "ecuId": 3,
    "errorCodeId": 3126,
    "id": 3126,
    "isOldAlarm": true,
    "timeGenerated": new Date()
  }
]

export const hmiDevice: IHardwareVersion = {
  "address": "2293800",
  "buildNumber": 2304251,
  "compVersion": 1,
  "ecuId": 6,
  "ecuName": "HMI",
  "id": 6,
  "installationDate": new Date(),
  "isSparePart": false,
  "lastConnected": new Date(),
  "swVersion": "0.5.13",
  "isExperimental": false,
  "isUpgradeable": false,
  "softwareVarient": 2,
  "softwareSubVarient": 0
}

export const ccuDevice: IHardwareVersion = {
  "address": "278872",
  "buildNumber": 2304281,
  "compVersion": 1,
  "ecuId": 2,
  "ecuName": "CCU",
  "id": 2,
  "installationDate": new Date(),
  "isSparePart": false,
  "lastConnected": new Date(),
  "swVersion": "0.5.15",
  "isExperimental": false,
  "isUpgradeable": false,
  "softwareVarient": 2,
  "softwareSubVarient": 0
}

export const tcuDevice: IHardwareVersion = {
  "address": "231132",
  "buildNumber": 2304281,
  "compVersion": 1,
  "ecuId": 1,
  "ecuName": "TCU",
  "id": 1,
  "installationDate": new Date(),
  "isSparePart": false,
  "lastConnected": new Date(),
  "swVersion": "0.5.15",
  "isExperimental": false,
  "isUpgradeable": false,
  "softwareVarient": 2,
  "softwareSubVarient": 0
}

export const parameters: IParameterData[] = [
    {
      "ecuId": 2,
      "id": 2137,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Machine serial number",
        "dataType": "U32",
        "defaultValue": 0,
        "maxValue": 999999999,
        "minValue": 0,
        "name": "MACHINE_SERNO",
        "offset": 0,
        "parameterId": 2137,
        "scale": 1,
        "unit": "-",
        "value": 0
      },
      "parameterId": 2137,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 278872
    },
    {
      "ecuId": 2,
      "id": 2008,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RO",
        "comment": "Serial number of HW (eeprom info, set in production)",
        "dataType": "U32",
        "name": "HW_PRODUCTION_SERIAL_NO",
        "offset": 0,
        "parameterId": 2008,
        "scale": 1,
        "unit": "-",
        "value": 0
      },
      "parameterId": 2008,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2031,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Flow calibration value for this function.",
        "dataType": "U16",
        "defaultValue": 400,
        "maxValue": 700,
        "minValue": 0,
        "name": "CALIBR_FEEDER_TILT_dP",
        "offset": 0,
        "parameterId": 2031,
        "scale": 0.1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2031,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 560
    },
    {
      "ecuId": 2,
      "id": 2032,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Flow calibration value for this function.",
        "dataType": "U16",
        "defaultValue": 500,
        "maxValue": 900,
        "minValue": 0,
        "name": "CALIBR_FEEDER_EXTRA1_dP",
        "offset": 0,
        "parameterId": 2032,
        "scale": 0.1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2032,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 600
    },
    {
      "ecuId": 2,
      "id": 2033,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Flow calibration value for this function.",
        "dataType": "U16",
        "defaultValue": 500,
        "maxValue": 900,
        "minValue": 0,
        "name": "CALIBR_FEEDER_EXTRA2_dP",
        "offset": 0,
        "parameterId": 2033,
        "scale": 0.1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2033,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 590
    },
    {
      "ecuId": 2,
      "id": 2134,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "The stored position of the wedge in which the tool is correctly locked.",
        "dataType": "U16",
        "defaultValue": 675,
        "maxValue": 995,
        "minValue": 5,
        "name": "MACHINE_LOCK_POSITION_dP",
        "offset": 0,
        "parameterId": 2134,
        "scale": 0.1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2134,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 660
    },
    {
      "ecuId": 2,
      "id": 2135,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Tolerance band for the locked position.",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 150,
        "minValue": 50,
        "name": "MACHINE_LOCK_UPPER_DEAD_BAND_dP",
        "offset": 0,
        "parameterId": 2135,
        "scale": 0.1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2135,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 90
    },
    {
      "ecuId": 2,
      "id": 2136,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Tolerance band for the locked position.",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 150,
        "minValue": 50,
        "name": "MACHINE_LOCK_LOWER_DEAD_BAND_dP",
        "offset": 0,
        "parameterId": 2136,
        "scale": 0.1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2136,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 150
    },
    {
      "ecuId": 2,
      "id": 2051,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder start current ",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2999,
        "minValue": 0,
        "name": "DOUBLE_FEEDER_NEG_START_CUR_mA",
        "offset": 0,
        "parameterId": 2051,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2051,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2052,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder max current ",
        "dataType": "U16",
        "defaultValue": 2100,
        "maxValue": 3000,
        "minValue": 1,
        "name": "DOUBLE_FEEDER_NEG_MAX_CUR_mA",
        "offset": 0,
        "parameterId": 2052,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2052,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2100
    },
    {
      "ecuId": 2,
      "id": 2053,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder ramp up",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "DOUBLE_FEEDER_NEG_RAMP_UP_10ms",
        "offset": 0,
        "parameterId": 2053,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2053,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2054,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder ramp down",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "DOUBLE_FEEDER_NEG_RAMP_DOWN_10ms",
        "offset": 0,
        "parameterId": 2054,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2054,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2047,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder start current ",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2999,
        "minValue": 0,
        "name": "DOUBLE_FEEDER_POS_START_CUR_mA",
        "offset": 0,
        "parameterId": 2047,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2047,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 46
    },
    {
      "ecuId": 2,
      "id": 2048,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder max current ",
        "dataType": "U16",
        "defaultValue": 2100,
        "maxValue": 3000,
        "minValue": 1,
        "name": "DOUBLE_FEEDER_POS_MAX_CUR_mA",
        "offset": 0,
        "parameterId": 2048,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2048,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2101
    },
    {
      "ecuId": 2,
      "id": 2049,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder ramp up",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "DOUBLE_FEEDER_POS_RAMP_UP_10ms",
        "offset": 0,
        "parameterId": 2049,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2049,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 40
    },
    {
      "ecuId": 2,
      "id": 2050,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Double feeder ramp down",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "DOUBLE_FEEDER_POS_RAMP_DOWN_10ms",
        "offset": 0,
        "parameterId": 2050,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2050,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2133,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Machine lock config",
        "dataType": "U16",
        "defaultValue": 1,
        "enumDefinition": {
          "enumFieldMap": {
            "0": "OFF",
            "1": "SL_NO_CTRL",
            "2": "SL_WITH_CTRL",
            "3": "SL_WITH_CTRL_AND_QC"
          },
          "enumName": "E_MACHINE_LOCK_CFG"
        },
        "maxValue": 3,
        "minValue": 0,
        "name": "MACHINE_LOCK_CFG",
        "offset": 0,
        "parameterId": 2133,
        "scale": 1,
        "unit": "Enum",
        "value": 0
      },
      "parameterId": 2133,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2113,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Handle machine dump valve",
        "dataType": "U16",
        "defaultValue": 0,
        "enumDefinition": {
          "enumFieldMap": {
            "0": "OFF",
            "1": "MACH_LOCK_CTRL",
            "2": "DIG_INPUT"
          },
          "enumName": "E_DUMP_VALVE_CFG"
        },
        "maxValue": 2,
        "minValue": 0,
        "name": "MACHINE_DUMP_VALVE_CFG",
        "offset": 0,
        "parameterId": 2113,
        "scale": 1,
        "unit": "Enum",
        "value": 0
      },
      "parameterId": 2113,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2115,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Set free return On/Off",
        "dataType": "U16",
        "defaultValue": 0,
        "enumDefinition": {
          "enumFieldMap": {
            "0": "OFF",
            "1": "ON"
          },
          "enumName": "E_ON_OFF"
        },
        "maxValue": 1,
        "minValue": 0,
        "name": "FREE_RETURN_ON_OFF",
        "offset": 0,
        "parameterId": 2115,
        "scale": 1,
        "unit": "On/Off",
        "value": 0
      },
      "parameterId": 2115,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2143,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x feeder start current ",
        "dataType": "U16",
        "defaultValue": 800,
        "maxValue": 2999,
        "minValue": 0,
        "name": "HCU_PWM_3_POS_START_CUR_mA",
        "offset": 0,
        "parameterId": 2143,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2143,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 800
    },
    {
      "ecuId": 2,
      "id": 2144,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x max current ",
        "dataType": "U16",
        "defaultValue": 2100,
        "maxValue": 3000,
        "minValue": 1,
        "name": "HCU_PWM_3_POS_MAX_CUR_mA",
        "offset": 0,
        "parameterId": 2144,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2144,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2100
    },
    {
      "ecuId": 2,
      "id": 2145,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp up",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_3_POS_RAMP_UP_10ms",
        "offset": 0,
        "parameterId": 2145,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2145,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2146,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp down",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_3_POS_RAMP_DOWN_10ms",
        "offset": 0,
        "parameterId": 2146,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2146,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2147,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x feeder start current ",
        "dataType": "U16",
        "defaultValue": 800,
        "maxValue": 2999,
        "minValue": 0,
        "name": "HCU_PWM_4_NEG_START_CUR_mA",
        "offset": 0,
        "parameterId": 2147,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2147,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 786
    },
    {
      "ecuId": 2,
      "id": 2148,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x max current ",
        "dataType": "U16",
        "defaultValue": 2100,
        "maxValue": 3000,
        "minValue": 1,
        "name": "HCU_PWM_4_NEG_MAX_CUR_mA",
        "offset": 0,
        "parameterId": 2148,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2148,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2100
    },
    {
      "ecuId": 2,
      "id": 2149,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp up",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_4_NEG_RAMP_UP_10ms",
        "offset": 0,
        "parameterId": 2149,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2149,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2150,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp down",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_4_NEG_RAMP_DOWN_10ms",
        "offset": 0,
        "parameterId": 2150,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2150,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2151,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x feeder start current ",
        "dataType": "U16",
        "defaultValue": 800,
        "maxValue": 2999,
        "minValue": 0,
        "name": "HCU_PWM_5_POS_START_CUR_mA",
        "offset": 0,
        "parameterId": 2151,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2151,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 800
    },
    {
      "ecuId": 2,
      "id": 2152,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x max current ",
        "dataType": "U16",
        "defaultValue": 2100,
        "maxValue": 3000,
        "minValue": 1,
        "name": "HCU_PWM_5_POS_MAX_CUR_mA",
        "offset": 0,
        "parameterId": 2152,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2152,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2097
    },
    {
      "ecuId": 2,
      "id": 2153,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp up",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_5_POS_RAMP_UP_10ms",
        "offset": 0,
        "parameterId": 2153,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2153,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2154,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp down",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_5_POS_RAMP_DOWN_10ms",
        "offset": 0,
        "parameterId": 2154,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2154,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 30
    },
    {
      "ecuId": 2,
      "id": 2155,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x feeder start current ",
        "dataType": "U16",
        "defaultValue": 800,
        "maxValue": 2999,
        "minValue": 0,
        "name": "HCU_PWM_6_NEG_START_CUR_mA",
        "offset": 0,
        "parameterId": 2155,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2155,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 800
    },
    {
      "ecuId": 2,
      "id": 2156,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x max current ",
        "dataType": "U16",
        "defaultValue": 2100,
        "maxValue": 3000,
        "minValue": 1,
        "name": "HCU_PWM_6_NEG_MAX_CUR_mA",
        "offset": 0,
        "parameterId": 2156,
        "scale": 1,
        "unit": "mA",
        "value": 0
      },
      "parameterId": 2156,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2100
    },
    {
      "ecuId": 2,
      "id": 2157,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp up",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_6_NEG_RAMP_UP_10ms",
        "offset": 0,
        "parameterId": 2157,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2157,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1
    },
    {
      "ecuId": 2,
      "id": 2158,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "HCU PWM x  ramp down",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "HCU_PWM_6_NEG_RAMP_DOWN_10ms",
        "offset": 0,
        "parameterId": 2158,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2158,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2
    },
    {
      "ecuId": 2,
      "id": 2035,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Feeder ramp",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "CALIBR_FEEDER_ACCELERATION_10ms",
        "offset": 0,
        "parameterId": 2035,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2035,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 20
    },
    {
      "ecuId": 2,
      "id": 2036,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Feeder ramp",
        "dataType": "U16",
        "defaultValue": 30,
        "maxValue": 100,
        "minValue": 0,
        "name": "CALIBR_FEEDER_DECELERATION_10ms",
        "offset": 0,
        "parameterId": 2036,
        "scale": 10,
        "unit": "ms",
        "value": 0
      },
      "parameterId": 2036,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 20
    },
    {
      "ecuId": 2,
      "id": 2021,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 0%",
        "dataType": "U16",
        "defaultValue": 15,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT1_dl",
        "offset": 0,
        "parameterId": 2021,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2021,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 3
    },
    {
      "ecuId": 2,
      "id": 2022,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 12.5%",
        "dataType": "U16",
        "defaultValue": 88,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT2_dl",
        "offset": 0,
        "parameterId": 2022,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2022,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 70
    },
    {
      "ecuId": 2,
      "id": 2023,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 25.0%",
        "dataType": "U16",
        "defaultValue": 161,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT3_dl",
        "offset": 0,
        "parameterId": 2023,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2023,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 203
    },
    {
      "ecuId": 2,
      "id": 2024,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 37.5%",
        "dataType": "U16",
        "defaultValue": 234,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT4_dl",
        "offset": 0,
        "parameterId": 2024,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2024,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 403
    },
    {
      "ecuId": 2,
      "id": 2025,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 50.0%",
        "dataType": "U16",
        "defaultValue": 307,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT5_dl",
        "offset": 0,
        "parameterId": 2025,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2025,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 603
    },
    {
      "ecuId": 2,
      "id": 2026,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 62.5%",
        "dataType": "U16",
        "defaultValue": 380,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT6_dl",
        "offset": 0,
        "parameterId": 2026,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2026,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 937
    },
    {
      "ecuId": 2,
      "id": 2027,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 75.0%",
        "dataType": "U16",
        "defaultValue": 453,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT7_dl",
        "offset": 0,
        "parameterId": 2027,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2027,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1270
    },
    {
      "ecuId": 2,
      "id": 2028,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 87.5%",
        "dataType": "U16",
        "defaultValue": 526,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT8_dl",
        "offset": 0,
        "parameterId": 2028,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2028,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1670
    },
    {
      "ecuId": 2,
      "id": 2029,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Calibrated feeder curve @ 100%",
        "dataType": "U16",
        "defaultValue": 600,
        "maxValue": 2000,
        "minValue": 0,
        "name": "LINEARIZATION_PT9_dl",
        "offset": 0,
        "parameterId": 2029,
        "scale": 0.1,
        "unit": "l",
        "value": 0
      },
      "parameterId": 2029,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1733
    },
    {
      "ecuId": 2,
      "id": 2044,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "",
        "dataType": "U16",
        "defaultValue": 1,
        "enumDefinition": {
          "enumFieldMap": {
            "1": "HCU",
            "2": "CAC1_1",
            "3": "CAC1_2",
            "4": "SMART_CONNECT",
            "5": "KOBELCO"
          },
          "enumName": "E_FEEDER_OUT_CFG"
        },
        "maxValue": 5,
        "minValue": 1,
        "name": "FEEDER_OUTPUT_CONFIG",
        "offset": 0,
        "parameterId": 2044,
        "scale": 1,
        "unit": "Enum",
        "value": 0
      },
      "parameterId": 2044,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1
    },
    {
      "ecuId": 2,
      "id": 2055,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC1,2 Feeder lower offset",
        "dataType": "U16",
        "defaultValue": 20,
        "maxValue": 40,
        "minValue": 0,
        "name": "CAC_FEEDER_LOWER_OFFSET_P",
        "offset": 0,
        "parameterId": 2055,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2055,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 20
    },
    {
      "ecuId": 2,
      "id": 2056,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC1,2 Feeder upper offset",
        "dataType": "U16",
        "defaultValue": 80,
        "maxValue": 100,
        "minValue": 60,
        "name": "CAC_FEEDER_UPPER_OFFSET_P",
        "offset": 0,
        "parameterId": 2056,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2056,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 80
    },
    {
      "ecuId": 2,
      "id": 2066,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of rotator rotation to handle",
        "dataType": "U16",
        "defaultValue": 1,
        "maxValue": 2047,
        "minValue": 0,
        "name": "ROTATOR_ROTATION_HANDLE",
        "offset": 0,
        "parameterId": 2066,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2066,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 5
    },
    {
      "ecuId": 2,
      "id": 2067,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of rotator tilt to handle",
        "dataType": "U16",
        "defaultValue": 5,
        "maxValue": 2047,
        "minValue": 0,
        "name": "ROTATOR_TILT_HANDLE",
        "offset": 0,
        "parameterId": 2067,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2067,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1
    },
    {
      "ecuId": 2,
      "id": 2068,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of rotator extra1 to handle",
        "dataType": "U16",
        "defaultValue": 6,
        "maxValue": 2047,
        "minValue": 0,
        "name": "ROTATOR_EXTRA1_HANDLE",
        "offset": 0,
        "parameterId": 2068,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2068,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 6
    },
    {
      "ecuId": 2,
      "id": 2069,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of rotator extra2 to handle",
        "dataType": "U16",
        "defaultValue": 2,
        "maxValue": 2047,
        "minValue": 0,
        "name": "ROTATOR_EXTRA2_HANDLE",
        "offset": 0,
        "parameterId": 2069,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2069,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2
    },
    {
      "ecuId": 2,
      "id": 2070,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of CAC1_1 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "CAC1_1_HANDLE",
        "offset": 0,
        "parameterId": 2070,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2070,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2071,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of CAC1_2 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "CAC1_2_HANDLE",
        "offset": 0,
        "parameterId": 2071,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2071,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2072,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of CAC2_1 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "CAC2_1_HANDLE",
        "offset": 0,
        "parameterId": 2072,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2072,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2073,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of CAC2_2 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "CAC2_2_HANDLE",
        "offset": 0,
        "parameterId": 2073,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2073,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2074,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of HCU_PWM_1_2 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "HCU_PWM_1_2_HANDLE",
        "offset": 0,
        "parameterId": 2074,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2074,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 773
    },
    {
      "ecuId": 2,
      "id": 2075,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of HCU_PWM_3_4 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "HCU_PWM_3_4_HANDLE",
        "offset": 0,
        "parameterId": 2075,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2075,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2076,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of HCU_PWM_5_6 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "HCU_PWM_5_6_HANDLE",
        "offset": 0,
        "parameterId": 2076,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2076,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2077,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of pulse funktion  to button",
        "dataType": "U16",
        "defaultValue": 16,
        "maxValue": 2047,
        "minValue": 0,
        "name": "PULSE_BUTTON",
        "offset": 0,
        "parameterId": 2077,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2077,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 16
    },
    {
      "ecuId": 2,
      "id": 2078,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of hold funktion  to button",
        "dataType": "U16",
        "defaultValue": 24,
        "maxValue": 2047,
        "minValue": 0,
        "name": "HOLD_BUTTON",
        "offset": 0,
        "parameterId": 2078,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2078,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 24
    },
    {
      "ecuId": 2,
      "id": 2079,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of crawler 1 to handler",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "CRAWLER_1_HANDLE",
        "offset": 0,
        "parameterId": 2079,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2079,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2080,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of crawler 2 to handler",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "CRAWLER_2_HANDLE",
        "offset": 0,
        "parameterId": 2080,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2080,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2081,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Mapping of rotator extra3 to handle",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 2047,
        "minValue": 0,
        "name": "ROTATOR_EXTRA3_HANDLE",
        "offset": 0,
        "parameterId": 2081,
        "scale": 1,
        "value": 0
      },
      "parameterId": 2081,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 3
    },
    {
      "ecuId": 2,
      "id": 2065,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Configuration of joystick in system",
        "dataType": "U16",
        "defaultValue": 1,
        "enumDefinition": {
          "enumFieldMap": {
            "1": "HERCULES",
            "2": "ANALOG_COMPL",
            "3": "ANALOG_SINGLE",
            "4": "PWM_COMPL",
            "5": "PWM_SINGLE",
            "6": "CAN"
          },
          "enumName": "E_SYSTEM_INPUT_CFG"
        },
        "maxValue": 6,
        "minValue": 1,
        "name": "SYSTEM_INPUT_CONFIG",
        "offset": 0,
        "parameterId": 2065,
        "scale": 1,
        "unit": "Enum",
        "value": 0
      },
      "parameterId": 2065,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1
    },
    {
      "ecuId": 2,
      "id": 2160,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Roller/switch configuration on the left joystick handle",
        "dataType": "U16",
        "defaultValue": 1,
        "enumDefinition": {
          "enumFieldMap": {
            "1": "HERCULES_3R_8S",
            "2": "HERCULES_4R_6S",
            "3": "HERCULES_2R_8S",
            "4": "L8_3R_5S",
            "5": "L8_2R_7S"
          },
          "enumName": "E_HANDLE_TYPE"
        },
        "maxValue": 5,
        "minValue": 1,
        "name": "HANDLE_TYPE_LEFT",
        "offset": 0,
        "parameterId": 2160,
        "scale": 1,
        "unit": "Enum",
        "value": 0
      },
      "parameterId": 2160,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1
    },
    {
      "ecuId": 2,
      "id": 2161,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Roller/switch configuration on the right joystick handle",
        "dataType": "U16",
        "defaultValue": 1,
        "enumDefinition": {
          "enumFieldMap": {
            "1": "HERCULES_3R_8S",
            "2": "HERCULES_4R_6S",
            "3": "HERCULES_2R_8S",
            "4": "L8_3R_5S",
            "5": "L8_2R_7S"
          },
          "enumName": "E_HANDLE_TYPE"
        },
        "maxValue": 5,
        "minValue": 1,
        "name": "HANDLE_TYPE_RIGHT",
        "offset": 0,
        "parameterId": 2161,
        "scale": 1,
        "unit": "Enum",
        "value": 0
      },
      "parameterId": 2161,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1
    },
    {
      "ecuId": 2,
      "id": 2162,
      "parameterId": 2162,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2057,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC1A Feeder dedband positive (upper) side ",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC1A_FEEDER_POS_DEADBAND_P",
        "offset": 0,
        "parameterId": 2057,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2057,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2058,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC1A Feeder dedband positive (lower)side",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC1A_FEEDER_NEG_DEADBAND_P",
        "offset": 0,
        "parameterId": 2058,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2058,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2059,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC1B Feeder dedband positive (upper) side ",
        "dataType": "U16",
        "defaultValue": 5,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC1B_FEEDER_POS_DEADBAND_P",
        "offset": 0,
        "parameterId": 2059,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2059,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 5
    },
    {
      "ecuId": 2,
      "id": 2060,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC1B Feeder dedband positive (lower)side",
        "dataType": "U16",
        "defaultValue": 5,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC1B_FEEDER_NEG_DEADBAND_P",
        "offset": 0,
        "parameterId": 2060,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2060,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 5
    },
    {
      "ecuId": 2,
      "id": 2163,
      "parameterId": 2163,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 20
    },
    {
      "ecuId": 2,
      "id": 2164,
      "parameterId": 2164,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 80
    },
    {
      "ecuId": 2,
      "id": 2165,
      "parameterId": 2165,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 20
    },
    {
      "ecuId": 2,
      "id": 2166,
      "parameterId": 2166,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 80
    },
    {
      "ecuId": 2,
      "id": 2061,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC2A Feeder dedband positive (upper) side ",
        "dataType": "U16",
        "defaultValue": 5,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC2A_FEEDER_POS_DEADBAND_P",
        "offset": 0,
        "parameterId": 2061,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2061,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 5
    },
    {
      "ecuId": 2,
      "id": 2062,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC2A Feeder dedband positive (lower)side",
        "dataType": "U16",
        "defaultValue": 5,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC2A_FEEDER_NEG_DEADBAND_P",
        "offset": 0,
        "parameterId": 2062,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2062,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 5
    },
    {
      "ecuId": 2,
      "id": 2063,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC2B Feeder dedband positive (upper) side ",
        "dataType": "U16",
        "defaultValue": 5,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC2B_FEEDER_POS_DEADBAND_P",
        "offset": 0,
        "parameterId": 2063,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2063,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 5
    },
    {
      "ecuId": 2,
      "id": 2064,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "CAC2B Feeder dedband positive (lower)side",
        "dataType": "U16",
        "defaultValue": 5,
        "maxValue": 20,
        "minValue": 0,
        "name": "CAC2B_FEEDER_NEG_DEADBAND_P",
        "offset": 0,
        "parameterId": 2064,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2064,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 5
    },
    {
      "ecuId": 2,
      "id": 2167,
      "parameterId": 2167,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 20
    },
    {
      "ecuId": 2,
      "id": 2168,
      "parameterId": 2168,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 80
    },
    {
      "ecuId": 2,
      "id": 2169,
      "parameterId": 2169,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 20
    },
    {
      "ecuId": 2,
      "id": 2170,
      "parameterId": 2170,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 80
    },
    {
      "ecuId": 2,
      "id": 2085,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce General outputs (rotor tilt \ufffd), profile 1",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF1_GENERAL",
        "offset": 0,
        "parameterId": 2085,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2085,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2089,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce rotator output, profile 1",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF1_ROTOR",
        "offset": 0,
        "parameterId": 2089,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2089,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2093,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Tilt output, profile 1",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF1_TILT",
        "offset": 0,
        "parameterId": 2093,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2093,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 61
    },
    {
      "ecuId": 2,
      "id": 2097,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra1 output, profile 1",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF1_EXTRA1",
        "offset": 0,
        "parameterId": 2097,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2097,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 94
    },
    {
      "ecuId": 2,
      "id": 2101,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra2 output, profile 1",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF1_EXTRA2",
        "offset": 0,
        "parameterId": 2101,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2101,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 82
    },
    {
      "ecuId": 2,
      "id": 2105,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Double feeder flow, profile 1",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF1_DFEEDER",
        "offset": 0,
        "parameterId": 2105,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2105,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 87
    },
    {
      "ecuId": 2,
      "id": 2109,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Invert dir on roller: bit0=Rotation, bit1=Tilt;    Switch roller Rotor/Tilt bit 6",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 65535,
        "minValue": 0,
        "name": "ROLLER_CFG_PF1",
        "offset": 0,
        "parameterId": 2109,
        "scale": 1,
        "unit": "Bit",
        "value": 0
      },
      "parameterId": 2109,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 65
    },
    {
      "ecuId": 2,
      "id": 2086,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce General outputs (rotor tilt \ufffd), profile 2",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF2_GENERAL",
        "offset": 0,
        "parameterId": 2086,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2086,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2090,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce rotator output, profile 2",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF2_ROTOR",
        "offset": 0,
        "parameterId": 2090,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2090,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2094,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Tilt output, profile 2",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF2_TILT",
        "offset": 0,
        "parameterId": 2094,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2094,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 45
    },
    {
      "ecuId": 2,
      "id": 2098,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra1 output, profile 2",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF2_EXTRA1",
        "offset": 0,
        "parameterId": 2098,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2098,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2102,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra2 output, profile 2",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF2_EXTRA2",
        "offset": 0,
        "parameterId": 2102,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2102,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2106,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Double feeder flow, profile 2",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF2_DFEEDER",
        "offset": 0,
        "parameterId": 2106,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2106,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2110,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Invert dir on roller: bit0=Rotation, bit1=Tilt;    Switch roller Rotor/Tilt bit 6",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 65535,
        "minValue": 0,
        "name": "ROLLER_CFG_PF2",
        "offset": 0,
        "parameterId": 2110,
        "scale": 1,
        "unit": "Bit",
        "value": 0
      },
      "parameterId": 2110,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 0
    },
    {
      "ecuId": 2,
      "id": 2087,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce General outputs (rotor tilt \ufffd), profile 3",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF3_GENERAL",
        "offset": 0,
        "parameterId": 2087,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2087,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 99
    },
    {
      "ecuId": 2,
      "id": 2091,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce rotator output, profile 3",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF3_ROTOR",
        "offset": 0,
        "parameterId": 2091,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2091,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 90
    },
    {
      "ecuId": 2,
      "id": 2095,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Tilt output, profile 3",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF3_TILT",
        "offset": 0,
        "parameterId": 2095,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2095,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 74
    },
    {
      "ecuId": 2,
      "id": 2099,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra1 output, profile 3",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF3_EXTRA1",
        "offset": 0,
        "parameterId": 2099,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2099,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 42
    },
    {
      "ecuId": 2,
      "id": 2103,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra2 output, profile 3",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF3_EXTRA2",
        "offset": 0,
        "parameterId": 2103,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2103,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 77
    },
    {
      "ecuId": 2,
      "id": 2107,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Double feeder flow, profile 3",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF3_DFEEDER",
        "offset": 0,
        "parameterId": 2107,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2107,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2111,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Invert dir on roller: bit0=Rotation, bit1=Tilt;    Switch roller Rotor/Tilt bit 6",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 65535,
        "minValue": 0,
        "name": "ROLLER_CFG_PF3",
        "offset": 0,
        "parameterId": 2111,
        "scale": 1,
        "unit": "Bit",
        "value": 0
      },
      "parameterId": 2111,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2
    },
    {
      "ecuId": 2,
      "id": 2088,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce General outputs (rotor tilt \ufffd), profile 4",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF4_GENERAL",
        "offset": 0,
        "parameterId": 2088,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2088,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 75
    },
    {
      "ecuId": 2,
      "id": 2092,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce rotator output, profile 4",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF4_ROTOR",
        "offset": 0,
        "parameterId": 2092,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2092,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 62
    },
    {
      "ecuId": 2,
      "id": 2096,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Tilt output, profile 4",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF4_TILT",
        "offset": 0,
        "parameterId": 2096,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2096,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 47
    },
    {
      "ecuId": 2,
      "id": 2100,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra1 output, profile 4",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF4_EXTRA1",
        "offset": 0,
        "parameterId": 2100,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2100,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2104,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Extra2 output, profile 4",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF4_EXTRA2",
        "offset": 0,
        "parameterId": 2104,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2104,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2108,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Reduce Double feeder flow, profile 4",
        "dataType": "U16",
        "defaultValue": 100,
        "maxValue": 100,
        "minValue": 20,
        "name": "SPEED_REDUCE_PF4_DFEEDER",
        "offset": 0,
        "parameterId": 2108,
        "scale": 1,
        "unit": "%",
        "value": 0
      },
      "parameterId": 2108,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 100
    },
    {
      "ecuId": 2,
      "id": 2112,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Invert dir on roller: bit0=Rotation, bit1=Tilt;    Switch roller Rotor/Tilt bit 6",
        "dataType": "U16",
        "defaultValue": 0,
        "maxValue": 65535,
        "minValue": 0,
        "name": "ROLLER_CFG_PF4",
        "offset": 0,
        "parameterId": 2112,
        "scale": 1,
        "unit": "Bit",
        "value": 0
      },
      "parameterId": 2112,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 2
    },
    {
      "ecuId": 2,
      "id": 2084,
      "parameter": {
        "_ecuId": 2,
        "accessType": "RW",
        "comment": "Selected profile",
        "dataType": "U16",
        "defaultValue": 0,
        "enumDefinition": {
          "enumFieldMap": {
            "0": "PROFILE_0",
            "1": "PROFILE_1",
            "2": "PROFILE_2",
            "3": "PROFILE_3",
            "4": "PROFILE_4"
          },
          "enumName": "E_PROFILE_SELECT"
        },
        "maxValue": 4,
        "minValue": 0,
        "name": "PROFILE_SELECT",
        "offset": 0,
        "parameterId": 2084,
        "scale": 1,
        "unit": "Enum",
        "value": 0
      },
      "parameterId": 2084,
      "responseType": "ParameterChanged",
      "status": 0,
      "value": 1
    }
]

