import { Type } from 'class-transformer';
import { ECU_ID } from '../CAN_Messages/CanEnums';

// REMOVE: Varför enum? Vad om enhet inte finns specad här? Bättre läsa dynamiskt från def.fil
// export enum ParamUnit {
//   NONE = '-',
//   MA = 'mA',
//   MS = 'ms',
//   S = 's',
//   PERCENT = '%',
//   DEG = 'deg',
//   BIT = 'bit',
//   PER_TIME_S = 'per time s',
//   S_REV = 's/rev',
//   PULS_REV = 'pul/rev',
//   HYST = 'Hysteresis',
//   HOURS = 'h',
//   LITERS = 'l',
//   VOLT = 'V',
//   ENUM = 'Enum'
// }

export enum AccessType {
  READ = 'R',
  WRITE = 'W',
  READ_ONLY = 'RO',
  READ_WRITE = 'RW',
}

export enum DType {
  U8 = 'U8',
  S8 = 'S8',
  U16 = 'U16',
  S16 = 'S16',
  U32 = 'U32',
  S32 = 'S32',
  STR='STR'
}

export class Parameter {
  private name:string;
  private parameterId: number;
  private minValue?: number;
  private defaultValue?: number;
  private maxValue?: number;
  private scale: number;
  private offset: number;
  private value: number;
  private unit?: string;
  private accessType: AccessType = AccessType.READ;
  private dataType: DType = DType.U8;
  private comment:string;
  private _ecuId: number = ECU_ID.UNDEFINED;
  
  constructor() {
    this.name = "";
    this.parameterId = 0;
    this.scale = 0;
    this.offset = 0;
    this.value = 0;
    this.comment = "";
  }
  
  get Name():string{
    return this.name;
  }
  
  set Name(name:string){
    this.name = name;
  }
  
  get Comment(): string {
    return this.comment;
  }
  
  set Comment(comment: string) {
    this.comment = comment;
  }
  
  get ParameterId(): number {
    return this.parameterId;
  }
  
  set ParameterId(paramId: number) {
    this.parameterId = paramId;
  }
  
  get Min(): number | undefined {
    return this.minValue;
  }
  
  set Min(val: number | undefined) {
    this.minValue = val;
  }
  
  get Max(): number | undefined {
    return this.maxValue;
  }
  
  set Max(val: number | undefined) {
    this.maxValue = val;
  }
  
  get Default(): number | undefined {
    return this.defaultValue;
  }
  
  set Default(val: number | undefined) {
    this.defaultValue = val;
  }
  
  get Scale(): number {
    return this.scale;
  }
  
  set Scale(val: number) {
    this.scale = val;
  }
  
  get Offset(): number {
    return this.offset;
  }
  
  set Offset(val: number) {
    this.offset = val;
  }
  
  get Unit(): string | undefined{
    return this.unit;
  }
  
  set Unit(val: string | undefined) {
    this.unit = val;
  }
  
  get DataType(): DType {
    return this.dataType;
  }
  
  set DataType(val: DType) {
    this.dataType = val;
  }
  
  get Access(): AccessType {
    return this.accessType;
  }
  
  set Access(val: AccessType) {
    this.accessType = val;
  }
  
  set Value(val: number) {
    this.value = val;
  }
  
  get Value(): number{
    return this.value;
  }

  public get ecuId(): number {
    return this._ecuId;
  }
  public set ecuId(value: number) {
    this._ecuId = value;
  }
}

export class PeriodicParameter {
  private name:string;
  private fid: number;
  private pgn:number;
  private startBit:number;
  private bitSize:number
  private minValue?: number;
  private defaultValue?: number;
  private maxValue?: number;
  private scale: number;
  private offset: number;
  private value: number;
  private unit?: string;
  private dataType: DType = DType.U32;
  private _ecuId: number = ECU_ID.UNDEFINED;
  
  constructor() {
    this.name = "";
    this.fid = 0;
    this.scale = 0;
    this.offset = 0;
    this.value = 0;
    this.pgn = 0;
    this.startBit = 0;
    this.bitSize = 0;
  }

  get Name():string{
    return this.name;
  }

  set Name(name:string){
    this.name = name;
  }

  get StartBit(): number {
    return this.startBit;
  }

  set StartBit(value: number) {
    this.startBit = value;
  }

  get BitSize(): number {
    return this.bitSize;
  }

  set BitSize(value: number) {
    this.bitSize = value;
  }

  get PGN(): number {
    return this.pgn;
  }

  set PGN(value: number) {
    this.pgn = value;
  }

  get FieldId(): number {
    return this.fid;
  }

  set FieldId(paramId: number) {
    this.fid = paramId;
  }

  get Min(): number | undefined {
    return this.minValue;
  }

  set Min(val: number | undefined) {
    this.minValue = val;
  }

  get Max(): number | undefined {
    return this.maxValue;
  }

  set Max(val: number | undefined) {
    this.maxValue = val;
  }

  get Default(): number | undefined {
    return this.defaultValue;
  }

  set Default(val: number | undefined) {
    this.defaultValue = val;
  }

  get Scale(): number {
    return this.scale;
  }

  set Scale(val: number) {
    this.scale = val;
  }

  get Offset(): number {
    return this.offset;
  }

  set Offset(val: number) {
    this.offset = val;
  }

  get Unit(): string | undefined {
    return this.unit;
  }

  set Unit(val: string | undefined) {
    this.unit = val;
  }

  set Value(val: number) {
    this.value = val;
  }

  get Value(): number{
    return this.value;
  }

  get DataType():DType{
    return this.dataType;
  }

  set  DataType(dataType:DType){
    this.dataType = dataType;
  }
  public get ecuId(): number {
    return this._ecuId;
  }
  public set ecuId(value: number) {
    this._ecuId = value;
  }
}

export class ParameterDefinition{

  @Type(() => Parameter)
  public parameterList: Parameter[];

  constructor() { 
    this.parameterList = [];
  }
  public printParameterData() {
      this.parameterList.forEach (x =>   
      console.log (x.ParameterId))
    
    }
}

export class PeriodicDataDefinition{
  @Type(() => PeriodicParameter)
  public periodicDataFieldList: PeriodicParameter[];

  constructor() { 
    this.periodicDataFieldList = [];
  }

  public printPeriodicData() {

  this.periodicDataFieldList.forEach (x =>   
    console.log (x.Name+" "+ x.FieldId))
  

  }

  
}


export class UnitDefinitionFile{
  // Q: Vad händer här?
  @Type(() => ParameterDefinition)
  public parameterDefinition: ParameterDefinition;

  @Type(() => PeriodicDataDefinition)
  public periodicDataDefinition:PeriodicDataDefinition;

  constructor(){
    this.parameterDefinition = new ParameterDefinition();
    this.periodicDataDefinition = new PeriodicDataDefinition();
  }

  
}