import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

//import { AngularFireStorage } from '@angular/fire/storage';
import { getStorage, ref, listAll, ListResult } from 'firebase/storage';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Network } from '@capacitor/network';
import { arrayUpsert } from '@datorama/akita';
import { environment } from '@env/environment';
import { IDefinition } from '@interfaces/definition.interface';
import { DefinitionsStore } from './definitions.store';
import { DefinitionsQuery } from './definitions.query';
//import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class DefinitionsService {
  public existingFiles: string[] = [];

  constructor(
    //private angularFireStorage: AngularFireStorage,
    private httpClient: HttpClient,
    private definitionsStore: DefinitionsStore,
    private definitionsQuery: DefinitionsQuery
  ) {
    this.existingFiles = [];
  }

  public async loadDefinitions(): Promise<void> {
    const existing = this.definitionsStore.getValue().unitConfigs;
    await this.updateExistingFiles();

    let firebaseStorageDefinitions: ListResult;

    await Network.getStatus().then(async (res) => {
      if (res.connected) {
        const storage = getStorage(); // Initialize Firebase Storage
        const storageRef = ref(storage, 'definitions/'); // Create a reference to 'definitions'
        firebaseStorageDefinitions = await listAll(storageRef); // List all items in the 'definitions' directory
        //firebaseStorageDefinitions = await this.angularFireStorage.ref('definitions').listAll().toPromise();
      }
    })
    //console.log (firebaseStorageDefinitions)
    //console.log(this.existingFiles)
    const newItems = firebaseStorageDefinitions?.items
      .filter((x) => !this.existingFiles?.includes(x.name))
      .map((x) => ({ name: x.name, fullPath: x.fullPath }));

      //console.log (newItems, "newItems")
    if (newItems && newItems?.length > 0) {
      const functionsObservable = newItems.map(
        (x) =>
          this.httpClient.get(environment.functions.storageDownload, {
            responseType: 'json',
            params: { path: x.fullPath },
          }) as Observable<IDefinition>
      );

      const jsonData = await forkJoin(functionsObservable).toPromise();
      //console.log(jsonData, "jsonData")
      for (const [index, data] of newItems.entries()) {
        this.saveNewDefinition(jsonData[index], data.fullPath);
      }
    }

    await this.setupDefinitionsState();
  }

  public async setupDefinitionsState(): Promise<void> {
    await this.updateExistingFiles();

    if (this.existingFiles) {
      //console.log(this.existingFiles)
      for (const [index, file] of this.existingFiles.entries()) {
        Filesystem.readFile({
          path: 'definitions/' + file,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        }).then((result) => {
          let newDefinition = {} as IDefinition;
          newDefinition = { ...JSON.parse(result.data as string), id: index };
          //console.log(newDefinition)
          var splittedFileName = file.split('.');
         // console.log(splittedFileName)
          const ecuId = parseInt(splittedFileName[0], 10);
          const compVersion = parseInt(splittedFileName[1], 10);
          const buildVersion = parseInt(splittedFileName[2], 10);

          let buildNumber;
          if (splittedFileName && splittedFileName.length > 3) {
            var splitted_ = splittedFileName[3].split('_');
            if (splitted_ && splitted_.length > 0) {
              buildNumber = parseInt(splitted_.reverse()[0], 10);
            }
          }
          
          if (isNaN(buildVersion)) {
            Filesystem.deleteFile({
              path: 'definitions/' + file,
              directory: Directory.Data
            }).then(res => {
              //console.log("Deleted file *" + file + "* from local storage");
              //console.log(res);
            }).catch(err => {
              //console.log("Could not delete file *" + file + "* from local storage");
              console.log(err);
            })
          }

          this.definitionsStore.update((state) => ({
            unitConfigs: [...state.unitConfigs, { ...newDefinition, ecuId, compVersion, buildVersion, buildNumber }],
          }));
        });
      }
    }
  }

  public async updateExistingFiles(): Promise<void> {
    await Filesystem.readdir({ directory: Directory.Data, path: 'definitions' })
      .then((readResult) => {
        //console.log('readResult definitions: ', readResult);
        this.existingFiles = readResult.files.map(x => x.name);

      })
      .catch((err) => {
        console.error(err);
        Filesystem.mkdir({ directory: Directory.Data, path: 'definitions' });
        this.updateExistingFiles();
      });
  }

  public async saveNewDefinition(definition, path: string) {

    //console.log (path)
    return await Filesystem.writeFile({
      path,
      recursive: true,
      data: JSON.stringify(definition),
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    });
  }

  public async addActiveUnitConfig(unitConfig): Promise<void> {
    this.definitionsStore.update((state) => ({
      activeUnitConfigs: arrayUpsert(state.activeUnitConfigs, unitConfig.id, unitConfig),
    }));
  }
}
