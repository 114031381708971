export class ValueConverter {
  static NumberToUint32(x: number): number {
    return x >>> 0;
  }

  static NumberToUint16(x: number): number {
    return this.NumberToUint32(x) & 0xffff;
  }

  static NumberToUint8(x: number): number {
    return this.NumberToUint32(x) & 0xff;
  }

  static NumberToInt32(x: number): number {
    return x >> 0;
  }

  static NumberToInt16(x: number): number {
    let r = 0;
    const n = this.NumberToUint16(x);
    if (n & 0x8000) {
      r = 0xffff8000 | (n & 0x7fff);
    } else {
      r = n;
    }
    return r;
  }

  static NumberToInt8(x: number): number {
    let r = 0;
    const n = this.NumberToUint8(x);
    if (n & 0x80) {
      r = 0xffffff80 | (n & 0x7f);
    } else {
      r = n;
    }
    return r;
  }

  static ArrayToU16(valArray: number[]): number {
    let val = 0;
    let i = 0;
    while (i < 2 && i < valArray.length) {
      val |= (valArray[i] & 0xff) << (i * 8);
      i++;
    }
    return val;
  }

  static ArraytoU16BigEndian(valArray: number[]): number {
    return this.ArrayToU16(valArray.slice(0, 2).reverse());
  }

  static ArraytoU32(valArray: number[]): number {
    let val = 0;
    let i = 0;
    while (i < 4 && i < valArray.length) {
      val |= (valArray[i] & 0xff) << (i * 8);
      i++;
    }
    return this.NumberToUint32(val);
  }

  static ArraytoU32BigEndian(valArray: number[]): number {
    return this.ArraytoU32(valArray.slice(0, 4).reverse());
  }

  static ArraytoS16(valArray: number[]): number {
    let val = 0;
    let i = 0;
    while (i < 2 && i < valArray.length) {
      val |= (valArray[i] & 0xff) << (i * 8);
      i++;
    }
    return this.NumberToInt16(val);
  }

  static ArraytoS16BigEndian(valArray: number[]): number {
    return this.ArraytoS16(valArray.slice(0, 2).reverse());
  }

  static ArraytoS32(valArray: number[]): number {
    let val = 0;
    let i = 0;
    while (i < 4 && i < valArray.length) {
      val |= (valArray[i] & 0xff) << (i * 8);
      i++;
    }

    return this.NumberToInt32(val);
  }

  static ArraytoS32BigEndian(valArray: number[]): number {
    return this.ArraytoS32(valArray.slice(0, 4).reverse());
  }

  static U32ToArray(val: number): number[] {
    const arr: number[] = [];
    arr.push(val & 0xff);
    arr.push((val >>> 8) & 0xff);
    arr.push((val >>> 16) & 0xff);
    arr.push(val >>> 24);
    return arr;
  }

  static U32ToArrayBigEndian(val: number): number[] {
    return this.U32ToArray(val).reverse();
  }

  static U16ToArray(val: number): number[] {
    const arr: number[] = [];
    arr.push(val & 0xff);
    arr.push(val >>> 8);
    return arr;
  }

  static U16ToArrayBigEndian(val: number): number[] {
    return this.U16ToArray(val).reverse();
  }

  static S32ToArray(val: number): number[] {
    const arr: number[] = [];
    arr.push(val & 0xff);
    arr.push((val >> 8) & 0xff);
    arr.push((val >> 16) & 0xff);
    arr.push((val >> 24) & 0xff);
    return arr;
  }

  static S32ToArrayBigEndian(val: number): number[] {
    return this.S32ToArray(val).reverse();
  }

  static S16ToArray(val: number): number[] {
    const arr: number[] = [];
    arr.push(val & 0xff);
    arr.push((val >> 8) & 0xff);
    return arr;
  }

  static S16ToArrayBigEndian(val: number): number[] {
    return this.S16ToArray(val).reverse();
  }
}
