import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  public showHideIcon = 'eye-off';
  public isPassword = false;

  @Input()
  public type: string;

  @Input()
  public labelText: string;

  @Input()
  public parentForm: UntypedFormGroup;

  @Input()
  public controlName: string;

  @Input()
  public mandatory: boolean;

  @Input()
  public disabled: boolean;

  ngOnInit(): void {
    this.isPassword = this.type === 'password';
  }

  public handleType() {
    if (this.type === 'password') {
      this.showHideIcon = 'eye';
      this.type = 'text';
    } else if (this.type === 'text') {
      this.showHideIcon = 'eye-off';
      this.type = 'password';
    }
  }
}
