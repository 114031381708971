import { Pipe, PipeTransform } from '@angular/core';
import { IUpgradeProgress } from '@shared/interfaces/firmware.interface';

@Pipe({
  name: 'upgradeProgress'
})
export class UpgradeProgressPipe implements PipeTransform {

  transform(value: IUpgradeProgress, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      return this.calcProgress(value, true);
    }

    return this.calcProgress(value);
  }

  private calcProgress(upgradeProgress: IUpgradeProgress, divide?: boolean) {
    var startProgress = (upgradeProgress.currentUpgradeIdx - 1) * 25

    if (divide) {
      return (startProgress + (upgradeProgress.progress * 100 / upgradeProgress.noOfUpgrades)) / 100;
    }

    return (startProgress + (upgradeProgress.progress * 100 / upgradeProgress.noOfUpgrades));
  }

}
