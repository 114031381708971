import { ResponseType } from 'connectapi/src/ApiMessage/apiResponseTypes';

import { IFunction } from '@interfaces/ble.interface';
import { IParameterList } from '@interfaces/definition.interface';
import { IParameterData, IPeriodicData } from '@interfaces/device.interface';

export const PERIODICDATA: IPeriodicData = {
  fieldId: 1010,
  id: 1010,
  value: 0,
  ecuId: 1,
  responseType: 'PeriodicDataChanged',
};

export const PARAMETERDATA: IParameterData = {
  ecuId: null,
  id: 0,
  parameterId: 0,
  responseType: ResponseType.ParamsChanged,
  value: 0,
  status: 0,
  parameter: {} as IParameterList,
};

export const BLEFUNCTIONS: IFunction[] = [
  {
    id: 2,
    title: 'Shake',
    icon: 'icon_shake.svg',
  },
  {
    id: 3,
    title: 'Auto Grade Ctrl',
    icon: 'icon_autograde.svg',
  },
  {
    id: 4,
    title: 'Home',
    icon: 'icon_home_B.svg',
  },
];
