import { Component, Input } from '@angular/core';
import { IHelpModal } from '@interfaces/help-modal.interface';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent {
  @Input()
  public content: IHelpModal;

  constructor(private modalService: ModalService) {}

  public closeModal() {
    this.modalService.closeHelpModal();
  }
}
