import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { I18nPipe } from '@pipes/i18n.pipe';

import { AuthService } from '@services/auth.service';
import { ModalService } from '@services/modal.service';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [I18nPipe],
})
export class ResetPasswordComponent {
  public resetPasswordForm: UntypedFormGroup;

  constructor(
    formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private modalService: ModalService,
    private router: Router,
    private i18nPipe: I18nPipe
  ) {
    this.resetPasswordForm = formBuilder.group({
      email: ['', Validators.required],
    });
  }

  public closeModal() {
    this.modalService.dismissModal('ResetPasswordComponent');
  }

  public resetPassword(): void {
    const email = this.resetPasswordForm.get('email').value;
    this.authService
      .sendPasswordResetEmail(email)
      .then(() => {
        this.toastService.successToast('auth.check your mail', true, 'auth.reset password');
        this.closeModal();
        this.router.navigate(['/']);
      })
      .catch((err) => {
        this.toastService.warningToast('auth.errors.' + err.code, true);
      });
  }
}
