import { IFooter } from '@interfaces/app.interface';

export const FOOTER: IFooter[] = [
  {
    id: 'start',
    label: 0,
    iconSrc: 'Home_Blue.png',
    routerLink: '/start',
    canAccess: true,
  },
  {
    id: 'alarm',
    label: 3,
    iconSrc: 'Warning_Blue.png',
    routerLink: '/alarm',
    canAccess: true,
  },
  {
    id: 'profile',
    label: 1,
    iconSrc: 'Profile_settings_Blue.png',
    routerLink: '/profile',
    canAccess: true,
  },
  {
    id: 'system',
    label: 0,
    iconSrc: 'System_info_Blue.png',
    routerLink: '/system',
    canAccess: true,
  },
];


export const FOOTERTCS: IFooter[] = [
  {
    id: 'start',
    label: 0,
    iconSrc: 'Home_Blue.png',
    routerLink: '/start',
    canAccess: true,
  },
  {
    id: 'alarm',
    label: 3,
    iconSrc: 'Warning_Blue.png',
    routerLink: '/alarm',
    canAccess: true,
  },
  
  {
    id: 'system',
    label: 0,
    iconSrc: 'System_info_Blue.png',
    routerLink: '/system',
    canAccess: true,
  },
];
