import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserQuery } from '@shared/state/user/user.query';
import { UserService } from '@shared/state/user/user.service';
import { take } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  public notMobileApp: boolean = false;
  public updateUserInformationForm: UntypedFormGroup;
  public loading = false;
  public errorMessage = '';

  public currentLanguage: string;
  public languages: string[];

  public currentUser$ = this.userQuery.currentUser$;
  public serviceToolHasBleConnection$ = this.userQuery.serviceToolHasBleConnection$

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userQuery: UserQuery,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
    private platform: Platform,
    private modalService: ModalService,

  ) { }

  ngOnInit(): void {
    this.languages = this.translateService.getLangs();
    this.currentLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });

    this.currentUser$.pipe(take(2)).subscribe(user => {
      this.updateUserInformationForm = this.formBuilder.group({
        firstName: [user.firstName, Validators.required],
        lastName: [user.lastName, Validators.required],
        email: [user.email, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        phoneNumber: [user.phoneNumber, Validators.required],
        company: [user.company, Validators.required],
        customerNumber: [user.customerNumber, Validators.required],
      });
    })
    this.checkPlatform()
  }

  public deleteUser() {
    this.userService.deleteUserAlert();
  }

  public updateUser() {
    setTimeout(() => {
      this.loading = false;
    }, 5000);
    this.loading = true;
    const data = this.updateUserInformationForm.value;
    this.userService.updateUser(data).then(res => {
      this.loading = false;
      this.errorMessage = '';
    }).catch(err => {
      this.loading = false;
      this.errorMessage = this.translateService.instant('auth.something went wrong');
    });
    
  }

  public changeLanguage(language: string): void {
    this.translateService.use(language);
    this.currentLanguage = language;
  }

  public goBack() {
    this.reRenderUserTable();
    this.router.navigate(['/start'])
  }

  public reRenderUserTable() {
    this.userService.reRenderDataTable(true);
    setTimeout(() => {
      this.userService.reRenderDataTable(false);
    }, 100);
  }

  private checkPlatform(): void {


    if (this.platform.is('capacitor')) {
      this.notMobileApp = false

    } else {

      this.notMobileApp = true

    }
  }

  public closeProfileModal() {
    this.modalService.closeSettingsModal();

  }
}
