// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-img-icon {
  height: 60px;
  width: 60px;
  margin-left: -20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN5c3RlbS1kZXRhaWxzLmNvbXBvbmVudC5zY3NzIiwiLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFxOZXclMjBmb2xkZXJcXGNvbm5lY3QtMy4wXFxzcmNcXGFwcFxcbGF5b3V0c1xcbWFpblxccGFnZXNcXHN5c3RlbS1kZXRhaWxzXFxzeXN0ZW0tZGV0YWlscy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFlBQUE7RUFDQSxXQUFBO0VBQ0Esa0JBQUE7QUNDSiIsImZpbGUiOiJzeXN0ZW0tZGV0YWlscy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pb24taW1nLWljb24ge1xyXG4gICAgaGVpZ2h0OiA2MHB4O1xyXG4gICAgd2lkdGg6IDYwcHg7XHJcbiAgICBtYXJnaW4tbGVmdDogLTIwcHg7XHJcbn0iLCIuaW9uLWltZy1pY29uIHtcbiAgaGVpZ2h0OiA2MHB4O1xuICB3aWR0aDogNjBweDtcbiAgbWFyZ2luLWxlZnQ6IC0yMHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layouts/main/pages/system-details/system-details.component.scss","webpack://./../../New%20folder/connect-3.0/src/app/layouts/main/pages/system-details/system-details.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;ACCJ;ADCA,gsBAAgsB","sourcesContent":[".ion-img-icon {\r\n    height: 60px;\r\n    width: 60px;\r\n    margin-left: -20px;\r\n}",".ion-img-icon {\n  height: 60px;\n  width: 60px;\n  margin-left: -20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
